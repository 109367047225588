import { useRef } from "react";
import './index.css'
import { ChevronDownIcon } from "@radix-ui/react-icons";


const processDataForCard = (visualData) => {
  const data = visualData.data;
  const propertyNames = Object.keys(data);

  const result = data[propertyNames[0]].map((_, index) => {
      const newObj = {};
      propertyNames.forEach(propertyName => {
          newObj[propertyName] = data[propertyName][index];
      });
      return newObj;
  });

  return { result };
};



export const AccordionItem = (props) => {
  const contentEl = useRef();
  const { handleToggle, active, item ,cardspec,val} = props;
  const { header, id, text } = item;

  let isText= cardspec.filter((item)=>{return item.is_primary===false&&item.is_boolean===false&&item.is_badge===false&&item.is_front===true})
  let isBoolean= cardspec.filter((item)=>{return item.is_boolean===true&&item.is_front===true})
  let isBadge= cardspec.filter((item)=>{return item.is_badge===true&&item.is_front===true})

  return (
    <div className="rc-accordion-card2">
      <div className="rc-accordion-header2">
        <div className={`rc-accordion-toggle2 p-3 py-2 ${active === id ? 'active text-white' : ''}`} onClick={() => handleToggle(id)}>
          <h5 className="rc-accordion-title2 text-sm sm:text-[14px]">{header}</h5>
          <ChevronDownIcon width={20} height={20} />
        </div>
      </div>
      <div ref={contentEl} className={`rc-collapse2 ${active === id ? 'show' : ''}`} style={
        active === id
          ? { height: contentEl.current.scrollHeight }
          : { height: "0px" }
      }>
        <div className="rc-accordion-body2">
          {/* <p className='mb-0'>{text}</p> */}
          {isText.map((front,frno)=>(
              <div key={frno} className={`w-full px-1 flex flex-row items-center justify-between`}>
                  {isText.length > 1 &&<div className={`text-zinc-600  font-normal font-montserrat leading-normal ${text}`}>{front.column}</div>}
                  <div className={`${isText.length > 1?'text-right':''} text-slate-800 font-normal font-montserrat leading-normal ${text}`}>{val[front.column]}</div>
              </div>
          ))}
          <div className="pl-1 pr-8 py-px rounded-[20px] justify-start items-center inline-flex">
              <div className="self-stretch justify-start items-start gap-1 inline-flex">
                  {isBadge?.map((badge,bno)=>(
                      <div key={bno} className="px-1.5 py-1  bg-indigo-50 rounded-md justify-center items-center flex">
                          <div className="text-slate-800 text-[8px] font-normal font-montserrat leading-tight">{val[badge.column]}</div>
                      </div>
                  ))}
              </div>
          </div>
          <div>
              {isBoolean?.map((bool,blno)=>(
                  <div  key={blno} className={`w-full px-1  flex flex-row items-center justify-between`}>
                      <div className={`${text} font-normal`}>{bool.column}</div>
                      {val[bool.column]?<div className="w-4 h-4 bg-emerald-500 rounded-full shadow-inner" />:<div className="w-4 h-4 bg-slate-200 rounded-full shadow-inner" />} 
                    </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}