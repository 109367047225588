import React, { useState } from 'react';

// const steps = {
//   label: ["Hello", "world", "Foo bar","text","monter"],
//   description: ["Bla bla bla", "Lorem ipsum jeghioe age", "ABC DEF EHIGHIEOGNH"],
// };

const Flywheel = ({ visualData }) => {
  const steps = visualData.data;
  const dimensionsLabel = visualData.spec.dimensions[0].column;
  const metricsLabel = visualData.spec.columns[1].column;

  const title = visualData.title;

  const [activeStep, setActiveStep] = useState(null);

  return (
    <div className="flex justify-between items-center p-8 bg-gray-900 text-purple-300 h-full w-full">
      <div className="w-1/2 relative">
        <div className="w-80 h-80 rounded-full border border-purple-500 relative ml-12">
          <div className="absolute inset-0 flex items-center justify-center">
            <span className="text-xl font-bold">{title}</span>
          </div>
          {steps[dimensionsLabel].map((label, index) => {
            const angle = (index * 2 * Math.PI) / steps[dimensionsLabel].length - Math.PI / 2;
            return (
              <div
                key={index}
                className={`absolute w-16 h-16 rounded-full bg-gray-800 flex items-center justify-center cursor-pointer transition-all duration-300 whitespace-nowrap ${
                  activeStep === index ? 'bg-purple-700' : ''
                }`}
                style={{
                  top: `${50 + 48 * Math.sin(angle)}%`,
                  left: `${50 + 48 * Math.cos(angle)}%`,
                  transform: 'translate(-50%, -50%)'
                }}
                onMouseEnter={() => setActiveStep(index)}
                onMouseLeave={() => setActiveStep(null)}>
                {label}
              </div>
            );
          })}
        </div>
      </div>
      <div className="w-1/2">
        {steps[dimensionsLabel].map((label, index) => (
          <div
            key={index}
            className={`mb-4 rounded-lg transition-all duration-300 flex items-center ${
              activeStep === index ? 'bg-gray-800' : 'bg-gray-900'
            }`}>
            <div className="p-5 rounded-full bg-gray-800 flex items-center justify-center mr-4">
              <div className="text-xl font-bold text-purple-500">{String(index + 1).padStart(2, '0')}</div>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-2">{label}</h3>
              <p className="text-sm text-gray-400">{steps[metricsLabel][index]}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Flywheel;
