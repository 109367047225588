import React,{useState,useRef} from 'react';
import Chart from '../Chart';
import * as echarts from 'echarts';
import { event } from 'react-nodemap';

const getVirtulData= (year, keyList, values)=> {
  year = year || "2017";
  let date = +echarts.number.parseDate(year + "-01-01");
  let end = +echarts.number.parseDate(+year + 1 + "-01-01");
  let dayTime = 3600 * 24 * 1000;
  let data = [];
  let total = 0;
  // 计算total
  for (let i = 0; i < values.length; i++) {
      let valueItem = values[i];
      let tempYear = new Date(keyList[i]).getFullYear();
      let tempValue = Number(valueItem) || 0;
      if (tempYear === Number(year) && tempValue) {
          total += tempValue;
      }
  }
  for (let time = date; time < end; time += dayTime) {
    //   let dateText = echarts.format.formatTime("yyyy-MM-dd", time);
      let dateText = echarts.time.format(time, '{yyyy}-{MM}-{dd}', false)
      let tempValue = null;
      let index = keyList.indexOf(dateText);
      if (index !== -1) {
          tempValue = Number(values[index]) || 0;
      }
      data.push({
          total: total,
          value: [dateText, tempValue],
      });
  }
  return data;
}
const processDataForBarChart = (visualData) => {
  const visual= visualData

  let data = visual.data;
  let dimensions = visual.spec.dimensions[0].column;
  let metrics = visual.spec.metrics[0].column;
  let tempYearMap = {};
  let min = 0;
  let max = 10;
  let tempLegendData = [];
  let tempSeries = [];
  for (let i = 0; i < data[dimensions].length; i++) {
      let item = data[dimensions][i];
      let tempArr = item?.split("-");
      let value = Number(data[metrics][i]);
      if (value < min) {
          min = value;
      }
      if (value > max) {
          max = value;
      }
      if (!Object.prototype.hasOwnProperty.call(tempYearMap, tempArr[0])) {
          tempYearMap[tempArr[0]] = false;
      }
  }
  for (let year in tempYearMap) {
      tempLegendData.push(year);
      tempSeries.push({
          name: year,
          type: "heatmap",
          coordinateSystem: "calendar",
          dimensionNames: [dimensions, metrics],
          data: getVirtulData(year, data[dimensions], data[metrics]),
      });
  }
  tempYearMap[tempLegendData[0]] = true;
  const legend = {
      data: tempLegendData,
      selected: tempYearMap,
      selectedMode: "single",
  };
  const visualMap = {
      min: min,
      max: max,
      show: false,
  };
  const range = tempLegendData[0];
  const series = tempSeries;
  return { legend, visualMap,range,series };
};

const CalendarChart = ({ visualData,rd }) => {
  const { legend, visualMap,range,series } = processDataForBarChart(visualData)
  const [calendarRange, setCalendarRange] = useState(range);
  const option = {
    legend: legend,
    tooltip: {
        formatter: (params) => {
            return `${params.dimensionNames[0]}:${params.value[0]}<br />${params.dimensionNames[1]}:${params.value[1]}<br />total:${params.data.total}`;
        },
        axisPointer: {
            type: "shadow",
        },
    },
    visualMap: visualMap,
    calendar: {
        right: 30,
        left: 30,
        cellSize: ["auto", 20],
        range: calendarRange,
        dayLabel: {
            nameMap: "EN",
        },
        monthLabel: {
            nameMap: "EN",
        },
        itemStyle: {
            borderWidth: 1,
        },
        splitLine: { show: false },
        yearLabel: { show: false },
    },
    series: series,
  };
  const chartRef = useRef(null);
  const events = {
    'legendselectchanged': (event) => {
        if (chartRef && chartRef.current){
            const myChart = chartRef.current.getEchartsInstance();
            myChart.setOption({
                calendar: {
                    range: event.name,
                },
            })
        }
    }
  };
  return (
    <Chart option={option} events={events} chartRef={chartRef} rd={rd}/>
  );
};

export { CalendarChart };
