import { Cross2Icon, MagicWandIcon, PaperPlaneIcon } from "@radix-ui/react-icons"
import { CopyLinkIcon, ShareIcon } from "../Icons"
import * as Tabs from "@radix-ui/react-tabs"
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

export const ShareModal = ({ closeModal, isModalOpen }) => {
  const [emails, setEmails] = useState([]);
  const [currentEmail, setCurrentEmail] = useState('');
  const [shareLink, setShareLink] = useState('');

  const handleEmailChange = (e) => {
    setCurrentEmail(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === ' ' || e.key === 'Enter' || e.key === ',') {
      addEmail();
    }
  };

  const addEmail = () => {
    if (currentEmail.trim() !== '' && isValidEmail(currentEmail)) {
      setEmails([...emails, currentEmail.trim()]);
      setTimeout(() => setCurrentEmail(''), [])
    }
  };

  const handleRemoveEmail = (index) => {
    const updatedEmails = [...emails];
    updatedEmails.splice(index, 1);
    setEmails(updatedEmails);
  };

  const isValidEmail = (email) => {
    // You can add more robust email validation if needed
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const { docId } = useParams()

  const generate = useCallback(async () => {
    try {
      if (!docId) {
        return toast.warn('Save your project before you share it with others.')
      }
      const loading = toast.loading('Please wait...')
      const res = await axios.post(`smartdoc/share/${docId}`, {
        type: 'public'
      })
      const token_id = res.data.message
      const shareLink = `
      ${window.location.href.split(window.location.host)[0]}${window.location.host}/smartdoc/share/${token_id}?type=public`
      setShareLink(shareLink)
      toast.dismiss(loading)
    } catch (e) {
      toast.dismiss()
      toast.error(e.message)
    }
  }, [docId])

  const shareWithEmail = useCallback(async () => {
    try {
      if (!docId) {
        return toast.warn('Save your project before you share it with others.')
      }
      if (emails.length < 1) {
        return toast.warn('You should add at least one address.')
      }
      const loading = toast.loading('Please wait...')
      await axios.post(`smartdoc/share/${docId}`, {
        type: 'private',
        emails: emails
      })
      toast.success('You sent share link successfully.')
      toast.dismiss(loading)
    } catch (e) {
      toast.dismiss()
      toast.error(e.message)
    }
  }, [emails, docId])

  const copyToClipboard = async () => {
    if (shareLink) {
      try {
        await navigator.clipboard.writeText(shareLink)
        toast.success('Copied link to clipboard.')
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <div>
      {isModalOpen && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div
                className="absolute inset-0 bg-[#00000080] opacity-75"
                onClick={closeModal}
              ></div>
            </div>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div
              className="inline-block align-bottom bg-white rounded-lg text-left text-grey overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white">
                <div className="text-center sm:text-left pt-4 pb-2 sm:px-6 px-4 flex justify-between">
                  <div className="flex gap-2">
                    <ShareIcon width={20} color={'#0694A3'} />
                    <div
                      className="font-medium"
                      id="modal-headline"
                    >
                      Share
                    </div>
                  </div>
                  <button onClick={closeModal}><Cross2Icon /></button>
                </div>
                <Tabs.Root defaultValue="public" className="border-b text-sm">
                  <Tabs.List size="1" className="shrink-0 border-b border-mauve6">
                    <Tabs.Trigger value="public" className="p-2 data-[state=active]:text-[#0694A3] text-xs leading-none text-mauve11 select-none first:rounded-tl-md last:rounded-tr-md hover:text-violet11 data-[state=active]:text-violet11 data-[state=active]:shadow-[inset_0_-1px_0_0,0_1px_0_0]">Public Link</Tabs.Trigger>
                    <Tabs.Trigger value="private" className="p-2 data-[state=active]:text-[#0694A3] text-xs leading-none text-mauve11 select-none first:rounded-tl-md last:rounded-tr-md hover:text-violet11 data-[state=active]:text-violet11 data-[state=active]:shadow-[inset_0_-1px_0_0,0_1px_0_0]">Share with Email</Tabs.Trigger>
                  </Tabs.List>
                  <Tabs.Content value="public">
                    <div className="sm:p-6 p-4 border-b font-normal">
                      <p className={`text-overflow-1 ${shareLink ? 'text-[#666]' : 'text-[#888]'}`}>{shareLink ? shareLink : 'Link will be here...'}</p>
                    </div>
                    <div className="sm:px-6 py-4 p-4 flex justify-end gap-4 text-[#0694A3]">
                      <button className="flex gap-1 items-center" onClick={generate}>
                        <MagicWandIcon />
                        Generate
                      </button>
                      <button className="flex gap-1" onClick={copyToClipboard}>
                        <CopyLinkIcon color="#0694A3" />
                        Copy Link
                      </button>
                    </div>
                  </Tabs.Content>
                  <Tabs.Content value="private">
                    <div className="sm:p-6 p-4 border-b font-normal">
                      <div>
                        <input
                          type="text"
                          placeholder="Enter valid email address."
                          className="outline-none border-b w-full focus:border-primary p-1"
                          value={currentEmail}
                          onChange={handleEmailChange}
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                      <div className="mt-4 p-1 flex flex-wrap gap-1 max-h-[200px] overflow-y-auto">
                        {emails.map((email, index) => (
                          <div key={index} className="flex gap-2 bg-primary text-white p-1 rounded px-2">
                            <span>{email}</span>
                            <button onClick={() => handleRemoveEmail(index)}>
                              <Cross2Icon color="white" />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="sm:px-6 py-4 p-4 flex justify-end gap-4 text-[#0694A3]">
                      <button className="flex gap-2 items-center" onClick={shareWithEmail}>
                        <PaperPlaneIcon color="#0694A3" />
                        Share
                      </button>
                    </div>
                  </Tabs.Content>
                </Tabs.Root>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}