import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import styled from 'styled-components';

const Node = styled.div`
  width: 140px;
  height: 48px;
  word-wrap:  break-word;
  position: relative;
  z-index: 3;
  font-size: 14px;
  .react-flow__handle {
    background: ${(props) => props.background ? props.background : '#01C2D7'};
    width: 8px;
    height: 8px;
    border-radius: 4px;
  }
`;

function CustomNode(props) {
  const { data, targetPosition } = props;
  const isHorizontal = targetPosition === 'left'
  const { style, setDetails, rndNo, fields, node_no, config, setStyleSettingNo, setShowDrawer } = data;

  let label = ''
  let image = ''
  if(fields && config) {
    const matchImage = fields.filter((val) => val.label === config['key_img'])[0]
    const matchLabel = fields.filter((val) => val.label === config['key_label'])[0]
    if(matchImage) image = matchImage?.value
    if(matchLabel) label = matchLabel?.value
  }

  return (
    <Node >
      <>
        {isHorizontal ?
          <>
            <Handle type="target" position={Position.Left} id='d' />
            <Handle type="source" position={Position.Right} id='b' />
          </> :
          <>
            <Handle type="target" position={Position.Top} id='a' />
            <Handle type="source" position={Position.Bottom} id='c' />
          </>
        }

        <div
          onClick={() => {
            setStyleSettingNo()
            setShowDrawer(true)
            setDetails({ data: fields || {}, rndNo: rndNo, type: 'orgchart', node_no: node_no, name: label })
          }}
          style={{ background: '#01C2D7' }}
          className={`
          ${style?.shape === 'rect' ? 'rounded-lg' : ''} 
          ${style?.shape === 'circle' ? 'rounded-full' : ''} 
          flex gap-2 text-[12px] cursor-pointer items-center p-2 py-3 text-[#4F4764] leading-[15px] justify-center max-w-[100%] mx-auto w-full h-full text-white rounded`}>
          {image && <img src={image} className='w-6 h-6 rounded' alt='' />}
          <div className='text-overflow-1'>{label}</div>
        </div>
      </>
    </Node>
  );
}

export default memo(CustomNode);