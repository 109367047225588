import React, { useEffect, useRef } from 'react';
import cytoscape from 'cytoscape';
import { Refresh } from "../../../components/Icons";

const dimensionColors = {}; // Store generated colors for each dimension
let colorIndex = 0;

function getColorForDimension(dimension) {
  const color = [
    "#ddc1f8",  // 主色1
    "#1C295C",  // 主色2
    "#aa97e0",  // 类似色
    "#c6aff7",  // 类似色
    "#b983ff",  // 类似色
    "#e0bbf3",  // 类似色
    "#e7d8ff",  // 类似色
    "#273870",  // 类似色
    "#192342",  // 类似色
    "#2e3a76",  // 类似色
    "#34425c",  // 类似色
    "#ff9e80",  // 对比色
    "#ffab91",  // 对比色
    "#ff7043",  // 对比色
    "#ffb74d",  // 对比色
    "#f0e130",  // 对比色
    "#ffeb3b",  // 对比色
    "#eaeaea",  // 中性色
    "#f2f2f2",  // 中性色
    "#d3d3d3"   // 中性色
  ];
  if (!dimensionColors[dimension]) {
    dimensionColors[dimension] = color[colorIndex];
    colorIndex = (colorIndex + 1) % color.length;
  }
  return dimensionColors[dimension];
}

function convertNetworkSpecToElements(data) {
  let elements = [];
  data.data.edges.forEach((item) => {
    let datas = { data: { ...item } };
    elements.push(datas);
  });
  data.data.nodes.forEach(node => {
    const nodeType = node.type.toLowerCase();
    const color = getColorForDimension(nodeType);

    elements.push({
      data: {
        nodeColor: color,
        ...node
      },
    });
  });
  return elements;
}

const NetworkGraphRaw = ({ rndNo, visualData, setDetails, setShowDrawer }) => {
  const data = visualData.data;
  const elements = convertNetworkSpecToElements(data);

  const cyRef = useRef(null);

  useEffect(() => {
    if (!cyRef.current) {
      cyRef.current = cytoscape({
        container: document.getElementById('cy' + rndNo),
        elements: elements,
        style: [
          {
            selector: 'node',
            style: {
              'cursor': 'grab',
              'shape': 'circle',
              'label': 'data(label)',
              'text-valign': 'center',
              'text-halign': 'center',
              'color': '#646464',
              'font-size': 18,
              'min-zoomed-font-size': 6,
               'text-wrap': 'wrap', // 启用文本换行
              'text-max-width': '80px', // 限制文本最大宽度
              'background-color': 'data(nodeColor)',
              'background-image': 'data(image)',
              'background-fit': 'cover',
              'width': 80,
              'height': 80,
            }
          }, 
          {
            selector: 'edge',
            style: {
              'label': 'data(label)',
              'labelFontSize': 2,
              'labelFontColor': 'white',
              'labelFontWeight': 'bold',
              'lineColor': 'data(color)',
              'width': 1,
              'target-arrow-color': 'data(color)',
              'curve-style': 'bezier',
              'target-arrow-shape': 'triangle',
              'target-arrow-color': 'data(edgeColor)',
            }
          }
        ]
      });

      cyRef.current.layout({
        "name": "cose",
        "animate": false,
        "aspectRatio": 'auto',
      }).run();

      // Example tap event for nodes
      // cyRef.current.on('tap', 'node', function (event) {
      //   const node = event.target;
      //   const nodeData = node.data();
      //   setShowDrawer(true);
      //   const dataArray = [];
      //   const rowData = { ...nodeData.rowData };
      //   let propNodes = networkspec.nodes.find((item) => { return item.entitytype === nodeData.entityType });
      //   let propCol = propNodes.prop_cols;
      //   const filteredData = Object.fromEntries(
      //     Object.entries(rowData).filter(([key]) => propCol.includes(key))
      //   );
      //   for (const key in filteredData) {
      //     dataArray.push({ label: key, value: rowData[key] });
      //   }
      //   setDetails({ data: dataArray || {}, name: nodeData.entityType });
      // });

      // Prevent default behavior
      // cyRef.current.on('mousedown', function (event) {
      //   event.stopPropagation();
      //   event.stopImmediatePropagation();
      // });
    }
  }, [elements, setDetails, setShowDrawer]);

  // Function to fit the view to the graph
  const handleFitView = () => {
    if (cyRef.current) {
      cyRef.current.fit(); // Fit the view to show all elements
    }
  };

  return (
    <div className='h-full w-full relative'>
    {/* The reset view button positioned in the top left corner */}
    <button 
      onClick={handleFitView} 
      className='absolute top-1 left-2 p-2  text-white rounded z-10'
    >
      {/* reset */}
      <Refresh color={"#646464"} width={18} height={18} />
    </button>
    <div 
      id={'cy' + rndNo} 
      onDrag={(event) => event.stopPropagation()} 
      className='flex gap-2 text-[12px] cursor-pointer items-center p-2 py-3 text-[#4F4764] leading-[15px] justify-center max-w-[100%] mx-auto w-full h-full text-white rounded min-h-[300px]' 
    />
  </div>
  );
};

export default NetworkGraphRaw;
