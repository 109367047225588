import React from 'react';
import {Table } from '@radix-ui/themes';
const TablesType = (visualData) => {
    const visual=visualData
    const data = visual.data;
    const tableData = data[Object.keys(data)[0]].map((_, index) => {
        const newObj = {};
        for (const key in data) {
          newObj[key] = data[key][index];
        }
        return newObj;
      })
    return { tableData}
  };
const Tables = ({ visualData }) => {
  const {tableData}=TablesType(visualData)
  return (
    <Table.Root variant="surface" size="1">
        <Table.Header>
            <Table.Row>
                {Object.keys(tableData[0]).map((column, index) => (
                <Table.ColumnHeaderCell key={index}>{column}</Table.ColumnHeaderCell>
                ))}
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {tableData.map((row, rowIndex) => (
                <Table.Row key={rowIndex}>
                    {Object.values(row).map((value, colIndex) => (
                        <Table.Cell key={colIndex}>{value}</Table.Cell>
                    ))}
                </Table.Row>
            ))}
        </Table.Body>
  </Table.Root>
);
};

export {Tables} ;
