
import ReactCaroussel from "react-caroussel";
import "react-caroussel/dist/index.css";
import React, { useState } from 'react';
import { FoldIcon } from "../../Icons";
import echartThemeTech from "../Chart/themes/echartThemeTech.json";
import echartTheme from "../Chart/themes/echartTheme.json";
import echartThemeDark from "../Chart/themes/echartThemeDark.json";
import './carouselStyles.css';


const processDataForCard = (visualData) => {
    const data = visualData.data;
    const propertyNames = Object.keys(data);

    const result = data[propertyNames[0]].map((_, index) => {
        const newObj = {};
        propertyNames.forEach(propertyName => {
            newObj[propertyName] = data[propertyName][index];
        });
        return newObj;
    });

    return { result };
};

const getCardSpec = (visualData) => {
    if (visualData.spec.cardspec.length > 0) {
        return visualData.spec.cardspec;
    } else {
        let cardspec = [];
        if (visualData.spec.dimensions.length > 0) {
            visualData.spec.dimensions.forEach(dimension => {
                cardspec.push({
                    column: dimension.column,
                    is_primary: true,
                    is_image: false,
                    is_boolean: false,
                    is_badge: false,
                    is_front: true,
                });
            });
        }
        if (visualData.spec.metrics.length > 0) {
            visualData.spec.metrics.forEach(metric => {
                cardspec.push({
                    column: metric.column,
                    is_primary: false,
                    is_image: false,
                    is_boolean: false,
                    is_badge: false,
                    is_front: true,
                });
            });
        }
        return cardspec;
    }
};

const CarouselSlideItem = ({ item, no, width, text, color, isImage, isPrimary, isText, isText1, isBadge, isBadge1, isBoolean, isBoolean1, cardVisibility, handleButtonClick, isFold }) => {
    return (
        <div className={`w-[95%] bg-white rounded-[28px] border flex-col justify-start items-center inline-flex relative pb-4 m-2`}>
            <div className={`${width} flex-col justify-start items-start gap-1 inline-flex`}>
                <div className={`w-full h-48 rounded-t-[28px] flex bg-indigo-50 font-bold justify-center items-center ${text}`}  
                    style={{
                        backgroundImage: isImage[0] ? `url(${item[isImage[0].column]})` : 'none', 
                        backgroundColor: isImage[0] ? 'transparent' : color[no], 
                        backgroundSize: 'cover', 
                        backgroundPosition: 'center', 
                        color: 'white'
                    }}>
                    {isPrimary.map((items, nos) => (
                        <div key={nos}>{item[items.column]}</div>
                    ))}
                </div>
                {(cardVisibility[no] ? isText : isText1).map((front, frno) => (
                    <div key={frno} className={`${width} px-4 flex flex-row items-center justify-between`}>
                        <div className={`text-zinc-600 font-normal font-montserrat leading-normal ${text}`}>{front.column}</div>
                        <div className={`text-right text-slate-800 font-normal font-montserrat leading-normal ${text}`}>{item[front.column]}</div>
                    </div>
                ))}
                <div className="pl-1 pr-8 py-px rounded-[20px] justify-start items-center inline-flex">
                    <div className="self-stretch justify-start items-start gap-1 inline-flex">
                        {(cardVisibility[no] ? isBadge : isBadge1).map((badge, bno) => (
                            <div key={bno} className="px-1.5 py-1 bg-indigo-50 rounded-md justify-center items-center flex">
                                <div className="text-slate-800 text-[8px] font-normal font-montserrat leading-tight">{item[badge.column]}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <div>
                    {(cardVisibility[no] ? isBoolean : isBoolean1).map((bool, blno) => (
                        <div key={blno} className={`${width} px-4 flex flex-row items-center justify-between`}>
                            <div className={`${text} font-normal`}>{bool.column}</div>
                            {item[bool.column] ? <div className="w-4 h-4 bg-emerald-500 rounded-full shadow-inner" /> : <div className="w-4 h-4 bg-slate-200 rounded-full shadow-inner" />}
                        </div>
                    ))}
                </div>
            </div>
            {isFold.length > 0 && (
                <div className={cardVisibility[no] ? 'absolute bottom-[-12px] cursor-pointer' : 'absolute bottom-[-12px] cursor-pointer transform rotate-180'} onClick={() => handleButtonClick(no)}>
                    <FoldIcon />
                </div>
            )}
        </div>
    );
};
const getTheme = (theme) => {
    switch (theme) {
        case 'tech':
            return echartThemeTech.color;
        case 'dark':
            return echartThemeDark.color;
        case 'normal':
        default:
            return echartTheme.color;
    }
};
const WebCard = ({ visualData, rd }) => {
    const themes = rd?.style?.theme || '';
    const { result } = processDataForCard(visualData);
    const cardspec = getCardSpec(visualData);

    const isImage = cardspec.filter(item => item.is_primary && item.is_image);
    const isPrimary = cardspec.filter(item => item.is_primary && !item.is_image);
    const isText1 = cardspec.filter(item => !item.is_primary && !item.is_boolean && !item.is_badge);
    const isBoolean1 = cardspec.filter(item => item.is_boolean);
    const isBadge1 = cardspec.filter(item => item.is_badge);
    const isText = cardspec.filter(item => !item.is_primary && !item.is_boolean && !item.is_badge && item.is_front);
    const isBoolean = cardspec.filter(item => item.is_boolean && item.is_front);
    const isBadge = cardspec.filter(item => item.is_badge && item.is_front);
    const isFold = cardspec.filter(item => !item.is_front);

    const [cardVisibility, setCardVisibility] = useState(result.map(() => true));
    const handleButtonClick = (index) => {
        const updatedVisibility = [...cardVisibility];
        updatedVisibility[index] = !updatedVisibility[index];
        setCardVisibility(updatedVisibility);
    };

    const [width, setWidth] = useState('w-[100%]');
    const [text, setText] = useState('text-[18px]');
    const color = getTheme(themes);

    return (
      <div className="p-2 custom-carousel">
        <ReactCaroussel slidesToShow={3} slidesToScroll={3} infinite={true} >
            {result.map((i, no) => (
                 <CarouselSlideItem
                 key={no}
                 item={i}
                 no={no}
                 width={width}
                 text={text}
                 color={color}
                 isImage={isImage}
                 isPrimary={isPrimary}
                 isText={isText}
                 isText1={isText1}
                 isBadge={isBadge}
                 isBadge1={isBadge1}
                 isBoolean={isBoolean}
                 isBoolean1={isBoolean1}
                 cardVisibility={cardVisibility}
                 handleButtonClick={handleButtonClick}
                 isFold={isFold}
             />
             ))}
        </ReactCaroussel>
      </div> 
    );
};

export default WebCard;