const de = {
  /**Side bar */
  'Explore': "Erkunden",
  'Questions': "Fragen",
  'Dashboards': "Dashboards",
  'Datasets': "Datensätze",
  'Graph': "Graph",
  'Reports': "Berichte",
  'SmartDoc': 'SmartDoc',
  'Workflow': 'Arbeitsablauf',
  'Log Out': "Ausloggen",
  'Payment & Subscription': 'Profil & Abonnement',
  'Profile': "Profile",
  'Upgrade': 'Aktualisierung',
  'Get access to all features on Shugic': 'Erhalten Sie Zugriff auf alle Funktionen von Shugic',
  'Get Premium': 'Premium bekommen',

  /** Smart Doc */
  'Get Started With Us': 'Starten Sie mit uns durch',
  'placeholder text': 'Platzhaltertext',
  'Type ‘/’ to see a list of predefined options': 'Geben Sie „/“ ein, um eine Liste vordefinierter Optionen anzuzeigen',
  'If we have a question click on “?” icon above to get the documentation or send a message!': 'Wenn wir eine Frage haben, klicken Sie auf „?“ Klicken Sie auf das Symbol oben, um die Dokumentation zu erhalten oder eine Nachricht zu senden!',
  'Press ‘/’  here to see the list of options ....': 'Drücken Sie hier „/“, um die Liste der Optionen anzuzeigen ....',
  'options':'OPTIONEN',
  'Share':'Aktie',
  'Download':'Herunterladen',
  'Full Width':'Gesamtbreite',
  'Small Text':'Kleiner Text',
  'Print':'Drucken',
  'New Report':'Neuer Bericht',
  
  /** Languages */
  Chinese: "Chinesisch",
  English: "Englisch",
  German: "Deutsch",
  toggle: "Umschalten",
  go: "Anfragen",
  // 搜索框相关
  search: "search",
  graph: 'graph',
  database: "database",
  // 顶部
  synonym: 'synonym',
  language: "language",
  question: 'question',
  notify: "notify",

  // 问题
  look: 'look',
  delete: 'delete',
  // vsHeader
  'Add to saved items': 'Add to saved items',
  'share items': 'share items',
  'change chart': 'change chart',
  'download': 'download',
  'send email': 'send email',
  'copy': 'copy',
  'config': 'config',

  // 历史记录
  'go right': 'go right',
  'delete all': 'delete all',
  'history': 'history',
  'Click the query': 'Click the query',
  'collapseCards': 'collapseCards',
  'goToDashboard': 'goToDashboard',
  // 移动
  'fold': 'fold',
  'up': 'up',
  'down': 'down',
  'top': 'top',
  'buttom': 'buttom',
  'refresh': 'refresh',

  edit: 'edit',
  'save': 'save',

  'parallel': 'parallel',
  'merge': 'merge',
  'sum': 'sum',

  // 标题、内容等等相关
  'Data Model': 'Data Model',
  'Edit': 'Edit',
  'Diagram': 'Diagram',
  'Data source replaced successfully': 'Data source replaced successfully',
  'Add this shot to a collection': 'Add this shot to a collection',
  'Done': 'Done',
  'Create New Collection': 'Create New Collection',
  'Filter collections': 'Filter collections',
  'Copy': 'Copy',
  'Cancel': 'Cancel',
  'Configuration': 'Configuration',
  'Style': 'Style',
  'Visuals': 'Visuals',
  'Description': 'Description',
  'Column Order': 'Column Order',
  'Set Visual Type': 'Set Visual Type',
  'Set Formula': 'Set Formula',
  'Theme': 'Theme',
  'Title BG Color': 'Title BG Color',
  'Title Font Color': 'Title Font Color',
  'Title alignment': 'Title alignment',
  'Hide title': 'Hide title',
  'Chart BG Color': 'Chart BG Color',
  'Change Background Color': 'Change Background Color',
  'Column as Label': 'Column as Label',
  'column': 'column',
  'order': 'order',
  'bottom': 'bottom',
  'Visual Type': 'Visual Type',
  'GroupBy': 'GroupBy',
  'Compute': 'Compute',
  'name': 'name',
  'fx': 'fx',
  'fx-operator': 'fx-operator',
  'fx-column': 'fx-column',

  'Auxiliary': 'Auxiliary',
  'Orientation': 'Orientation',
  'Sort': 'Sort',
  'X-Tick': 'X-Tick',
  'Y-Tick': 'Y-Tick',
  'Label': 'Label',
  'Legend': 'Legend',
  'Average Line': 'Average Line',
  'Top': 'Top',
  'Bottom': 'Bottom',
  'tags': 'tags',
  'choose': 'choose',
  'layout': 'layout',
  'Layout': 'Layout',
  'style': 'style',
  'theme': 'theme',
  'title plain text': 'title plain text',
  'round corner': 'round corner',
  'show shadow': 'show shadow',
  'compact': 'compact',
  'default': 'default',
  'dark': 'dark',
  'auto': 'auto',
  'intelligent': 'intelligent',
  'No Data': 'No Data',
  'Comment': 'Comment',
  'Comments': 'Comments',
  'There are no comments. Add first.': 'There are no comments. Add first.',
  'Delete Comment': 'Delete Comment',
  'OK': 'OK',
  'remove': 'remove',
  'from': 'from',
  'Quick Overview': 'Quick Overview',

  'Upload': 'Upload',
  'Specify Data Types': 'Specify Data Types',
  'Configure': 'Configure',

  'Table Name': 'Table Name',
  'Schema Name': 'Schema Name',
  'Apply to existing': 'Apply to existing',
  'New': 'New',
  'Submit': 'Submit',
  'Save': 'Save',
  'Host name/address': 'Host name/address',
  'Port': 'Port',
  'Maintenance database': 'Maintenance database',
  'Password': 'Password',
  'Already uploaded files': 'Already uploaded files',
  'Delete': 'Delete',
  'Open': 'Open',
  'File Name': 'File Name',

  'Rows': 'Rows',
  'Columns': 'Columns',
  'Date': 'Date',
  'Author Name': 'Author Name',
  'Dashbords': 'Dashbords',
  'Click or drag file to this area to upload': 'Click or drag file to this area to upload',
  'Color': 'Color',
  'visualTypeInGrid': 'visualTypeInGrid',
  'upload': 'upload',
  'choose image': 'choose image',
  'Click this area to upload': 'Click this area to upload',
  '': '',
};

export default de;
