import React from 'react';
import Chart from '../Chart';

const processDataForHeatMapChart = visualData => {
  try {
    const visual = visualData;
    const data = visual.data;
    const dimensions = visual.spec.dimensions;
    const metrics = visual.spec.metrics[0].column;

    // 构建热力图数据
    const heatmapData = data[metrics].map((value, index) => {
      return [
        data[dimensions[0].column][index], // x 轴
        data[dimensions[1].column][index], // y 轴
        parseFloat(value) // 数据值
      ];
    });
    const xAxisData = Array.from(new Set(data[dimensions[0].column]));
    const yAxisData = Array.from(new Set(data[dimensions[1].column]));
    const maxVisualMap = Math.max(...data[metrics]);
    return { xAxisData, yAxisData, maxVisualMap, heatmapData };
  } catch (error) {
    console.error('Error processing data for heatmap chart:', error);
    return { xAxisData: [], yAxisData: [], maxVisualMap: 0, heatmapData: [] };
  }
};

const HeatMapChart = ({ visualData, rd }) => {
  try {
    const { xAxisData, yAxisData, maxVisualMap, heatmapData } = processDataForHeatMapChart(visualData);
    const option = {
      // 配置选项...
      tooltip: {
        position: 'top',
        formatter(params) {
          return `x:${params.value[0]}<br/> y:${params.value[1]}<br/>value:${params.value[2]}`;
        }
      },

      xAxis: {
        type: 'category',
        data: xAxisData
      },
      yAxis: {
        type: 'category',
        data: yAxisData
      },
      //   dataZoom: [
      //     {
      //         height: 20,
      //         type: "slider",
      //         xAxisIndex: 0,
      //         start: 0,
      //         end: 100,
      //         filterMode: "none",
      //         bottom:10
      //     },
      //     {
      //         width: 20,
      //         type: "slider",
      //         yAxisIndex: 0,
      //         start: 0,
      //         end: 100,
      //         filterMode: "none",
      //     },
      //     {
      //         type: "inside",
      //         xAxisIndex: 0,
      //         start: 0,
      //         end: 100,
      //         filterMode: "none",
      //     },
      //     {
      //         type: "inside",
      //         yAxisIndex: 0,
      //         start: 0,
      //         end: 100,
      //         filterMode: "none",
      //     },
      // ],
      visualMap: {
        show: false,
        min: 0,
        max: maxVisualMap,
        calculable: true,
        orient: 'horizontal',
        left: 'center',
        bottom: '-15'
      },
      series: [
        {
          name: 'Heatmap',
          type: 'heatmap',
          data: heatmapData,
          label: {
            show: true
          }
        }
      ]
    };
    return <Chart option={option} rd={rd} />;
  } catch (error) {
    console.error('Error rendering HeatMapChart:', error);
    return <div>Error rendering the heatmap chart</div>;
  }
};

export { HeatMapChart };
