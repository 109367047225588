import React from 'react';
import Chart from '../Chart';

const processDataForGroupedBarChart = (visualData) => {
    const visual=visualData
    const data = visual.data;
    const dimensions = visual.spec.dimensions;
    const metrics = visual.spec.metrics[0].column;

    // 从对象中提取维度和度量的数组
    const xAxisData = Array.from(new Set(data[dimensions[0].column]));
    const legendData = Array.from(new Set(data[dimensions[1].column]));
    const seriesData = legendData.map(legend => ({
        name: legend,
        type: 'bar',
        emphasis: { focus: 'series' },
        data: xAxisData.map(dimension => {
            const indices = data[dimensions[0].column]
                .map((val, index) => (val === dimension && data[dimensions[1].column][index] === legend ? index : -1))
                .filter(index => index !== -1);

            return indices.length > 0
                ? indices.reduce((sum, index) => sum + (parseFloat(data[metrics][index]) || 0), 0)
                : 0;
        }),
    }));
    return {xAxisData,legendData,seriesData};
};

const GroupedBarChart = ({visualData,rd}) => {
    const {xAxisData,legendData,seriesData}=processDataForGroupedBarChart(visualData)
    const option = {
        legend:{
            show:true,
            data:legendData
        },
        tooltip: {
            trigger: "item",
        },
        dataset: [
            {
                transform: {
                    type: "sort",
                },
            },
        ],
        xAxis: {
           
            type: "category",
            data: xAxisData,
            axisLabel: {
                show: true
            }
        },
        yAxis: {
            type: "value",
            axisLabel: {
                show: true
            },
        },
        series: seriesData,
        };
    return (
        <Chart option={option} rd={rd} />
    );
};

export { GroupedBarChart };
