import React from "react";

import { BarChart, DonutChart, LineChart, PieChart, DonutGaugeChart, FunnelChart } from "../../Smartdoc/Chart/EchartsOneDimension";
import { StackBarChart, HeatMapChart, GroupedBarChart, TreeMapChart, TextCloudChart, CirlePackingChart, TimeLine, GridBarChart, GridLineChart, GridDonutChart, RadarChart, SankeyChart, SunburstChart ,ScatterChart} from "../../Smartdoc/Chart/EchartsColumnsMore";
import { Card, Tables } from "../../Smartdoc/Chart/Cards";
import { FilteredBarChart } from "../../Smartdoc/Chart/Filtered/FilteredBar";
import { ParliamentDonutChart,CalendarChart } from "../../Smartdoc/Chart/OthersChart"


const chartComponents = {
  BAR: BarChart,
  DONUT: DonutChart,
  LINE: LineChart,
  PIE: PieChart,
  DONUTGAUAGE: DonutGaugeChart,

  STACKBAR: StackBarChart,
  HEATMAP: HeatMapChart,
  GROUPEDBAR: GroupedBarChart,
  TREEMAP: TreeMapChart,
  TEXTCLOUD: TextCloudChart,
  CirlePacking: CirlePackingChart,
  TIMELINE: TimeLine,
  GRIDBAR: GridBarChart,
  GRIDLINE: GridLineChart,
  GRIDDONUT: GridDonutChart,
  FLATCARD: Card,
  FUNNEL: FunnelChart,
  TABLE: Tables,
  RADAR: RadarChart,
  SANKEY: SankeyChart,
  SUNBURST: SunburstChart,
  FILTEREDBAR: FilteredBarChart,
  SCATTER:ScatterChart,
  ParliamentDonut:ParliamentDonutChart,
  CALENDAR:CalendarChart
};

const DynamicChart = ({ chartType, visualData }) => {
  const ChartComponent = chartComponents[chartType];
  if (ChartComponent) {
    return (
      <ChartComponent visualData={visualData}/>
    );
  }
  return null;
};

export { DynamicChart };
