import React from 'react';
import { startOfMonth, endOfMonth, eachDayOfInterval, format, getDay } from 'date-fns';

const getData = data => {
  const keys = Object.keys(data);
  const length = data[keys[0]].length;

  const processedData = Array.from({ length }, (_, index) => {
    const obj = {};
    keys.forEach(key => {
      obj[key] = data[key][index];
    });
    return obj;
  });
  return processedData;
};

// 辅助函数：将十六进制颜色转换为 HSL
const hexToHSL = hex => {
  // 移除 # 号（如果有的话）
  hex = hex.replace('#', '');

  // 将十六进制转换为 RGB
  let r = parseInt(hex.substring(0, 2), 16) / 255;
  let g = parseInt(hex.substring(2, 4), 16) / 255;
  let b = parseInt(hex.substring(4, 6), 16) / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return { h: h * 360, s: s * 100, l: l * 100 };
};

// 辅助函数：将 HSL 转换为十六进制颜色
const HSLToHex = (h, s, l) => {
  s /= 100;
  l /= 100;

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;
  let r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }

  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  const toHex = x => {
    const hex = x.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

// 获取深色版本的颜色
const getDarkerColor = (hexColor, darkenAmount = 30) => {
  const hsl = hexToHSL(hexColor);
  // 降低亮度，但不低于 20%
  const newL = Math.max(20, hsl.l - darkenAmount);
  return HSLToHex(hsl.h, hsl.s, newL);
};

const MonthCalendar = ({ visualData }) => {
  let data = getData(visualData.data);
  let config = { ...visualData.config };
  const currentDate = new Date(config.month);
  const startDate = startOfMonth(currentDate);
  const endDate = endOfMonth(currentDate);
  const days = eachDayOfInterval({ start: startDate, end: endDate });

  const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const presetColors = [
    '#FF0000',
    '#00FF00',
    '#0000FF',
    '#FFFF00',
    '#FF00FF',
    '#00FFFF',
    '#FFA500',
    '#800080',
    '#008000',
    '#000080'
  ];

  const getColorByValue = value => {
    const index = Math.abs(value.charCodeAt(0)) % presetColors.length;
    return presetColors[index];
  };

  const getTasksForDate = date => {
    return data.filter(task => task.date === format(date, 'yyyy-MM-dd'));
  };

  const getUniqueTags = tasks => {
    const tags = tasks.map(task => task[config.colorBy]).filter(Boolean);
    return [...new Set(tags)];
  };

  const colorPalette = [
    '#E6F3F5',
    '#F7EBE5',
    '#E8F4D4',
    '#F5E6EA',
    '#E5EAF7',
    '#F4E9D1',
    '#DCF5EB',
    '#F2E4F6',
    '#E9E9ED',
    '#E3C9B5',
    '#30D6B0'
  ];

  return (
    <div className="calendar">
      <h2 className="text-xl font-bold mb-4">{config.month}</h2>
      <div className="grid grid-cols-7 gap-1 ">
        {weekdays.map(day => (
          <div key={day} className="text-center font-bold p-2 bg-[#f3f4f6]  ">
            {day}
          </div>
        ))}
        {Array(getDay(startDate))
          .fill(null)
          .map((_, index) => (
            <div key={`empty-${index}`} className="p-2"></div>
          ))}
        {days.map(day => {
          const tasksForDay = getTasksForDate(day);
          const uniqueTags = getUniqueTags(tasksForDay);
          return (
            <div key={day.toString()} className="border rounded px-2 min-h-[85px]">
              <div className="font-bold">{format(day, 'd')}</div>
              <div className="flex gap-1  text-xs">
                {uniqueTags.map(tag => (
                  <div
                    key={tag}
                    className="w-2 h-2 rounded-full"
                    style={{ backgroundColor: getColorByValue(tag) }}></div>
                ))}
              </div>
              <div className="mt-2">
                {tasksForDay.map((task, index) => {
                  const bgColor = colorPalette[index % colorPalette.length];
                  return (
                    <div
                      key={index}
                      className="text-xs p-1 my-1 rounded"
                      style={{
                        backgroundColor: bgColor,
                        color: getDarkerColor(bgColor, 35) // 将颜色调深 35%
                      }}>
                      {task.task}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MonthCalendar;
