import React, { memo,useEffect,useState ,useCallback } from 'react';
import { Handle, Position, NodeResizer,NodeToolbar  } from 'reactflow';
import styled from 'styled-components';
import { FunctionBar } from './GraphChart/FunctionBar'

const Node = styled.div`
  width: 100%;
  height:100%;
  border-radius: 6px;
  word-wrap:  break-word;
  font-size: 12px;
  border:dashed 1px #aaa;

  // background: #fff;
  background-color: rgba(56, 189, 248, 0.05);
  // box-shadow: 0px 2px 5px rgba(60,66,87,.08), 0px 1px 1px rgba(0,0,0,.12);
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 12px;

`;
function CustomGroupChart({id,data,selected }) {
  const { label,minHeight,minWidth,visualData } = data;
  const [isContainerVisible, setContainerVisible] = useState(false);
  const [opacity, setOpacity] = useState(0)
  const handleMouseEnter = useCallback(() => {
    setOpacity(1)
  }, []);
  const handleMouseLeave = useCallback(() => {
    setOpacity(0)
  }, []);
  const handleButtonClick = () => {
    data.onChange(id,visualData)
  };
  return (
    <Node background='#01BDD2' dashed='false' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <>
        <NodeResizer color="#0694A3" isVisible={selected}/>
        <Handle type="target" position={Position.Left}  className=" bg-[white] border border-solid border-[gray] rounded-full" style={{'opacity':opacity}}/>
        <Handle type="source" position={Position.Right} className=" bg-[white] border border-solid border-[gray] rounded-full" style={{'opacity':opacity}}/>
        <div className={`w-full h-full  text-[12px] text-gray-400 max-w-[100%] mx-auto w-full   overflow-hidden whitespace-nowrap overflow-ellipsis`}
          onMouseEnter={() => setContainerVisible(true)}
          onMouseLeave={() => setContainerVisible(false)}>
          <div className="absolute w-full h-8  -top-7 flex justify-between items-center">
            <div>{label?label:'unnamed'}</div>
            {isContainerVisible &&(
                <FunctionBar isShowCard={true} onChangeCardClick={handleButtonClick}/>
            )}
          </div>
        </div>
      </>
    </Node>
  );
}

export default memo(CustomGroupChart);
