import { useSelector } from "react-redux";
import { useState } from "react";
import { Flex, RadioGroup, Text, RadioCards, Box } from '@radix-ui/themes';

export const StackBarSetting = ({ rndData, setRndData }) => {
    const settingStyleNo = useSelector((state) => state.global.settingStyleNo);
    const spec = rndData[settingStyleNo].chartData.data.visualData.spec;
    const dimension = spec.dimensions;

    // 设置默认选中项为第一个item.column
    const [stackbarSelectedColumn, setStackbarSelectedColumn] = useState(0);

    const handleChange = (selectedColumn) => {
        console.log({selectedColumn})
        setStackbarSelectedColumn(selectedColumn);
        let setting={stackbarSelectedColumn:selectedColumn}
        let data={...rndData[settingStyleNo],setting}
        rndData[settingStyleNo]=data
        const newRndData = [...rndData];
        setRndData(newRndData)
    };

    return (
        <div className="mt-4 flex items-center justify-center">
            <h3 className="mr-4">x-axis:</h3>
            <Box maxWidth="600px">
                <RadioCards.Root
                    value={stackbarSelectedColumn}
                    columns={{ initial: '1', sm: '3' }}
                    color="cyan"
                    onValueChange={handleChange} // 绑定选择事件
                >
                    {dimension.map((item,index) => (
                        <RadioCards.Item key={index} value={index}>
                            <Flex direction="column" width="100%">
                                <Text>{item.column}</Text>
                            </Flex>
                        </RadioCards.Item>
                    ))}
                </RadioCards.Root>
            </Box>
        </div>
    );
};
