import React from 'react';
import Chart from '../Chart';
import { useSelector } from "react-redux"
const processDataForBarChart = (data,type) => {
  const visuals = data
  const { dimensions, metrics } = visuals.spec;
  const xAxisData = visuals.data[dimensions[0].column];
  let optionsData =[];
 
  // let maxMetricValue = 0;
  // metrics.forEach((item,index) => {
  //    let max= Math.max.apply(null, visuals.data[item.column]); 
  //    if(max>maxMetricValue){
  //      maxMetricValue=max
  //    }
  // });
  metrics.forEach((item,index) => {
    optionsData.push({
      tooltip: {
        trigger: "item",
      },
      grid: {
        bottom: type === 'filteredBar' ? '10%' : 0
      },
      xAxis: {
        type: 'category',
        data: xAxisData,
      },
      yAxis: {
        type: 'value',
        // max:maxMetricValue,
      },
      series: [{
        type: 'bar',
        data: visuals.data[item.column],
      }],
    }) 
  });

  return { optionsData };
};

const BarChart = ({ visualData, type,rndData,rd }) => {

  const { optionsData } = processDataForBarChart(visualData,type)
 
  return (
    <div className='flex h-full'>
     {optionsData.map((option, index) =>
      <Chart key={index} option={option} rd={rd}/>
      )}
    </div>
    );
};

export { BarChart };
