import React, { useState, useEffect } from "react";
import * as Tabs from '@radix-ui/react-tabs';
import { StyleSetting } from './StyleSetting'

function SettingsDrawer({ visible, onClose,id,nodes,setNodes}) {
  const [opened, setOpened] = useState(visible);
  const [active, setActive] = useState(visible);
  useEffect(() => {
    if (visible) {
      setOpened(true);
    }
    requestAnimationFrame(() => {
      setActive(visible);
    });
  }, [visible]);

  if (!opened && !visible) {
    return null;
  }

  const drawerStyle = {
    height: 'calc(100% - 40px)',
    minHeight: 782,
    position: 'fixed',
    top:  40,
    zIndex: '10'
  };

  return (
    <div style={drawerStyle}
      className={`sm:w-[400px] w-[320px] ${active ? 'opacity-1 right-0' : 'opacity-0 right-[-100%] pointer-events-none'} text-[#6B7280] transition-all bg-white shadow-xl shadow-[#ccc] overflow-y-auto overflow-hidden`}>
      <div className="flex items-center justify-between px-3 py-2">
        <div className="text-base font-normal tracking-tight">Settings</div>
        <button onClick={onClose}><img src="/settingImages/x.png" alt="" width='16px' /></button>
      </div>
      <div className="w-full h-px left-[1px] absolute border-neutral-100" />
      <Tabs.Root defaultValue="style" className="px-4 pb-6">
        <Tabs.List className="mb-2 shrink-0 flex border-b border-mauve6">
          <Tabs.Trigger
            className="p-2 data-[state=active]:text-[#0694A3] text-sm leading-none text-mauve11 select-none first:rounded-tl-md last:rounded-tr-md hover:text-violet11 data-[state=active]:text-violet11 data-[state=active]:shadow-[inset_0_-1px_0_0,0_1px_0_0] "
            value="style"
          >
            Style
          </Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content value="style">
          <StyleSetting id={id} nodes={nodes} setNodes={setNodes}></StyleSetting>
        </Tabs.Content>
      </Tabs.Root>
    </div>
  );
}

export { SettingsDrawer };
