import { createSlice } from '@reduxjs/toolkit'

export const smartdocSlice = createSlice({
  name: 'smartdoc',
  initialState: {
    docs: [],
    smartDoc: {
      name: 'Untitled',
      data: [],
      shared_users: [],
      views: 0,
      updatedAt: ''
    },
    shareType: ``,
    unreadComments: [],
    showShareModal: false,
    showCommentDrawer: -1,
    isGrouping: false,
    graph: {
      name: 'Untitled',
      data: [],
      shared_users: [],
      views: 0,
      updatedAt: ''
    },
    smartDocStyle:{}
  },
  reducers: {
    updateSmartdoc: (state, action) => {
      state.smartDoc = action.payload
    },
    updateGraph: (state, action) => {
      state.graph = action.payload
    },
    setUnreadComments : (state, action) => {
      state.unreadComments = action.payload
    },
    setShowShareModal: (state, action) => {
      state.showShareModal = action.payload
    },
    setShowCommentDrawer: (state, action) => {
      state.showCommentDrawer = action.payload
    },
    setShareType: (state, action) => {
      state.shareType = action.payload
    },
    setIsGrouping: (state, action) => {
      state.isGrouping = action.payload
    },
    setSmartDocStyle: (state, action) => {
      state.smartDocStyle = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { updateSmartdoc, setShowShareModal, setUnreadComments, setShowCommentDrawer, setShareType, setIsGrouping ,updateGraph,setSmartDocStyle} = smartdocSlice.actions


export default smartdocSlice.reducer