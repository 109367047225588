import { useState } from "react"
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CaretLeftIcon } from '@radix-ui/react-icons';
import { Checkbox } from "@radix-ui/themes"
import { toast } from "react-toastify";

import { fetchRegister } from "../../store/slices/authSlice";
import AuthCarousel from "../../components/AuthCarousel"
import Input from "../../components/Input"
import Button from "../../components/Buton"
import Loader from "../../components/Loader";
import store from '../../store/index'

function SignUp() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userState = useSelector((state) => state.auth.userState)

  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [conPassword, setConPassword] = useState('')
  const [term, setTerm] = useState(false)
  const [step, setStep] = useState(1)

  const signUp = () => {
    if (!name || !email || !password || !conPassword) {
      toast.warn('Please complete all fields.')
    } else if (password !== conPassword) {
      toast.warn('Confirm password is not matched.')
    } else if (!term) {
      toast.warn('You must agree with Shugic terms.')
    } else {
      dispatch(fetchRegister({ name, email, password }))
        .then(() => {
          const userSt = store.getState().auth.userState
          if (userSt.isError) {
            toast.error(userSt.errorMessage)
          } else if (userSt.status === 'FAILED SIGNUP') {
            userSt.errorMessage.map((msg) => toast.warn(msg))
          } else if (userSt.status === 'OK') {
            toast.success('You created an account successfully!')
            navigate('/signin')
          }
        })
        .catch(e => console.log(e))
    }
  }

  return (
    <div className="flex bg-white min-h-screen overflow-y-auto h-fit">
      <div className="w-full sm:w-[55%] pt-8 px-8 pb-4 relative flex justify-center">
        {/** Google Sign up */}
        <section className={`w-[calc(100%-64px)] md:w-[400px] absolute transition duration-[800ms] ${step === 1 ? 'opacity-1 z-[2]' : 'opacity-0 translate-x-[-60%]'}`}>
          <img src="/logo-title.png" className="sm:w-52 w-36" alt="" />
          <div className="font-bold sm:text-4xl text-2xl sm:mt-8 mt-6 relative">Sign up.</div>
          <div>
            <Button className='w-full mt-4 text-white bg-primary'>
              <img src="/imgs/google.png" className="w-5 h-5 mr-2" alt="" />SIGN UP WITH GOOGLE
            </Button>
            <div className="flex w-full items-center text-[#BDBDBD] gap-4 text-sm font-[500] my-6">
              <div className="bg-[#BDBDBD] h-[1px] w-[45%]"></div>
              OR
              <div className="bg-[#BDBDBD] h-[1px] w-[45%]"></div>
            </div>
            <Button
              onClick={() => setStep(2)}
              className='w-full mt-4 bg-white border border-[#BDBDBD] font-[600] text-grey-10'>
              CONTINUE WITH EMAIL
            </Button>
          </div>

          <div className="flex gap-2 mt-4">
            <Checkbox color="cyan" checked={term} onClick={(e) => setTerm(!term)} className="mt-[2px]" />
            <div className="text-grey-10 text-sm font-[500]">By creating acccount you agree with
              <span className="text-primary font-bold"> Terms of service </span>
              and our
              <span className="text-primary font-bold"> privacy policy</span>
            </div>
          </div>
          <div className="text-[#989898] text-sm font-[500] mt-4 text-center">
            Already have an account? <button className="text-primary font-bold" onClick={() => navigate('/signin')}>Sign in</button>
          </div>
        </section>

        {/** Email Sign up */}
        <section className={`w-[calc(100%-64px)] md:w-[400px] absolute transition duration-[800ms] ${step === 2 ? 'opacity-1 z-[2]' : 'opacity-0 translate-x-[-60%]'}`}>
          <img src="/logo-title.png" className="sm:w-52 w-36" alt="" />
          <div className="font-bold sm:text-4xl text-2xl sm:mt-8 mt-6 relative">
            <button
              onClick={() => setStep(1)}
              className="rounded-full border md:-left-8 lg:-left-16 -left-[26px] absolute sm:top-2 top-1">
              <CaretLeftIcon className="sm:w-6 sm:h-6 h-5 w-5" />
            </button>
            Sign up.
          </div>
          <p className="text-grey-10 mt-2">Create account to access all our features and tools.</p>

          <div className="py-3">
            <Input
              className="my-3"
              value={name}
              setValue={setName}
              placeholder='username'
              label='Username'
              type='text'
            />
            <Input
              className="my-3"
              value={email}
              setValue={setEmail}
              placeholder='name@mail.com'
              label='Email'
              type='text'
            />
            <Input
              className="my-3"
              value={password}
              setValue={setPassword}
              placeholder='at least 12 characters'
              label='Password'
              type='password'
            />
            <Input
              className="my-3"
              value={conPassword}
              setValue={setConPassword}
              placeholder='confirm Password'
              label='Confirm password'
              type='password'
            />
          </div>
          <div className="flex gap-2">
            <Checkbox color="cyan" checked={term} onClick={(e) => setTerm(!term)} className="mt-[2px]" />
            <div className="text-grey-10 text-sm font-[500]">I have read and agree with Shugic’s
              <span className="text-primary font-bold"> Terms of service </span>
              and our
              <span className="text-primary font-bold"> privacy policy</span>
            </div>
          </div>

          <Button className='w-full mt-4 text-white bg-primary' onClick={() => signUp()}>
            {userState.isLoading ? <Loader className="w-[6px] h-[6px] text-[5px]" /> : 'CREATE ACCOUNT'}
          </Button>
          <div className="text-[#989898] text-sm font-[500] mt-4 text-center">
            Already have an account? <button className="text-primary font-bold" onClick={() => navigate('/signin')}>Sign in</button>
          </div>
        </section>
      </div>

      <div className="hidden sm:block sm:w-[45%]">
        <AuthCarousel />
      </div>
    </div>
  )
}

export default SignUp