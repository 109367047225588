import React,{useState} from "react";
import Button from '../../Buton';

const data = [
    { id: 1, name: 'Table',value:'TABLE'},
    { id: 2, name: 'Bar',value:'BAR'},
    { id: 3, name: 'Line',value:'LINE' },
    { id: 4, name: 'Donut',value:'DONUT' },
    { id: 5, name: 'Gauge',value:'DONUTGAUAGE' },
    { id: 6, name: 'Funnel',value:'FUNNEL' },
    { id: 7, name: 'StackBar',value:'STACKBAR'},
    { id: 8, name: 'GroupedBar',value:'GROUPEDBAR'},
    { id: 9, name: 'HeatMap',value:'HEATMAP' },
    { id: 10, name: 'TreeMap',value:'TREEMAP' },
    { id: 11, name: 'TimeLine',value:'TIMELINE' },
    { id: 12, name: 'TextCloud',value:'TEXTCLOUD'},
    { id: 13, name: 'Card',value:'FLATCARD'},
    { id: 14, name: 'GridBar',value:'GRIDBAR'},
    { id: 15, name: 'GridLine',value:'GRIDLINE'},
    { id: 16, name: 'GridDonut',value:'GRIDDONUT'},
    { id: 17, name: 'Radar',value:'RADAR' },
    { id: 18, name: 'Sankey',value:'SANKEY' },
    { id: 19, name: 'FilteredBar',value:'FILTEREDBAR' },
    { id: 20, name: 'Scatter',value:'SCATTER' },
    { id: 21, name: 'Sunburst',value:'SUNBURST' },
    { id: 22, name: 'CirlePacking',value:'CirlePacking' },
    // { id: 23, name: 'ParliamentDonut',value:'ParliamentDonut' },
    { id: 24, name: 'RecordCard',value:'RECORDCARD' },
    { id: 25, name: 'WebCard',value:'WebCard' },
];
const alternatives=({visualData,chartType})=>{
    const spec=visualData.visuals[0].spec
    const dimensionsLength=spec.dimensions.length
    const metricsLength=spec.metrics.length
    const foundItem = data.find(item => item.value === chartType);
    let alternativesData=[]
    if(dimensionsLength===1&&metricsLength===1){
        alternativesData=['Table','Bar','Line','Donut','Gauge','Funnel','TreeMap','Radar','TextCloud']    
    }else if(dimensionsLength===2&&metricsLength===1){
        alternativesData=['Table','Radar','TreeMap','HeatMap','GroupedBar','StackBar','TextCloud','GridBar','GridLine','GridDonut','FilteredBar','Sunburst','Sankey','CirlePacking','ParliamentDonut']    
    }else if(metricsLength>=2){
        alternativesData=['Scatter']
    }else{
        alternativesData=['Table']
    }
    if(spec.vtype.vtype==='RECORDCARD'){
        alternativesData.push('WebCard')
    }
    alternativesData=alternativesData.concat(foundItem?.name)
    return alternativesData

}
const ToggleChart = ({ onImageClick,chartType,visualData}) => {
    const [selectedCharts, setSelectedCharts] = useState(chartType);
    const handleImageClick=(name)=>{
        setSelectedCharts(null);
        setSelectedCharts(name);
        onImageClick(name)
    }
    const alternativesList=alternatives({visualData,chartType})

    return (
        <div className="flex flex-col gap-2.5">
            <p className="text-[14px]  font-medium ">View Options</p>
            <hr />
            <div className="flex flex-wrap">
                {data.map(item => (
                    <div key={item.id} className="w-full sm:w-1/3 md:w-1/5 p-2 flex flex-col items-center">
                        <Button className={`relative border rounded p-1 h-[32px] w-[32px] flex items-center justify-center ${alternativesList?.indexOf(item.name) === -1?'':'hover:bg-[#0694A3]'}  ${selectedCharts === item.value?'bg-[#0694A3]':''}`} disabled={alternativesList?.indexOf(item.name) === -1?true:false} onClick={() => handleImageClick(item.value)}>
                            <img src={'/chartImages/'+item.name+(alternativesList?.indexOf(item.name) === -1?'_BW.png':'_C.png')} alt={item.name} width="20px" height="20px" />
                            {selectedCharts === item.value && (
                                <div className="absolute top-0 right-0 mt-1 mr-1">
                                    <div className="w-2 h-2 bg-white rounded-full border-1 border-[#0694A3] flex items-center justify-center">
                                        <svg className="w-2 h-2 text-[#0694A3]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                        </svg>
                                    </div>
                                </div>
                            )}
                        </Button>
                        <div className='text-[9px] mt-1'>{item.name}</div>
                    </div>
                ))}
            </div>
      </div>
    );
  
};
export { ToggleChart };
