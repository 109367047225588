import React, { useRef, useCallback, useEffect,useState} from 'react';
import {  ArchiveIcon,ChevronRightIcon } from "@radix-ui/react-icons";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  ControlButton ,
  MiniMap,
  Background,
  ConnectionLineType,
  MarkerType,
  getIncomers,
  getOutgoers,
  getConnectedEdges,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { updateGraph } from "../../store/slices/smartdocSlice";
import { useDispatch, useSelector } from "react-redux"
// 剩余需要解决的问题：
// 结点位置的问题 【优先级低】
// 多个样式更改为与card相同【优先级低】
// card的bugfix

import CustomChart from './CustomChart';
import CustomCard from './CustomCard';
import ConnectionLine from './ConnectionLine';
import CustomGroupChart from './CustomGroupChart';
import GraphInput from './GraphInput'
import CustomText from './CustomText'
import { SettingsDrawer } from "./GraphChart/SettingsDrawer"
const defaultViewport = { x: 50, y: 40, zoom: 0.9 };

const nodeTypes = {
  CustomChart: CustomChart,
  CustomCard:CustomCard,
  CustomGroupChart:CustomGroupChart,
  CustomText:CustomText
};

const Graph = () => {
  const { graphId, token } = useParams()
  const dispatch = useDispatch()
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const [clickNode, setClickNode]= useState({});
  const [value, setValue] = useState('')
  const [visible, setVisible] = useState(false);
  const [id, setId] = useState('');
  const [isShowInput, setIsShowInput] = useState(true);
  const graphDoc = useSelector((state) => state.smart.graph)
  const navigate = useNavigate()
  const [isFirst, setIsFirst] = useState(null)
  const textStyle={
    backgroundColor:'white',
    color:'#9ca3af',
    'fontSize':'12px',
  }

  const onConnect = useCallback((params) => {
    setEdges((eds) => addEdge({ ...params, animated: true }, eds));
  }, [setEdges]);
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);
  // 点击node
  const onNodeClick= (event, element) => {
    setClickNode(element)
  };
  // 点击看板
  const onPaneClick= (event, element) => {
    setClickNode({})
  };
  // 新增按钮
  const onElementClick =  (command) => {
    if (isFirst) {
      setIsFirst(false)
      const words = value.split(/[ ,.?!;:'"-]/);
      // Filter out empty strings or strings with only whitespace
      const filteredWords = words.filter((word) => word.trim() !== '');
      if (!graphDoc.id && value) {
        const name =  `${filteredWords[0].charAt(0).toUpperCase() + filteredWords[0].slice(1)}${filteredWords[1] ? ` ${filteredWords[1]}` : ''}`
        updateDoc({ data: [], name: name })
      }
    }
    addNode(command);
  }; 
  const addNode=async (command)=>{
    let type=command.replace('/', '')==='ai'?'CustomChart':'CustomText'
    // 生成子节点
    if(JSON.stringify(clickNode) !== '{}'){
      // 生成一个子节点
      let x=0
      let y=0
      const outgoers = getOutgoers(clickNode, nodes, edges);
      if(outgoers.length>=1){
        // 不是第一个子节点
        let maxY = Math.max(...outgoers.map(obj => obj.position.y));
        x=clickNode.position.x + 300
        y=maxY+200
      }else{
        // 第一个子节点
        x=clickNode.position.x + 300
        y=clickNode.position.y
      }

      const newElement = {
        id: (Math.random() * 1000).toString(),
        type: type,   
        data: { label: `New Node`,q:value ,textStyle:textStyle,onChange: onChange,onChangeStyleClick:onChangeStyleClick},
        position: { x: x, y:  y},
        style: {
          borderRadius: '12px',
          boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
        },
      };
      // 生成一条新边，连接点击的节点和新生成的节点
      const newEdge = {
        id: `edge-${newElement.id}`,
        source: clickNode.id,
        target: newElement.id,
        // type: 'default',
        animated: true, 
        markerEnd: { type: MarkerType.Arrow },
      };

      // 更新元素数组，包括新节点和新边
      setNodes((prevNodes) => [...prevNodes, newElement]);
      setEdges((prevEdges) => [...prevEdges, newEdge]);
    }else{
    // 生成所有的首个父节点
      const newElement = {
        id: (Math.random() * 1000).toString(),
        type: type,   
        data: { label: `New Node`,q:value ,textStyle:textStyle,onChange: onChange, onChangeStyleClick:onChangeStyleClick },
        position: { x: 0, y: 0 },
        style: {
          borderRadius: '12px',
          boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
        },
      };

      setNodes((prevNodes) => [...prevNodes, newElement]);
    }
    setValue('')
  }
  const onNodesDelete = useCallback(
    (deleted) => {
      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);
          const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));
          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({ id: `${source}->${target}`, source, target,animated: true,  }))
          );
          return [...remainingEdges, ...createdEdges];
        }, edges)
      );
    },
    [nodes, edges]
  );
  const onGroupChange = (nodeId, visualData) => {
    setNodes((prevNodes) => {
      const nodeToGroup= prevNodes.find((node) => node.id === nodeId);
      if (nodeToGroup) {
        const newElement = {
          id: nodeToGroup.id,
          type: 'CustomChart',   
          data: { label: `New Node`,visuals:visualData ,onChange: onChange, },
          position: nodeToGroup.position,
          style: {
            borderRadius: '12px',
            boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
          },
        };
        return [
          ...prevNodes.filter((node) => !(node.id === nodeId||node.parentNode===nodeId)),
          newElement,
        ];
      }  
    });
  };
  const onChange = (nodeId,visualData) => {
    setNodes((prevNodes) => {
      // 找到要更改的节点
      const nodeToChange = prevNodes.find((node) => node.id === nodeId);
      const visuals = visualData.visuals[0]
      const { dimensions, metrics } = visuals.spec;
      const xAxisData = visuals.data[dimensions[0].column];
      const seriesData = visuals.data[metrics[0].column];
      if (nodeToChange) {
        // 创建一个包含多个子节点的节点组
        const newNodeGroup = {
          id: nodeId,
          type: 'CustomGroupChart',
          data: { label:visuals.title,onChange: onGroupChange,visualData:visualData},
          position: nodeToChange.position,
          style: {
            width: 220,
            height:20+xAxisData.length*42,
            borderRadius: '12px',
            boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
          },
        };
       // 根据 xAxisData 的长度创建子节点
       const childNodes = xAxisData.map((dataPoint, index) => ({
        id: `child-${index + 1}-${nodeToChange.id}`,
        type: 'CustomCard',
        data: { label: `Child Node ${index + 1}`, xAxisData: dataPoint,seriesData:seriesData[index] },
        position: { x: 10, y: 12 + index * 42 }, // 调整子节点的位置
        parentNode: newNodeGroup.id,
        extent: 'parent',
        style: {
          borderRadius: '12px',
          boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
        },
       }));
        return [
          ...prevNodes.filter((node) => node.id !== nodeToChange.id),
          newNodeGroup,
          ...childNodes,
        ];
      }
      return prevNodes;
    });
  };  
  const onShowInput=()=>{
    setIsShowInput(!isShowInput)
  }
  const onChangeStyleClick=(id)=>{
    setVisible(!visible)
    setId(id)
  }
  const updateDoc = useCallback((data) => {
    dispatch(updateGraph(data))
  }, [dispatch])
  // 保存
  const onSave = async () => {
    if (!graphDoc.name) {
      toast.warn('Graph name is empty now.')
      return
    }
    const loading = toast.loading("Please wait ...")
    try {
      const save={
        nodes,edges
      }
      if (graphId) {
        await axios.patch(`graph/save/${graphId}`, {
          data: { data: save, name: graphDoc.name }
        })
        toast.dismiss(loading)
        toast.success('Your doc has been updated successfully.')
      } else {
        const res = await axios.post('graph/create', {
          data: {
            name: graphDoc.name ,
            data: save
          }
        })
        toast.success('Your new doc has been created successfully.')
        toast.dismiss(loading)
        const { data } = res
        data.id && navigate(`/graph/${data.id}`)
        }
    } catch (e) {
      toast.dismiss(loading)
      toast.error(e.message)
    }
  }
  // 根据历史id的时候获取历史的
  const getGraph = useCallback(async (graphId) => {
    const loading = toast.loading("Please wait ...")
    try {
      const res = await axios.get(`graph/${graphId}`)
      toast.dismiss(loading)
      const { data } = res
      if (data) {
       setIsFirst(false)
       setEdges(data.data.data.edges)
       setNodes(data.data.data.nodes)
       updateDoc({ ...data.data.data, name: data.data.name })
      }
    } catch (e) {
      toast.dismiss(loading)
      toast.error(e.message)
    }
  }, [updateDoc])

  useEffect(() => {
    if (graphId) {
      getGraph(graphId)
    } else {
      createNew()
    }
  }, [graphId, getGraph])

  const createNew = useCallback(() => {
    !token && setIsFirst(true)
    setEdges([])
    setNodes([])
    updateDoc({ data: [], name: 'Untitled' })
  }, [updateDoc, token])


  return (
    <div className='h-[95vh] w-full relative'>
      <ReactFlowProvider>
        <div className="reactflow-wrapper w-full h-full" ref={reactFlowWrapper}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onDragOver={onDragOver}
            onConnectStart={(e) => e.stopPropagation()}
            nodeTypes={nodeTypes}
            defaultViewport={defaultViewport}
            connectionLineComponent={ConnectionLine}
            connectionLineType={ConnectionLineType.SmoothStep}
            // attributionPosition="bottom-left"
            onNodeClick={onNodeClick}
            onPaneClick={onPaneClick}
            onNodesDelete={onNodesDelete}
          >
            <Controls className='mb-6'>
              <ControlButton onClick={onSave}>
                <ArchiveIcon color="#000"  />
              </ControlButton>
              <ControlButton onClick={onShowInput}>
                <ChevronRightIcon color="#000" />
              </ControlButton>
            </Controls>
            <Background color="#fff" variant="dots"  style={{ backgroundColor: '#FAFBFC' }}/>
          </ReactFlow>
        </div>
      </ReactFlowProvider>
       {isShowInput&&(<div className='absolute bottom-6 left-1/2 transform -translate-x-1/2 w-[80%]' >
         <GraphInput onElementClick={onElementClick} query={value} setQuery={setValue}></GraphInput> 
      </div>)}
      <SettingsDrawer
          visible={visible}
          onClose={() => setVisible(false)}
          id={id}
          nodes={nodes}
          setNodes={setNodes}
        />
    </div>
  );
};


export default Graph;
