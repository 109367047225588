import React, { useEffect, useState } from 'react';
const processDataForCard = (visualData,rndData,setRndData,rndNo) => {
  const visual = visualData
  let data = visual.data;
  let dimensions = visual.spec.dimensions[0].column;
  let metrics = visual.spec.metrics[0].column;
  let tempList = [];
  for (let i = 0; i < data[dimensions].length; i++) {
    let coloured = false;
    let dimensionItem = data[dimensions][i];
    let metricItem = data[metrics][i];
    tempList.push({
      coloured: coloured,
      one: {
        name: dimensions,
        value: dimensionItem,
      },
      two: {
        name: metrics,
        value: metricItem,
      },
    });
  }
  // let rndDataCopy = JSON.parse(JSON.stringify(rndData));
  // rndDataCopy[rndNo].changeChartData.data=tempList
  // setRndData(rndDataCopy)
  return { tempList }
};
const Card = ({ visualData, rndData, rndNo ,setRndData}) => {
  const { tempList } = processDataForCard(visualData,rndData,setRndData,rndNo)

  useEffect(() => {
    if(rndData[rndNo].changeChartData.data.length<=0){
      rndData[rndNo].changeChartData.data=tempList
      setRndData(rndData)
    }
  }, [tempList]);
  // const settingChartData = { ...rndData[rndNo]?.changeChartData }
  // useEffect(() => {
  //   console.log({settingChartData})
  //   if(settingChartData.data.length<=0){
  //     settingChartData.data=tempList
  //     rndData[rndNo] = { ...rndData[rndNo], changeChartData: settingChartData }
  //     setRndData([...rndData])
  //   }
  // }, [tempList]);
  return (
    <div className="flex  items-center flex-wrap gap-2">
      {tempList.map((item, index) => (
        <div className={`max-w-[280px] min-w-[180px] h-full   box-border border rounded-[8px] flex items-center overflow: hidden;
           ${(rndData[rndNo].changeChartData.styleList?.length > 0 && rndData[rndNo].changeChartData.styleList[index] && rndData[rndNo].changeChartData.styleList[index].position)
            ? rndData[rndNo].changeChartData.styleList[index].position === 'bottom' ? 'flex-col-reverse' :
              (rndData[rndNo].changeChartData.styleList[index].position === 'top' ? 'flex-col' :
                (rndData[rndNo].changeChartData.styleList[index].position === 'right' ? 'flex-row-reverse' : '')) : ''}`} style={{ 'background': rndData[rndNo].changeChartData.styleList?.length > 0 && rndData[rndNo].changeChartData.styleList[index] ? rndData[rndNo].changeChartData.styleList[index].color : '' }} key={index}>

          {rndData[rndNo].changeChartData.styleList?.length > 0 && rndData[rndNo].changeChartData.styleList[index] && rndData[rndNo].changeChartData.styleList[index].imageUrl &&
            <div className='flex-1 flex justify-center items-center  '>
              <img src={rndData[rndNo].changeChartData.styleList[index].imageUrl} alt="Uploaded" className='w-[100%] h-[100%]  bg-cover bg-no-repeat bg-center' />
            </div>}
          <div className="flex-1 flex flex-col justify-between h-full w-full overflow-hidden p-[8px]" >
            <div className="flex flex-row items-center justify-between w-full overflow-hidden">
              <span className='text-[#4F4764] text-[12px]'>{item.one.name}</span>
              {/* <h3 className='text-white text-[14px] font-bold whitespace-nowrap ml-4'>{ item.one.value }</h3> */}
              <div className="h-5 px-2 py-1 bg-cyan-600 bg-opacity-10 rounded-[100px] flex-col justify-start items-start gap-2.5 inline-flex">
                <div className="text-cyan-600 text-[10px] font-bold  capitalize leading-3 flex">{item.one.value}</div>
              </div>
            </div>
            <div className="mt-[8px] flex flex-row items-center justify-between w-full overflow-hidden">
              <span className='text-[#4F4764] text-[12px]'>{item.two.name}</span>
              <div className="px-2 py-1 bg-sky-400 bg-opacity-10 rounded-[100px] flex-col justify-start items-start gap-2.5 inline-flex">
                <div className="text-sky-400 text-[10px] font-bold  uppercase leading-3">{item.two.value}</div>
              </div>
              {/* <h3 className='text-[#2CB8F1] bg-[rgba(44, 184, 241, 0.10)]  rounded-[100px] text-[14px] font-bold ml-4'>{ item.two.value }</h3> */}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export { Card };
