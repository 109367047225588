import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next'
import { DropdownMenu } from '@radix-ui/themes';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation,useNavigate } from 'react-router-dom';
import { DashboardIcon, HamburgerMenuIcon ,CaretDownIcon} from '@radix-ui/react-icons';
import axios from 'axios';

import { useOutSideClick } from '../hooks/useOutSideClick'
import { BellIcon, DataIcon, ExploreIcon, GraphIcon, WorkFlowIcon } from "../components/Icons";
import { updateSmartdoc,updateGraph, setShowShareModal, setShowCommentDrawer, setIsGrouping } from '../store/slices/smartdocSlice'

function Navbar({ showSidebar, setShowSidebar, setLanguage, language, theme, setTheme }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const path = useLocation().pathname
  const nameRef = useRef(null)

  const smartDoc = useSelector((state) => state.smart.smartDoc)
  const graph = useSelector((state) => state.smart.graph)
  const unreadComments = useSelector((state) => state.smart.unreadComments)
  const isGrouping = useSelector(state => state.smart.isGrouping)
  const [editable, setEditable] = useState(false)
  const [graphData, setGraphData] = useState([])
  // const dropdownLists = [
  //   { text: 'English', value: 'en' },
  //   { text: '中文', value: 'ch' },
  //   { text: 'Deutsch', value: 'de' },
  // ]

  // const DropDownItem = ({ text, value }) => (
  //   <DropdownMenu.Item
  //     onClick={() => {
  //       setLanguage(value);
  //       i18n.changeLanguage(value)
  //     }}
  //   >
  //     {text}&nbsp;
  //     <CheckIcon className={`${language === value ? 'opacity-1' : 'opacity-0'}`} />
  //   </DropdownMenu.Item>
  // )

  const getAllGraphDoc = useCallback(async () => {
    const isPublicShare = path.includes('type=public')
    try {
      const res = await axios.get(`graph/all`)
      const { data } = res
      setGraphData(data)
    } catch (e) {
      // toast.error(e.message)
    }
  }, [graph])

  useEffect(() => {
    getAllGraphDoc()
  }, [getAllGraphDoc])

  const hasUnread = unreadComments && unreadComments.length > 0
  useOutSideClick(setEditable, nameRef)
  return (
    <section className={`${showSidebar ? 'sm:pl-[250px] pl-[70px]' : 'pl-2'} z-[7] w-full h-10 fixed top-0
      bg-white dark:bg-[#111113] transition-all flex justify-between z-10`}>
      <div className="flex h-full items-center sm:px-2">
        <button onClick={() => setShowSidebar(!showSidebar)}>
          <HamburgerMenuIcon color='#bbb' strokeWidth='0.6px' stroke='#bbb' />
        </button>
        <img
          className='w-4 ml-2'
          src='/logo.png'
          alt="shugic" />

        <div className="flex ml-2 text-[13px]">
          <p className="text-[#00000066] hidden sm:block">
            Shugic&nbsp;&nbsp;
          </p>
          <span className='hidden sm:block'>/</span>
          <p className='flex'>&nbsp;&nbsp;
            {path.indexOf('smartdoc') > -1 &&
              <><ExploreIcon color={"#666"} width={18} height={18} /> <span>&nbsp;&nbsp;-&nbsp;</span>
                {
                  editable
                    ?
                    <input
                      placeholder='Type here'
                      value={smartDoc.name}
                      className='md:w-60 w-28 text-[#666] pl-1 outline-none border-b border-[#aaa'
                      ref={nameRef}
                      onKeyDown={async (e) => {
                        if (e.key === 'Enter') {
                          setEditable(false)
                          await axios.patch(`smartdoc/save/${smartDoc.id}`, {
                            data: { data: smartDoc.data.data, name: e.target.value }
                          })
                        }
                      }}
                      onChange={(e) => dispatch(updateSmartdoc({ ...smartDoc, name: e.target.value }))}
                      onBlur={(e) => {
                        if (!smartDoc.id || smartDoc.id == undefined) return
                        axios.patch(`smartdoc/save/${smartDoc.id}`, {
                          data: { data: smartDoc.data.data, name: e.target.value }
                        })
                      }}
                    />
                    : <span
                      onDoubleClick={() => {
                        setEditable(true)
                        setTimeout(() => {
                          nameRef.current?.focus()
                        }, 0)
                      }}
                      className='font-[500] text-[#666] pl-1 text-overflow-1 w-28 md:w-60 border-b border-transparent'>
                      {smartDoc.name ? smartDoc.name : 'Type here'}
                    </span>
                }
              </>
            }
            {path.indexOf('datesets') > -1 && <><DataIcon color={"#666"} width={17} height={17} /> <span>&nbsp;&nbsp;-&nbsp;</span></>}
            {path.indexOf('contact') > -1 && <>Contact Sales</>}
            {path.indexOf('graph') > -1 && <><GraphIcon color={"#666"} width={18} height={16} /> <span>&nbsp;&nbsp;-&nbsp;</span>
                {
                  editable
                    ?
                    <input
                      placeholder='Type here'
                      value={graph.name}
                      className='max-w-[80px] max-w-[120px] text-[#666] pl-1 outline-none border-b border-[#aaa] w-fit'
                      ref={nameRef}
                      onKeyDown={async (e) => {
                        if (e.key === 'Enter') {
                          setEditable(false)
                          await axios.patch(`graph/save/${graph.id}`, {
                            data: { data: graph.data, name: e.target.value }
                          })
                        }
                      }}
                      onChange={(e) => dispatch(updateGraph({ ...graph, name: e.target.value }))}
                      onBlur={(e) => {
                        if (!graph.id) return
                        axios.patch(`graph/save/${graph.id}`, {
                          data: { data: graph.data, name: e.target.value }
                        })
                      }}
                    />
                    : <span
                      onDoubleClick={() => {
                        setEditable(true)
                        setTimeout(() => {
                          nameRef.current?.focus()
                        }, 0)
                      }}
                      className='font-[500] text-[#666] pl-1 text-overflow-1 w-[80px] sm:w-[120px] border-b border-transparent'>
                      {graph?(graph.name ? graph.name : 'Type here'):''}
                    </span>
                }
                 <DropdownMenu.Root>
                    <DropdownMenu.Trigger className='outline-none'>
                       <CaretDownIcon className="w-5 h-5 mb-[2px]" />
                    </DropdownMenu.Trigger>

                    <DropdownMenu.Content className="sm:w-[228px] w-[190px] text-black">
                      {graphData.map((item, index) => (
                        <DropdownMenu.Item className="w-full bg-white hover:bg-[#eee] my-1" key={index}>
                            <div className="cursor-pointer flex gap-4 py-1 items-center text-black" onClick={() => navigate(`/graph/${item.id}`)}>
                              <div>{item.data.name}</div>
                            </div>
                          </DropdownMenu.Item>
                      ))}
                    </DropdownMenu.Content>
                  </DropdownMenu.Root>
              </>}

            
            {path.indexOf('workflow') > -1 && <><WorkFlowIcon color={"#666"} width={17} height={17} /> <span>&nbsp;&nbsp;-&nbsp;</span></>}
            {path.indexOf('mydoc') > -1 && t('My Docs')}
            {path.indexOf('pricing') > -1 && t('Pricing')}
            {path.indexOf('myaccount') > -1 && t('My Account')}
          </p>
        </div>
      </div>

      <div className="flex items-center gap-2 sm:px-4 scale-[0.8] sm:scale-[1] text-[13px]">
        <button onClick={() => dispatch(setShowShareModal(true))}>Share</button>
        <button className='relative' onClick={() => dispatch(setIsGrouping(!isGrouping))}>
          <DashboardIcon width={16} height={16} color={isGrouping ? "#0694a3" : "#666"} />
        </button>
        <button className='relative' onClick={() => dispatch(setShowCommentDrawer(99999))}>
          {hasUnread &&
            <div className='w-[6px] h-[6px] rounded-full bg-red-500 absolute top-[2px] right-[2px]' />}
          <BellIcon width={18} height={18} color="#666" />
        </button>
      </div>
    </section>
  )
}

export default Navbar;