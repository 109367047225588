import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

function convert_table_to_json_for_network(inputTable, layer1Column, layer2Column, colorColumn, sizeColumn) {
  try {
    const data = {
      layers: [],
      edges: []
    };

    const layer1 = {
      name: layer1Column,
      nodes: []
    };

    const layer2 = {
      name: layer2Column,
      nodes: []
    };

    inputTable.forEach(row => {
      const layer1Value = row[layer1Column];
      const layer2Value = row[layer2Column];
      const groupValue = row[colorColumn];
      const countValue = row[sizeColumn];

      let layer1Node = {
        id: `${layer1Column}.${layer1Value}`,
        name: layer1Value,
        group: groupValue,
        count: countValue
      };

      if (!layer1.nodes.some(node => node.id === layer1Node.id)) {
        layer1.nodes.push(layer1Node);
      }

      let layer2Node = {
        id: `${layer2Column}.${layer2Value}`,
        name: layer2Value
      };

      if (!layer2.nodes.some(node => node.id === layer2Node.id)) {
        layer2.nodes.push(layer2Node);
      }

      data.edges.push({
        from: layer1Node.id,
        to: layer2Node.id
      });
    });

    data.layers.push(layer1);
    data.layers.push(layer2);

    return data;
  } catch (error) {
    console.error('Error in data conversion:', error);
    return { layers: [], edges: [] };
  }
}

const CircularNetwork = ({ visualData, rndData, rndNo }) => {
  let vdata = visualData.data;
  const keys = Object.keys(vdata);
  const length = vdata[keys[0]].length;
  const result = Array.from({ length }, (_, index) => {
    return keys.reduce((obj, key) => {
      obj[key] = vdata[key][index];
      return obj;
    }, {});
  });

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const svgRef = useRef(null);
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [selectedNode, setSelectedNode] = useState(null);

  useEffect(() => {
    try {
      const l1 = rndData[rndNo]?.setting?.layer1Column || visualData.spec.columns[0].column;
      const l2 = rndData[rndNo]?.setting?.layer2Column || visualData.spec.columns[1].column;
      const colorColumn = rndData[rndNo]?.setting?.colorColumn || visualData.spec.columns[0].column;
      const sizeColumn = rndData[rndNo]?.setting?.sizeColumn || '';
      const newData = convert_table_to_json_for_network(result, l1, l2, colorColumn, sizeColumn);
      setData(newData);
    } catch (error) {
      console.error('Error in initial data processing:', error);
      setError('Failed to load data.');
    }
  }, [rndData, rndNo, visualData]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      if (!entries || !entries.length) return;
      const { width, height } = entries[0].contentRect;
      setDimensions({ width, height });
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!data || !svgRef.current || !dimensions.width || !dimensions.height) return;

    try {
      const svg = d3.select(svgRef.current);
      svg.selectAll('*').remove();

      const { width, height } = dimensions;
      const centerX = width / 2;
      const centerY = height / 2;

      svg.attr('width', width).attr('height', height);

      const g = svg.append('g').attr('transform', `translate(${centerX},${centerY})`);

      const radius = Math.min(width, height) / 2 - 50;
      const layerRadius = d3
        .scaleLinear()
        .domain([0, data.layers.length - 1])
        .range([radius * 0.3, radius]);

      const colorScale = d3.scaleOrdinal().domain(['SEED', 'EXIT', 'DEFAULT']).range(['#b881f3', '#cb91f5', '#7e57c2']);

      const sizeScale = d3
        .scaleLinear()
        .domain([0, d3.max(data.layers.flatMap(layer => layer.nodes.map(node => node.count || 0)))])
        .range([5, 20]);

      g.selectAll('.layer-circle')
        .data(data.layers)
        .enter()
        .append('circle')
        .attr('class', 'layer-circle')
        .attr('r', (d, i) => layerRadius(i))
        .attr('fill', 'none')
        .attr('stroke', '#e0e0e0')
        .attr('stroke-width', 1);

      const nodes = data.layers.flatMap((layer, layerIndex) =>
        layer.nodes.map((node, nodeIndex) => ({
          ...node,
          layerIndex,
          angle: (nodeIndex / layer.nodes.length) * 2 * Math.PI - Math.PI / 2
        }))
      );

      const edgeMap = new Map(
        data.edges.flatMap(edge => [
          [edge.from, edge.to],
          [edge.to, edge.from]
        ])
      );

      const isRelatedNode = node => {
        if (!selectedNode) return true;
        if (node.id === selectedNode.id) return true;
        return edgeMap.has(selectedNode.id) && edgeMap.get(selectedNode.id).includes(node.id);
      };

      const isRelatedEdge = edge => {
        if (!selectedNode) return true;
        return edge.from === selectedNode.id || edge.to === selectedNode.id;
      };

      const edges = g
        .selectAll('.link')
        .data(data.edges)
        .enter()
        .append('line')
        .attr('class', 'link')
        .attr('stroke', '#999')
        .attr('stroke-opacity', 0.6);

      const nodeElements = g
        .selectAll('.node')
        .data(nodes)
        .enter()
        .append('circle')
        .attr('class', 'node')
        .style('cursor', 'pointer')
        .on('click', (event, d) => {
          setSelectedNode(selectedNode && selectedNode.id === d.id ? null : d);
        });

      const labels = g
        .selectAll('.label')
        .data(nodes)
        .enter()
        .append('text')
        .attr('class', 'label')
        .attr('font-size', '12px')
        .attr('fill', '#333');

      const legend = svg.append('g').attr('transform', `translate(${width - 100}, 20)`);

      const legendData = Array.from(new Set(nodes.map(n => n.group))).filter(Boolean);

      legend.selectAll('.legend-item').data(legendData).enter().append('g').attr('class', 'legend-item');

      const updateSelection = () => {
        nodeElements.attr('fill', d => (isRelatedNode(d) ? colorScale(d.group || 'DEFAULT') : '#ccc'));
        edges
          .attr('stroke', d => (isRelatedEdge(d) ? '#999' : '#eee'))
          .attr('stroke-opacity', d => (isRelatedEdge(d) ? 0.6 : 0.2));
        labels.attr('fill', d => (isRelatedNode(d) ? '#333' : '#999'));
      };

      updateSelection();

      return () => {
        nodeElements.on('click', null);
      };
    } catch (error) {
      console.error('Error in D3 rendering:', error);
      setError('Failed to render network.');
    }
  }, [data, selectedNode, dimensions]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div ref={containerRef} className="w-full h-full">
      <svg ref={svgRef} className="w-full h-full"></svg>
    </div>
  );
};

export default CircularNetwork;
