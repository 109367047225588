
export const freeThings = [
    'MAX 1 Superdoc',
    'Max 10 Cells',
    'Max 10k tokens per query',
    'Share with others',
    'Unlimited sharing',
    'Text to infographics using ad-hoc data',
    '10 Comments per Superdoc',
    'Beautiful diagrams, infographics and interactive charts',
]
export const proThings = [
    'Text to infographics using ad-hoc data',
    'Beautiful diagrams, infographics and interactive charts',
    'Unlimited Superdoc',
    'Unlimited size of content per Superdoc',
    'Max 1M tokens per query',
    'Share with others',
    'Configure signals and automate actions',
    'Periodic and automated update using local files',
    'Unlimited share',
    'Unlimited comments',
]
export const enterThings = [
    'Text to infographics using ad-hoc data',
    'Beautiful diagrams, infographics and interactive charts',
    'Unlimited Superdoc',
    'Unlimited size of content per Superdoc',
    'Max 1M tokens per query',
    'Share with others',
    'Unlimited share',
    'Unlimited comments',
    'DataBases & ERP as data sources',
    'Generate and orchestrate the entire data science workflow & deployment',
    'Bleeding edge data analytics incl. churn prediction, process mining, graph embedding and more',
    'Data Query without sending your data to OpenAI or other 3rd party.',
]

export const faqs = [
    {
        id: 1,
        header: "How can I get support?",
        text:
            <>
                <p>- The best way to get fast response and support is to join our slack channel here: ______</p>
                <p>- Alternatively, you can send us an email to help@shugic.com</p>
            </>
    },
    {
        id: 2,
        header: "What payment methods are supported?",
        text:
            <>
                <p>- Creditcard</p>
                <p>- Paypal</p>
            </>
    },
    {
        id: 3,
        header: "When and how often is it billed?",
        text:
            <>
                <p>- For the monthly billing, it is billed on the same day you choose to pay. It will be billed on the same day next month and the month after until you cancel. If the day you choose to pay is the 29th, 30th or 31th, then the billing date will be the 28th for the following months.</p>
                <p>- For the yearly billing, it is billed on the same day you choose to pay. It will be billed on the same day or within 2 days prior to that date in the following years until you cancel.</p>
            </>
    },
    {
        id: 4,
        header: "Can I upgrade or downgrade my subscription?",
        text:
            <>
                <p>- Upgrade: Yes, sure you can. Please check the “My Subscription” page.</p>
                <p>- Downgrade: Yes, sure you can. Please check the “My Subscription” page.</p>
            </>
    },
    {
        id: 5,
        header: "Can I cancel my subscription?",
        text:
            <>
                <p>- Yes, sure you can. Please check the “My Subscription” page.</p>
            </>
    },
    {
        id: 6,
        header: "When will the billing be stopped after my cancellation?",
        text:
            <>
                <p>- If your cancelation is 7 calendar days before the next billing date, then it will take effect immediately. No future billing will take place.</p>
            </>
    },
]

export const LookupKeys = {
    free: 'shugic_free',
    pro: 'shugic_pro',
    enter: 'shugic_enter',
}