import React from "react";
import { MainTimeline } from "timeline-card";
import "timeline-card/dist/index.css";

const TimeLine = ({ visualData, rd }) => {
  const apiData = visualData?.data?.data;

  const structuredTimelineData = [
    {
      timelineType: "VerticalTimeline1",
      timelineItems: apiData,
    },
  ];
  return (
    <div className="w-full">
      {" "}
      <MainTimeline timelineData={structuredTimelineData} />
    </div>
  );
};

export { TimeLine };
