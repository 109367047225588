import React from "react";
import { MinimizeIcon, MaximizeIcon } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import SmartInput from "./SmartInput";
import { Button } from "../ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";

const MinimizableSmartInput = ({
  smartdocTheme,
  setSmartdocTheme,
  isFirst,
  setIsFirst,
  rndData,
  setRndData,
  parentRef,
  removeNode,
  updateDoc,
  isMinimized,
  setIsMinimized,
}) => {
  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const buttonVariants = {
    initial: { scale: 0.8, opacity: 0 },
    animate: { scale: 1, opacity: 1 },
    exit: { scale: 0.8, opacity: 0 },
    tap: { scale: 0.95 },
  };

  const containerVariants = {
    expanded: {
      height: "auto",
      opacity: 1,
      transition: {
        height: { duration: 0.3, ease: "easeOut" },
        opacity: { duration: 0.2, delay: 0.1 },
      },
    },
    collapsed: {
      height: 0,
      opacity: 0,
      transition: {
        height: { duration: 0.3, ease: "easeIn" },
        opacity: { duration: 0.2 },
      },
    },
  };

  return (
    <TooltipProvider>
      <div className="w-full">
        <AnimatePresence mode="wait">
          {isMinimized ? (
            <motion.div
              key="minimized"
              className="flex justify-center"
              initial="initial"
              animate="animate"
              exit="exit"
              variants={buttonVariants}
            >
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    onClick={toggleMinimize}
                    className="w-12 h-12 rounded-full bg-primary hover:bg-primary/90 text-white shadow-lg flex items-center justify-center"
                    whileTap="tap"
                    component={motion.button}
                  >
                    <motion.div
                      initial={{ rotate: -180, opacity: 0 }}
                      animate={{ rotate: 0, opacity: 1 }}
                      transition={{ duration: 0.3 }}
                    >
                      <MaximizeIcon className="w-6 h-6" />
                    </motion.div>
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Open Smart Input</p>
                </TooltipContent>
              </Tooltip>
            </motion.div>
          ) : (
            <motion.div
              key="expanded"
              className="w-full"
              initial="initial"
              animate="animate"
              exit="exit"
              variants={buttonVariants}
            >
              <motion.div
                className="flex items-center w-full"
                variants={containerVariants}
                initial="collapsed"
                animate="expanded"
                exit="collapsed"
              >
                <div className="flex-1">
                  <SmartInput
                    smartdocTheme={smartdocTheme}
                    setSmartdocTheme={setSmartdocTheme}
                    isFirst={isFirst}
                    setIsFirst={setIsFirst}
                    rndData={rndData}
                    setRndData={setRndData}
                    parentRef={parentRef}
                    removeNode={removeNode}
                    updateDoc={updateDoc}
                  />
                </div>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      onClick={toggleMinimize}
                      variant="ghost"
                      size="icon"
                      className="ml-2 h-10 w-10 rounded-full hover:bg-gray-100 flex-shrink-0"
                      component={motion.button}
                      whileTap="tap"
                    >
                      <motion.div
                        initial={{ rotate: 180, opacity: 0 }}
                        animate={{ rotate: 0, opacity: 1 }}
                        transition={{ duration: 0.3 }}
                      >
                        <MinimizeIcon className="w-5 h-5 text-gray-500" />
                      </motion.div>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Minimize</p>
                  </TooltipContent>
                </Tooltip>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </TooltipProvider>
  );
};

export default MinimizableSmartInput;
