// NetworkGraph.js
import React, { useEffect, useRef } from 'react';
import cytoscape from 'cytoscape';
// import elk from 'cytoscape-elk';
import hash from 'object-hash';
// cytoscape.use(elk);

// 测试数据
// const data= {
//   "Employee ID": [
//       1,
//       2,
//       3,
//       4,
//       5,
//       6,
//       7,
//       8,
//       9,
//       10,
//       11,
//       12,
//       13,
//       14,
//       15,
//       16,
//       17,
//       18,
//       19,
//       20,
//       21,
//       22,
//       23,
//       24,
//       25,
//       26,
//       27,
//       28,
//       29,
//       30
//   ],
//   "Name": [
//       "Norma Perry",
//       "Erica Romaguera",
//       "Irwin Spinello",
//       "Russell Ross",
//       "David Sellner",
//       "Bent Grasha",
//       "Matteo Gobeaux",
//       "Kendra Scrammage",
//       "Berkley Esherwood",
//       "Brigida Withey",
//       "Birgitta Rosoni",
//       "Holmes Dever",
//       "Dorena Whebell",
//       "Percy Veltman",
//       "Bartholemy Durgan",
//       "Phil Acres",
//       "Bernelle Cubley",
//       "Mickey Neilands",
//       "Caleigh Jerde",
//       "Laney Christmas",
//       "Nia Gutkowski",
//       "Debby Lethem",
//       "Jonis Thring",
//       "Bernadine Godsell",
//       "Gianni Block",
//       "Camila Hintz",
//       "Jeremiah Oakton",
//       "Shana Maguire",
//       "Anna Yost",
//       "Dante Collins"
//   ],
//   "Supervisor ID": [
//       "",
//       1,
//       2,
//       1,
//       4,
//       1,
//       4,
//       3,
//       8,
//       1,
//       6,
//       4,
//       10,
//       3,
//       10,
//       7,
//       16,
//       1,
//       10,
//       6,
//       16,
//       4,
//       15,
//       10,
//       22,
//       3,
//       16,
//       10,
//       24,
//       7
//   ],
//   "Role": [
//       "CEO",
//       "VP Product",
//       "Senior PM",
//       "VP Engineering",
//       "Sr. Engineer",
//       "VP Support",
//       "Sr. Engineer",
//       "PM",
//       "PM Intern",
//       "VP Marketing",
//       "Support Rep",
//       "Sr. Engineer",
//       "Social Media Specialist",
//       "PM",
//       "SEM Manager",
//       "Engineer",
//       "Engineering Intern",
//       "Assistant",
//       "Content Writer",
//       "Support Rep",
//       "Engineering Intern",
//       "Sr. Engineer",
//       "SEM Specialist",
//       "Sr. Designer",
//       "Engineer",
//       "PM",
//       "Engineering Intern",
//       "Email Manager",
//       "Designer",
//       "Engineer"
//   ],
//   "Email": [
//       "Norma.Perry@company.com",
//       "Erica.Romaguera@company.com",
//       "Irwin.Spinello@company.com",
//       "Russell.Ross@company.com",
//       "David.Sellner@company.com",
//       "Bent.Grasha@company.com",
//       "Matteo.Gobeaux@company.com",
//       "Kendra.Scrammage@company.com",
//       "Berkley.Esherwood@company.com",
//       "Brigida.Withey@company.com",
//       "Birgitta.Rosoni@company.com",
//       "Holmes.Dever@company.com",
//       "Dorena.Whebell@company.com",
//       "Percy.Veltman@company.com",
//       "Bartholemy.Durgan@company.com",
//       "Phil.Acres@company.com",
//       "Bernelle.Cubley@company.com",
//       "Mickey.Neilands@company.com",
//       "Caleigh.Jerde@company.com",
//       "Laney.Christmas@company.com",
//       "Nia.Gutkowski@company.com",
//       "Debby.Lethem@company.com",
//       "Jonis.Thring@company.com",
//       "Bernadine.Godsell@company.com",
//       "Gianni.Block@company.com",
//       "Camila.Hintz@company.com",
//       "Jeremiah.Oakton@company.com",
//       "Shana.Maguire@company.com",
//       "Anna.Yost@company.com",
//       "Dante.Collins@company.com"
//   ],
//   "Cell Phone": [
//       "06-61-53-25-39",
//       "629-584-074",
//       "174-327-3642",
//       "853-964-2488",
//       "0460-325-809",
//       "0955-941-5661",
//       "0436-245-930",
//       "081-691-1714",
//       "241-954-3617",
//       "(07) 0239-1931",
//       "040-440-56-01",
//       "0962-117-2515",
//       "380-959-3299",
//       "(429)-021-7553",
//       "(645)-661-8692",
//       "(997)-325-6514",
//       "(282)-934-6218",
//       "0475-050-929",
//       "044-899-29-17",
//       "(64) 3721-5116",
//       "0978-339-3255",
//       "0171-1487994",
//       "(446)-139-3019",
//       "(729)-669-3444",
//       "0936-226-2768",
//       "631-440-041",
//       "45787707",
//       "0729-450-003",
//       "(02) 9214-6177",
//       "(54) 3563-5199"
//   ],
//   "Location": [
//       "US",
//       "US",
//       "US",
//       "US",
//       "US",
//       "US",
//       "US",
//       "US",
//       "US",
//       "US",
//       "China",
//       "China",
//       "China",
//       "China",
//       "China",
//       "China",
//       "China",
//       "China",
//       "China",
//       "Germany",
//       "Germany",
//       "Germany",
//       "Germany",
//       "Germany",
//       "Germany",
//       "Germany",
//       "Germany",
//       "Germany",
//       "Germany",
//       "Germany"
//   ],
//   "Image URL": [
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/B00_women.jpg",
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/B01_women.jpg",
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/A00_men.jpg",
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/A01_men.jpg",
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/A03_men.jpg",
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/A04_men.jpg",
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/A05_men.jpg",
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/B02_women.jpg",
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/B03_women.jpg",
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/B04_women.jpg",
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/B05_women.jpg",
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/A06_men.jpg",
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/B06_women.jpg",
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/B07_women.jpg",
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/A07_men.jpg",
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/A08_men.jpg",
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/A09_men.jpg",
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/A10_men.jpg",
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/B08_women.jpg",
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/B09_women.jpg",
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/B10_women.jpg",
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/B11_women.jpg",
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/B12_women.jpg",
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/B13_women.jpg",
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/B14_women.jpg",
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/B15_women.jpg",
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/A11_men.jpg",
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/B16_women.jpg",
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/B17_women.jpg",
//       "https://d2slcw3kip6qmk.cloudfront.net/org-charts/sample/A02_men.jpg"
//   ]
// }
// const networkspec={
// "nodes": [
//   {
//       "entitytype": "Employee ",
//       "id_cols": [
//           "Employee ID"
//       ],
//       "prop_cols": [
//           "Employee ID",
//           "Image URL"
//       ]
//   },
//   {
//       "entitytype": "Supervisor ",
//       "id_cols": [
//           "Supervisor ID"
//       ],
//       "prop_cols": [
//           "Supervisor ID"
//       ]
//   },
//   {
//       "entitytype": "Location",
//       "id_cols": [
//           "Location"
//       ],
//       "prop_cols": [
//           "Location"
//       ]
//   }
// ],
// "edges": [
//   {
//       "source_entity_type": "Employee ",
//       "source_id_cols": [
//           "Employee ID"
//       ],
//       "target_entity_type": "Supervisor ",
//       "target_id_cols": [
//           "Supervisor ID"
//       ]
//   },
//   {
//       "source_entity_type": "Employee ",
//       "source_id_cols": [
//           "Employee ID"
//       ],
//       "target_entity_type": "Location",
//       "target_id_cols": [
//           "Location"
//       ]
//   }
// ],
// "config": {
//   "node_label": {
//       "Employee ": "Employee ID",
//       "Supervisor ": "Supervisor ID",
//       "Location": "Location"
//   },
//   "node_image": {
//       "Employee ": "Image URL",
//       "Supervisor ": "",
//       "Location": ""
//   }
// }
// }
const dimensionColors = {}; // Store generated colors for each dimension
let colorIndex = 0;

function getColorForDimension(dimension) {
  // Check if a color is already generated for this dimension
  const color= [
    "#ddc1f8",
    "#cfaef6",
    "#c098f4",
    "#b183f2",
    "#a571f1",
    "#9b84f3",
    "#9295f5",
    "#8ba2f7",
    "#84aff9",
    "#7ebcfa",
    "#77c9fc",
    "#72d3fd",
    "#D2B1E8",
    "#B79FD5",
    "#9C8DC2",
    "#817BAF",
    "#66699C",
    "#4B5789",
    "#304576",
    "#C8A3DB",
    "#AD91C8",
    "#927FB5",
    "#776DA2",
    "#5C5B8F",
    "#41497C",
    "#263769",
    "#BE95CE",
    "#A383BB",
    "#8871A8",
    "#6D5F95",
    "#524D82",
    "#373B6F",
    "#1C295C"
]
  if (!dimensionColors[dimension]) {
    // If not, generate a random color and store it
    dimensionColors[dimension] = color[colorIndex];
    colorIndex = (colorIndex + 1) % color.length;
  }
  return dimensionColors[dimension];
}

function convertNetworkSpecToElements(data, networkspec) {
  const elements = [];

  // 生成节点 id 的辅助函数
  function generateNodeId(type, rowData) {
    const idHash = hash(
      networkspec.nodes.find(node => node.type === type).key_columns
        .map(col => rowData[col])
        .join('_')
    );
    return `${type}_${idHash}`;
  }

  // 处理节点
  networkspec.nodes.forEach(nodeSpec => {
    const { type, key_columns, attr_columns,image_column,label_column } = nodeSpec; // 使用新的字段
    data[key_columns[0]].forEach((id, index) => {
      const rowData = {};
      Object.keys(data).forEach(key => {
        rowData[key] = data[key][index];
      });

      const nodeId = generateNodeId(type, rowData);

      const labelKey = label_column; // 使用 type 而不是 entitytype
      const imageKey = image_column;
      const labelValue = rowData[labelKey];

      const nodeData = {
        data: {
          id: nodeId,
          label: imageKey ? '' : labelValue, // 如果有 imageKey 则不显示 label
          entityType: type, // 使用新的 type
          nodeColor: getColorForDimension(type), // 设置初始颜色
          rowData: { ...rowData },
        },
      };
      
      if (imageKey && rowData[imageKey]) {
        nodeData.data.image = rowData[imageKey];
      }
      
      elements.push(nodeData);
    });
  });

  // 处理边
  networkspec.edges.forEach(edgeSpec => {
    const { source_type, source_key_columns, target_type, target_key_columns ,label} = edgeSpec; // 使用新的字段

    data[source_key_columns[0]].forEach((sourceId, index) => {
      const rowData = {};
      Object.keys(data).forEach(key => {
        rowData[key] = data[key][index];
      });

      const sourceNodeId = generateNodeId(source_type, rowData);
      const targetNodeId = generateNodeId(target_type, rowData);

      // 检查 target_key_columns 是否为空
      if (target_key_columns.every(col => !rowData[col])) {
        return; // 如果 target_key_columns 为空，则跳过创建边
      }

      const edgeData = {
        data: {
          id: `${sourceNodeId}_${targetNodeId}`,
          source: sourceNodeId,
          target: targetNodeId,
          edgeColor: 'data(color)', // 设置边的颜色
          label:label
        },
      };
      
      elements.push(edgeData);
    });
  });

  return elements;
}

  

const NetworkGraph = ({rndNo, visualData, setDetails, setShowDrawer}) => {
  const data = visualData.data;
  const networkspec = visualData.spec.networkspec;

  const elements = convertNetworkSpecToElements(data, networkspec); // 需要确保这个函数已适应新数据结构
  console.log({elements})
  const cyRef = useRef(null);

  useEffect(() => {
    if (!cyRef.current) {
      cyRef.current = cytoscape({
        container: document.getElementById('cy'+rndNo), // Use an existing DOM element as container
        elements: elements,
        style: [
          {
            selector: 'node',
            style: {
              'cursor': 'grab',
              'shape': 'circle',
              'label': 'data(label)',
              'text-valign': 'center',
              'text-halign': 'center',
              'color': 'white',
              'font-size': 24, // Font size for the label
              'background-color': 'data(nodeColor)',
              'background-image': 'data(image)', // 背景图
              'background-fit': 'cover', // 图片填充方式
              'width': 80,
              'height': 80,
            }
          },
          {
            selector: 'edge',
            style: {
              'label': 'data(label)', // 边的标签（例如 'Supervises'）
              'labelFontSize': 2,
              'labelFontColor': 'white',
              'labelFontWeight': 'bold',
              'lineColor': 'data(color)',
              'width': 1,
              'target-arrow-color': 'data(color)',
              'curve-style': 'bezier',
              'target-arrow-shape': 'triangle',
              'target-arrow-color': 'data(edgeColor)',
            }
          }
        ]
      });

      cyRef.current.layout({
        "name": "cose",
        "animate": false,
        "aspectRatio": 'auto',
      }).run();
   
      // Node tap event to show drawer details
      cyRef.current.on('tap', 'node', function (event) {
        const node = event.target;
        const nodeData = node.data(); // Get node's data
        setShowDrawer(true);
        const dataArray = [];
        const rowData = { ...nodeData.rowData };
        
        // Find node definition in networkspec and filter attributes to display
        const propNodes = networkspec.nodes.find((item) => item.type === nodeData.entityType);
        const propCols = propNodes.attr_columns; // Use 'attr_columns' in the new structure
        const filteredData = Object.fromEntries(
          Object.entries(rowData).filter(([key]) => propCols.includes(key))
        );
        
        for (const key in filteredData) {
          dataArray.push({ label: key, value: rowData[key] });
        }

        setDetails({ data: dataArray || {}, name: nodeData.entityType });
      });

      cyRef.current.on('mousedown', function (event) {
        event.stopPropagation();
        event.stopImmediatePropagation();
      });
    }
  }, [elements, setDetails, setShowDrawer]);

  return (
    <div className='h-full w-full '>
      <div 
        id={'cy' + rndNo}
        onDrag={(event) => event.stopPropagation()}
        className='flex gap-2 text-[12px] cursor-pointer items-center p-2 py-3 text-[#4F4764] leading-[15px] justify-center max-w-[100%] mx-auto w-full h-full min-h-[300px] text-white rounded'
      />
    </div>
  );
};


export default NetworkGraph;
