import { Select } from "@radix-ui/themes";

function ViewDrawer({ showDrawer, setShowDrawer, details, isPublic }) {
  const drawerStyle = {
    height: isPublic ? '100%' : 'calc(100% - 40px)',
    minHeight: 782,
    position: 'fixed',
    top: isPublic ? 0 : 40,
    zIndex: '10'
  };

  function isURL(text) {
    try {
      new URL(text);
      return true;
    } catch (e) {
      return false;
    }
  }

  return (
    <div style={drawerStyle}
      className={`sm:w-[400px] w-[320px] ${showDrawer ? 'opacity-1 right-0' : 'opacity-0 right-[-100%] pointer-events-none'} px-3 h-screen text-[#6B7280] transition-all bg-white shadow-xl shadow-[#ccc] overflow-y-auto overflow-hidden`}>
      <div className="flex items-center justify-between py-2 border-b">
        <div className="text-base tracking-tight font-medium">Details</div>
        <button onClick={() => setShowDrawer(false)}>
          <img src="/settingImages/x.png" alt="" width='16px' />
        </button>
      </div>
      <div>
        <div className="text-[#374151] text-sm font-[600] w-3/4 ml-auto px-2 mt-3 uppercase">{details.name}</div>
        {(details.data) &&
          <>
            {(details.type === 'landscape') ?
              Object.keys(details.data)?.map((key, ind) => {
                return (
                  <div key={ind} className="mt-1">
                    <div className="p-3 text-xs flex gap-2">
                        <div className="w-1/4 break-all text-[#374151] font-[600]">{key}</div>
                        <div className="w-3/4 break-all text-[#6B7280] font-normal">
                          {isURL(details.data[key])
                            ? <a href={details.data[key]} className="text-primary" target="_blank" rel="noreferrer">{details.data[key]}</a>
                            : (details.data[key] || '...')}
                        </div>
                      </div>
                  </div>
                )
              })
              :
              details.data.map((val, ind) => {
                return (
                  <div key={ind} className="mt-1">
                    {
                      (!val.type || val.type === 'text' || val.type === 'url') &&
                      <div className="p-3 text-xs flex gap-2">
                        <div className="w-1/4 break-all text-[#374151] font-[600]">{val.label}</div>
                        <div className="w-3/4 break-all text-[#6B7280] font-normal">
                          {isURL(val.value)
                            ? <a href={val.value} className="text-primary" target="_blank" rel="noreferrer">{val.value}</a>
                            : (val.value || '...')}
                        </div>
                      </div>
                    }
                    {
                      val.type === 'choice' &&
                      <div className="p-3 text-xs flex gap-2">
                        <div className="w-1/4 break-all text-[#374151] font-[600]">{val.label}</div>
                        <div className="w-3/4 break-all text-[#6B7280] font-normal">
                          <Select.Root size="2"
                            defaultValue={val.value}
                          >
                            <Select.Trigger className="w-full focus:border-red-500" />
                            <Select.Content position="popper" className="w-full" color="cyan" >
                              {details && val.choices.map((val, ind) => (
                                <Select.Item value={val} key={ind}>
                                  <div className="text-xs">{val}</div>
                                </Select.Item>
                              ))}
                            </Select.Content>
                          </Select.Root>
                        </div>
                      </div>
                    }
                  </div>
                )
              })
            }
          </>
        }
      </div>
    </div>
  )
}

export default ViewDrawer;