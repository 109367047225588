import { useCallback, useEffect, useMemo, useRef, useState, createContext, useContext } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChevronRightIcon } from '@radix-ui/react-icons';
import { kbdPropDefs, Tooltip, Popover, Text } from '@radix-ui/themes';
import axios from 'axios';
import { ShapesIcon, DiamondIcon } from '../Icons';
import { AI, TEXTS, TableColumns, VISUALIZES, ai_list, banners, textList, getTooltipContent } from './config.const';
import { setSmartDocStyle } from '../../store/slices/smartdocSlice';
const IconButton = ({ Icon, children, text, func, isActive, id }) => {
  return (
    <button
      id={`button_${id}`}
      onClick={func}
      className={`${isActive ? 'text-primary bg-[#0694A31A]' : 'hover:bg-[#0694A31A] bg-[#f9fafb] text-[#6B7280]'} 
    py-[6px] my-2 px-3 w-full flex items-center gap-2 rounded-[5px] font-normal`}>
      {children}
      {text}
    </button>
  );
};

const COMMANDS = ['/image', '/data', ...textList, ...banners, ...ai_list];
const bannerData = [
  { src: '/imgs/topbanner.png', command: '/topbanner', text: '' },
  { src: '/imgs/rightbanner.png', command: '/rightbanner', text: '' },
  // { src: '/imgs/vertical.png', command: '/vertical', text: '' },
  { src: '/imgs/horizontal.png', command: '/horizontal', text: '' }
];

const SmartInput = ({
  rndData,
  setRndData,
  parentRef,
  isFirst,
  setIsFirst,
  updateDoc,
  setSmartdocTheme,
  smartdocTheme
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const smartdoc = useSelector(state => state.smart.smartDoc);
  const textareaRef = useRef(null);
  const itemRef = useRef(null);
  const listRef = useRef(null);

  const [query, setQuery] = useState('');
  const [command, setCommand] = useState('/text');
  const [showList, setShowList] = useState(false);
  const [commands, setCommands] = useState({});
  const [selectedCommand, setselectedCommand] = useState(-1);
  const [totalNo, setTotalNo] = useState(-1);
  const dispatch = useDispatch();
  function convertToNumber(value) {
    if (typeof value === 'string') {
      return parseInt(value.replace(/\D/g, ''), 10);
    } else if (typeof value === 'number') {
      return parseInt(value);
    } else {
      throw new Error('Unsupported type');
    }
  }

  // Text Area Stuff Start
  const adjustTextareaHeight = useCallback(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;

      // Set max-height to 200px and enable scrolling if content exceeds this height
      if (textarea.scrollHeight > 200) {
        textarea.style.height = '200px';
        textarea.style.overflowY = 'auto';
      } else {
        textarea.style.overflowY = 'hidden';
      }
    }
  }, []);

  useEffect(() => {
    adjustTextareaHeight();
  }, [query, adjustTextareaHeight]);

  // Text Area Stuff Ended
  const previousStyle = useRef(null);
  const moveFitPosition = (rnd, scrollParent) => {
    if (rnd && rnd.size) {
      const screenHeight = window.innerHeight;

      if (rnd.chartData.type === 'banner' && rnd.chartData.name !== 'horizontal') {
        scrollParent.scrollTop = 0;
      } else if (rnd.pos.y + rnd.size.height + 250 < screenHeight) {
        return null;
      } else {
        scrollParent.scrollTop = rnd.pos.y + 250 + rnd.size.height - screenHeight;
      }
    }
  };

  const getMaxYData = useCallback(() => {
    let maxY = 0;
    let item = { height: 0, y: 0 };
    rndData.map((data, ind) => {
      const docId = `rnd${ind}`;
      const eleHeight = document.getElementById(docId)?.offsetHeight || 0;
      let dHeight = convertToNumber(data.size.height);

      if (eleHeight > convertToNumber(data.size.height) || data.size.height === 'auto') {
        dHeight = eleHeight;
        const nrndData = [...rndData];
        nrndData[ind].size.height = dHeight;
        setRndData(nrndData);
      }

      if (convertToNumber(data.pos.y) + convertToNumber(data.size.height) > maxY) {
        maxY = convertToNumber(data.pos.y) + convertToNumber(data.size.height);
        item = {
          y: data.pos.y,
          height: data.size.height
        };
      }
      return item;
    });
    return item;
  }, [rndData]);

  const onSubmit = useCallback(
    async cm => {
      const scrollParent = document.getElementById('scroll');
      const offsetWidth = parentRef.current?.offsetWidth;
      const parentWidth = offsetWidth - offsetWidth * 0.32;
      try {
        setQuery('');
        if (isFirst) {
          setIsFirst(false);
        }
        if (smartdoc.name === 'Untitled' && !cm) {
          const words = query.split(/[,.\n?!;:'"-]/);
          const filteredWords = words.filter(word => word.trim() !== '');
          if (!smartdoc.id && query) {
            let name;
            name = filteredWords.slice(0, 1).join(' ');
            updateDoc({ data: [], name: name });
          }
        }
        const cmd = COMMANDS.includes(cm) ? cm : command;
        const params = { q: query };
        const isAI = ai_list.includes(cmd);
        const maxYData = getMaxYData();
        const defaultY =
          parseInt(maxYData.height) + maxYData.y + 10 > 10 ? parseInt(maxYData.height) + maxYData.y + 10 : 48;

        const rnd = {
          id: rndData.length,
          class: 'rounded border',
          currentType: '',
          zIndex: 2,
          pos: { x: offsetWidth * 0.16, y: defaultY },
          size: { width: parentWidth, height: 60 },
          chartData: {
            type: 'Text',
            data: {},
            // cm === `/table` ? cm : query
            query: query,
            settings: {},
            name: cmd.split('/')[1],
            title: ''
          },
          changeChartData: {
            type: '',
            data: [],
            styleList: []
          },
          style: {
            isShowTitle: true,
            titleAlignment: 'left',
            verticalAlignment: '',
            titleFontColor: isAI ? '#ffffff' : '#000000',
            titleBGColor: isAI ? 'linear-gradient(90deg, #DDC1F8 0%, #A571F1 54%, #72D3FD 100%)' : '#ffffff',
            borderColor: 'transparent',
            cardBGColor: 'transparent',
            bgTransparent: 100,
            titleBgTransparent: 100,
            theme: 'fun'
          }
        };

        if (isAI) {
          if (cmd === '/ai') params.vtype = '';
          else params.vtype = cmd.replace(/^\/+/, '');

          toast.loading('Processing ...');
          const res = await axios.post(`smart/smartchart`, {
            ...params
          });
          toast.dismiss();

          const data = res.data;

          if (params.vtype === 'style') {
            if (data.style) {
              setSmartdocTheme(data.style);
              dispatch(setSmartDocStyle(data.style));

              if (data.style?.paddingTitle?.length === 4) {
                const paddingTop = data.style.paddingTitle[0];
                const paddingBottom = data.style.paddingTitle[2];

                // 计算 padding 差值
                const paddingDiffTop = paddingTop - (previousStyle.current?.paddingTitle?.[0] || 0);
                const paddingDiffBottom = paddingBottom - (previousStyle.current?.paddingTitle?.[2] || 0);

                // 遍历更新元素的高度和位置
                rndData.forEach((item, index) => {
                  if (item.chartData.type === 'Text') {
                    item.size.height += paddingDiffTop + paddingDiffBottom;
                  }

                  // 累积前一个元素的 y 位置 + 高度
                  if (index > 0) {
                    item.pos.y = rndData[index - 1].pos.y + rndData[index - 1].size.height;
                  }
                });

                setRndData([...rndData]);
                previousStyle.current = data.style; // 更新 previousStyle
              }
              return;
            }

            if (data?.visuals[0]?.style) {
              setSmartdocTheme(data.visuals[0].style);
              dispatch(setSmartDocStyle(data.visuals[0].style));
              return;
            }
          }

          if (data && data.visuals) {
            let index = 0;

            const intervalId = setInterval(() => {
              if (index >= data.visuals.length) {
                clearInterval(intervalId); // Stop the interval when done
                return;
              }

              const visual = data.visuals[index];
              let type = visual?.spec?.vtype?.vtype;
              let filters = [];
              rnd.size = {
                width: parentWidth > 800 ? parentWidth : 800,
                height: 'auto'
              };

              if (cmd === '/ai') {
                rnd.size.height = 160;
                rnd.size.width = parentWidth / 2;
              }

              if (type === 'ORGCHART' || type === 'GRAPH') rnd.size.height = 500;

              if (type === 'RECORDCARD') {
                if (cmd === '/accordion') type = 'ACCORDION';
                rnd.size.height = 400;
                rnd.size.width = parentWidth;
              }

              if (type === 'CALENDAR' || type === 'NETWORK_FROM_TABLE') {
                rnd.size.height = 250;
                rnd.size.width = parentWidth;
              }
              if (type === 'BAR') {
                rnd.size.height = 160;
                rnd.size.width = parentWidth / 2;
              }

              if (type === `BLOCKS`) {
                const { blocks } = visual?.data;
                if (blocks && blocks.length > 0) {
                  blocks.map((blo, ind) => {
                    rnd.chartData.query = blo.content;
                    rnd.style.titleFontColor = 'black';
                    rnd.style.titleBGColor = 'transparent';

                    switch (blo.blocktype) {
                      case 'TEXT':
                        rnd.size.height = 36;
                        rnd.size.width = parentWidth;
                        rnd.chartData.data.font_size = 14;
                        rnd.chartData.data.font_weight = 400;
                        break;
                      case '/h1': {
                        rnd.chartData.data.font_size = 20;
                        rnd.size.height = 44;
                        rnd.size.width = parentWidth;
                        rnd.chartData.data.font_weight = 400;
                        rnd.style.titleFontColor = 'white';
                        rnd.style.titleBGColor = 'linear-gradient(90deg, #DDC1F8 0%, #A571F1 54%, #72D3FD 100%)';
                        break;
                      }
                      case '/h2': {
                        rnd.size.height = 42;
                        rnd.size.width = parentWidth;
                        rnd.chartData.data.font_size = 24;
                        rnd.chartData.data.font_weight = 400;
                        rnd.style.titleFontColor = 'white';
                        // rnd.style.titleBGColor = 'linear-gradient(90deg, #16EFD7 0%, #43B2A5 100%)'
                        break;
                      }
                      case '/heroline': {
                        rnd.size.height = 42;
                        rnd.size.width = parentWidth;
                        rnd.chartData.data.font_size = 24;
                        rnd.chartData.data.font_weight = 400;
                        rnd.style.titleFontColor = 'white';
                        // rnd.style.titleBGColor = 'linear-gradient(90deg, #16EFD7 0%, #43B2A5 100%)'
                        break;
                      }
                      default:
                        break;
                    }

                    rndData.push(rnd);
                    setRndData([...rndData]);

                    moveFitPosition(rnd, scrollParent);
                    index++;
                    return null;
                  });
                }

                return null;
              } else if (type === 'IMAGE') {
                const { url } = visual?.data;
                rnd.size.height = 300;
                rnd.size.width = parentWidth;
                rnd.style.isShowTitle = false;
                rnd.chartData = {
                  ...rnd.chartData,
                  type: 'image',
                  src: url ? url : ''
                };
                const maxyData = getMaxYData();
                const defaulty =
                  parseInt(maxyData.height) + maxyData.y + 10 > 10 ? parseInt(maxyData.height) + maxyData.y + 10 : 48;

                const nRnd = { ...rnd, pos: { x: rnd.pos.x, y: defaulty } };
                rndData.push({ ...nRnd });
                setRndData([...rndData]);

                moveFitPosition(rnd, scrollParent);
                index++;
              } else if (
                type === 'TEXT' ||
                type === 'HEADER1' ||
                type === 'HEADER2' ||
                type === 'HEADER' ||
                type === 'HEROLINE'
              ) {
                const { tag, text, parts } = visual?.data;
                switch (type) {
                  case 'TEXT':
                    rnd.size.height = 36;
                    rnd.size.width = parentWidth;
                    rnd.chartData.data.font_size = 14;
                    rnd.chartData.data.font_weight = 400;
                    rnd.style.titleFontColor = 'black';
                    rnd.style.titleBGColor = 'transparent';
                    break;
                  case 'HEADER1':
                  case 'HEADER': {
                    rnd.chartData.data.font_size = '20px';
                    rnd.size.height = 44;
                    rnd.size.width = parentWidth;
                    rnd.chartData.data.font_weight = 400;
                    rnd.style.titleFontColor = 'white';
                    rnd.style.titleBGColor = 'linear-gradient(90deg, #DDC1F8 0%, #A571F1 54%, #72D3FD 100%)';
                    break;
                  }
                  case 'HEADER2': {
                    rnd.size.height = 42;
                    rnd.size.width = parentWidth;
                    rnd.chartData.data.font_size = 24;
                    rnd.chartData.data.font_weight = 400;
                    rnd.style.titleFontColor = 'white';
                    // rnd.style.titleBGColor = 'linear-gradient(90deg, #16EFD7 0%, #43B2A5 100%)'
                    break;
                  }
                  case 'HEROLINE': {
                    rnd.size.height = 42;
                    rnd.size.width = parentWidth;
                    rnd.chartData.data.font_size = '3.75rem';
                    rnd.chartData.data.font_weight = 800;
                    rnd.style.titleFontColor = 'hsl(0deg 0% 62.7% /1)';
                    rnd.style.titleBGColor = 'transparent';
                    break;
                  }

                  default:
                    break;
                }
                let nnrnd = JSON.parse(JSON.stringify(rnd));
                nnrnd.chartData = {
                  type: 'Text',
                  data: {
                    visualData: visual,
                    ...rnd.chartData.data
                  },
                  parts: parts,
                  name:
                    type === 'TEXT'
                      ? 'text'
                      : type === 'HEADER1' || type === 'HEADER'
                      ? 'h1'
                      : type === 'HEADER2'
                      ? 'h2'
                      : type === 'HEROLINE'
                      ? 'heroline'
                      : 'text',
                  query: text
                };

                const maxyData = getMaxYData();
                const defaulty =
                  parseInt(maxyData.height) + maxyData.y + 10 > 10 ? parseInt(maxyData.height) + maxyData.y + 10 : 48;

                const nRnd = {
                  ...nnrnd,
                  pos: { x: nnrnd.pos.x, y: defaulty }
                };
                rndData.push({ ...nRnd });
                setRndData([...rndData]);
                moveFitPosition(nRnd, scrollParent);
                index++;
                return null;
              } else if (type === 'SHAPE_LINE_HORIZONTAL') {
                rnd.chartData.type = 'banner';
                rnd.chartData.name = 'horizontal';
                rnd.type = 'horizontal';
                // rnd.size = { width: '100%',height:50 };
                // rnd.pos = { x: parentWidth - 180, y: 0 };
                rnd.size.height = 36;
                rnd.size.width = parentWidth;
                rnd.style.titleBGColor = 'transparent';

                const maxyData = getMaxYData();
                const defaulty =
                  parseInt(maxyData.height) + maxyData.y + 10 > 10 ? parseInt(maxyData.height) + maxyData.y + 10 : 48;

                const nRnd = {
                  ...rnd,
                  pos: { x: rnd.pos.x, y: defaulty }
                };
                rndData.push({ ...nRnd });
                setRndData([...rndData]);
                moveFitPosition(nRnd, scrollParent);

                index++;
                return null;
              } else {
                const dataConfig = visual?.data.config || {};
                const filtersData = dataConfig['key_filters'];

                if (filtersData && filtersData?.length > 0) {
                  filters = filtersData.map(val => ({
                    ...val,
                    com_type: val?.values ? 'select' : 'input',
                    cur_vals: val?.values ? [] : '',
                    isShow: false
                  }));
                }

                rnd.chartData = {
                  ...(type === 'LANDSCAPE' && {
                    settings: {
                      label: '',
                      layout: 'drip',
                      filters: filters
                    }
                  }),
                  type: type,
                  data: {
                    visualData: visual
                  },
                  name: visual.title || type,
                  title: visual.title,
                  query: query
                };
                rnd.style.titleFontColor = 'white';
                rnd.style.titleBGColor = 'linear-gradient(90deg, #DDC1F8 0%, #A571F1 54%, #72D3FD 100%)';
                const maxyData = getMaxYData();
                const defaulty =
                  parseInt(maxyData.height) + maxyData.y + 10 > 10 ? parseInt(maxyData.height) + maxyData.y + 10 : 48;

                const nRnd = { ...rnd, pos: { x: rnd.pos.x, y: defaulty } };
                rndData.push({ ...nRnd });
                setRndData([...rndData]);

                moveFitPosition(rnd, scrollParent);
                index++;
              }
            }, 100);
          }
        } else {
          switch (cmd) {
            case '/text': {
              rnd.size.height = 36;
              rnd.size.width = parentWidth;
              rnd.chartData.data.font_size = '14px';
              rnd.chartData.data.font_weight = 400;
              rnd.style.titleBGColor = 'transparent';
              break;
            }
            case '/h1': {
              rnd.chartData.data.font_size = '20px';
              rnd.size.height = 44;
              rnd.size.width = parentWidth;
              rnd.chartData.data.font_weight = 400;
              rnd.style.titleFontColor = 'white';
              rnd.style.titleBGColor = 'linear-gradient(90deg, #DDC1F8 0%, #A571F1 54%, #72D3FD 100%)';
              break;
            }
            case '/h2': {
              rnd.size.height = 42;
              rnd.size.width = parentWidth;
              rnd.chartData.data.font_size = '24px';
              rnd.chartData.data.font_weight = 400;
              // rnd.style.titleFontColor = 'white'
              // rnd.style.titleBGColor = 'linear-gradient(90deg, #16EFD7 0%, #43B2A5 100%)'
              break;
            }
            case '/heroline': {
              rnd.size.height = 42;
              rnd.size.width = parentWidth;
              rnd.chartData.data.font_size = '3.75rem';
              rnd.chartData.data.font_weight = 800;
              rnd.style.titleFontColor = 'hsl(0deg 0% 62.7% /1)';
              rnd.style.titleBGColor = 'transparent';
              break;
            }

            case '/topbanner': {
              rnd.chartData.type = 'banner';
              rnd.type = 'topbanner';
              rnd.size = { width: '100%', height: 100 };
              rnd.pos = { x: 0, y: 0 };
              rnd.style.titleBGColor = 'linear-gradient(185deg, #4F4764 0%, #0E9AB0 64.06%, #006676 100%)';
              break;
            }
            case '/rightbanner': {
              rnd.chartData.type = 'banner';
              rnd.type = 'rightbanner';
              rnd.size = { width: 180, height: '100vh' };
              rnd.pos = { x: parentWidth, y: 0 };
              rnd.style.titleBGColor = 'linear-gradient(185deg, #4F4764 0%, #0E9AB0 64.06%, #006676 100%)';
              break;
            }
            case '/horizontal': {
              rnd.chartData.type = 'banner';
              rnd.type = 'horizontal';
              // rnd.size = { width: '100%',height:50 };
              // rnd.pos = { x: parentWidth - 180, y: 0 };
              rnd.size.height = 36;
              rnd.size.width = parentWidth;
              rnd.style.titleBGColor = 'transparent';
              break;
            }

            // case "/table": {
            //   rnd.style.titleFontColor = "white";
            //   rnd.style.titleBGColor = "#0694a3";
            //   rnd.size.height = 370;
            //   rnd.size.width = parentWidth ;

            //   rnd.chartData = {
            //     ...rnd.chartData,
            //     type: "table",
            //     records: [{}, {}],
            //     columns: TableColumns,
            //   };
            //   break;
            // }
            case '/image': {
              rnd.size.height = 300;
              rnd.size.width = parentWidth - 104;
              rnd.style.isShowTitle = false;
              rnd.chartData = {
                ...rnd.chartData,
                type: 'image',
                src: ''
              };
              break;
            }
            case '/markdown': {
              rnd.size.width = parentWidth;
              rnd.style.titleBGColor = 'transparent';
              break;
            }
            default:
              break;
          }

          rndData.push(rnd);
          setRndData([...rndData]);
          moveFitPosition(rnd, scrollParent);
        }
        // if (rnd.size.height === 'auto') {
        //   console.log('{ myDiv }');

        //   requestAnimationFrame(() => {
        //     var myDiv = document.getElementById('rnd' + rnd.id);
        //     console.log({ myDiv });
        //     requestAnimationFrame(() => {
        //       if (myDiv) {
        //         // 获取元素的高度
        //         let height = myDiv.offsetHeight;
        //         console.log({ height });
        //         rnd.size.height = height;
        //         console.log({ rndData });
        //       }
        //     });
        //   });
        // }
      } catch (err) {
        toast.dismiss();
        const message = err?.response?.data?.message || err.message;

        if (message === 'You have used all available credits.') {
          toast.error(
            <div>
              {message}
              <div className="p-3">
                <div
                  style={{ backgroundImage: 'url("/imgs/upgrade-bg.png")' }}
                  className={`sm:flex hidden justify-center flex-col text-white p-2 w-full aspect-square bg-center bg-contain bg-no-repeat`}>
                  <div className="bg-white w-8 h-7 items-center justify-center flex rounded mx-auto">
                    <DiamondIcon color="#0694A3" />
                  </div>
                  <p className="text-lg text-center mt-2">{t('Upgrade')}</p>
                  <p className="text-sm text-center mt-2">{t('Get access to all features')}</p>
                  <button
                    onClick={() => navigate('/pricing')}
                    className="text-sm w-[calc(100%-16px)] bg-white text-primary ml-2 p-2 rounded mt-4">
                    {t('See Plans')}
                  </button>
                </div>
                <button className={`w-full justify-center flex sm:hidden`}>
                  <DiamondIcon color="#0694A3" />
                </button>
              </div>
            </div>
          );
        } else {
          toast.error(message);
        }
      }
    },
    [
      parentRef,
      command,
      query,
      isFirst,
      setIsFirst,
      rndData,
      setRndData,
      // getMaxYData,
      smartdoc,
      updateDoc
    ]
  );

  const comList = useMemo(
    () => ({
      Visualize: [...VISUALIZES],
      AI: [...AI],
      Text: [...TEXTS],
      Banner: {
        Icon: color => <ShapesIcon color={color} width={20} height={20} />,
        text: (
          <div className="flex items-center gap-2">
            {bannerData.map((vl, ind) => (
              <div key={ind} className="cursor-pointer hover:bg-[#ccc] rounded" onClick={() => onSubmit(vl.command)}>
                <img src={vl.src} className="w-[14px] h-[14px]" alt="shugic" />
              </div>
            ))}
          </div>
        )
      }
    }),
    [onSubmit]
  );

  const isValidCommand = useCallback(
    ary => {
      let isValid = false;
      ary.map(vl => {
        if (vl.command.toLowerCase().includes(query.slice(1)) || vl.text.toLowerCase().includes(query.slice(1))) {
          isValid = true;
        }
        return isValid;
      });
      return isValid;
    },
    [query]
  );

  useEffect(() => {
    if (COMMANDS.indexOf(query) > -1) {
      setCommand(query);
      setQuery('');
    }
    const result = Object.keys(comList).filter(key => {
      const s_ary = key === 'Banner' ? bannerData : comList[key];
      const isVlaid = isValidCommand(s_ary);
      return isVlaid && comList[key];
    });

    if (result.length > 0 && query.startsWith('/')) setShowList(true);
    else setShowList(false);
  }, [query, comList, isValidCommand]);

  useEffect(() => {
    const n_obj = {};
    let no = -1;
    Object.keys(comList)
      .filter(key => {
        const s_ary = key === 'Banner' ? bannerData : comList[key];
        const isVlaid = isValidCommand(s_ary);
        return isVlaid && comList[key];
      })
      .map(key => {
        if (key === 'Banner') {
          no += 1;
          n_obj[key] = { ...comList[key], no: no };
        } else {
          const ary = comList[key]
            .filter(
              vl => vl.command.toLowerCase().includes(query.slice(1)) || vl.text.toLowerCase().includes(query.slice(1))
            )
            .map(val => {
              no += 1;
              return { ...val, no: no };
            });
          n_obj[key] = ary;
        }
        return null;
      });

    setTotalNo(no);
    setselectedCommand(0);
    setCommands(n_obj);
  }, [query, comList, isValidCommand]);

  const handleKeyDown = useCallback(
    event => {
      if (event.key === `ArrowUp`) {
        // event.preventDefault()
        setselectedCommand(prevIndex => (prevIndex > 0 ? prevIndex - 1 : totalNo));
      } else if (event.key === `ArrowDown`) {
        // event.preventDefault()
        setselectedCommand(prevIndex => (prevIndex < totalNo ? prevIndex + 1 : 0));
      } else if (event.key === `Enter`) {
        if (showList) event.preventDefault();
        if (selectedCommand !== -1 && showList) {
          let curCommand = {};
          Object.keys(commands).map((key, ind) => {
            if (key === 'Banner') {
              if (commands[key].no === selectedCommand) curCommand = { command: '/topbanner' };
            } else {
              commands[key].map(val => {
                if (val && val.no === selectedCommand) curCommand = val;
                return null;
              });
            }
            return null;
          });
          if (curCommand.command) {
            setCommand(curCommand.command);
            setQuery('');
          }
        }
      } else if (event.key === `Escape`) {
        setQuery('');
      }
    },
    [selectedCommand, totalNo, commands]
  );

  useEffect(() => {
    itemRef.current = document.getElementById(`button_${selectedCommand}`);
    if (itemRef.current && selectedCommand > -1) {
      itemRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }, [listRef, selectedCommand]);
  // || command === "/table"
  const isAvailable = command && (query || banners.indexOf(command) > -1);

  return (
    <div
      className={`relative bg-white items-center shadow-custom rounded-xl flex z-10 smart-input`}
      onKeyDown={handleKeyDown}>
      <Popover.Root>
        <Popover.Trigger>
          <div className="absolute top-2 -left-4 w-2 h-2 rounded-full bg-primary cursor-help" />
        </Popover.Trigger>
        <Popover.Content size="2" maxWidth="600px">
          <pre className="whitespace-pre-wrap font-mono text-sm rounded">{getTooltipContent(command)}</pre>
        </Popover.Content>
      </Popover.Root>
      <div
        ref={listRef}
        className={`px-3 scroll text-grey-10 ${
          showList ? 'opacity-1' : 'opacity-0 pointer-events-none'
        } text-xs py-4 overflow-y-auto transition-all absolute left-0 bottom-[100%] w-full md:w-80 max-h-52 bg-white rounded-lg shadow-custom mb-2`}>
        {Object.keys(commands).map((key, ind) => {
          if (key === 'Banner') {
            return (
              <div key={ind}>
                <p className="px-2 py-[1px] text-[#374151] font-bold text-xs">{key}</p>
                <IconButton
                  isActive={selectedCommand === commands[key].no}
                  id={commands[key].no}
                  Icon={commands[key].Icon}
                  text={commands[key].text}>
                  {selectedCommand === commands[key].no ? commands[key].Icon('#0694A3') : commands[key].Icon('#9CA3AF')}
                </IconButton>
              </div>
            );
          } else {
            return (
              <div key={ind}>
                <p className="px-2 py-[1px] text-[#374151] font-bold text-xs">{key}</p>

                {commands[key].map((li, no) => (
                  <IconButton
                    isActive={selectedCommand === li.no}
                    id={li.no}
                    key={no}
                    text={t(li.text)}
                    Icon={li.Icon}
                    func={() => {
                      {
                        /* li.command === "/table" || */
                      }
                      if (li.command === '/image') {
                        onSubmit(li.command);
                      } else {
                        setCommand(li.command);
                      }
                      setQuery('');
                    }}>
                    {selectedCommand === li.no ? li.Icon('#0694A3') : li.Icon('#9CA3AF')}
                  </IconButton>
                ))}
              </div>
            );
          }
        })}
      </div>
      <span className="absolute top-1 left-2 text-[#bbb] uppercase">{command.replace('/', '')}</span>
      <textarea
        ref={textareaRef}
        value={query}
        onChange={e => {
          setQuery(e.target.value);
          // if (e.target.value === "/table") {
          //   setTimeout(() => onSubmit("/table"), 50);
          // }
          if (e.target.value === '/image') {
            setTimeout(() => onSubmit('/image'), 50);
          }
          if (e.target.value === '/topbanner') {
            setTimeout(() => onSubmit('/topbanner'), 50);
          }
          if (e.target.value === '/rightbanner') {
            setTimeout(() => onSubmit('/rightbanner'), 50);
          }
        }}
        onKeyDown={e => {
          if (e.shiftKey && e.key === 'Enter' && isAvailable) {
            e.preventDefault();
            onSubmit();
          }
        }}
        style={{
          outline: 'none !important',
          overflow: 'hidden', // Hide the default scrollbar
          resize: 'none' // Disable manual resizing
        }}
        className="text-[#222] px-4 w-[calc(100%-50px)] mt-7 mb-2 outline-none  placeholder:text-[#888] placeholder:font-[400] tour-search"
        placeholder={t('Press ‘/’  here to see the list of options ....')}
      />

      <Tooltip
        content={
          <span className="text-center text-[10px]">
            <span className="flex gap-1 items-center">
              Add to library
              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 8 7" fill="none">
                <path
                  d="M3.2796 3.65476C3.32062 3.61374 3.34375 3.55802 3.34375 3.5C3.34375 3.44198 3.3207 3.38634 3.27968 3.34532L3.27779 3.34345C3.23694 3.30365 3.18204 3.28125 3.125 3.28125C3.11844 3.28125 3.11188 3.28155 3.10534 3.28214C3.05433 3.28674 3.00654 3.3091 2.97032 3.34532L1.65782 4.65782C1.6168 4.69884 1.59375 4.75448 1.59375 4.8125C1.59375 4.87052 1.6168 4.92616 1.65782 4.96718L2.97032 6.27968C3.01134 6.3207 3.06698 6.34375 3.125 6.34375C3.18302 6.34375 3.23866 6.3207 3.27968 6.27968C3.3207 6.23866 3.34375 6.18302 3.34375 6.125C3.34375 6.06698 3.3207 6.01134 3.27968 5.97032L2.12186 4.8125L3.2796 3.65476Z"
                  fill="#BDBDBD"
                />
                <path
                  d="M5.53125 4.59375H1.8125C1.69169 4.59375 1.59375 4.69169 1.59375 4.8125C1.59375 4.93331 1.69169 5.03125 1.8125 5.03125H5.75C5.87081 5.03125 5.96875 4.93331 5.96875 4.8125V0.875C5.96875 0.754188 5.87081 0.65625 5.75 0.65625C5.62919 0.65625 5.53125 0.754188 5.53125 0.875V4.59375Z"
                  fill="#BDBDBD"
                />
              </svg>
            </span>
            <span className="text-[#9E9E9E] px-4">Shift + key</span>
          </span>
        }>
        <div
          className={`${
            isAvailable ? 'bg-primary cursor-pointer' : 'bg-[#BDBDBD]'
          } w-6 h-6 ml-2 rounded-full flex justify-center items-center tour-button`}
          onClick={() => {
            if (isAvailable) onSubmit();
          }}>
          <ChevronRightIcon color="white" width={20} height={20} />
        </div>
      </Tooltip>
    </div>
  );
};

export default SmartInput;
