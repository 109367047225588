import React from 'react';
import * as Menubar from '@radix-ui/react-menubar';
import {  MoreIcon,CardButton, ToggleChartIcon } from '../../Icons'
import * as Popover from '@radix-ui/react-popover';
import { Cross2Icon } from '@radix-ui/react-icons';
import { ToggleChart } from './ToggleChart';

const FunctionBar = ({
  isShowCard,
  chartType,
  onImageClick,
  visualData,
  onChangeCardClick,
  textStyle,
  onChangeStyleClick
}) => {
 
  return (
    <Menubar.Root className="flex bg-[#595959] p-[1px] rounded-md relative z-[25] scale-[0.7] -mr-2">
    {!textStyle&& !isShowCard&& (<Popover.Root>
        <Popover.Trigger asChild>
          <button
            className="py-1 px-1 outline-none select-none font-medium leading-none rounded text-violet11 text-[13px] flex items-center justify-between gap-[2px] data-[highlighted]:bg-violet4 data-[state=open]:bg-violet4"
            aria-label="Update dimensions"
          >
            <ToggleChartIcon color="#fff" />
          </button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className="rounded p-3 w-[320px] bg-white shadow-[0_10px_18px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2)] focus:shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2),0_0_0_2px_theme(colors.violet7)] will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
            sideOffset={5}
          >
            <ToggleChart onImageClick={onImageClick} chartType={chartType} visualData={visualData} ></ToggleChart>
            <Popover.Close
              className="rounded-full h-[25px] w-[25px] inline-flex items-center justify-center text-violet11 absolute top-[5px] right-[5px] hover:bg-violet4  focus:shadow-violet7 outline-none cursor-default focus:outline-none"
              aria-label="Close"
            >
              <Cross2Icon />
            </Popover.Close>
            <Popover.Arrow className="fill-white" />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>)}

    {!textStyle&& (<Menubar.Menu>
        <Menubar.Trigger
          onClick={onChangeCardClick}
          className="py-1 px-1 outline-none select-none font-medium leading-none rounded text-violet11 text-[13px] flex items-center justify-between gap-[2px] data-[highlighted]:bg-violet4 data-[state=open]:bg-violet4">
          <CardButton color="#fff" />
        </Menubar.Trigger>
      </Menubar.Menu>)}
     {textStyle&& (<Menubar.Menu>
        <Menubar.Trigger
          onClick={onChangeStyleClick}
          className="py-1 px-1 outline-none select-none font-medium leading-none rounded text-violet11 text-[13px] flex items-center justify-between gap-[2px] data-[highlighted]:bg-violet4 data-[state=open]:bg-violet4">
           <MoreIcon color="#fff" />
        </Menubar.Trigger>
      </Menubar.Menu>)}
    </Menubar.Root>
  );
};

export { FunctionBar };