import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import SuperTokens from "supertokens-web-js";
import EmailPassword, {
  doesEmailExist,
  signUp,
  signIn,
  signOut,
} from "supertokens-web-js/recipe/emailpassword";
import Session from "supertokens-web-js/recipe/session";
import axios from 'axios'

const isDevMode = process.env.NODE_ENV === 'development'

const apiBasePath = process.env.REACT_APP_API_BASE_PATH;
const appName = process.env.REACT_APP_NAME;
const storageBaseUrl = isDevMode
  ? process.env.REACT_APP_STORAGE_BASE_URL
  : process.env.REACT_APP_PROD_STORAGE_BASE_URL

const init = () => {
  if (!apiBasePath) return
  try {
    SuperTokens.init({
      appInfo: {
        appName: appName,
        apiBasePath: apiBasePath,
        apiDomain: storageBaseUrl,
      },
      recipeList: [Session.init(), EmailPassword.init()],
    });
  } catch (e) {
    console.log(e);
  }
}

init()

export const fetchLogin = createAsyncThunk(
  'auth/fetchLogin',
  async ({ email, password }) => {
    const res = await signIn({
      formFields: [
        { id: "email", value: email },
        { id: "password", value: password },
      ],
    })
    return res
  }
)

export const updateUserEmailPassword = async ({ userId, email, password }) => {
  const updatedUser = await EmailPassword.updateEmailOrPassword({
    recipeUserId: userId,
    email: email,
    password: password,
  });

  return updatedUser
}

export const fetchRegister = createAsyncThunk(
  'auth/fetchRegister',
  async ({ name, email, password }) => {
    let emailResponse = await doesEmailExist({ email });
    if (emailResponse.doesExist) {
      return {
        isError: true,
        status: 'DUPLICATED_EMAIL',
        error: "Email already exists. Please sign in instead",
      };
    }
    const res = await signUp({
      formFields: [
        { id: "name", value: name },
        { id: "email", value: email },
        { id: "password", value: password },
      ],
    })
    return res
  }
)
export const fetchLogout = createAsyncThunk(
  'auth/fetchLogout',
  async () => {
    await signOut()
    return true
  }
)

export const fetchUserInfo = createAsyncThunk(
  'auth/fetchUserInfo',
  async () => {
    const userData = await axios({
      method: "get",
      url: new URL('/auth/get-user-info', storageBaseUrl).toString(),
      headers: {},
    })
    return userData
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    userData: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : {},
    userState: {},
    userSubscription: {},
  },
  reducers: {
    setUserSubscription: (state, action) => {
      state.userSubscription = action.payload
    },
  },
  extraReducers: (builder) => {
    /** Login Reducer */
    builder.addCase(fetchLogin.pending, (state) => {
      return {
        ...state,
        userState: {
          status: 'PENDING SIGNIN',
          isLoading: true,
          isError: false,
          isLoggedIn: false,
          errorMessage: [],
        }
      }
    })
    builder.addCase(fetchLogin.fulfilled, (state, action) => {
      if (action.payload.status === 'OK') {
        localStorage.setItem('userData', JSON.stringify(action.payload.user))
        return {
          ...state,
          userState: {
            status: 'OK',
            isLoading: false,
            isError: false,
            isLoggedIn: true,
            errorMessage: [],
          },
          userData: { ...action.payload.user }
        }
      } else {
        if (action.payload.status === 'WRONG_CREDENTIALS_ERROR') {
          return {
            ...state,
            userState: {
              status: 'FAILED SIGNIN',
              isLoading: false,
              isError: false,
              isLoggedIn: false,
              errorMessage: ['Invalid credentials'],
            }
          }
        }
        if (action.payload.status === "FIELD_ERROR") {
          return {
            ...state,
            userState: {
              status: 'FAILED SIGNIN',
              isLoading: false,
              isError: false,
              isLoggedIn: false,
              errorMessage: [action.payload.formFields[0].error],
            }
          }
        }
      }
    })
    builder.addCase(fetchLogin.rejected, (state, action) => {
      return {
        ...state,
        userState: {
          status: 'ERROR SIGNIN',
          isLoading: false,
          isError: true,
          isLoggedIn: false,
          errorMessage: [action.error.message],
        }
      }
    })

    /** Register Reducer */
    builder.addCase(fetchRegister.pending, (state, action) => {
      return {
        ...state,
        userState: {
          status: 'PENDING REGISTER',
          isLoading: true,
          isError: false,
          isLoggedIn: false,
          errorMessage: [],
        }
      }
    })
    builder.addCase(fetchRegister.fulfilled, (state, action) => {
      if (action.payload.status === 'OK') {
        return {
          ...state,
          userState: {
            status: 'OK',
            isLoading: false,
            isError: false,
            isLoggedIn: false,
            errorMessage: [],
          },
        }
      } else {
        if (action.payload.status === "FIELD_ERROR") {
          return {
            ...state,
            userState: {
              status: 'FAILED SIGNUP',
              isLoading: false,
              isError: false,
              isLoggedIn: false,
              errorMessage: action.payload.formFields.map((fl) => fl.error),
            }
          }
        }
        if (action.payload.status === "DUPLICATED_EMAIL") {
          return {
            ...state,
            userState: {
              status: 'FAILED SIGNUP',
              isLoading: false,
              isError: false,
              isLoggedIn: false,
              errorMessage: ["User is already exist."],
            }
          }
        }
      }
    })
    builder.addCase(fetchRegister.rejected, (state, action) => {
      return {
        ...state,
        userState: {
          status: 'ERROR SIGNUP',
          isLoading: false,
          isError: true,
          isLoggedIn: false,
          errorMessage: [action.error.message],
        }
      }
    })

    /** Logout Reducer */
    builder.addCase(fetchLogout.fulfilled, (state, action) => {
      if (action.payload === true) {
        localStorage.setItem('userData', null)
        return {
          ...state,
          userState: {},
          userData: {},
        }
      }
    })

    /** GetUser Reducer */
    builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
      const userData = action.payload.data
      localStorage.setItem('userData', JSON.stringify(userData))
      return {
        ...state,
        userState: {
          status: 'OK',
          isLoading: false,
          isError: false,
          isLoggedIn: true,
          errorMessage: [],
        },
        userData,
      }
    })
  },
})

// Action creators are generated for each case reducer function
export const { logOut, setUserSubscription } = authSlice.actions
export default authSlice.reducer