import { useSelector } from "react-redux"
import { useEffect, useRef, useState } from 'react';
import { CardSetting } from './CardSetting'
import { StackBarSetting } from './StackBarSetting'
import {CircluarNetworkSetting } from './CircluarNetworkSetting'
import { CheckIcon, ChevronDownIcon, ClipboardCopyIcon, PaperPlaneIcon } from "@radix-ui/react-icons";
import { useOutSideClick } from '../../../hooks/useOutSideClick'
import { toast } from 'react-toastify';
import axios from 'axios';

export const ChartData = (props) => {
  const { rndData, setRndData, visible, details } = props
  const currentNo = useSelector((state) => state.global.settingStyleNo)
  const filterRef = useRef(null)
  
  const [value, setValue] = useState('')
  const [type, setType] = useState('')
  const [showFilter, setShowFilter] = useState(false)
  const [settings, setSettings] = useState({})
  
  const [prompt, setPrompt] = useState('')
  const { filters } = settings
  const settingChartData = { ...rndData[currentNo]?.chartData }

  const changeValue = (item) => {
    setValue(item)
    settingChartData.title = item
    rndData[currentNo] = { ...rndData[currentNo], chartData: settingChartData }
    console.log(rndData[currentNo])
    setRndData([...rndData])
  }
  
  const changePrompt = (item) => {
    setPrompt(item)
  }
  
  const sendPrompt = () => {
    if (type === 'Text') {
      rndData[currentNo].chartData.query = prompt
      setRndData([...rndData])
    } else {
      sendRequest()
    }
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(prompt)
    .then(() => {
      console.log('Text data copied to clipboard successfully');
    })
    .catch(err => {
      console.error('Failed to copy text data to clipboard:', err);
    });
  }

  const sendRequest = async () => {
    const loading = toast.loading("Please wait ...")
    try {
      const res = await axios.post(`smart/smartchart`, {
        q: prompt,
        vtype: rndData[currentNo].currentType ? rndData[currentNo].currentType : rndData[currentNo].chartData.type
      })
      const data = res.data
      if (data) {
        const old_chartData = rndData[currentNo].chartData
        let chartData = {
          ...old_chartData,
          data: {
            ...old_chartData.data,
            visualData: data.visuals[0]
          },
          query: prompt
        }
        rndData[currentNo] = { ...rndData[currentNo], chartData: chartData }
        setRndData([...rndData])
      }

      toast.dismiss(loading)
    } catch (e) {
      toast.dismiss(loading)
      toast.error(e.message)
    }
  }

  useEffect(() => {
    const chartData = rndData[currentNo]?.chartData
    // if (chartData?.name) setValue(chartData.name)
    if (chartData?.title) setValue(chartData.title)
    console.log(chartData?.name)
    if (chartData?.settings) setSettings(chartData.settings)
    if (chartData?.type) setType(chartData.type)
    if (chartData?.query) setPrompt(chartData.query)
  }, [currentNo, rndData])

  useOutSideClick(setShowFilter, filterRef)

  return (
    <div>
      <div className="h-full  overflow-y-auto text-grey">
        <div>
          {type !== 'Text' &&
            <div className="relative inline-block w-full">
              <input
                placeholder="Type name.."
                value={value}
                onChange={(e) => changeValue(e.target.value)}
                className="outline-none text-sm rounded-lg border border-gray-300 focus:border-primary h-9 px-2 w-full mt-1"
              />
              <img src="/imgs/Edit.png" alt="icon" className="absolute top-1/2 right-3 transform -translate-y-1/2 h-4 w-4" />
            </div>
          }

          <div className="w-full flex-col justify-start items-start gap-1 inline-flex">
            <div className="self-stretch justify-start items-center gap-2 inline-flex">
              <div className="grow shrink basis-0 text-gray-700 text-sm mt-3">Your Prompt</div>
            </div>
            <div className="rounded-lg border border-gray-300 flex-col justify-start items-start flex w-full">
              <div className="w-full bg-gray-50 rounded-tl-lg rounded-tr-lg border-b border-gray-300 justify-start items-start gap-2.5 inline-flex">
                <textarea value={prompt} onChange={(e) => changePrompt(e.target.value)} className="border-none outline-none text-sm rounded-lg border border-gray-300 focus:border-primary p-2 px-2 w-full " rows="6" />
              </div>
              <div className="w-full p-2 bg-gray-50 rounded-bl-lg rounded-br-lg justify-start items-center inline-flex">
                <div className="justify-start items-center flex gap-2">
                  <button className="px-3 py-[6px] bg-primary rounded-lg justify-start items-center gap-1 inline-flex" onClick={sendPrompt}>
                    <PaperPlaneIcon color="white" width={13} height={13} />
                  </button>
                  <button className="px-3 py-[6px] bg-primary rounded-lg justify-start items-center gap-1 inline-flex" onClick={copyToClipboard}>
                    <ClipboardCopyIcon color="white" width={13} height={13} />
                  </button>
                </div>
                <div className="grow shrink basis-0 h-[18px] justify-end items-center gap-[15px] flex">
                  <div className="w-[18px] h-[18px] relative" />
                </div>
              </div>
            </div>
          </div>

          {(type !== 'Text' && type !== 'banner') &&
            <div className="w-full mt-4 px-2 py-1 bg-gray-100 rounded-lg justify-start items-center inline-flex">
              <div className="p-1 text-sm">Visualization options</div>
            </div>
          }
        </div>

        <div className="text-sm">
          {visible &&
            <>
              {rndData[currentNo]?.chartData?.data?.visualData &&
                <>
                  {((rndData[currentNo].chartData.type === 'FLATCARD' || rndData[currentNo].currentType === 'FLATCARD')) &&
                    <CardSetting rndData={rndData} setRndData={setRndData} />
                  }
                </>
              }
            </>}  
        </div>
        
        <div className="text-sm">
        {visible &&
            <>
              {rndData[currentNo]?.chartData?.data?.visualData &&
                <>
                  {((rndData[currentNo].chartData.type === 'STACKBAR' || rndData[currentNo].currentType === 'STACKBAR')) &&
                    <StackBarSetting rndData={rndData} setRndData={setRndData} />
                  }
                </>
              }
            </>}
        </div>

        <div className="text-sm">
        {visible &&
            <>
              {rndData[currentNo]?.chartData?.data?.visualData &&
                <>
                  {((rndData[currentNo].chartData.type === 'CircularNetwork' || rndData[currentNo].currentType === 'CircularNetwork')) &&
                    <CircluarNetworkSetting rndData={rndData} setRndData={setRndData} />
                  }
                </>
              }
            </>}
        </div>

        {type === 'LANDSCAPE' &&
          <div className="h-[200px] overflow-y-auto mt-2 pb-4" ref={filterRef}>
            {filters && filters.map((item, ind) => (
              <div className="py-1" key={ind}>
                {item.com_type === 'text' && (
                  <div>
                  </div>
                )}
                {item.com_type === 'select' && (
                  <div className="relative w-full">
                    <div className="grow shrink basis-0 text-gray-700 text-sm my-2">Filter</div>
                    <div
                      onClick={() => setShowFilter(!showFilter)}
                      className={`border-gray-300 border-[1px] cursor-default justify-between items-center flex h-10 w-full border rounded px-1`}>
                      <div className="flex items-center">
                        <span className="ml-2 text-xs font-[500]">{item.key}</span>
                      </div>
                      <span className="text-xs">
                        <ChevronDownIcon />
                      </span>
                    </div>
                    <div className={`${showFilter ? 'opacity-1 h-[100px] rounded' : 'pointer-events-none opacity-0 h-0'} py-2 shadow-lg shadow-[#eee] transition absolute overflow-y-auto bg-white w-full border-gray-300 z-10 mt-[2px] rounded`}>
                      {item.values.map((val, no) => (
                        <div
                          onClick={() => {
                            const updatedRndData = [...rndData];
                            const f_ary = [...filters];
                            const index = item.cur_vals.indexOf(val);
                            const cur_vals = [...f_ary[ind].cur_vals]
                            if (index > -1) {
                              cur_vals.splice(index, 1);
                            } else {
                              cur_vals.push(val);
                            }
                            f_ary[ind] = { ...f_ary[ind], cur_vals: cur_vals }
                            updatedRndData[currentNo].chartData.settings.filters = f_ary;
                            setRndData(updatedRndData)
                          }}
                          className="w-full flex justify-between py-[6px] px-1 hover:bg-[#0694A31F] text-xs"
                          key={no}>
                          <p>{val}</p>
                          {item.cur_vals.indexOf(val) > -1 &&
                            <p className="text-white bg-primary p-[1px] rounded-full">
                              <CheckIcon />
                            </p>
                          }
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
            <div className="mt-2 w-full">
              <div className="grow shrink basis-0 text-gray-700 text-sm my-2">Company</div>
              <input
                placeholder="Search by label"
                className="placeholder:text-xs placeholder:font-normal text-sm w-full outline-none px-2 h-10 border border-gray-300 border-[1px] rounded focus:border-[#0694A3]"
                onChange={(e) => {
                  const updatedRndData = [...rndData];
                  updatedRndData[currentNo].chartData.settings.label = e.target.value;
                  setRndData(updatedRndData)
                }}
              />
            </div>
          </div>}
      </div>
    </div>
  )
}