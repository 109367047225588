import React, { useState, useEffect } from 'react';
// import React from 'react';
import Chart from '../Chart';

const processDataForParliamentDonutChart = ({visualData}) => {
  const visuals=visualData
    const { dimensions, metrics } = visuals.spec;
    const visualsData = visuals.data;
    let data=[]
    visualsData[Object.keys(visualsData)[0]].forEach((value, index) => {
      let ite={}
      Object.keys(visualsData).forEach((item)=>{
        ite[item]=visualsData[item][index]
      })
      data.push(ite)
    })
          // 创建一个对象，用于存储分组后的数组
      const groupedArrays = {};

      // 遍历原始数组
      data.forEach(item => {
          // 获取产品名称
          const productName = item.Product;

          // 如果该产品的数组不存在，创建一个空数组
          if (!groupedArrays[productName]) {
              groupedArrays[productName] = [];
          }

          // 将当前项添加到相应产品的数组中
          groupedArrays[productName].push(item);
      });

      // 将对象的值（即分组后的数组）转换为数组
      const resultArrays = Object.values(groupedArrays);

      console.log({resultArrays});
    return resultArrays
    
};


const ParliamentDonutChart = ({visualData,rd}) => {

  const data =  processDataForParliamentDonutChart({visualData})
  const defaultPalette = [
    "#0e9ab0",
    "#26b2e4",
    "#4acbff",
    "#86e9ff",
    "#b6ffec",
    "#e6ffd4",
    "#ffffb8",
    "#14a0bd",
    "#2cb8f1",
    "#56d1FF",
    "#92EFFE",
    "#C2FFE6",
    "#E5FBC7",
    "#FFF5A8",
    "#1AA6CA",
    "#32BEFE",
    "#6EDDFF",
    "#9EF5F8",
    "#CEFFE0",
    "#E6F7B9",
    "#FFF28E",
    "#20ACD7",
    "#3EC5FF",
    "#7AE3FF",
    "#AAFBF2",
    "#DAFFDA",
    "#E9F2AB",
    "#FFEE69",
    "#FFEB51"
  ];

  const radius = ['30%', '80%'];

  const parliamentOption = (function () {
    // let sum = data.reduce(function (sum, cur) {
    //   return sum + cur.value;
    // }, 0);

    let angles = [];
    let startAngle = -Math.PI / 2;
    let curAngle = startAngle;

    data.forEach(function (item) {
      angles.push(curAngle);
      curAngle += (Math.PI * 2) / data.length; // 计算角度，平均分布
    });

    angles.push(startAngle + Math.PI * 2);

    function parliamentLayout(startAngle, endAngle, totalAngle, r0, r1, size,idx) {
      let rowsCount = data[idx].length;
      let points = [];
      let r = r0;

      for (let i = 0; i < rowsCount; i++) {
        let totalRingSeatsNumber = Math.round((totalAngle * r) / size);
        let newSize = (totalAngle * r) / totalRingSeatsNumber;
        let k = Math.floor((startAngle * r) / newSize) * newSize;
        
        let angle = (k + Math.floor((endAngle * r) / newSize) * newSize) / (2 * r);
        let x = Math.cos(angle) * r;
        let y = Math.sin(angle) * r;

        points.push([x, y]);
 
        r += size;
      }
      return points;
    }

    return {
      // tooltip: {
      //   backgroundColor: 'rgba(255,255,255,0.9)',
      //   textStyle: {
      //     color: '#333'
      //   },
      // },
      series: {
        type: 'custom',
        id: 'distribution',
        data: data,
        coordinateSystem: undefined,
        universalTransition: true,
        animationDurationUpdate: 1000,
        renderItem: function (params, api) {
          var idx = params.dataIndex;
          var viewSize = Math.min(api.getWidth(), api.getHeight());
          var r0 = ((parseFloat(radius[0]) / 100) * viewSize) / 2;
          var r1 = ((parseFloat(radius[1]) / 100) * viewSize) / 2;
          var cx = api.getWidth() * 0.5;
          var cy = api.getHeight() * 0.5;
          var size = viewSize / 50;
          var points = parliamentLayout(
            angles[idx],
            angles[idx + 1],
            Math.PI * 2,
            r0,
            r1,
            size + 3,
            idx
          );

          return {
            type: 'group',
            children: points.map(function (pt) {
              return {
                type: 'circle',
                autoBatch: true,
                shape: {
                  cx: cx + pt[0],
                  cy: cy + pt[1],
                  r: size / 2
                },
                style: {
                  fill: defaultPalette[idx % defaultPalette.length]
                }
              };
            })
          };
        }
      }
    };
  })();
return (
  <Chart option={parliamentOption}  rd={rd}/>
) };


export {ParliamentDonutChart};
