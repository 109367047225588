import { DropdownMenu } from "@radix-ui/themes";
import {
  ListBulletIcon,
  ArchiveIcon,
  DashboardIcon,
  StarIcon,
} from "@radix-ui/react-icons";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Switch } from "@radix-ui/themes";

import { DeleteIcon, PaperIcon, PrintIcon } from "../Icons";
import {
  setFullWidth,
  setIsSmallText,
  setCanvasView,
} from "../../store/slices/globalSlice";

const IconButton = ({ props }) => {
  const { Icon, text } = props;
  return (
    <div
      className="flex gap-2 my-3 text-xs items-center cursor-pointer text-grey dark:text-white"
      onClick={props.func}
    >
      {Icon}
      <span>{text}</span>
    </div>
  );
};
const OptionList = ({
  saveDoc,
  createNew,
  navigate,
  isPublicShare,
  isPublic,
  groupVcards,
  print,
  bookMark,
  deleteDoc,
  hideDeleteBtn,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isFullWidth = useSelector((state) => state.global.isFullWidth);
  const isSmallText = useSelector((state) => state.global.isSmallText);
  const isCanvasView = useSelector((state) => state.global.isCanvasView);

  const dropdownLists = [
    {
      Icon: (
        <StarIcon
          className="text-[#666] dark:text-white"
          width={27}
          height={24}
        />
      ),
      text: t("Bookmark"),
      func: bookMark,
    },
    {
      Icon: (
        <ArchiveIcon
          className="text-[#666] dark:text-white"
          width={28}
          height={20}
        />
      ),
      text: t("Save"),
      func: () => {
        saveDoc();
      },
      isHide: isPublicShare,
    },
    {
      Icon: <DeleteIcon width={27} height={25} color="#6B7280" />,
      text: t("Delete"),
      func: () => deleteDoc(),
      isHide: hideDeleteBtn,
    },
    {
      Icon: <Switch size="1" color="cyan" checked={isCanvasView} />,
      text: t("Canvas View"),
      func: () => {
        dispatch(setCanvasView(!isCanvasView));
      },
    },
    {
      Icon: <Switch size="1" color="cyan" checked={isFullWidth} />,
      text: t("Full Width"),
      func: () => {
        dispatch(setFullWidth(!isFullWidth));
      },
    },
    {
      Icon: <Switch size="1" color="cyan" checked={isSmallText} />,
      text: t("Small Text"),
      func: () => {
        dispatch(setIsSmallText(!isSmallText));
      },
    },
    {
      Icon: <PrintIcon className="text-[#666] dark:text-white" />,
      text: t("Print"),
      func: print,
    },
    {
      Icon: <PaperIcon className="text-[#666] dark:text-white" />,
      text: t("New Report"),
      func: () => {
        if (isPublic) {
          return navigate("/signin");
        }
        createNew();
        navigate("/smartdoc");
      },
    },
    {
      Icon: (
        <DashboardIcon
          className="text-[#666] dark:text-white ml-1"
          width={22}
          height={20}
        />
      ),
      text: t("Group Vcard"),
      isHide: isPublicShare,
      func: () => {
        groupVcards();
      },
    },
  ];

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className="outline-none">
        <button className="fixed bottom-4 sm:right-4 right-3 text-white bg-primary rounded sm:p-2 p-1">
          <ListBulletIcon className="sm:w-6 w-5 h-[15px] sm:h-[18px]" />
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className="pr-4">
        <div className="text-sm">
          <p className="uppercase text-[#bbb] dark:text-white">
            {t("options")}
          </p>
          {dropdownLists.map((item, no) => (
            <div key={no}>{!item?.isHide && <IconButton props={item} />}</div>
          ))}
        </div>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default OptionList;
