import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import * as Tabs from '@radix-ui/react-tabs';
import { useNavigate } from "react-router-dom";
import { CheckIcon, Cross1Icon } from "@radix-ui/react-icons";

import { PenIcon, DeleteIcon } from "../components/Icons";
import { setUserSubscription } from "../store/slices/authSlice";
import { LookupKeys } from "./config";

function MyAccount() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.userData)
  const showSidebar = useSelector((state) => state.global.showSidebar)
  const userSubscription = useSelector((state) => state.auth.userSubscription)

  const [editMode, setEditMode] = useState(false)
  const [activeTav, setActiveTav] = useState('');
  const [userAvatar, setUserAvatar] = useState('')
  const [username, setUsername] = useState('')

  function getUsernameFromEmail(email = '') {
    // Split the email address at the "@" symbol
    const parts = email.split('@');

    // The username is the first part of the email address
    const username = parts[0];

    return username;
  }

  const updateSubscription = async (plan = '') => {
    const lookup_key = LookupKeys[plan]

    const params = {
      customer_id: userSubscription.customer_id,
      lookup_key: lookup_key,
      sub_id: userSubscription.sub_id
    }

    const res = await axios.post('/subscription/update-membership', { ...params })

    // console.log(res)
    getSubscription();
  }

  const createSubscription = async (plan = '') => {
    const params = {
      type: plan,
      lookup_key: LookupKeys[plan],
      email: user.email,
    }

    const res = await axios.post('/subscription/create-membership', { ...params })
    window.location.href = res.data.url
  }

  const getSubscription = async () => {
    const res = await axios.get('/subscription/get')
    dispatch(setUserSubscription(res.data))
  }

  const upgrade = (plan = '') => {
    if (!user.id) return
    if (userSubscription.sub_id) {
      updateSubscription(plan)
    } else {
      createSubscription(plan)
    }
  }

  const fileChangeHandler = (e) => {
    const file = e.target.files[0]
    if (!file) return
    if (file.type.includes('image')) {
      const data = {
        url: URL.createObjectURL(file),
        file: file,
        name: file.name.replaceAll("'", ""),
      }
      setUserAvatar(data.url)
    }
  }

  useEffect(() => {
    if (user) {
      const username = getUsernameFromEmail(user.email)
      setUsername(username)
    }
    getSubscription()
  }, [user])

  const isActive = userSubscription?.sub_status === 'active'
  const isFreeSub = (isActive && userSubscription?.membership_type === 'free')||userSubscription?.type==='noSubscription'
  const isProSub = isActive && userSubscription?.membership_type === 'pro'
  const isEnterSub = isActive && userSubscription?.membership_type === 'enter'

  return (
    <div className={`w-full h-screen min-h-[783px] pt-20 bg-white
        ${showSidebar
        ? 'lg:pl-[314px] md:pl-[256px] sm:pl-[218px] pl-20'
        : 'pl-12'} lg:pr-20 md:pr-10 sm:pr-8 relative`}>
      <div
        onClick={() => setEditMode(false)}
        className={`${editMode ? 'opacity-1' : 'pointer-events-none opacity-0'} transition-all w-full h-screen absolute left-0 top-0 bg-[#11111199] z-20 flex justify-center items-center p-4`}>
        <div className="sm:w-[500px] p-6 rounded-xl bg-white text-sm text-grey relative" onClick={e => e.stopPropagation()}>
          <button className="absolute right-6 top-6" onClick={() => setEditMode(false)}>
            <Cross1Icon color="#555" />
          </button>
          <div className="text-center font-semibold text-xl">Edit Profile</div>
          <div className="mt-5">
            <Tabs.Root defaultValue="general" className="">
              <Tabs.List className="mb-2 shrink-0 flex border-b border-mauve6 gap-1">
                <Tabs.Trigger
                  className="px-2 pb-3 data-[state=active]:text-[#0694a3] text-sm leading-none text-mauve11 select-none first:rounded-tl-md last:rounded-tr-md hover:text-violet11 data-[state=active]:text-violet11 data-[state=active]:shadow-[inset_0_-1px_0_0,0_1px_0_0]"
                  value="general"
                >
                  Generel
                </Tabs.Trigger>
                <Tabs.Trigger
                  className="px-2 pb-3 data-[state=active]:text-[#0694a3] text-sm leading-none text-mauve11 select-none first:rounded-tl-md last:rounded-tr-md hover:text-violet11 data-[state=active]:text-violet11 data-[state=active]:shadow-[inset_0_-1px_0_0,0_1px_0_0] "
                  value="password"
                >
                  Password
                </Tabs.Trigger>

              </Tabs.List>
              <Tabs.Content value="general">
                <div className="flex justify-center my-4">
                  <div>
                    {user.src
                      ? <div className="bg-cover bg-center bg-no-repeat w-40 h-40 rounded-full" style={{ backgroundImage: `url(${user.src})` }} />
                      : <div style={{ backgroundImage: `url('${userAvatar}')` }} className="bg-cover bg-center relative rounded-full w-40 h-40 border text-primary border-primary uppercase items-center justify-center flex text-xl font-semibold">
                        {userAvatar ? '' : ((username[0] || '') + '' + (username[1] || ''))}
                        <button className="p-1 bg-primary absolute bottom-3 right-3 rounded-full">
                          <PenIcon color={'white'} />
                          <input
                            type="file"
                            className="w-full absolute opacity-0 h-full left-0 top-0 z-[2]"
                            onChange={(e) => { fileChangeHandler(e); e.target.value = null }}
                          />
                        </button>
                      </div>
                    }
                  </div>
                </div>
                <div className="w-full mt-2">
                  <p className="text-grey">Username</p>
                  <input
                    disabled
                    defaultValue={username}
                    type="text" className={`border w-full p-2 px-3 rounded-lg mt-2 outline-none focus:border-primary disabled:bg-transparent p-1`} />
                </div>
                <div className="w-full mt-2">
                  <p className="text-grey">Email</p>
                  <input
                    disabled
                    defaultValue={user.email || ''}
                    type="text" className={`border w-full p-2 px-3 rounded-lg mt-2 outline-none focus:border-primary disabled:bg-transparent p-1`} />
                </div>
                <div className="flex gap-6 mt-8 font-semibold">
                  <button className="bg-primary text-white w-1/2 py-2 rounded-lg">Save</button>
                  <button className="bg-white text-black w-1/2 py-2 rounded-lg border" onClick={() => setEditMode(false)}>Cancel</button>
                </div>
              </Tabs.Content>
              <Tabs.Content value="password">
                <div className="w-full mt-6">
                  <p className="text-grey">Old Password</p>
                  <input
                    disabled
                    type="password"
                    placeholder="current password"
                    className={`border w-full p-2 px-3 rounded-lg mt-2 outline-none focus:border-primary disabled:bg-transparent p-1`} />
                </div>
                <div className="w-full mt-2">
                  <p className="text-grey">New Password</p>
                  <input
                    disabled
                    type="password"
                    placeholder="new password"
                    className={`border w-full p-2 px-3 rounded-lg mt-2 outline-none focus:border-primary disabled:bg-transparent p-1`} />
                </div>
                <div className="w-full mt-2">
                  <p className="text-grey">Password Confirmation</p>
                  <input
                    disabled
                    type="password"
                    placeholder="confirm password"
                    className={`border w-full p-2 px-3 rounded-lg mt-2 outline-none focus:border-primary disabled:bg-transparent p-1`} />
                </div>
                <div className="flex gap-6 mt-8 font-semibold">
                  <button className="bg-primary text-white w-1/2 py-2 rounded-lg">Save</button>
                  <button className="bg-white text-black w-1/2 py-2 rounded-lg border" onClick={() => setEditMode(false)}>Cancel</button>
                </div>
              </Tabs.Content>
            </Tabs.Root>
          </div>
        </div>
      </div>

      <p className="font-bold text-center mb-4">My Profile</p>
      <div className="p-6 rounded-lg border">
        <div className="md:flex justify-between gap-4">
          <div className="flex gap-3 items-center">
            <div className="bg-primary items-center justify-center flex w-20 h-20 rounded-full text-white font-bold uppercase">
              {(username[0] || '') + '' + (username[1] || '')}
            </div>
            <div>
              <p className="text-[#000] font-semibold">{username}</p>
              <p className="text-grey mt-1">{user.email}</p>
            </div>
          </div>
          <div className="flex gap-3 mt-5">
            <button
              onClick={() => setEditMode(true)}
              className={`${editMode ? 'border-primary bg-[#f5fbfb]' : ''} flex gap-1 border rounded-lg py-[6px] px-4 text-black font-semibold h-fit text-sm`}>
              <PenIcon color={'#000'} />
              <span className="md:block hidden">Edit Profile</span>
            </button>
            <button className="flex gap-1 border border-[#f06565] rounded-lg py-[6px] px-4 text-[#f06565] font-semibold h-fit text-sm">
              <DeleteIcon color={'#f06565'} />
              <span className="md:block hidden">Delete Account</span>
            </button>
          </div>
        </div>

        <div className="border-b pb-4 mt-4">
          <p className="text-lg text-[#000]">Personal Information</p>
          <div className="md:grid grid-cols-3 gap-3 text-sm mt-5">
            <div className="w-full mt-2">
              <p className="text-grey">Username</p>
              <div className="p-1 font-semibold">{username}</div>
              {/* <input
                defaultValue={username}
                disabled={!editMode}
                type="text" className={`${editMode ? 'border-primary' : 'border-white'} text-[#000] font-semibold mt-1 outline-none focus:border-primary border-b disabled:bg-transparent p-1`} /> */}
            </div>
            <div className="w-full mt-2">
              <p className="text-grey">Email</p>
              <div className="p-1 font-semibold">{user?.email || ''}</div>
            </div>
            <div className="w-full mt-2">
              <p className="text-grey">Password</p>
              <input
                type="password"
                defaultValue={'sdfsafsafsfasdf'}
                className={`text-[#000] font-semibold mt-1 outline-none focus:border-primary p-1 disabled:bg-transparent`} disabled />
            </div>
          </div>
        </div>

        <div className="pt-5 text-grey">
          <p>Plan</p>
          <div className="md:grid xl:grid-cols-3 grid-cols-2 mt-3 gap-4">
            <div className={`p-4 rounded-lg mt-2 border ${isFreeSub ? 'bg-[#f5fbfb] border-primary' : ''}`}>
              <div className="flex justify-between gap-4">
                <div>
                  <div className="flex gap-1">
                    <p className="text-[#000] font-semibold">Free Plan</p>
                    <div className="p-[1px] w-5 h-5 rounded-full border border-primary scale-[0.8]">
                      <CheckIcon color="#05c1d1" />
                    </div>
                  </div>
                  <p className="text-xs">24 days remaining</p>
                </div>
                <div className="text-lg font-semibold">
                  <span className="text-[#000] font-bold">$0.00</span>
                  /month
                </div>
              </div>
              <button
                onClick={() => upgrade('free')}
                disabled={isFreeSub}
                className={`${isFreeSub ? '' : 'border-primary bg-[#f5fbfb] text-primary'} border mt-5 rounded px-4 py-2 text-sm`}>{isFreeSub ? 'Subscribed' : 'Upgrade'}</button>
            </div>

            <div className={`p-4 rounded-lg mt-2 border ${isProSub ? 'bg-[#f5fbfb] border-primary' : ''}`}>
              <div className="flex justify-between gap-4">
                <div>
                  <div className="flex gap-1">
                    <p className="text-[#000] font-semibold">Premium Plan</p>
                    <div className="p-[1px] w-5 h-5 rounded-full border border-primary scale-[0.8]">
                      <CheckIcon color="#05c1d1" />
                    </div>
                  </div>
                  <p className="text-xs">24 days remaining</p>
                </div>
                <div className="text-lg font-semibold">
                  <span className="text-[#000] font-bold">$17.99</span>
                  /month
                </div>
              </div>
              <button
                onClick={() => upgrade('pro')}
                disabled={isProSub}
                className={`${isProSub ? '' : 'border-primary bg-[#f5fbfb] text-primary'} border mt-5 rounded px-4 py-2 text-sm`}>
                {isProSub ? 'Subscribed' : 'Upgrade'}
              </button>
            </div>

            <div className={`p-4 rounded-lg mt-2 border ${isEnterSub ? 'bg-[#f5fbfb] border-primary' : ''}`}>
              <div className="flex justify-between gap-4">
                <div>
                  <div className="flex gap-1">
                    <p className="text-[#000] font-semibold">Custom Plan</p>
                    <div className="p-[1px] w-5 h-5 rounded-full border border-primary scale-[0.8]">
                      <CheckIcon color="#05c1d1" />
                    </div>
                  </div>
                  <p className="text-xs">24 days remaining</p>
                </div>
                <div className="text-lg font-semibold">
                  <span className="text-[#000] font-bold">$0.00</span>
                  /month
                </div>
              </div>
              <button 
              onClick={(() => navigate('/contact-sales'))}
              className="border border-primary bg-[#f5fbfb] text-primary mt-5 rounded px-4 py-2 text-sm">
                Contact Us
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyAccount;