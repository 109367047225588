const cn = {
  /**Nav bar */
  'Explore': "探索",
  'Questions': "问题",
  'Dashboards': "仪表板",
  'Datasets': "数据集",
  'Reports': "报告",
  'Graph': '图形',
  'SmartDoc': '智慧型文檔',
  'Workflow': '工作流程',
  'Log Out': '登出',
  'Profile': "筛选",
  'Payment & Subscription': '個人資料和訂閱',
  'Upgrade': '升級',
  'Get access to all features on Shugic': '存取 Shugic 的所有功能',
  'Get Premium': '獲得高級版',

  /** Smart Doc */
  'Get Started With Us': '開始與我們合作',
  'placeholder text': '佔位符文字',
  'Type ‘/’ to see a list of predefined options': '輸入“/”查看預定義選項列表',
  'If we have a question click on “?” icon above to get the documentation or send a message!': '如果我們有疑問，請點擊“？” 上面的圖示可取得文件或傳送訊息！',
  'Press ‘/’  here to see the list of options ....': '按此處的“/”查看選項清單...',
  'options':'選項',
  'Share':'分享',
  'Download':'下載',
  'Full Width':'全螢幕寬度',
  'Small Text':'小文字',
  'Print':'列印',
  'New Report':'新報告',

  /**Languages */
  Chinese: "中文",
  English: "英文",
  German: "德文",
  toggle: "切换",
  go: "查询",
  // 搜索框相关
  search: "查询",
  profile: "筛选",
  graph: '图形',
  database: "数据源",
  // 顶部
  language: "语言",
  question: '问题',
  notify: "通知",
  // 问题
  look: '查看',
  delete: '删除',
  // vsHeader
  'Add to saved items': '添加',
  'share items': '分享',
  'change chart': '切换图表',
  'download': '下载',
  'send email': '发送邮件',
  'copy': '复制',
  'config': '配置',

  // 历史记录
  'go right': '向右移动',
  'delete all': '全部删除',
  'history': '历史记录',
  'Click the query': '点击查询',
  'collapseCards': '折叠卡片',
  'goToDashboard': '转到仪表板',
  // 移动
  'fold': '折叠',
  'up': '向上',
  'down': '向下',
  'top': '置顶',
  'bottom': '置底',
  'refresh': '刷新',

  edit: '编辑',
  'save': '保存',

  'parallel': '平行放置',
  'merge': '合并图表',
  'sum': '图表相加',
  label: "标签",
  to: "转换",
  breakdown: "扩展",
  "Category": "横坐标",
  "Dimensions": "纵坐标",
  "radius": "半径",
  "Bubbles Per Row": "间距",

  // 标题、内容等等相关
  'Data Model': '数据模型',
  Update: "更改",
  "Create new one": "新建",
  'Edit': '编辑',
  'Diagram': '图表',
  'Data source replaced successfully': '数据源更换成功',
  'synonym': '同义词',
  'Add this shot to a collection': '将此添加到收藏夹',
  'Done': '完成',
  'Create New Collection': '创建新的收藏夹',
  'Filter collections': '过滤收藏夹',
  'Copy': '复制',
  'Cancel': '关闭',
  'Configuration': '配置',
  'Style': '风格',
  'Visuals': '视觉',
  'Description': '描述',
  'Column Order': '列顺序',
  'Set Visual Type': '设置视觉类型',
  'Set Formula': '设置公式',
  'Theme': '主题',
  'Title BG Color': '标题背景颜色',
  'Title Font Color': '标题字体颜色',
  'Title alignment': '标题对齐方式',
  'Hide title': '是否隐藏标题',
  'Chart BG Color': '图表背景色',
  'Change Background Color': '背景颜色',
  'Column as Label': '标签列',
  'column': '列',
  'order': '排序',
  'Visual Type': '图表类型',
  'GroupBy': '分组',
  'Compute': '计算',
  'name': '名称',
  'fx': '公式',
  'fx-operator': '公式符号',
  'fx-column': '公式列',

  'Auxiliary': '辅助',
  'Orientation': '方向',
  'Sort': '排序',
  'X-Tick': 'X轴是否显示',
  'Y-Tick': 'Y轴是否显示',
  'Label': 'label标签',
  'Legend': '图例是否显示',
  'Average Line': '平均线',
  'Top': '前几位',
  'Bottom': '后几位',
  'tags': '添加标签',
  'choose': '选择',
  'layout': '布局',
  'Layout': '布局',
  'style': '风格',
  'theme': '主题',
  'title plain text': '是否标题纯文本',
  'round corner': '是否圆角',
  'show shadow': '是否显示阴影',
  'compact': '紧凑模式',
  'default': '默认',
  'dark': '黑暗',
  'technology': '科技',
  'auto': '自动布局',
  'intelligent': '智能布局',
  'No Data': '暂无数据',
  'Comment': '评论',
  'Comments': '评论',
  'There are no comments. Add first.': '暂无评论，请先添加。',
  'Delete Comment': '删除评论',
  'OK': '确认',
  'remove': '移除',
  'from': '来自',
  'Quick Overview': '快速概览',
  'Upload': '上传',
  'Specify Data Types': '指定数据类型',
  'Configure': '配置',

  'Table Name': '文件名',
  'Schema Name': '自定义名字',
  'Apply to existing': '应用于现有',
  Append: "附加",
  Select: "选择",
  "Data Space": "数据空间",
  'New': '新建',
  'Submit': '提交',
  'Save': '保存',
  'Host name/address': '主机名/地址',
  'Port': '端口',
  'Maintenance database': '维护数据库',
  'Password': '密码',
  'Already uploaded files': '已上传文件',
  'Delete': '删除',
  'Open': '打开',
  'File Name': '文件名',
  'Rows': '行',
  'Columns': '列',
  'Date': '日期',
  'Author Name': '作者姓名',
  'Dashbords': '仪表盘',
  'Click or drag file to this area to upload': '点击上传或者拖拽文件到此处上传',
  DataSpace: "数据空间",
  'Color': '颜色',
  'visualTypeInGrid': '网格类型',
  'choose image': '选择图片',
  'Click this area to upload': '点击此区域上传',
  Username: "用户名",
  "Schema name": "文件名",
  "Datasource id": "数据源编号",
  "Database name": "表明",
  "Dataspace name": "数据空间名称",
  "There are no dashboards created.": "暂无数据",
  "Refresh": "刷新",
  "Delete dataspace (all tables will be also deleted).": "删除数据空间（所有表也将被删除）。"
};

export default cn;
