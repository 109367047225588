import { useSelector } from "react-redux";
import { useState } from "react";
import { Flex, RadioGroup, Text, RadioCards, Box } from '@radix-ui/themes';

export const CircluarNetworkSetting = ({ rndData, setRndData }) => {
    const settingStyleNo = useSelector((state) => state.global.settingStyleNo);
    const spec = rndData[settingStyleNo].chartData.data.visualData.spec;
    const columns = spec.columns;
    // 设置默认选中项为第一个item.column
    const [layer1Column, setLayer1Column] = useState(columns[0]?.column || '');
    const [layer2Column, setLayer2Column] = useState(columns[1]?.column || '');
    const [colorColumn, setColorColumn] = useState(columns[0]?.column || '');
    const [sizeColumn, setSizeColumn] = useState( '');
    const handleChange1 = (layer1Column) => {
        setLayer1Column(layer1Column);
        setRnd("layer1Column",layer1Column);
    };
    const handleChange2 = (layer2Column) => {
        setLayer2Column(layer2Column);
        setRnd("layer2Column",layer2Column);
    };
    const handleChange3 = (colorColumn) => {
        setColorColumn(colorColumn);
        setRnd("colorColumn",colorColumn);
    };
    const handleChange4 = (sizeColumn) => {
        setSizeColumn(sizeColumn);
        setRnd("sizeColumn",sizeColumn);
    };
    const [setting, setSetting] = useState({});
    const setRnd = (key, value) => {
      setting[key]=value
      setSetting(setting)
      let data={...rndData[settingStyleNo],setting}
      rndData[settingStyleNo]=data
      const newRndData = [...rndData];
      setRndData(newRndData)
    };

    return (
       <>
        <div className="mt-4 flex items-center justify-center text-xs">
            <h3 className="mr-4">layer1Column:</h3>
            <Box maxWidth="600px">
                <RadioCards.Root
                    value={layer1Column}
                    columns={{ initial: '1', sm: '2' }}
                    color="cyan"
                    onValueChange={handleChange1} // 绑定选择事件
                >
                    {columns.map((item) => (
                        <RadioCards.Item key={item.column} value={item.column}>
                            <Flex direction="column"  height={14}>
                                <Text className="text-xs">{item.column}</Text>
                            </Flex>
                        </RadioCards.Item>
                    ))}
                </RadioCards.Root>
            </Box>
            
        </div>
        <div className="mt-4 flex items-center justify-center text-xs">
            <h3 className="mr-4">layer2Column:</h3>
            <Box maxWidth="600px">
                <RadioCards.Root
                    value={layer2Column}
                    columns={{ initial: '1', sm: '2' }}
                    color="cyan"
                    onValueChange={handleChange2} // 绑定选择事件
                >
                    {columns.map((item) => (
                        <RadioCards.Item key={item.column} value={item.column}>
                            <Flex direction="column"  height={14}>
                                <Text className="text-xs">{item.column}</Text>
                            </Flex>
                        </RadioCards.Item>
                    ))}
                </RadioCards.Root>
            </Box>
            
        </div>
        <div className="mt-4 flex items-center justify-center text-xs">
            <h3 className="mr-4">colorColumn:</h3>
            <Box maxWidth="600px">
                <RadioCards.Root
                    value={colorColumn}
                    columns={{ initial: '1', sm: '2' }}
                    color="cyan"
                    onValueChange={handleChange3} // 绑定选择事件
                >
                    {columns.map((item) => (
                        <RadioCards.Item key={item.column} value={item.column}>
                             <Flex direction="column"  height={14}>
                                <Text className="text-xs">{item.column}</Text>
                            </Flex>
                        </RadioCards.Item>
                    ))}
                </RadioCards.Root>
            </Box>
            
        </div>
        <div className="mt-4 flex items-center justify-center text-xs">
            <h3 className="mr-4">sizeColumn:</h3>
            <Box maxWidth="600px">
                <RadioCards.Root
                    value={sizeColumn}
                    columns={{ initial: '1', sm: '2' }}
                    color="cyan"
                    onValueChange={handleChange4} // 绑定选择事件
                >
                    {columns.map((item) => (
                        <RadioCards.Item key={item.column} value={item.column}>
                             <Flex direction="column"  height={14}>
                                <Text className="text-xs">{item.column}</Text>
                            </Flex>
                        </RadioCards.Item>
                    ))}
                </RadioCards.Root>
            </Box>
            
        </div>
       </>
    );
};
