import React from 'react';
import Chart from '../Chart';

const processDataForTreeMapChart = (visualData) => {
    const visual=visualData
    const data = visual.data;
    const dimensions = visual.spec.dimensions;
    const metrics = visual.spec.metrics[0].column;
    let tempData = [];
    let tempDataMap = {};
    if (dimensions.length > 1) {
        for (let i = 0; i < data[dimensions[0].column].length; i++) {
            let key = data[dimensions[1].column][i];
            let name = data[dimensions[0].column][i];
            let value = data[metrics][i];
            if (Object.prototype.hasOwnProperty.call(tempDataMap, key)) {
                tempDataMap[key].value += Number(value) || 0;
                tempDataMap[key].children.push({
                    name: name,
                    value: value,
                    blockInfo: `${dimensions[1].column}：${key}`,
                    dimensionName: dimensions[0].column,
                    metricsName: metrics,
                });
            } else {
                tempDataMap[key] = {
                    value: Number(value) || 0,
                    children: [
                        {
                            name: name,
                            value: value,
                            blockInfo: `${dimensions[1].column}：${key}`,
                            dimensionName: dimensions[0].column,
                            metricsName: metrics,
                        },
                    ],
                };
            }
        }
        for (let key in tempDataMap) {
            tempData.push({
                name: key,
                value: tempDataMap[key].value,
                children: tempDataMap[key].children,
            });
        }
    } else {
        for (let i = 0; i < data[dimensions[0].column].length; i++) {
            let name = data[dimensions[0].column][i];
            let value = data[metrics][i];
            tempData.push({
                name: name,
                value: value,
                dimensionName: dimensions[0].column,
                metricsName: metrics,
            });
        }
    }

    return {tempData};
};

const TreeMapChart = ({visualData,rd}) => {
    const {tempData}=processDataForTreeMapChart(visualData)
    const option = {
        tooltip: {
            trigger: "item",
            formatter: (params) => {
                let tempName = `${params.data.dimensionName}：${params.data.name}<br />${params.data.metricsName}：${params.data.value}`;
                if (params.data.blockInfo) {
                    tempName = `${params.data.blockInfo}<br />` + tempName;
                }
                return tempName;
            },
        },
        series: [
            {
                type: "treemap",
                data:tempData,
                label: {
                    show: true,
                },
            },
        ],
    };
    return (
        <Chart option={option} rd={rd}/>
    );
};

export { TreeMapChart };
