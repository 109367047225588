// export const CodeIcon = ({ color, width, height,transform }) => (
//     <svg xmlns="http://www.w3.org/2000/svg" width={`${width ? width : '20'}`} height={`${height ? height : '20'}`} viewBox="0 0 20 20" fill="none">
//       <path d="M6.66797 14.9587L1.66797 9.95866L6.70964 4.91699L7.60547 5.81283L3.45964 9.95866L7.5638 14.0628L6.66797 14.9587ZM13.293 15.0003L12.3971 14.1045L16.543 9.95866L12.4388 5.85449L13.3346 4.95866L18.3346 9.95866L13.293 15.0003Z" fill="white"/>
//     </svg>
    
// )
export const CodeIcon = ({ color, width, height,transform }) => (
  <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width={`${width ? width : '20'}`} 
      height={`${height ? height : '20'}`} 
      viewBox="0 0 20 20" 
      fill="none"
  >
      <g transform={transform?"rotate(90 10 10)":""}>
          <path 
              d="M6.66797 14.9587L1.66797 9.95866L6.70964 4.91699L7.60547 5.81283L3.45964 9.95866L7.5638 14.0628L6.66797 14.9587ZM13.293 15.0003L12.3971 14.1045L16.543 9.95866L12.4388 5.85449L13.3346 4.95866L18.3346 9.95866L13.293 15.0003Z" 
              fill="white"
          />
      </g>
  </svg>
)
