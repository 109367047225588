import { configureStore } from '@reduxjs/toolkit'

import globalReducer from './slices/globalSlice'
import authReducer from './slices/authSlice'
import smartdocSlice from './slices/smartdocSlice'

export default configureStore({
  reducer: {
    global: globalReducer,
    auth: authReducer,
    smart: smartdocSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['auth/fetchLogin/fulfilled', 'auth/fetchRegister/fulfilled', 'auth/fetchUserInfo/fulfilled', 'auth/logOut/fulfilled'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
        // Ignore these paths in the state
        ignoredPaths: ['items.dates'],
      },
    }),
})