import { useSelector } from "react-redux"
import { Select, Switch } from "@radix-ui/themes";
import { ColorPicker } from '../../Smartdoc/VisualCardWidge/ColorPicker'
import { CircleIcon, StarsIcon, TextIcon } from "../../Icons";
const fontSizes = ['8px', '12px', '14px', '16px', '18px', '20px', '24px', '28px', '32px', '36px', '40px', '44px', '48px', '52px', '56px', '60px']
export const StyleSetting = (({nodes,id,setNodes }) => {

  const changeNode= nodes.find((item)=>item.id===id)

  const handleSetFontColor=((value)=>{
    changeNode.data.textStyle.color=value
    setNodes((nds) =>
    nds.map((node) => {
      if (node.id ===id) {
        node.style = { ...node.style, color:value};
      }
      return node;
      })
    );
  })
  const handleSetBGColor=((value)=>{
    changeNode.data.textStyle.backgroundColor=value
    setNodes((nds) =>
    nds.map((node) => {
      if (node.id ===id) {
        node.style = { ...node.style, background:value};
      }
      return node;
      })
    );
  })
  const fontSizeChange=((e)=>{
    changeNode.data.textStyle.fontSize=e.target.value
    setNodes((nds) =>
    nds.map((node) => {
      if (node.id ===id) {
        node.style = { ...node.style, fontSize:e.target.value};
      }
      return node;
      })
    );
  })
  const fontWeightChange=((value)=>{
    changeNode.data.textStyle.fontWeight=value
    setNodes((nds) =>
    nds.map((node) => {
      if (node.id ===id) {
        node.style = { ...node.style, fontWeight:value};
      }
      return node;
      })
    );
  })
  const themeChanges=((value)=>{
    let styleList={}
    if(value==='light'){
      styleList={color:'#1f2937',fontWeight:400,fontSize:'16px',background:'white'}
    }else if(value==='dark'){
      styleList={color:'white',fontWeight:400,fontSize:'16x',background:'black'}
    }else if(value==='tech'){
      styleList={color:'#white',fontWeight:400,fontSize:'16px',background:'#0694a3'}
    }else if(value==='fun'){
      styleList={color:'#white',fontWeight:400,fontSize:'16px',background:'linear-gradient(79deg, #DCBFF6 5.17%, #8F73C1 48.95%, #6DD3FB 86.65%)'}
    }
    changeNode.data.textStyle.fontWeight=styleList.fontWeight
    changeNode.data.textStyle.fontSize=styleList.fontSize
    changeNode.data.textStyle.backgroundColor=styleList.background
    changeNode.data.textStyle.color=styleList.color
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id ===id) {
          node.style = { ...node.style, ...styleList};
        }
        return node;
        })
      );
   })
  // const changeValue=((value)=>{
  //   changeNode.data.q=value
  //   setNodes((nds) =>
  //   nds.map((node) => {
  //     if (node.id ===id) {
  //       node.data = {
  //         ...node.data,
  //         q: value,
  //       };
  //     }
  //     return node;
  //     })
  //   );
  // })
  return (
    <div className="w-full text-[#1F2A37]">
      <div>
            <div className="w-full left-1 top-1 flex-col justify-center items-center gap-1 inline-flex">
              <div className="self-stretch flex-col justify-start items-start inline-flex">
                <div className="font-medium text-sm bg-[#F3F4F6] w-full py-[6px] px-2 rounded-lg flex items-center gap-2">
                  <CircleIcon width={18} height={18} /> 
                  Theme
                </div>
                <div className="items-start grid grid-cols-5 gap-3 w-full justify-center text-white sm:text-[10px] text-[9px] my-3 px-2">
                  <button className="active:border border-primary btn-grad text-overflow-1 break-all bg-white text-black" onClick={() => themeChanges('light')} >Light</button>
                  <button className="active:border border-primary btn-grad text-overflow-1 break-all bg-black" onClick={() => themeChanges('dark')}>Dark</button>
                  <button className="active:border border-primary btn-grad text-overflow-1 break-all bg-primary" onClick={() => themeChanges('tech')}>Tech</button>
                  <button className="active:border border-primary btn-grad text-overflow-1 break-all btn-grad5" onClick={() => themeChanges('fun')}>Fun</button>
                </div>
              </div>
            </div>
            <div className="flex gap-6 mt-2">
                <div className="flex-col justify-start items-start gap-1 flex">
                  <div className="text-sm font-normal leading-[18px]">Font Size</div>
                  <select
                    onChange={fontSizeChange}
                    value={changeNode?.data?.textStyle.fontSize|| '12px'}
                    className="w-20 font-normal text-sm outline-none border rounded p-1 focus:border-primary border-[#D1D5DB] text-[#6B7280] h-9">
                    {fontSizes.map((val, ind) => (
                      <option value={val} key={ind} className="text-[13px]">{val}</option>
                    ))}
                  </select>
                </div>
                <div className="flex-col justify-start items-start gap-1 flex">
                  <div className="text-sm font-normal leading-[18px]">Font Style</div>
                  <Select.Root
                    size="1"
                    value={changeNode?.data?.textStyle.fontWeight || 400}
                    className="w-full"
                    onValueChange={fontWeightChange}>
                    <Select.Trigger  className="w-full" style={{ height: 36, background: 'white', fontSize: 13, color: '#6B7280', borderColor: '#D1D5DB', fontWeight: 400 }} />
                    <Select.Content position="popper" className="w-full" style={{ fontSize: 13 }}>
                      <Select.Item value={300} className="w-full"><span className="font-[300] pr-1">Ag</span> font-light</Select.Item>
                      <Select.Item value={400} className="w-full"><span className="font-normal pr-1">Ag</span> font-normal</Select.Item>
                      <Select.Item value={500} className="w-full"><span className="font-medium pr-1">Ag</span> font-medium</Select.Item>
                      <Select.Item value={600} className="w-full"><span className="font-bold pr-1">Ag</span> font-bold</Select.Item>
                      <Select.Item value={700} className="w-full"><span className="font-[800] pr-1">Ag</span> font-extrabold</Select.Item>
                    </Select.Content>
                  </Select.Root>
                </div>
            </div>
            <div className="flex-col justify-start items-start gap-1 flex mt-2">
              <div className="text-sm font-normal">Font Color</div>
              <div className="justify-start items-start gap-2 inline-flex">
                <ColorPicker isFont={true} selectedColor={changeNode?.data?.textStyle.color} setSelectedColor={handleSetFontColor}></ColorPicker>
              </div>
            </div>
            <div className="flex-col justify-start items-start gap-1 flex mt-2">
              <div className="text-sm font-normal leading-[18px]">BG Color</div>
              <div className="justify-start items-start gap-2 inline-flex">
                <ColorPicker selectedColor={changeNode?.data?.textStyle.backgroundColor} setSelectedColor={handleSetBGColor}></ColorPicker>
              </div>
            </div>
      </div>
    </div>
  )
})