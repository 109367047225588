import React, { useState, useEffect, useRef } from 'react';
import { ChromePicker } from 'react-color';

export const ColorPicker = ({ selectedColor, setSelectedColor, isFont = false }) => {
  const [customColor, setCustomColor] = useState(selectedColor);
  const [showPicker, setShowPicker] = useState(false);
  const colorPickerRef = useRef(null);
  const handleCustomColorChange = (color) => {
    setCustomColor(color.hex);
    setSelectedColor(color.hex);
    // setShowPicker(false);
  };

  const togglePicker = () => {
    setShowPicker(!showPicker);
  };

  const handleColorSelection = (color) => {
    setSelectedColor(color);
    setShowPicker(false);
  };

  const colorOptions = [isFont ? 'black' : 'white', '#0694A3', '#26B2E4', '#AAFBF2', '#DAFFDA'];

  const isColorOfOptions = colorOptions.includes(selectedColor)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [colorPickerRef]);
  return (
    <div ref={colorPickerRef} className="flex items-center space-x-4">
      <div className="justify-start items-start gap-4 inline-flex">
        <div
          className="w-[18px] h-[18px] border rounded-full shadow relative"
          // style={{ backgroundColor: 'transparent' }}
          onClick={() => handleColorSelection('transparent')}
        >
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center cursor-pointer">
            <div className="w-full h-[2px] bg-gray-200 -rotate-45"></div>
          </div>
        </div>
        {colorOptions.map((color, index) => (
          <div
            key={index}
            className={`w-[18px] h-[18px] border rounded-full cursor-pointer shadow ${selectedColor === color ? 'border-[1px] border-gray-600' : ''
              }`}
            style={{ backgroundColor: color }}
            onClick={() => handleColorSelection(color)}
          ></div>
        ))}
        <div className="relative">
          <div className={`w-[18px] h-[18px] rounded-full cursor-pointer border flex justify-center items-center 
          ${(!isColorOfOptions && selectedColor) ? 'border-[1px] border-gray-600' : ''}`}
            style={{ backgroundColor: customColor ? customColor : 'white' }}
            onClick={togglePicker}
          >
            {/* <svg width="10" height="10" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.8536 1.14645C11.6583 0.951184 11.3417 0.951184 11.1465 1.14645L3.71455 8.57836C3.62459 8.66832 3.55263 8.77461 3.50251 8.89155L2.04044 12.303C1.9599 12.491 2.00189 12.709 2.14646 12.8536C2.29103 12.9981 2.50905 13.0401 2.69697 12.9596L6.10847 11.4975C6.2254 11.4474 6.3317 11.3754 6.42166 11.2855L13.8536 3.85355C14.0488 3.65829 14.0488 3.34171 13.8536 3.14645L11.8536 1.14645ZM4.42166 9.28547L11.5 2.20711L12.7929 3.5L5.71455 10.5784L4.21924 11.2192L3.78081 10.7808L4.42166 9.28547Z" fill={customColor !== '#ffffff' ? 'white' : '#757575'} fillRule="evenodd" clipRule="evenodd"></path></svg> */}
            <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M341.6 29.2L240.1 130.8l-9.4-9.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-9.4-9.4L482.8 170.4c39-39 39-102.2 0-141.1s-102.2-39-141.1 0zM55.4 323.3c-15 15-23.4 35.4-23.4 56.6v42.4L5.4 462.2c-8.5 12.7-6.8 29.6 4 40.4s27.7 12.5 40.4 4L89.7 480h42.4c21.2 0 41.6-8.4 56.6-23.4L309.4 335.9l-45.3-45.3L143.4 411.3c-3 3-7.1 4.7-11.3 4.7H96V379.9c0-4.2 1.7-8.3 4.7-11.3L221.4 247.9l-45.3-45.3L55.4 323.3z" fill={customColor !== '#ffffff' ? 'white' : '#757575'}/></svg>
          </div>
          {showPicker && (
            <div className="absolute z-10 mt-2">
              <ChromePicker color={(!isColorOfOptions && selectedColor) ? selectedColor : customColor} onChange={handleCustomColorChange} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};