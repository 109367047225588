import React from 'react';

const ConnectionLine = ({
  fromX,
  fromY,
  // fromPosition,
  // toPosition,
  // connectionLineType,
  // connectionLineStyle,
  toX,
  toY,
}) => (
  <g>
    <path
      fill="none"
      stroke="#aaa"
      strokeWidth={1}
      className="animated"
      d={`M${fromX},${fromY} C ${fromX} ${toY} ${fromX} ${toY} ${toX},${toY}`}
    />
    <circle cx={toX} cy={toY} fill="#fff" r={3} stroke="#ccc" strokeWidth={1.2} />
  </g>
);

export default ConnectionLine