import React from 'react';
import Chart from '../Chart';
const processDataForPieChart = (data) => {
    const visuals=data
    const { dimensions, metrics } = visuals.spec;
    const seriesData = visuals.data[metrics[0].column];
    const seriesDataMap=seriesData.map((value, index) => ({
        value,
        name: visuals.data[dimensions[0].column][index],
    }))
    return {
        seriesDataMap
    }
};
const PieChart = ({visualData,rd}) => {
  const {seriesDataMap}=processDataForPieChart(visualData)
  const option = {
    tooltip: {
        trigger: "item",
    },
    legend: {
        orient: "horizontal", 
        x: "left",
        y: "bottom",
    },
    series: [
        {
            type: "pie",
            radius: '50%',
            top: -70,
            emphasis: {
                label: {
                    show: true,
                    fontSize: '16',
                }
            },
            data:seriesDataMap,
        },
    ],
  };

  return (
    <Chart option={option} rd={rd}/>
  );
};

export { PieChart };
