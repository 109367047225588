import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ChevronRightIcon, TableIcon } from "@radix-ui/react-icons";
import { Tooltip } from '@radix-ui/themes';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import axios from 'axios';

import {
  StarsIcon,
  H1Icon,
  H2Icon,
  TextIcon,
  ShapesIcon,
  GraphIcon,
  OrgIcon,
  MindmapIcon,
  CalcIcon
} from '../Icons';


const IconButton = ({ Icon, children, text, func, isActive, id }) => {
  return (
    <button id={`button_${id}`} onClick={func}
      className={`${isActive ? 'text-primary bg-[#0694A31A]' : 'hover:bg-[#0694A31A] bg-[#f9fafb] text-[#6B7280]'} 
    py-[6px] my-2 px-3 w-full flex items-center gap-2 rounded-[5px] font-normal`}>
      {children}
      {text}
    </button>
  )
}

const ai = [ '/ai']
const COMMANDS = ['/text', ...ai]
const GraphInput = ({onElementClick,query, setQuery }) => {
  const { t } = useTranslation()
  const listRef = useRef(null)

//   const [query, setQuery] = useState('')
  const [command, setCommand] = useState('/text')
  const [showList, setShowList] = useState(false)
  const [commands, setCommands] = useState({})
  const [selectedCommand, setselectedCommand] = useState(-1)
  const [totalNo, setTotalNo] = useState(-1)
//   const [isAvailable, setIsAvailable] = useState(false)

  const comList = useMemo(() => ({
    AI: [
      { Icon: (color) => <StarsIcon color={color} width={16} height={16} />, command: '/ai', text: 'Ask to do something' },
    ],
    Text: [
      // { Icon: (color) => <H1Icon color={color} width={16} height={16} />, command: '/h1', text: 'Header 1' },
      // { Icon: (color) => <H2Icon color={color} width={16} height={16} />, command: '/h2', text: 'Header 2' },
      { Icon: (color) => <TextIcon color={color} width={16} height={16} />, command: '/text', text: 'Text' },
    ],
   
  }), [])

  useEffect(() => {
    if (query.startsWith('/')) setShowList(true)
    else setShowList(false)
  }, [query])

  useEffect(() => {
    if (COMMANDS.indexOf(query) > -1) {
      setCommand(query)
      setQuery('')
    }
  }, [query])

  const handleKeyDown = useCallback(event => {
    if (event.key === `ArrowUp`) {
      // event.preventDefault()
      setselectedCommand(prevIndex =>
        prevIndex > 0 ? prevIndex - 1 : totalNo
      )
    }
    else if (event.key === `ArrowDown`) {
      // event.preventDefault()
      setselectedCommand(prevIndex =>
        prevIndex < totalNo ? prevIndex + 1 : 0
      )
    }
    else if (event.key === `Enter`) {
      // event.preventDefault()
      if (selectedCommand !== -1) {
        let curCommand = {}
        Object.keys(commands).map((key, ind) => {
          if (key === 'Banner') {
            if (commands[key].no === selectedCommand) curCommand = { command: '/topbanner' }
          } else {
            commands[key].map(val => {
              if (val && val.no === selectedCommand) curCommand = val
              return null
            })
          }
          return null
        })
        if (curCommand.command) {
          setCommand(curCommand.command)
          setQuery('')
        }
      }
    }
    else if (event.key === `Escape`) {
      setQuery('')
    }
  }, [selectedCommand, totalNo, commands])
  const isValidCommand = useCallback((ary) => {
    let isValid = false
    ary.map((vl) => {
      if (vl.command.toLowerCase().includes(query.slice(1)) || vl.text.toLowerCase().includes(query.slice(1))) {
        isValid = true
      }
      return isValid
    })
    return isValid
  }, [query])
  useEffect(() => {
    const n_obj = {}
    let no = -1
    Object.keys(comList)
      .filter((key) => {
        const s_ary =comList[key]
        const isVlaid = isValidCommand(s_ary)
        return isVlaid && (comList[key])
      }).map((key) => {
        if (key === 'Banner') {
          no += 1;
          n_obj[key] = { ...comList[key], no: no }
        }
        else {
          const ary = comList[key]
            .filter((vl) => vl.command.toLowerCase().includes(query.slice(1)) || vl.text.toLowerCase().includes(query.slice(1)))
            .map(val => {
              no += 1;
              return { ...val, no: no }
            })
          n_obj[key] = ary
        }
        return null
      })

    setTotalNo(no)
    setCommands(n_obj)
  }, [query, comList, isValidCommand])
  const isAvailable = command && (query)
  return (
    <div className={`w-full h-full relative bg-white items-center shadow-custom rounded flex z-10 smart-input`} onKeyDown={handleKeyDown}>
      <div ref={listRef} className={`px-3 scroll text-grey-10 ${showList ? 'opacity-1' : 'opacity-0 pointer-events-none'} text-xs py-4 overflow-y-auto transition-all absolute left-0 bottom-[100%] w-full md:w-80 max-h-54 bg-white rounded-lg shadow-custom mb-2 `}>
        {Object.keys(commands).map((key, ind) => {
            return (
              <div key={ind}>
                <p className='px-2 py-[1px] text-[#374151] font-bold text-xs'>{key}</p>
                {
                  commands[key].map((li, no) => (
                    <IconButton
                      isActive={selectedCommand === li.no}
                      id={li.no}
                      key={no}
                      text={t(li.text)}
                      Icon={li.Icon}
                      func={() => {
                        setCommand(li.command);
                        setQuery('')
                      }}
                    >
                      {
                        selectedCommand === li.no
                          ? li.Icon('#0694A3')
                          : li.Icon('#9CA3AF')
                      }
                    </IconButton>
                  ))
                }
              </div>
            )
        })}
      </div>
      <div className='w-full h-full rounded px-4 py-5 flex justify-center items-center bg-white shadow-custom text-sm'>
              <span className='absolute top-1 left-2 text-[#bbb] uppercase'>{command.replace('/', '')}</span>
              <textarea
                  value={query}
                  onChange={(e) => {setQuery(e.target.value);}}
                  onKeyDown={(e) => {
                    if (e.shiftKey && e.key === 'Enter' ) {
                      e.preventDefault();
                      onElementClick(command)
                    }
                  }}
                  style={{ outline: 'none !important' }}
                  className="text-[#222] py-2 w-full h-[85px]  outline-none resize-none placeholder:text-[#888] placeholder:font-[400]"
                  placeholder={t("Press ‘/’  here to see the list of options ....")} />
                <Tooltip content={
                    <span className='text-center text-[10px] '>
                      <span className='flex gap-1 items-center'>
                        Add to library
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 8 7" fill="none">
                          <path d="M3.2796 3.65476C3.32062 3.61374 3.34375 3.55802 3.34375 3.5C3.34375 3.44198 3.3207 3.38634 3.27968 3.34532L3.27779 3.34345C3.23694 3.30365 3.18204 3.28125 3.125 3.28125C3.11844 3.28125 3.11188 3.28155 3.10534 3.28214C3.05433 3.28674 3.00654 3.3091 2.97032 3.34532L1.65782 4.65782C1.6168 4.69884 1.59375 4.75448 1.59375 4.8125C1.59375 4.87052 1.6168 4.92616 1.65782 4.96718L2.97032 6.27968C3.01134 6.3207 3.06698 6.34375 3.125 6.34375C3.18302 6.34375 3.23866 6.3207 3.27968 6.27968C3.3207 6.23866 3.34375 6.18302 3.34375 6.125C3.34375 6.06698 3.3207 6.01134 3.27968 5.97032L2.12186 4.8125L3.2796 3.65476Z" fill="#BDBDBD" />
                          <path d="M5.53125 4.59375H1.8125C1.69169 4.59375 1.59375 4.69169 1.59375 4.8125C1.59375 4.93331 1.69169 5.03125 1.8125 5.03125H5.75C5.87081 5.03125 5.96875 4.93331 5.96875 4.8125V0.875C5.96875 0.754188 5.87081 0.65625 5.75 0.65625C5.62919 0.65625 5.53125 0.754188 5.53125 0.875V4.59375Z" fill="#BDBDBD" />
                        </svg>
                      </span>
                      <span className='text-[#9E9E9E] px-4'>Shift + key</span>
                    </span>}>
                    <div className={`${isAvailable ? 'bg-primary cursor-pointer' : 'bg-[#BDBDBD]'} w-6 h-6 ml-2 rounded-full flex justify-center items-center`}
                      onClick={() => { if (isAvailable) onElementClick(command) }}>
                      <ChevronRightIcon color="white" width={20} height={20} />
                    </div>
              </Tooltip>
          </div>
    </div>
  )
}

export default GraphInput