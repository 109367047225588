export const PenIcon = ({ color, width, height }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={`${width ? width : '18'}`} height={`${height ? height : '19'}`} viewBox="0 0 18 19" fill="none">
        <g clipPath="url(#clip0_2785_9590)">
            <path d="M1.5 18.5V16.2313H16.5V18.5H1.5ZM4.125 12.8563H4.95L12.2812 5.525L11.8688 5.1125L11.4563 4.7L4.125 12.0312V12.8563ZM3 13.9813V11.5813L12.2625 2.31875C12.375 2.20625 12.5 2.125 12.6375 2.075C12.775 2.025 12.9125 2 13.05 2C13.1875 2 13.3281 2.025 13.4719 2.075C13.6156 2.125 13.7375 2.20625 13.8375 2.31875L14.6625 3.14375C14.775 3.24375 14.8594 3.36563 14.9156 3.50938C14.9719 3.65313 15 3.79375 15 3.93125C15 4.06875 14.9719 4.20625 14.9156 4.34375C14.8594 4.48125 14.775 4.60625 14.6625 4.71875L5.4 13.9813H3ZM12.2812 5.525L11.8688 5.1125L11.4563 4.7L12.2812 5.525Z" fill={color ? color : "#6B7280"} />
        </g>
        <defs>
            <clipPath id="clip0_2785_9590">
                <rect width="18" height="18" fill="white" transform="translate(0 0.5)" />
            </clipPath>
        </defs>
    </svg>
)