export const PaperIcon = ({ color, width, height, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ? width : "28"}
    height={height ? height : "24"}
    viewBox="0 0 24 24"
    className={`fill-current ${className}`}
  >
    <path d="M4.5 21C4.1 21 3.75 20.85 3.45 20.55C3.15 20.25 3 19.9 3 19.5V4.5C3 4.1 3.15 3.75 3.45 3.45C3.75 3.15 4.1 3 4.5 3H14.35V4.5H4.5V19.5H19.5V9.65H21V19.5C21 19.9 20.85 20.25 20.55 20.55C20.25 20.85 19.9 21 19.5 21H4.5ZM8.025 17.075V15.575H16V17.075H8.025ZM8.025 13.9V12.4H16V13.9H8.025ZM8.025 10.725V9.225H16V10.725H8.025ZM17.3 8.9V6.7H15.1V5.2H17.3V3H18.8V5.2H21V6.7H18.8V8.9H17.3Z" />
  </svg>
);
