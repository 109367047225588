import React from 'react';
import Chart from '../Chart';
const processDataForLineChart = (data) => {
  const visuals=data
  const { dimensions, metrics } = visuals.spec;
  const xAxisData = visuals.data[dimensions[0].column];
  const seriesData = visuals.data[metrics[0].column];
  return {xAxisData,seriesData};
};


const LineChart = ({visualData,rd}) => {
  const {xAxisData,seriesData}=processDataForLineChart(visualData)
  const option = {
    tooltip: {
      trigger: "item",
  },
    xAxis: {
      type: 'category',
      data:xAxisData,
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        type: 'line',
        data: seriesData
      },
    ],
  };

  return (
    <Chart option={option} rd={rd}/>
  );
};

export { LineChart };
