// App.js
import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import Graph2 from '../components/Graph';

const Graph = () => {
  const showSidebar = useSelector((state) => state.global.showSidebar)
  const isFullWidth = useSelector((state) => state.global.isFullWidth)
 

  return (
    // <div className='w-full h-[100vh] lg:pl-[78px] md:pl-[214px] sm:pl-[350px] pl-16 lg:pl-56 md:pl-32 sm:pl-16 bg-[#FAFBFC] relative'>
    <div className={`w-full h-full ${showSidebar
      ? `${isFullWidth
        ? 'sm:pl-[218px]' : 'lg:pl-[250px] md:pl-[250px] sm:pl-[348px]'} pl-16`
      : `${isFullWidth
        ? 'sm:pl-16' : 'lg:pl-2 md:pl-2 sm:pl-2'}`} 
    ${isFullWidth ? 'sm:pr-16' : 'lg:pr-0 md:pr-0 sm:pr-0'} relative mt-10`}>
      <Graph2></Graph2>
    
    </div>
  );
};

export default Graph;
