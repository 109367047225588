import React, { useEffect, useRef, useState } from 'react';

const DrawLandScape = ({
  landJson,
  label,
  landscapeLayout,
  dataConfig,
  filters,
  isContainer,
  setDetails,
  setVisible,
  rndNo,
  setStyleSettingNo,
  setShowDrawer
}) => {
  const MAX_SM = 3;
  const [isSm, setIsSm] = useState(false)
  const scrollContainer = useRef(null)
  const isFilterMatched = (data, l_val = '') => {
    const isLabelMatched = l_val ? l_val.toUpperCase().indexOf(label.toUpperCase()) > -1 : true;
    let selectFilterValue = true;
    for (let i = 0; i < filters.length; i++) {
      const f_item = filters[i];
      const f_key = f_item?.key || '';
      if (f_item?.cur_vals) {
        if (f_item?.cur_vals?.length > 0) {
          if (f_item?.cur_vals.indexOf(data[f_key]) < 0) {
            selectFilterValue = false;
          }
        }
      }
    }
    return selectFilterValue && isLabelMatched;
  };

  const handleScroll = (event) => {
    // event.preventDefault(); // Prevent default vertical scrolling
    if (event.shiftKey && scrollContainer.current) {
      scrollContainer.current.scrollLeft += event.deltaY; // Scroll horizontally
    }
  };

  const parentWidth = scrollContainer?.current?.offsetWidth || 0
  useEffect(() => {
    if (scrollContainer.current) {
      if (parentWidth > 500) {
        setIsSm(false)
      } else {
        setIsSm(true)
      }
    }
  }, [parentWidth])

  return (
    <div
      ref={scrollContainer}
      onWheel={handleScroll}
      className={[
        landscapeLayout === 'drip'
          ? isContainer
            ? 'max-w-[100%] overflow-x-auto justify-start'
            : 'w-max'
          : 'flex-wrap',
        'flex rounded gap-[6px]',
      ].join(' ')}
    >
      {landJson.map((val, ind) => (
        <div
          key={ind}
          className={[
            val?.subgraph
              ? landscapeLayout === 'drip' ? '' : 'shadow-lg shadow-[#ccc] mx-1 border'
              : landscapeLayout === 'drip'
                ? 'rounded-b-[20px] w-[80px] border'
                : 'shadow-none flex-1-auto max-w-[100%] border-t border-r',
            'h-fit pb-6 mb-4 mt-1 rounded ',
          ].join(' ')}
        >
          <h2
            style={{ background: val?.color }}
            className={[
              landscapeLayout === 'drip'
                ? val?.subgraph
                  ? `text-base text-white py-2 text-center uppercase font-bold`
                  : `text-center text-sm text-gray-600 py-1 text-overflow-1`
                : val?.subgraph
                  ? `text-left text-white rounded-r-lg -ml-1 mt-4 w-fit text-base py-1 font-bold`
                  : 'text-left text-sm text-gray-600 py-1 font-[500] text-overflow-1 max-w-[50%] -mt-4',
              'px-2 py-1 rounded-t',
              landscapeLayout === 'drip' ? '' : '',
            ].join(' ')}
          >
            {val?.label}
          </h2>

          {val?.subset &&
            <div
              className={[
                val?.subset?.length > MAX_SM
                  ? landscapeLayout === 'drip'
                    ? 'mx-auto w-full'
                    : `flex flex-wrap mx-auto gap-4 justify-center ${isSm ? 'max-w-[280px] ' : 'max-w-[450px]'}`
                  : '',
                'mt-2 px-1',
              ].join(' ')}
            >
              {val?.subset.map((subData, no) => (
                (subData[dataConfig?.key_img] || subData[dataConfig?.key_label]) &&
                <div
                  onClick={() => {
                    setStyleSettingNo()
                    setShowDrawer(true)
                    setDetails({ data: subData, type: 'landscape', rndNo: rndNo, name: subData[dataConfig?.key_label] })
                  }}
                  key={no}
                  className={[
                    isFilterMatched(subData, subData[dataConfig?.key_label])
                      ? ''
                      : 'opacity-[0.1]',
                    landscapeLayout === 'drip' ? 'w-full' : 'w-[120px]',
                    `my-2 text-gray-800 font-semibold text-sm text-center mx-auto p-1 cursor-pointer `,
                  ].join(' ')}
                >
                  {subData[dataConfig?.key_img] && (
                    <img
                      src={subData[dataConfig?.key_img]}
                      className="h-auto w-full object-contain"
                      alt="landscape"
                    />
                  )}
                  <p className="w-full mx-auto font-[400] mb-0 mt-1 text-xs font-[500] text-overflow-2">
                    {subData[dataConfig?.key_label]}
                  </p>
                </div>
              ))}
            </div>}

          {val?.subgraph && (
            <div className={`flex flex-wrap ${landscapeLayout === 'drip' ? '' : 'px-2 mt-6'}`}>
              <DrawLandScape
                landJson={val?.subgraph}
                landscapeLayout={landscapeLayout}
                dataConfig={dataConfig}
                label={label}
                filters={filters}
                isContainer={false}
                setDetails={setDetails}
                setShowDrawer={setShowDrawer}
                setStyleSettingNo={setStyleSettingNo}
                rndNo={rndNo}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default DrawLandScape;
