export const CardButton = ({ color, width, height }) => (
    <svg xmlns="http://www.w3.org/2000/svg"  width={`${width ? width : '20'}`} height={`${height ? height : '20'}`} viewBox="0 0  30 30" fill="none">
        <g filter="url(#filter0_dd_4031_15986)">
        <path d="M3 14C3 7.37258 8.37258 2 15 2C21.6274 2 27 7.37258 27 14C27 20.6274 21.6274 26 15 26C8.37258 26 3 20.6274 3 14Z" fill="white"/>
        <path d="M3.5 14C3.5 7.64873 8.64873 2.5 15 2.5C21.3513 2.5 26.5 7.64873 26.5 14C26.5 20.3513 21.3513 25.5 15 25.5C8.64873 25.5 3.5 20.3513 3.5 14Z" stroke="#6B7280"/>
        <path d="M10.332 18.6673V14.6673H11.332V17.6673H14.332V18.6673H10.332ZM18.6654 13.334V10.334H15.6654V9.33398H19.6654V13.334H18.6654Z" fill="#6B7280"/>
        </g>
        <defs>
        <filter id="filter0_dd_4031_15986" x="0" y="0" width="30" height="30" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect1_dropShadow_4031_15986"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4031_15986"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1.5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
        <feBlend mode="normal" in2="effect1_dropShadow_4031_15986" result="effect2_dropShadow_4031_15986"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4031_15986" result="shape"/>
        </filter>
        </defs> 
    </svg>
)

