import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Session from "supertokens-web-js/recipe/session";

const AuthComponent = ({ Component }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true)
  const checkSession = async () => {
    try {
      const session = await Session.doesSessionExist()
      setIsAuthenticated(session)
    } catch (e) {
      setIsAuthenticated(false)
    }
  }
  useEffect(() => {
    checkSession()
  }, [])

  return isAuthenticated ? <Component /> : <Navigate to='/signin' />
}

export default AuthComponent;