import React, { memo,useEffect,useState ,useCallback } from 'react';
import { Handle, Position, NodeResizer,NodeToolbar  } from 'reactflow';
import styled from 'styled-components';
import { FunctionBar } from './GraphChart/FunctionBar'


const Node = styled.div`
  width: 100%;
  height:100%;
  word-wrap:  break-word;
  // font-size: 12px;
  // border:solid 1px #aaa;
  // box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  // background:white
  // background: #fff;
  // box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
`;

function CustomChart({id,data,selected }) {
  const { q } = data;
  const [isContainerVisible, setContainerVisible] = useState(false);
  const [opacity, setOpacity] = useState(0)
  const handleMouseEnter = useCallback(() => {
    setOpacity(1)
  }, []);
  const handleMouseLeave = useCallback(() => {
    setOpacity(0)
  }, []);
  const onChangeStyleClick=()=>{
    data.onChangeStyleClick(id)
  }
  return (
    <Node  dashed='false' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <>
        <NodeResizer color="#0694A3" isVisible={selected}  />
        <Handle type="target" position={Position.Left}  className="bg-[white] border border-solid border-[#aaa] rounded-full " style={{'opacity':opacity}}/>
        <Handle type="source" position={Position.Right} className="bg-[white] border border-solid border-[#aaa] rounded-full " style={{'opacity':opacity}}/>
        <div className={`w-full h-full flex justify-between items-center max-w-[100%] mx-auto w-full   overflow-hidden whitespace-nowrap overflow-ellipsis`}
          onMouseEnter={() => setContainerVisible(true)}
          onMouseLeave={() => setContainerVisible(false)}>
          {isContainerVisible && (
            <div className="absolute  right-[0] -top-6" >
                <FunctionBar textStyle={true} onChangeStyleClick={onChangeStyleClick}/>
            </div>
          )}
          <pre className="font-montserrat px-4 py-2 flex-grow overflow-auto h-full w-full rounded-[12px]"  >   
            {q}
          </pre>
        </div>
      </>
    </Node>
  );
}

export default memo(CustomChart);

