import React, { useState, useEffect } from 'react';
import './FlippableCards.css';
const getData = data => {
  const keys = Object.keys(data);
  const length = data[keys[0]].length;

  const processedData = Array.from({ length }, (_, index) => {
    const obj = {};
    keys.forEach(key => {
      obj[key] = data[key][index];
    });
    return obj;
  });
  return processedData;
};
const gradients = {
  basic: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
  pro: 'linear-gradient(135deg, #6B46C1 0%, #3B82F6 100%)',
  enterprise: 'linear-gradient(135deg, #F472B6 0%, #F59E0B 100%)'
};
const FlippableCards = ({ visualData }) => {
  let data = getData(visualData.data);
  let config = { ...visualData.config };

  const [flippedCards, setFlippedCards] = useState({});
  const [containerWidth, setContainerWidth] = useState(0);

  const mergedConfig = {
    ...config,
    maxWidth: 280,
    maxCountPerRow: 3,
    radius: 10,
    whRatio: 1.5,
    distance: 20,
    borderWidth: 0
  };

  useEffect(() => {
    const updateWidth = () => {
      setContainerWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateWidth);
    updateWidth();

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const toggleCard = index => {
    setFlippedCards(prev => ({ ...prev, [index]: !prev[index] }));
  };

  const cardWidth = Math.min(
    mergedConfig.maxWidth,
    (containerWidth - (mergedConfig.maxCountPerRow - 1) * mergedConfig.distance) /
      Math.min(data.length, mergedConfig.maxCountPerRow)
  );

  const cardHeight = cardWidth / mergedConfig.whRatio;

  if (data.length === 0) {
    return <div>No data available</div>;
  }

  return (
    <div className="flex flex-wrap justify-center" style={{ gap: `${mergedConfig.distance}px` }}>
      {data.map((card, index) => (
        <div
          key={index}
          className="flip-card"
          style={{
            width: `${cardWidth}px`,
            height: `${cardHeight}px`,
            perspective: '1000px',
            cursor: 'pointer'
          }}
          onClick={() => toggleCard(index)}>
          <div
            className={`flip-card-inner ${flippedCards[index] ? 'flipped' : ''}`}
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
              textAlign: 'center',
              transition: 'transform 0.6s',
              transformStyle: 'preserve-3d'
            }}>
            <div
              className="flip-card-front"
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backfaceVisibility: 'hidden',
                background: gradients[card[mergedConfig.colorBy]] || gradients.basic,
                borderRadius: `${mergedConfig.radius}px`,
                padding: '1.5rem',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                color: 'white'
              }}>
              <div className="text-xl font-bold mb-4  h-8">
                {card[mergedConfig['title_column']] || Object.values(card)[0]}
              </div>
              <div className="text-3xl font-bold mb-4 h-12">
                {' '}
                {card[mergedConfig['text_column']] || Object.values(card)[1]}
              </div>
              {/* <div className="flex flex-col space-y-2">
                {card.features.map((feature, idx) => (
                  <div key={idx} className="flex items-center">
                    <span className="mr-2">✓</span>
                    <span>{feature}</span>
                  </div>
                ))}
              </div> */}
              <button className="mt-4 bg-white text-purple-600 font-bold py-2 px-4 rounded">SELECT</button>
            </div>
            <div
              className="flip-card-back"
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backfaceVisibility: 'hidden',
                background: 'white',
                color: 'black',
                transform: 'rotateY(180deg)',
                borderRadius: `${mergedConfig.radius}px`,
                padding: '1.5rem',
                overflowY: 'auto'
              }}>
              <h3 className="font-bold text-xl mb-2">Details</h3>
              <p>{card[mergedConfig['title_column']] || Object.values(card)[2]} </p>
              <p> {card[mergedConfig['text_column']] || Object.values(card)[3]}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FlippableCards;
