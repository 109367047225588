import * as React from "react";
import { cn } from "../../../utils/cn";
import ChevronDown from "../icons/ChevronDown";

const AccordionTrigger = React.forwardRef(
  ({ className, children, ...props }, ref) => (
    <button
      ref={ref}
      className={cn(
        "flex w-full items-center justify-between py-4 font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180",
        className
      )}
      {...props}
    >
      {children}
      <ChevronDown className="h-4 w-4 shrink-0 transition-transform duration-200" />
    </button>
  )
);
AccordionTrigger.displayName = "AccordionTrigger";

export default AccordionTrigger;
