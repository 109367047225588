import React, { memo,useEffect,useState ,useCallback } from 'react';
import { Handle, Position, NodeResizer  } from 'reactflow';
import styled from 'styled-components';
import {DynamicChart} from "./GraphChart/DynamicChart";
import { FunctionBar } from './GraphChart/FunctionBar'
import { toast } from 'react-toastify';
import axios from 'axios';

const Node = styled.div`
  width: 100%;
  height:100%;
  border-radius: 6px;
  word-wrap:  break-word;
  font-size: 12px;
  // border:solid 1px #aaa;
  // box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  // background:white
  background: #fff;
  // box-shadow: 0px 2px 5px rgba(60,66,87,.08), 0px 1px 1px rgba(0,0,0,.12);
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 12px;
`;

// const visualData={
//   "visuals": [
//       {
//           "vid": "",
//           "title": "Fortune 500 Top Industries",
//           "databasequery": "",
//           "interpretations": [],
//           "spec": {
//               "columns": [
//                   {
//                       "techtext": "Industry",
//                       "column": "Industry",
//                       "dtype": "str"
//                   },
//                   {
//                       "techtext": "CompanyCount",
//                       "column": "CompanyCount",
//                       "dtype": "int"
//                   }
//               ],
//               "dimensions": [
//                   {
//                       "column": "Industry"
//                   }
//               ],
//               "metrics": [
//                   {
//                       "column": "CompanyCount"
//                   }
//               ],
//               "category": [],
//               "commands": [],
//               "vtype": {
//                   "vtype": "BAR",
//                   "alternatives": [],
//                   "resistence_to_transit": 0.875
//               },
//               "visualaction": "",
//               "networkspec": {
//                   "nodes": [],
//                   "edges": [],
//                   "config": {
//                       "node_label": {},
//                       "node_image": {}
//                   }
//               }
//           },
//           "data": {
//               "Industry": [
//                   "Technology",
//                   "Healthcare",
//                   "Financial Services",
//                   "Retail",
//                   "Energy",
//                   "Automotive",
//                   "Telecommunications",
//                   "Consumer Goods",
//                   "Manufacturing",
//                   "Media"
//               ],
//               "CompanyCount": [
//                   12,
//                   8,
//                   7,
//                   6,
//                   5,
//                   4,
//                   4,
//                   3,
//                   3,
//                   3
//               ]
//           },
//           "suggestions": {},
//           "computationsetting": []
//       }
//   ],
//   "commands": [],
//   "networks": [],
//   "network_generation_plans": [],
//   "profiles": [],
//   "ambiguity": null,
//   "error": {
//       "code": 0,
//       "message": "",
//       "debugmsg": ""
//   }
// }
function CustomChart({id,data,selected }) {
  const { q,visuals } = data;
  const [visualData, setVisuals] = useState({})
  const [chartType, setType] = useState('')
  const [title, setTitle] = useState('unnamed')
  const [isContainerVisible, setContainerVisible] = useState(false);
  const [opacity, setOpacity] = useState(0)
  const handleMouseEnter = useCallback(() => {
    setOpacity(1)
  }, []);
  const handleMouseLeave = useCallback(() => {
    setOpacity(0)
  }, []);
  
  const sendRequest = async () => {
    const loading = toast.loading("Please wait ...")
    try {
        const res = await axios.post(`smart/smartchart`, {
          q:q,
        })
        const data = res.data
        if (data) {
          const visuals = data.visuals[0]
          const title = data.visuals[0].title
          const type = visuals?.spec?.vtype?.vtype
          setVisuals(data)
          setType(type)
          setTitle(title)
        }
        toast.dismiss(loading)
    } catch (e) {
      toast.dismiss(loading)
      toast.error(e.message)
    }
  }
  useEffect(() => {
    if(q){
      sendRequest()
    }else{
      const visual = visuals.visuals[0]
      const title = visual?.title
      const type = visual?.spec?.vtype?.vtype
      setVisuals(visuals)
      setType(type)
      setTitle(title)
    }

  }, []); 
  const handleButtonClick = () => {
    data.onChange(id,visualData)
  };

  const handleImageClick = (name) => {
    setType(name)
  };

  return (
    <Node background='#01BDD2' dashed='false' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <>
        <NodeResizer color="#0694A3" isVisible={selected} minWidth={100} minHeight={100} />
        <Handle type="target" position={Position.Left}  className="bg-[white] border border-solid border-[#aaa] rounded-full" style={{'opacity':opacity}}/>
        <Handle type="source" position={Position.Right} className="bg-[white] border border-solid border-[#aaa] rounded-full" style={{'opacity':opacity}}/>
        <div className='w-full h-full flex flex-col' >
            {/* <div className='flex bg-[#006676] p-1.5 text-[white] text-[10px] rounded-t  uppercase font-montserrat overflow-hidden whitespace-nowrap overflow-ellipsis '>
                <div> {title?title:'unnamed'}</div>
                <button onClick={handleButtonClick}>按钮</button>
            </div> */}
            <div className={`flex justify-between items-center text-[12px] p-2 text-gray-400 max-w-[100%] mx-auto w-full -mt-8  overflow-hidden whitespace-nowrap overflow-ellipsis`}
               onMouseEnter={() => setContainerVisible(true)}
               onMouseLeave={() => setContainerVisible(false)}>
                <div> {title?title:'unnamed'}</div>
                {isContainerVisible && (
                 <div className="absolute  right-[0]" >
                  <FunctionBar
                  chartType={chartType}
                  visualData={visualData}
                  onImageClick={handleImageClick}
                  onChangeCardClick={handleButtonClick}
                />
                </div>
                )}
            </div>
            <div className="px-4 py-2 flex-grow overflow-auto h-full w-full" >   
                <DynamicChart  chartType={chartType} visualData={visualData} />
            </div>
        </div>
      </>
    </Node>
  );
}

export default memo(CustomChart);

