import React from 'react';
import Chart from '../Chart';

const processDataForSankeyChart = (visualData) => {
  const visual=visualData
  let SankeyData = [];
  let SankeyLinks = [];
  let data = visual.data;
  let from = data[visual.spec.dimensions[0].column];
  let to = data[visual.spec.dimensions[1].column];
  let metrics = data[visual.spec.metrics[0].column];
  //筛选只流出的项
  let list = from.filter((items) => {
      return !to.includes(items)? items:null;
  });
  //设置data
  const sortCount = [...new Set(from.concat(to))];
  for (let number = 0; number < sortCount.length; number++) {
      if (list.indexOf(sortCount[number]) >= 0) {
          SankeyData.push({
              name: sortCount[number],
              label: { position: "left" },
          });
      } else {
          SankeyData.push({
              name: sortCount[number],
              label: { position: "right" },
          });
      }
  }
  //设置links
  for (let number = 0; number < from.length; number++) {
      if (from[number] !== to[number]) {
          SankeyLinks.push({
              source: from[number],
              target: to[number],
              value: metrics[number],
          });
      }
  }
  return {SankeyData,SankeyLinks};
};

const SankeyChart = ({visualData,rd}) => {
  const {SankeyData,SankeyLinks}=processDataForSankeyChart(visualData)
  const option = {
     
      series: {
        type: "sankey",
        layout: "none",
        data: SankeyData,
        links: SankeyLinks,
        left: "20%",
    },
  };

  return (
    <Chart option={option}  rd={rd}/>
  );
};

export { SankeyChart };
