import React, { useCallback, useEffect, useState } from 'react';
import ReactFlow, {
  addEdge,
  ConnectionLineType,
  Panel,
  useNodesState,
  useEdgesState,
} from 'reactflow';
import dagre from 'dagre';
import 'reactflow/dist/style.css';

import CustomNode from './CustomNode';

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 150;
const nodeHeight = 50;

const getLayoutedElements = (nodes, edges, direction = 'TB') => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? 'left' : 'top';
    node.sourcePosition = isHorizontal ? 'right' : 'bottom';
    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return { nodes, edges };
};

const nodeTypes = {
  customNode: CustomNode,
};

const OrgChart = (props) => {
  const [initElements, setInitElements] = useState({ nodes: [], edges: [] })
  const [layoutElements, setLayoutElements] = useState({ nodes: [], edges: [] })

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge({ ...params, type: ConnectionLineType.SmoothStep, animated: true }, eds)
      ),
    [setEdges]
  );

  const onLayout = useCallback(
    (direction) => {
      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
        nodes,
        edges,
        direction
      );

      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
    },
    [nodes, edges, setNodes, setEdges]
  );

  const { data = {}, setDetails, rndNo, config, setStyleSettingNo, setShowDrawer } = props
  const { nodes: p_nodes, edges: p_edges } = data

  useEffect(() => {
    const position = { x: 0, y: 0 };
    const edgeType = 'smoothstep';

    if (p_nodes && p_edges) {
      const initNodes = p_nodes.map((val, ind) => {
        return {
          id: 'node_' + val.hash,
          position,
          data: {
            node_no: ind,
            fields: [...val.fields],
            rndNo: rndNo,
            setShowDrawer,
            setDetails,
            config: config,
            setStyleSettingNo
          },
          type: 'customNode',
        }
      })
      const initEdges = []
      p_edges.map((val, i) => {
        if (val.source && val.target) {
          initEdges.push({
            source: 'node_' + val.source,
            target: 'node_' + val.target,
            id: `edge-${val.source}-${val.target}-${i}`,
            animated: true,
            type: edgeType
          })
        }
        return null
      })
      setInitElements({ nodes: initNodes, edges: initEdges })
    }
  }, [p_nodes, p_edges, rndNo, setDetails, config])

  useEffect(() => {
    const { nodes, edges } = getLayoutedElements(
      initElements.nodes,
      initElements.edges
    );
    setLayoutElements({ nodes: nodes, edges: edges })
  }, [initElements])

  useEffect(() => {
    const { nodes, edges } = layoutElements
    if (nodes && edges) {
      setNodes(layoutElements.nodes)
      setEdges(layoutElements.edges)
    }
  }, [layoutElements, setEdges, setNodes])

  return (
    <div className='h-full w-full'>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onConnectStart={(e) => e.stopPropagation()}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        connectionLineType={ConnectionLineType.SmoothStep}
        fitView
      >
        <Panel position="top-right" className='flex gap-2 text-xs text-grey'>
          <button onClick={() => { onLayout('TB') }}>Vertical</button>
          <button onClick={() => { onLayout('LR') }}>Horizontal</button>
        </Panel>
      </ReactFlow>
    </div>
  );
};

export default OrgChart;