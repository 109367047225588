// export const OppositeIcon = ({ color, width, height,transform}) => (
//     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none"  width={`${width ? width : '20'}`} height={`${height ? height : '20'}`} >
//       <g data-name="Layer 2" stroke="white" strokeWidth="5" transform={transform?"rotate(90 10 10)":""}>
//         <path d="M6.66797,51.913H30.9144L18.39742,64.43a1.91284,1.91284,0,1,0,2.70516,2.70516L36.884,51.35381a1.92155,1.92155,0,0,0,.23983-.29331c.03036-.04542.051-.09469.07706-.142a1.12076,1.12076,0,0,0,.15786-.3782c.01641-.05535.03777-.10841.04916-.1658a1.91844,1.91844,0,0,0,0-.749c-.01138-.05739-.03275-.11046-.04916-.1658a1.12076,1.12076,0,0,0-.15786-.3782c-.026-.04729-.04671-.09656-.07706-.142a1.92155,1.92155,0,0,0-.23983-.29331L21.10258,32.86481A1.91284,1.91284,0,0,0,18.39742,35.57l12.517,12.517H7.913a1.913,1.913,0,0,0,0,3.82609Z" fill="white"/>
//         <path d="M92.087,48.087H69.0856l12.517-12.517a1.91284,1.91284,0,0,0-2.70516-2.70516L63.116,48.64619a1.92155,1.92155,0,0,0-.23983.29331c-.03036.04542-.051.09469-.07706.142a1.12076,1.12076,0,0,0-.15786.3782c-.01641.05535-.03777.10841-.04916.1658a1.91844,1.91844,0,0,0,0,.749c.01138.05739.03275.11046.04916.1658a1.12076,1.12076,0,0,0,.15786.3782c.026.04729.04671.09656.07706.142a1.92155,1.92155,0,0,0,.23983.29331L78.89742,67.13519A1.91284,1.91284,0,0,0,81.60258,64.43L69.0856,51.913H92.087a1.913,1.913,0,0,0,0-3.82609Z" fill="white"/>
//       </g>
//     </svg>    
// )

export const OppositeIcon = ({ color, width, height, transform }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none" width={width || '20'} height={height || '20'}>
      <g data-name="Layer 2" stroke="white" strokeWidth="5" transform={transform ? "rotate(90 50 50)" : ""}>
          <path d="M6.66797,51.913H30.9144L18.39742,64.43a1.91284,1.91284,0,1,0,2.70516,2.70516L36.884,51.35381a1.92155,1.92155,0,0,0,.23983-.29331c.03036-.04542.051-.09469.07706-.142a1.12076,1.12076,0,0,0,.15786-.3782c.01641-.05535.03777-.10841.04916-.1658a1.91844,1.91844,0,0,0,0-.749c-.01138-.05739-.03275-.11046-.04916-.1658a1.12076,1.12076,0,0,0-.15786-.3782c-.026-.04729-.04671-.09656-.07706-.142a1.92155,1.92155,0,0,0-.23983-.29331L21.10258,32.86481A1.91284,1.91284,0,0,0,18.39742,35.57l12.517,12.517H7.913a1.913,1.913,0,0,0,0,3.82609Z" fill="white"/>
          <path d="M92.087,48.087H69.0856l12.517-12.517a1.91284,1.91284,0,0,0-2.70516-2.70516L63.116,48.64619a1.92155,1.92155,0,0,0-.23983.29331c-.03036.04542-.051.09469-.07706.142a1.12076,1.12076,0,0,0-.15786.3782c-.01641.05535-.03777.10841-.04916.1658a1.91844,1.91844,0,0,0,0,.749c.01138.05739.03275.11046.04916.1658a1.12076,1.12076,0,0,0,.15786.3782c.026.04729.04671.09656.07706.142a1.92155,1.92155,0,0,0,.23983.29331L78.89742,67.13519A1.91284,1.91284,0,0,0,81.60258,64.43L69.0856,51.913H92.087a1.913,1.913,0,0,0,0-3.82609Z" fill="white"/>
      </g>
  </svg>
)


