import axios from "axios";

export function useAxios() {
    const isDevMode = process.env.NODE_ENV === 'development'
    const storageBaseUrl = isDevMode
        ? process.env.REACT_APP_STORAGE_BASE_URL
        : process.env.REACT_APP_PROD_STORAGE_BASE_URL

    axios.defaults.baseURL = storageBaseUrl;
    axios.defaults.headers.post["Content-Type"] =
        "application/json";
    axios.interceptors.request.use((request) => {
        request.timeout = 3600000;
        return request;
    });

    axios.interceptors.response.use(
        (response) => {
            return Promise.resolve(response);
        },
        (error) => {
            return Promise.reject(error);
        }
    );
}
