
import Chart from '../Chart';
import React, { useEffect, useRef, useState } from 'react';
const processDataForStackBarChart = (visualData,stackbarSelectedColumn) => {
    const visual=visualData
    const data = visual.data;
    const dimensions = visual.spec.dimensions;
    const metrics = visual.spec.metrics[0].column;

    // 使用一个对象来存储每个维度组合的度量总和
    const metricSumMap = {};

    // 遍历数据，计算度量总和
    for (let i = 0; i < data[dimensions[0].column].length; i++) {
      let dimension1Value = data[dimensions[stackbarSelectedColumn].column][i];
      let dimension2Value = data[dimensions[stackbarSelectedColumn===0?1:0].column][i];
      let metricValue = parseFloat(data[metrics][i]); // 转换为浮点数

      // 使用维度组合作为键，累加度量值
      const key = `${dimension1Value}-${dimension2Value}`;
      if (metricSumMap[key]) {
        metricSumMap[key] += metricValue;
      } else {
        metricSumMap[key] = metricValue;
      }
    }

      // 从对象中提取维度和度量的数组
      const xAxisData = Array.from(new Set(data[dimensions[stackbarSelectedColumn].column]));
      const legendData = Array.from(new Set(data[dimensions[stackbarSelectedColumn===0?1:0].column]));
      const seriesData = legendData.map(legend => ({
        name: legend,
        type: 'bar',
        stack: '总量',
        emphasis: { focus: 'series' },
        data: xAxisData.map(dimension => metricSumMap[`${dimension}-${legend}`] || 0),
      }));
  

    return {xAxisData,legendData,seriesData};
};

const StackBarChart = ({visualData,rndData,rndNo,rd}) => {
    const [stackbarSelectedColumn, setStackbarSelectedColumn] = useState(0);
    useEffect(() => {
        console.log(rndData[rndNo]?.setting?.stackbarSelectedColumn)
        setStackbarSelectedColumn(rndData[rndNo]?.setting?.stackbarSelectedColumn || 0);
    }, [rndData,rndNo, visualData]);
    const {xAxisData,legendData,seriesData}=processDataForStackBarChart(visualData,stackbarSelectedColumn)
    const option = {
        legend:{
            show:true,
            data:legendData
        },
        tooltip: {
            trigger: "item",
        },
        xAxis: [
            {
                type: "category",
                data: xAxisData,
                axisLabel: {
                    show: true
                }
            },
        ],
        yAxis: [
            {
                type: "value",
                axisLabel: {
                    show: true
                }
            },
        ],
        series:seriesData,
        };
    return (
        <Chart option={option} rd={rd}/>
    );
};

export { StackBarChart };
