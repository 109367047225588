import * as React from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";

// Provider component
const TooltipProvider = TooltipPrimitive.Provider;

// Root Tooltip component
const Tooltip = TooltipPrimitive.Root;

// Trigger component
const TooltipTrigger = TooltipPrimitive.Trigger;

// Content component with forwardRef
const TooltipContent = React.forwardRef(function TooltipContent(
  { className = "", sideOffset = 4, ...props },
  ref
) {
  return (
    <TooltipPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={`z-50 overflow-hidden rounded-md bg-primary px-3 py-1.5 text-xs text-primary-foreground animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 ${className}`}
      {...props}
    />
  );
});

TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider };
