import React from 'react';
import Chart from '../Chart';
import {Table } from '@radix-ui/themes';
const gridBarType = (visualData) => {
    const visual=visualData
    const { groupData, x, y } = dataHandle(visual);
    return { groupData, x, y}
  };

const dataHandle = (visual) => {
    const tableData = [];
    const data = visual.data;
    for (var field of Object.keys(data)) {
      let valueForField = data[field];
      for (let i = 0; i < valueForField.length; ++i) {
        let item = {};
        if (tableData[i]) item = tableData[i];
        item[field] = valueForField[i];
        tableData[i] = item;
      }
    }
    const dimensions1 = visual.spec.dimensions;
    const rDimensions = visual.spec.dimensions[dimensions1.length - 1].column;
    const metrics = visual.spec.metrics[0].column;
    const x = [...new Set(visual.data[dimensions1[1].column])];
    const y = [...new Set(visual.data[dimensions1[0].column])];
    const rl = visual.data[rDimensions];
    const xData = [...new Set(rl)].sort(function (a, b) {
      return a - b;
    });

    const dimensions = [];
    visual.spec.dimensions.forEach((el) => {
      dimensions.push(el.column);
    });
    dimensions.pop();
    //分组数据
    const groupData = [];
    tableData.forEach((e, j) => {
      const cData = [];
      const qData = tableData[j];
      const result = tableData.filter((element) => {
        let m = 0;
        dimensions.forEach((el) => {
          if (element[el] === qData[el]) {
            m = m + 1;
          }
        });
        return m === dimensions.length? true:' ';
      });
      xData.forEach((e) => {
        let tmp = result.filter((el) => {
          return e === el[rDimensions];
        });
        tmp.length > 0?cData.push(tmp[0][metrics]): cData.push("0")
      });
      groupData.push(
        Object.assign({}, e, { id: j }, { rData: xData }, { cData: cData })
      );
    });
    return {
      groupData:groupData,
      x: x,
      y: y ,
    };
  };
const GridBarChart = ({ visualData, visIdx,rd }) => {
  const {groupData, x, y}=gridBarType(visualData)
  const dataOption=({item1,item2})=>{
    const trData=item2+'-'+item1
    const optionData=[]
    groupData.forEach((e) => {
        const options = {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow',
              },
            },
            xAxis: {
              type: 'category',
              data: e.rData,
            },
            yAxis: {
              type: 'value',
              interval: 10,
              // min: 0,
              // max: 50,
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  width: 0.5,
                  color: '#ccc',
                },
              },
            },
            series: [
              {
                data: e.cData,
                type: 'bar',
              },
            ],
        }
        optionData.push({id:Object.values(e)[1]+'-'+Object.values(e)[2],options:options})
    });
    const datas=optionData.filter((item)=>item.id===trData)
    return datas.length>0?datas[0].options:''
  }
  return (
     <Table.Root className='w-full h-full' size="1">
        <Table.Header>
          <Table.Row align="center">
            <Table.ColumnHeaderCell></Table.ColumnHeaderCell>
            {x.map((item1, index1) => (
                <Table.ColumnHeaderCell key={index1}  justify="center">{item1}</Table.ColumnHeaderCell>
             ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {y.map((item2, index2) => (
            <Table.Row align="center" key={index2}>
              <Table.RowHeaderCell className="!font-bold" key={index2}>{item2}</Table.RowHeaderCell>
              {x.map((item1, indexs) => (
                <Table.Cell justify="center" key={indexs}>
                   <Chart option={dataOption({ item1: item1, item2: item2 })} key={indexs} rd={rd} />
                </Table.Cell>
              ))}
            </Table.Row>
            ))}
        </Table.Body>
      </Table.Root>
  );
};

export {GridBarChart} ;
