const en = {
  message: {
    navigation: {
      Explore: "Explore",
      Questions: "Questions",
      Dashboards: "Dashboards",
      Datasets: "Datasets",
      Graph: "Graph",
      Reports: "Reports",
    },
    language: {
      Chinese: "Chinese",
      English: "English",
      German: "German",
    },
    button: {
      toggle: "toggle",
      go: "GO",
    },
    prompt: {},
  },
};

export default en;
