import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { CaretLeftIcon } from '@radix-ui/react-icons';
import { toast } from "react-toastify";
import axios from "axios";

import AuthCarousel from "../../components/AuthCarousel"
import Input from "../../components/Input"
import Button from "../../components/Buton"

function ResetPassword() {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [conPassword, setConoPassword] = useState('')
  const [userId, setUserId] = useState('')
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(1)

  const urlParams = new URLSearchParams(window.location.search)
  const id = decodeURIComponent(urlParams.get('id'))
  const st = decodeURIComponent(urlParams.get('step'))

  useEffect(() => {
    if (id !== 'null' && st !== 'null') {
      setStep(parseInt(st))
      setUserId(id)
    }
  }, [id, st])

  const submit = () => {
    setLoading(true)
    axios.post(`auth/password-recovery`, {
      email: email
    }).then((res) => {
      setLoading(false)
      if (res.error) {
        toast.warn(res.message)
      } else if (res.data === "Successfully sent recovery link.") {
        toast.success('Your request has been sent successfully. Please check your email.')
      }
    }).catch((err) => {
      setLoading(false)
      toast.error(err.message)
    })
  }

  const reset = () => {
    if (password !== conPassword) {
      toast.warn('Passwords are not matched.')
      return
    }
    setLoading(true)
    axios.post(`auth/password-recovery/${userId}`, {
      newPassword: password
    }).then((res) => {
      setLoading(false)
      if(res.data === "Success") {
        toast.success('Your password has been successfully reset!')
        navigate('/signin')
      } else {
        toast.warn(res.message)
      }
    }).catch((err) => {
      setLoading(false)
      toast.error(err.message)
    })
  }

  return (
    <div className="flex bg-white min-h-screen overflow-y-auto h-fit">
      <div className="w-full sm:w-[55%] pt-8 px-8 pb-4 relative flex justify-center">
        {
          step === 1 &&
          <section className={`w-full md:w-[400px]`}>
            <img src="/logo-title.png" className="sm:w-52 w-36" alt="" />
            <div className="font-bold sm:text-4xl text-2xl sm:mt-8 mt-6 relative">
              <button
                onClick={() => navigate('/signin')}
                className="rounded-full border md:-left-8 lg:-left-16 -left-[26px] absolute sm:top-2 top-1">
                <CaretLeftIcon className="sm:w-6 sm:h-6 h-5 w-5" />
              </button>
              Forgot Password?
            </div>
            <p className="text-grey-10 mt-4">Please provide the email address that you used to sign up and we will email you the steps to reset your password.</p>
            <p className="text-grey-10 mt-4">We value your security and privacy, so we do NOT keep your password in our records. You can be sure that we will never reveal your password in any email.</p>
            <div className="py-3 mt-5">
              <Input
                className="my-3"
                value={email}
                setValue={setEmail}
                placeholder='name@domain.com'
                label='Email'
                type='text'
              />
            </div>
            <Button className='w-full mt-4 text-white bg-primary' onClick={() => submit()}>
              {loading ? 'Please wait ...' : 'SEND RESET INSTRUCTIONS'}
            </Button>
          </section>
        }
        {step === 2 &&
          (<section className={`w-full md:w-[400px]`}>
            <img src="/logo-title.png" className="sm:w-52 w-36" alt="" />
            <div className="font-bold sm:text-4xl text-2xl sm:mt-8 mt-6 relative">Reset your password</div>
            <p className="text-grey-10 mt-2">Enter a new password for your account. This password will be required when you log in.</p>
            <div className="py-3">
              <Input
                className="my-3"
                value={password}
                setValue={setPassword}
                placeholder='at least 12 characters'
                label='New Password'
                type='password'
              />
              <Input
                className="my-3"
                value={conPassword}
                setValue={setConoPassword}
                placeholder='confirm new password'
                label='Confirm New Password'
                type='password'
              />
            </div>

            <Button className='w-full mt-4 text-white bg-primary' onClick={reset}>
              {loading ? 'Please wait ...' : 'RESET PASSWORD'}
            </Button>
          </section>)}
      </div>

      <div className="hidden sm:block sm:w-[45%]">
        <AuthCarousel />
      </div>
    </div>
  )
}

export default ResetPassword