import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import { toast } from "react-toastify";
import axios from 'axios';
import 'react-phone-input-2/lib/style.css'; // Import the library's styles

const ContactSales = () => {
  const showSidebar = useSelector((state) => state.global.showSidebar);
  const isFullWidth = useSelector((state) => state.global.isFullWidth);

  const [userData, setUserData] = useState({
    name: '', email: '', job: '', phonenumber: '', comment: ''
  })

  const StaticCheckedIcon = () => {
    return (
      <div className="pt-1">
        <div className="h-5 w-5 rounded-full bg-primary flex items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>
      </div>
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await axios.post(`/contact/create`, {
        ...userData
      })
      console.log(res)

      setUserData({ name: '', email: '', job: '', phonenumber: '', comment: '' })
      toast.success('You\'ve submitted succcessfully.')
    } catch (e) {
      toast.error(e.message)
    }
  }

  return (
    <div className={`w-full h-full ${showSidebar
      ? `${isFullWidth
        ? 'sm:pl-[218px]' : 'lg:pl-[250px] md:pl-[250px] sm:pl-[348px]'} pl-16`
      : `${isFullWidth
        ? 'sm:pl-16' : 'lg:pl-2 md:pl-2 sm:pl-2'}`} 
    ${isFullWidth ? 'sm:pr-16' : 'lg:pr-0 md:pr-0 sm:pr-0'} relative mt-10`}>

      <div className="md:flex justify-between w-full gap-6 md:p-10 p-4 md:pt-20 pt-4 mt-2 mb-10 xl:px-48 lg:px-36 md:px-16 px-8">
        <div className="h-fit md:top-28 md:w-1/2 max-w-[34.375rem] mt-12">
          <h1 className="font-outfit w-fit lg:text-5xl text-3xl font-bold"
            style={{
              background: 'linear-gradient(88deg, rgb(10 190 180) 6.18%, rgb(0, 204, 255) 97.22%) text',
              color: 'transparent',
              WebkitBackgroundClip: 'text'
            }}
          >
            Contact Our Sales Team
          </h1>
          <div className="flex flex-col gap-10 mt-4 w-full">
            <p className="md:text-lg font-semibold text-artisan-gray-900 w-full">
              We understand that every business has unique requirements.
              So, we'll work with you to create a pricing package that fits your needs.
            </p>
            <ul className="flex flex-col gap-2 text-sm md:text-base">
              <li className="flex gap-2 font-medium text-artisan-gray-900">
                <StaticCheckedIcon />
                Companies consistently boost productivity &amp; reduce costs with Artisan
              </li>
              <li className="flex gap-2 font-medium text-artisan-gray-900">
                <StaticCheckedIcon />
                Hundreds of companies automate their outbound with Artisan
              </li>
              <li className="flex gap-2 font-medium text-artisan-gray-900">
                <StaticCheckedIcon />
                We’re backed by top VCs, including Y Combinator
              </li>
              <li className="flex gap-2 font-medium text-artisan-gray-900">
                <StaticCheckedIcon />
                Artisan is the most comprehensive &amp; automated outbound solution
              </li>
            </ul>
            
            <p className="font-medium laptop:w-[27rem] text-artisan-gray-900 md:text-base text-sm">
              Not quite ready to schedule a call? Reach out to our team at
              <a
                href="mailto:hello@artisan.co"
                className="font-semibold text-primary hover:underline underline-offset-2">
                hello@shugic.com
              </a>
            </p>
          </div>
        </div>
        
        <div className="relative h-fit md:w-[400px] mt-12">
          <div className="w-full">
            <form
              action="#"
              onSubmit={handleSubmit}
              className="p-5 z-30 w-full tablet:max-w-[22.5rem] laptop:w-[27.625rem] gap-6 flex flex-col bg-white rounded-3xl shadow-[0px_0px_20px_0px_rgba(125,55,255,0.20)]">
              <div className="flex flex-col gap-3">
                <label className="text-sm text-artisan-gray-900" htmlFor="fullName">
                  Full Name*
                </label>
                <input
                  value={userData.name}
                  onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                  className="border border-gray-300 outline-none flex h-10 w-full bg-white text-artisan-gray-900 shadow-input rounded-[1.25rem] px-4 py-3 text-sm placeholder:text-artisan-gray-400 focus-visible:outline-none focus-visible:ring-[0.125rem] focus-visible:ring-artisan-primary-600 disabled:cursor-not-allowed disabled:opacity-50 transition duration-400"
                  label="Full Name*"
                  required
                  placeholder="John Smith" />
              </div>
              <div className="flex flex-col gap-3">
                <label className="text-sm text-artisan-gray-900" htmlFor="workEmail">Work Email*</label>
                <input
                  type="email"
                  value={userData.email}
                  onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                  className="border border-gray-300 outline-none flex h-10 w-full bg-white text-artisan-gray-900 shadow-input rounded-[1.25rem] px-4 py-3 text-sm placeholder:text-artisan-gray-400 focus-visible:outline-none focus-visible:ring-[0.125rem] focus-visible:ring-artisan-primary-600 disabled:cursor-not-allowed disabled:opacity-50 transition duration-400"
                  label="Work Email*"
                  placeholder="john@artisan.co"
                  required />
              </div>
              <div className="flex flex-col gap-3">
                <label className="text-sm text-artisan-gray-900" htmlFor="jobTitle">Job Title*</label>
                <input
                  value={userData.job}
                  onChange={(e) => setUserData({ ...userData, job: e.target.value })}
                  className="border border-gray-300 outline-none flex h-10 w-full bg-white text-artisan-gray-900 shadow-input rounded-[1.25rem] px-4 py-3 text-sm placeholder:text-artisan-gray-400 focus-visible:outline-none focus-visible:ring-[0.125rem] focus-visible:ring-artisan-primary-600 disabled:cursor-not-allowed disabled:opacity-50 transition duration-400"
                  required
                  label="Job Title*"
                  placeholder="VP of Sales" />
              </div>
              <div className="flex flex-col gap-3">
                <label className="text-sm text-artisan-gray-900" htmlFor="phone">Phone Number</label>
                <div className="relative w-full">
                  <PhoneInput
                    value={userData.phone}
                    onChange={phone => setUserData({ ...userData, phonenumber: phone })}
                    country={'us'}
                    inputProps={{
                      id: 'phone',
                      name: 'phone',
                      required: true,
                      autoFocus: true,
                      placeholder: '1 (702) 123-4567'
                    }}
                    containerClass="w-full"
                    inputClass="border border-gray-300 outline-none flex h-10 !w-full bg-white text-artisan-gray-900 !rounded-0 shadow-input px-4 !py-3 text-sm placeholder:text-artisan-gray-400 focus-visible:outline-none focus-visible:ring-[0.125rem] focus-visible:ring-artisan-primary-600 disabled:cursor-not-allowed disabled:opacity-50 transition duration-400"
                    buttonClass="flag-dropdown !bg-white"
                    dropdownClass="react-tel-input-dropdown !bg-white"
                  />
                </div>
              </div>
              <div className="flex flex-col gap-3">
                <label className="text-sm text-artisan-gray-900" htmlFor="comments">Comments</label>
                <div
                  className="p-[0.125rem] rounded-3xl transition duration-300 group/input"
                  style={{ background: 'radial-gradient(0px at 299.5px 78.8281px, rgb(125, 55, 255), transparent 80%)' }}>
                  <textarea
                    rows="3"
                    value={userData.comment}
                    onChange={(e) => setUserData({ ...userData, comment: e.target.value })}
                    className="border border-gray-300 resize-none flex w-full h-[120px] bg-white text-artisan-gray-900 shadow-input rounded-[1.25rem] px-4 py-3 text-sm placeholder:text-artisan-gray-400 focus-visible:outline-none focus-visible:ring-[0.125rem] focus-visible:ring-artisan-primary-600 disabled:cursor-not-allowed disabled:opacity-50 transition duration-400"
                    placeholder="Hi, I would like to learn more about your products"
                    label="Comments"
                  />
                </div>
              </div>
              <div className="relative flex items-center justify-center w-full bg-transparent rounded-full shadow-md shadow-artisan-primary-300">
                <button
                  type="submit"
                  className="relative z-50 flex items-center justify-center w-full px-5 py-2 font-semibold rounded-full bg-primary text-white font-outfit opacity-75 disabled:cursor-not-allowed" disabled="">
                  <p>Submit</p>
                </button>
              </div>

              <p className="-mt-1 text-sm font-medium text-center text-artisan-gray-500">
                Get a tailored quote in as little as 24 hours
              </p>
            </form>
          </div>
          <div className="absolute z-20 opacity-50 h-[13.875rem] -left-16 bottom-0 tablet:-left-24 laptop:-left-44">
            <img src="/assets/contact/purple-blur.svg" alt="" loading="lazy" width="auto" height="auto" decoding="async" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSales;
