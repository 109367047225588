import { useSelector } from "react-redux";
import { Select, Switch, Slider } from "@radix-ui/themes";
import {
  TextAlignLeftIcon,
  TextAlignCenterIcon,
  TextAlignRightIcon,
  LayersIcon,
  TextAlignMiddleIcon,
} from "@radix-ui/react-icons";
import * as ToggleGroup from "@radix-ui/react-toggle-group";

import { CircleIcon, StarsIcon, TextIcon } from "../../Icons";
import { ColorPicker } from "./ColorPicker";

const fontSizes = [
  "8px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "24px",
  "28px",
  "32px",
  "36px",
  "40px",
  "44px",
  "48px",
  "52px",
  "56px",
  "60px",
];
export const StyleSetting = ({ rndData, setRndData }) => {
  const setSettingStyleNo = useSelector((state) => state.global.settingStyleNo);
  const settingStyle = { ...rndData[setSettingStyleNo]?.style };
  const chartData = rndData[setSettingStyleNo]?.chartData || {};

  const titleAlignmentChange = ({ title }) => {
    settingStyle.titleAlignment = title;
    setData();
  };

  const verticalAlignmentChange = ({ value }) => {
    settingStyle.verticalAlignment = value;
    setData();
  };

  const isShowTitleChange = ({ isShowTitle }) => {
    settingStyle.isShowTitle = isShowTitle;
    setData();
  };
  const handleSetTitleFontColor = (e) => {
    settingStyle.titleFontColor = e;
    setData();
  };
  const handleSetTitleBGColor = (e) => {
    settingStyle.titleBGColor = e;
    setData();
  };
  const handleBGColor = (e) => {
    settingStyle.titleBGColor = "white";
    settingStyle.titleFontColor = "#000000";
    settingStyle["theme"] = "light";
    setData();
  };
  const handleBGColor1 = (e) => {
    settingStyle.titleBGColor = "#4B5563";
    settingStyle.titleFontColor = "white";
    settingStyle["theme"] = "dark";
    setData();
  };
  const handleBGColor2 = (e) => {
    settingStyle.titleBGColor =
      "linear-gradient(90deg, #026C7D 0%, #0F99AF 54%, #4E4965 100%)";
    settingStyle.titleFontColor = "white";
    settingStyle["theme"] = "tech";
    setData();
  };
  const handleBGColor3 = (e) => {
    settingStyle.titleBGColor =
      "linear-gradient(90deg, #DDC1F8 0%, #A571F1 54%, #72D3FD 100%)";
    settingStyle.titleFontColor = "white";
    settingStyle["theme"] = "fun";
    setData();
  };
  const handleBGColor4 = (e) => {
    settingStyle.titleBGColor =
      "linear-gradient(90deg, rgb(2, 108, 125) -1.49%, rgb(15, 153, 175) 54.36%, rgb(78, 73, 101) 100.42%)";
    settingStyle.titleFontColor = "white";
    settingStyle["theme"] = "ai";
    setData();
  };
  const handleColor1 = (e) => {
    settingStyle.styles = "metal-text-light";

    settingStyle["theme"] = "geek";
    setData();
  };
  const handleColor2 = (e) => {
    settingStyle.styles = "metal-text-colorful";
    settingStyle["theme"] = "colorful";

    setData();
  };

  const handleSetBorderColor = (e) => {
    settingStyle.borderColor = e;
    setData();
  };
  const handleSetCardBGColor = (e) => {
    console.log({ e });
    settingStyle.cardBGColor = e;
    setData();
  };
  const handleSetChartBorderColor = (e) => {
    settingStyle.chartBorderColor = e;
    setData();
  };
  const handleSetCardBGTransparent = (e) => {
    settingStyle.bgTransparent = e;
    setData();
  };
  const handleSetTitleBGTransparent = (e) => {
    settingStyle.titleBgTransparent = e;
    setData();
  };
  const setData = () => {
    rndData[setSettingStyleNo] = {
      ...rndData[setSettingStyleNo],
      style: settingStyle,
    };
    setRndData([...rndData]);
  };

  const chartType = chartData?.type || "";
  const toggleGroupItemClasses =
    "hover:text-white hover:bg-[#0694a3] color-[#0694a3] data-[state=on]:bg-[#0694a3] data-[state=on]:text-[#fff] flex p-[2px] border rounded-sm  items-center justify-center bg-white text-base  focus:bg-[#0694a3] ";

  return (
    <div className="w-full text-[#1F2A37]  h-screen overflow-y-auto">
      {chartType === "LANDSCAPE" && (
        <div className="my-4">
          <div className="font-medium text-sm bg-[#F3F4F6] w-full py-[6px] px-2 rounded-lg flex items-center gap-2 mb-2">
            <LayersIcon width={18} height={18} />
            Layout
          </div>
          <Select.Root
            defaultValue="drip"
            className="w-full"
            onValueChange={(val) => {
              let data = { ...chartData };
              if (!data.settings) return;
              data.settings.layout = val;
              rndData[setSettingStyleNo] = {
                ...rndData[setSettingStyleNo],
                chartData: data,
              };
              setRndData([...rndData]);
            }}
          >
            <Select.Trigger
              className="w-full"
              style={{
                height: 36,
                background: "#F3F4F6",
                fontSize: 13,
                color: "#6B7280",
                borderColor: "#D1D5DB",
                fontWeight: 400,
              }}
            />
            <Select.Content position="popper" className="w-full">
              <Select.Item value="drip" className="w-full">
                Drip (Default)
              </Select.Item>
              <Select.Item value="tile" className="w-full">
                Tile
              </Select.Item>
            </Select.Content>
          </Select.Root>
        </div>
      )}
      <div className="w-full left-1 top-1 flex-col justify-center items-center gap-1 inline-flex">
        <div className="self-stretch flex-col justify-start items-start inline-flex">
          <div className="font-medium text-sm bg-[#F3F4F6] w-full py-[6px] px-2 rounded-lg flex items-center gap-2">
            <CircleIcon width={18} height={18} />
            Theme
          </div>
          <div className="items-start grid grid-cols-5 gap-3 w-full justify-center text-white sm:text-[10px] text-[9px] my-3 px-2">
            <button
              className="active:border border-primary btn-grad text-overflow-1 break-all bg-white text-black"
              onClick={handleBGColor}
            >
              Light
            </button>
            <button
              className="active:border border-primary btn-grad text-overflow-1 break-all bg-black"
              onClick={handleBGColor1}
            >
              Dark
            </button>
            <button
              className="active:border border-primary btn-grad text-overflow-1 break-all bg-primary"
              onClick={handleBGColor2}
            >
              Tech
            </button>
            <button
              className="active:border border-primary btn-grad text-overflow-1 break-all btn-grad5"
              onClick={handleBGColor3}
            >
              Fun
            </button>
            <button
              className="active:border border-primary btn-grad bg-white text-primary flex items-center gap-1 h-7"
              onClick={handleBGColor4}
            >
              <StarsIcon color="#0694A3" />
              <span className="text-overflow-1 w-[calc(100%-14px)] text-left">
                AI generated
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="w-full left-1 top-1 flex-col justify-center items-center gap-1 inline-flex">
        <div className="self-stretch flex-col justify-start items-start inline-flex">
          <div className="font-medium text-sm bg-[#F3F4F6] w-full py-[6px] px-2 rounded-lg flex items-center gap-2">
            <CircleIcon width={18} height={18} />
            Color Theme
          </div>
          <div className="items-start grid grid-cols-5 gap-3 w-full justify-center text-white sm:text-[10px] text-[9px] my-3 px-2">
            <button
              className="active:border border-primary btn-grad text-overflow-1 break-all bg-black"
              onClick={handleColor1}
            >
              geek
            </button>
            <button
              className="active:border border-primary btn-grad text-overflow-1 break-all btn-grad5"
              onClick={handleColor2}
            >
              colorful
            </button>
          </div>
        </div>
      </div>
      <div>
        {chartData?.type !== "Text" && (
          <div className="justify-between items-end flex bg-[#F3F4F6] w-full py-[6px] px-2 rounded-lg">
            <div className="self-stretch justify-center items-center gap-2.5 inline-flex">
              <div className="font-medium text-sm flex items-center gap-2">
                <TextIcon width={18} height={18} stroke color="#1F2A37" />
                Title
              </div>
            </div>
            <div className="self-stretch justify-start items-center gap-0.5 inline-flex">
              <div className="text-opacity-60 text-sm font-medium text-sm leading-3 tracking-tight">
                <Switch
                  size="2"
                  color="cyan"
                  checked={settingStyle.isShowTitle}
                  onCheckedChange={(isShowTitle) => {
                    isShowTitleChange({ isShowTitle });
                  }}
                />
              </div>
            </div>
          </div>
        )}

        {settingStyle.isShowTitle && (
          <div className="flex-col justify-start items-start gap-2 inline-flex px-3">
            <div className="flex-col justify-start items-start gap-1 flex mt-4">
              <div className="text-sm font-normal leading-[18px]">
                Alignment
              </div>
              <div className="flex gap-6">
                <div className="mt-1">
                  <ToggleGroup.Root
                    className="inline-flex bg-mauve6 rounded shadow-blackA4 gap-3"
                    type="single"
                    value={settingStyle.titleAlignment}
                    onValueChange={(title) => {
                      titleAlignmentChange({ title });
                    }}
                  >
                    <ToggleGroup.Item
                      className={toggleGroupItemClasses}
                      value="left"
                      aria-label="Left aligned"
                    >
                      <TextAlignLeftIcon />
                    </ToggleGroup.Item>
                    <ToggleGroup.Item
                      className={toggleGroupItemClasses}
                      value="center"
                      aria-label="Center aligned"
                    >
                      <TextAlignCenterIcon />
                    </ToggleGroup.Item>
                    <ToggleGroup.Item
                      className={toggleGroupItemClasses}
                      value="right"
                      aria-label="Right aligned"
                    >
                      <TextAlignRightIcon />
                    </ToggleGroup.Item>
                  </ToggleGroup.Root>
                </div>
                <div className="mt-1">
                  <ToggleGroup.Root
                    className="inline-flex bg-mauve6 rounded shadow-blackA4 gap-3"
                    type="single"
                    value={settingStyle.verticalAlignment}
                    onValueChange={(value) => {
                      verticalAlignmentChange({ value });
                    }}
                  >
                    {/* <ToggleGroup.Item className={toggleGroupItemClasses} value="top" aria-label="Left aligned">
                      <TextAlignLeftIcon />
                    </ToggleGroup.Item> */}
                    {/* <ToggleGroup.Item className={toggleGroupItemClasses} value="center" aria-label="Center aligned">
                      <TextAlignCenterIcon />
                    </ToggleGroup.Item> */}
                    <ToggleGroup.Item
                      className={toggleGroupItemClasses}
                      value="middle"
                      aria-label="Middle aligned"
                    >
                      <TextAlignMiddleIcon />
                    </ToggleGroup.Item>
                  </ToggleGroup.Root>
                </div>
              </div>
            </div>
            {chartData?.type === "Text" && (
              <div className="flex gap-6 mt-2">
                <div className="flex-col justify-start items-start gap-1 flex">
                  <div className="text-sm font-normal leading-[18px]">
                    Font Size
                  </div>
                  <select
                    onChange={(e) => {
                      if (!chartData) return;
                      chartData.data.font_size = e.target.value;
                      rndData[setSettingStyleNo] = {
                        ...rndData[setSettingStyleNo],
                        chartData: chartData,
                      };
                      setRndData([...rndData]);
                    }}
                    value={chartData?.data?.font_size || "14px"}
                    className="w-20 font-normal text-sm outline-none border rounded p-1 focus:border-primary border-[#D1D5DB] text-[#6B7280] h-9"
                  >
                    {fontSizes.map((val, ind) => (
                      <option value={val} key={ind} className="text-[13px]">
                        {val}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex-col justify-start items-start gap-1 flex">
                  <div className="text-sm font-normal leading-[18px]">
                    Font Style
                  </div>
                  <Select.Root
                    size="1"
                    value={chartData.data.font_weight || 400}
                    className="w-full"
                    onValueChange={(val) => {
                      if (!chartData) return;
                      chartData.data.font_weight = val;
                      rndData[setSettingStyleNo] = {
                        ...rndData[setSettingStyleNo],
                        chartData: chartData,
                      };
                      setRndData([...rndData]);
                    }}
                  >
                    <Select.Trigger
                      style={{
                        width: 150,
                        height: 36,
                        background: "white",
                        fontSize: 13,
                        color: "#6B7280",
                        borderColor: "#D1D5DB",
                        fontWeight: 400,
                      }}
                    />
                    <Select.Content
                      position="popper"
                      className="w-full"
                      style={{ fontSize: 13 }}
                    >
                      <Select.Item value={300} className="w-full">
                        <span className="font-[300] pr-1">Ag</span> font-light
                      </Select.Item>
                      <Select.Item value={400} className="w-full">
                        <span className="font-normal pr-1">Ag</span> font-normal
                      </Select.Item>
                      <Select.Item value={500} className="w-full">
                        <span className="font-medium pr-1">Ag</span> font-medium
                      </Select.Item>
                      <Select.Item value={600} className="w-full">
                        <span className="font-bold pr-1">Ag</span> font-bold
                      </Select.Item>
                      <Select.Item value={700} className="w-full">
                        <span className="font-[800] pr-1">Ag</span>{" "}
                        font-extrabold
                      </Select.Item>
                    </Select.Content>
                  </Select.Root>
                </div>
              </div>
            )}
            <div className="flex-col justify-start items-start gap-1 flex mt-2">
              <div className="text-sm font-normal">Font Color</div>
              <div className="justify-start items-start gap-2 inline-flex">
                <ColorPicker
                  isFont={true}
                  selectedColor={settingStyle.titleFontColor}
                  setSelectedColor={handleSetTitleFontColor}
                ></ColorPicker>
              </div>
            </div>
            <div className="flex-col justify-start items-start gap-1 flex mt-2">
              <div className="text-sm font-normal leading-[18px]">BG Color</div>
              <div className="justify-start items-start gap-2 inline-flex">
                <ColorPicker
                  selectedColor={settingStyle.titleBGColor}
                  setSelectedColor={handleSetTitleBGColor}
                ></ColorPicker>
              </div>
            </div>

            <div className="flex-col justify-start items-start gap-1 flex mt-2">
              <div className="text-sm font-normal leading-[18px]">
                Border Color
              </div>
              <div className="justify-start items-start gap-2 inline-flex">
                <ColorPicker
                  selectedColor={settingStyle.borderColor}
                  setSelectedColor={handleSetBorderColor}
                ></ColorPicker>
              </div>
            </div>

            <div className="flex-col justify-start items-start gap-1 flex mt-2">
              <div className="text-sm font-normal leading-[18px]">
                BG Transparency
              </div>
              <div className="w-full mt-1">
                <Slider
                  onValueChange={handleSetTitleBGTransparent}
                  value={[
                    settingStyle.titleBgTransparent
                      ? settingStyle.titleBgTransparent
                      : 100,
                  ]}
                  color="cyan"
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {chartData?.type !== "Text" && chartData?.type !== "banner" && (
        <div className="mt-4 w-full">
          <div className="pb-3 left-4 flex-col justify-start items-start gap-2 inline-flex w-full">
            <div className="font-medium text-sm mt-2 bg-[#F3F4F6] w-full py-[6px] px-2 rounded-lg">
              Chart
            </div>
            <div className="self-stretch flex-col justify-start items-start gap-2 inline-flex mt-1 px-3">
              <div className="flex-col justify-start items-start gap-1 flex">
                <div className="text-sm font-normal leading-[18px]">
                  BG Color
                </div>
                <div className="justify-start items-start gap-2 inline-flex">
                  <ColorPicker
                    selectedColor={settingStyle.cardBGColor}
                    setSelectedColor={handleSetCardBGColor}
                  ></ColorPicker>
                </div>
              </div>
              <div className="flex-col justify-start items-start gap-1 flex mt-2">
                <div className="text-sm font-normal leading-[18px]">
                  BG Transparency
                </div>
                <div className="w-full mt-1">
                  <Slider
                    onValueChange={handleSetCardBGTransparent}
                    value={[
                      settingStyle.bgTransparent
                        ? settingStyle.bgTransparent
                        : 100,
                    ]}
                    color="cyan"
                  />
                </div>
              </div>
              {/* <div className="flex-col justify-start items-start gap-1 flex mt-2">
                <div className="text-sm font-normal leading-[18px]">Border Color</div>
                <div className="justify-start items-start gap-2 inline-flex">
                  <ColorPicker selectedColor={settingStyle.chartBorderColor} setSelectedColor={handleSetChartBorderColor}></ColorPicker>
                </div>
             </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// import React from "react";
// import { useSelector } from "react-redux";
// import { Select, Switch, Slider } from "@radix-ui/themes";
// import {
//   TextAlignLeftIcon,
//   TextAlignCenterIcon,
//   TextAlignRightIcon,
//   LayersIcon,
//   TextAlignMiddleIcon,
// } from "@radix-ui/react-icons";
// import * as ToggleGroup from "@radix-ui/react-toggle-group";

// import { CircleIcon, StarsIcon, TextIcon } from "../../Icons";
// import { ColorPicker } from "./ColorPicker";

// const fontSizes = [
//   { className: "text-xs", size: "12px" },
//   { className: "text-sm", size: "14px" },
//   { className: "text-base", size: "16px" },
//   { className: "text-lg", size: "18px" },
//   { className: "text-xl", size: "20px" },
//   { className: "text-2xl", size: "24px" },
//   { className: "text-3xl", size: "30px" },
//   { className: "text-4xl", size: "36px" },
//   { className: "text-5xl", size: "48px" },
//   { className: "text-6xl", size: "60px" },
//   { className: "text-7xl", size: "72px" },
//   { className: "text-8xl", size: "96px" },
//   { className: "text-9xl", size: "128px" },
// ];

// const themeOptions = [
//   {
//     name: "Light",
//     bgColor: "bg-white",
//     textColor: "text-black",
//     theme: "light",
//   },
//   {
//     name: "Dark",
//     bgColor: "bg-gray-700",
//     textColor: "text-white",
//     theme: "dark",
//   },
//   {
//     name: "Tech",
//     bgColor: "bg-gradient-to-r from-cyan-700 via-cyan-500 to-indigo-700",
//     textColor: "text-white",
//     theme: "tech",
//   },
//   {
//     name: "Fun",
//     bgColor: "bg-gradient-to-r from-purple-300 via-purple-400 to-blue-300",
//     textColor: "text-white",
//     theme: "fun",
//   },
//   {
//     name: "AI",
//     bgColor: "bg-gradient-to-r from-cyan-700 via-cyan-500 to-indigo-700",
//     textColor: "text-white",
//     theme: "ai",
//   },
// ];

// export const StyleSetting = ({ rndData, setRndData }) => {
//   const setSettingStyleNo = useSelector((state) => state.global.settingStyleNo);
//   const settingStyle = { ...rndData[setSettingStyleNo]?.style };
//   const chartData = rndData[setSettingStyleNo]?.chartData || {};
//   const updateStyle = (updates) => {
//     const updatedStyle = { ...settingStyle, ...updates };
//     const updatedRndData = [...rndData];
//     updatedRndData[setSettingStyleNo] = {
//       ...updatedRndData[setSettingStyleNo],
//       style: updatedStyle,
//     };
//     setRndData(updatedRndData);
//   };

//   const handleThemeChange = (theme, bgColor, textColor) => {
//     updateStyle({ titleBGColor: bgColor, titleFontColor: textColor, theme });
//   };

//   const handleColorThemeChange = (colorTheme, theme) => {
//     updateStyle({ styles: colorTheme, theme });
//   };

//   const updateCustomClassNames = (updates) => {
//     const updatedCustomClassNames = {
//       ...settingStyle.customClassNames,
//       ...updates,
//     };
//     updateStyle({ customClassNames: updatedCustomClassNames });
//   };

//   const renderStyleOption = (label, styleProp) => (
//     <div className="space-y-2 px-3">
//       <div className="text-sm font-medium">{label}</div>
//       <Select.Root
//         value={settingStyle.customClassNames?.[styleProp] || "default"}
//         onValueChange={(val) =>
//           updateCustomClassNames({ [styleProp]: val === "default" ? "" : val })
//         }
//       >
//         <Select.Trigger className="w-full h-10 bg-white text-sm text-gray-600 border border-gray-300 rounded-md" />
//         <Select.Content position="popper" className="w-full">
//           <Select.Item value="default" className="w-full">
//             Default
//           </Select.Item>
//           {getStyleOptions(styleProp).map((option) => (
//             <Select.Item
//               key={option.value}
//               value={option.value}
//               className="w-full"
//             >
//               {option.label}
//             </Select.Item>
//           ))}
//         </Select.Content>
//       </Select.Root>
//     </div>
//   );

//   const getStyleOptions = (styleProp) => {
//     const commonOptions = [
//       { value: "text-xl font-bold text-blue-600", label: "Bold Blue" },
//       {
//         value: "text-lg font-semibold text-green-600",
//         label: "Semibold Green",
//       },
//       {
//         value: "text-2xl font-extrabold text-purple-700",
//         label: "Extra Bold Purple",
//       },
//     ];

//     const specificOptions = {
//       title: commonOptions,
//       content: [
//         { value: "text-gray-700 leading-relaxed", label: "Relaxed Gray" },
//         { value: "text-blue-800 text-sm", label: "Small Blue" },
//         { value: "text-green-700 italic", label: "Italic Green" },
//       ],
//       container: [
//         {
//           value: "bg-gray-100 p-4 rounded-lg shadow-md",
//           label: "Gray Background with Shadow",
//         },
//         {
//           value: "bg-blue-50 p-4 rounded-lg border border-blue-200",
//           label: "Light Blue with Border",
//         },
//         {
//           value: "bg-green-50 p-6 rounded-xl shadow-lg",
//           label: "Green with Large Shadow",
//         },
//       ],
//       tab: [
//         {
//           value: "bg-blue-100 text-blue-600 hover:bg-blue-200",
//           label: "Blue Tab",
//         },
//         {
//           value: "bg-green-100 text-green-600 hover:bg-green-200",
//           label: "Green Tab",
//         },
//         {
//           value: "bg-purple-100 text-purple-600 hover:bg-purple-200",
//           label: "Purple Tab",
//         },
//       ],
//       itemButton: [
//         { value: "bg-gray-100", label: "Gray Background with Shadow" },
//         {
//           value: "bg-blue-50 border border-blue-200",
//           label: "Light Blue with Border",
//         },
//         {
//           value: "bg-green-50 p-6 rounded-xl shadow-lg",
//           label: "Green with Large Shadow",
//         },
//       ],
//     };

//     return specificOptions[styleProp] || commonOptions;
//   };

//   const renderCustomStyling = () => {
//     const chartType = chartData?.type?.toLowerCase();

//     if (["card", "gallery", "testimonial"].includes(chartType)) {
//       return (
//         <div className="space-y-2">
//           <div className="font-medium text-sm bg-gray-100 w-full py-2 px-3 rounded-lg flex items-center gap-2">
//             <LayersIcon className="w-5 h-5" />
//             {`${chartData.type} Styling`}
//           </div>

//           {renderStyleOption("Title Style", "title")}
//           {renderStyleOption("Content Style", "content")}
//           {renderStyleOption("Container Style", "container")}

//           {chartType === "card" &&
//             settingStyle.layout === "tab" &&
//             renderStyleOption("Tab Style", "tab")}
//           {(chartType === "gallery" || chartType === "testimonial") &&
//             renderStyleOption("Item Style", "itemButton")}
//         </div>
//       );
//     }
//     return null;
//   };

//   const toggleGroupItemClasses =
//     "hover:bg-primary hover:text-white text-primary data-[state=on]:bg-primary data-[state=on]:text-white flex items-center justify-center p-1 border rounded-sm bg-white focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50";

//   return (
//     <div className="w-full text-gray-800 h-screen overflow-y-auto space-y-4">
//       {chartData?.type === "LANDSCAPE" && (
//         <div className="space-y-2">
//           <div className="font-medium text-sm bg-gray-100 w-full py-2 px-3 rounded-lg flex items-center gap-2">
//             <LayersIcon className="w-5 h-5" />
//             Layout
//           </div>
//           <Select.Root
//             defaultValue="drip"
//             onValueChange={(val) => updateStyle({ layout: val })}
//           >
//             <Select.Trigger className="w-full h-10 bg-gray-100 text-sm text-gray-600 border border-gray-300 rounded-md" />
//             <Select.Content position="popper" className="w-full">
//               <Select.Item value="drip" className="w-full">
//                 Drip (Default)
//               </Select.Item>
//               <Select.Item value="tile" className="w-full">
//                 Tile
//               </Select.Item>
//             </Select.Content>
//           </Select.Root>
//         </div>
//       )}

//       <div className="space-y-2">
//         <div className="font-medium text-sm bg-gray-100 w-full py-2 px-3 rounded-lg flex items-center gap-2">
//           <CircleIcon className="w-5 h-5" />
//           Theme
//         </div>
//         <div className="grid grid-cols-5 gap-3 w-full">
//           {themeOptions.map((option) => (
//             <button
//               key={option.theme}
//               className={`${option.bgColor} ${option.textColor} text-xs py-2 px-3 rounded-md transition-all duration-200 ease-in-out hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50`}
//               onClick={() =>
//                 handleThemeChange(
//                   option.theme,
//                   option.bgColor,
//                   option.textColor
//                 )
//               }
//             >
//               {option.name}
//             </button>
//           ))}
//         </div>
//       </div>

//       <div className="space-y-2">
//         <div className="font-medium text-sm bg-gray-100 w-full py-2 px-3 rounded-lg flex items-center gap-2">
//           <CircleIcon className="w-5 h-5" />
//           Color Theme
//         </div>
//         <div className="grid grid-cols-2 gap-3 w-full">
//           <button
//             className="bg-black text-white text-xs py-2 px-3 rounded-md transition-all duration-200 ease-in-out hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50"
//             onClick={() => handleColorThemeChange("metal-text-light", "geek")}
//           >
//             Geek
//           </button>
//           <button
//             className="bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 text-white text-xs py-2 px-3 rounded-md transition-all duration-200 ease-in-out hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50"
//             onClick={() =>
//               handleColorThemeChange("metal-text-colorful", "colorful")
//             }
//           >
//             Colorful
//           </button>
//         </div>
//       </div>

//       {chartData?.type === "Text" && (
//         <div className="space-y-2">
//           <div className="flex justify-between items-center bg-gray-100 w-full py-2 px-3 rounded-lg">
//             <div className="font-medium text-sm flex items-center gap-2">
//               <TextIcon className="w-5 h-5 stroke-current text-gray-800" />
//               Title
//             </div>
//             <Switch
//               size="2"
//               color="cyan"
//               checked={settingStyle.isShowTitle}
//               onCheckedChange={(isShowTitle) => updateStyle({ isShowTitle })}
//             />
//           </div>

//           {settingStyle.isShowTitle && (
//             <div className="space-y-4 px-3">
//               <div className="space-y-2">
//                 <div className="text-sm font-medium">Alignment</div>
//                 <div className="flex gap-4">
//                   <ToggleGroup.Root
//                     type="single"
//                     value={settingStyle.titleAlignment}
//                     onValueChange={(title) =>
//                       updateStyle({ titleAlignment: title })
//                     }
//                     className="flex gap-2"
//                   >
//                     <ToggleGroup.Item
//                       value="left"
//                       className={toggleGroupItemClasses}
//                       aria-label="Left aligned"
//                     >
//                       <TextAlignLeftIcon />
//                     </ToggleGroup.Item>
//                     <ToggleGroup.Item
//                       value="center"
//                       className={toggleGroupItemClasses}
//                       aria-label="Center aligned"
//                     >
//                       <TextAlignCenterIcon />
//                     </ToggleGroup.Item>
//                     <ToggleGroup.Item
//                       value="right"
//                       className={toggleGroupItemClasses}
//                       aria-label="Right aligned"
//                     >
//                       <TextAlignRightIcon />
//                     </ToggleGroup.Item>
//                   </ToggleGroup.Root>
//                   <ToggleGroup.Root
//                     type="single"
//                     value={settingStyle.verticalAlignment}
//                     onValueChange={(value) =>
//                       updateStyle({ verticalAlignment: value })
//                     }
//                     className="flex"
//                   >
//                     <ToggleGroup.Item
//                       value="middle"
//                       className={toggleGroupItemClasses}
//                       aria-label="Middle aligned"
//                     >
//                       <TextAlignMiddleIcon />
//                     </ToggleGroup.Item>
//                   </ToggleGroup.Root>
//                 </div>
//               </div>
//               {chartData?.type === "Text" && (
//                 <div className="flex gap-4">
//                   <div className="space-y-2 flex-1">
//                     <div className="text-sm font-medium">Font Size</div>
//                     <Select.Root
//                       value={chartData?.data?.font_size || "text-base"}
//                       onValueChange={(val) => {
//                         const updatedChartData = {
//                           ...chartData,
//                           data: { ...chartData.data, font_size: val },
//                         };
//                         const updatedRndData = [...rndData];
//                         updatedRndData[setSettingStyleNo] = {
//                           ...updatedRndData[setSettingStyleNo],
//                           chartData: updatedChartData,
//                         };
//                         setRndData(updatedRndData);
//                       }}
//                     >
//                       <Select.Trigger className="w-full h-10 bg-white text-sm text-gray-600 border border-gray-300 rounded-md" />
//                       <Select.Content position="popper" className="w-full">
//                         {fontSizes.map((font) => (
//                           <Select.Item
//                             key={font.className}
//                             value={font.className}
//                             className="w-full"
//                           >
//                             {`${font.size}`}
//                           </Select.Item>
//                         ))}
//                       </Select.Content>
//                     </Select.Root>
//                   </div>
//                   <div className="space-y-2 flex-1">
//                     <div className="text-sm font-medium">Font Style</div>
//                     <Select.Root
//                       value={chartData.data.font_weight || 400}
//                       onValueChange={(val) => {
//                         const updatedChartData = {
//                           ...chartData,
//                           data: { ...chartData.data, font_weight: val },
//                         };
//                         const updatedRndData = [...rndData];
//                         updatedRndData[setSettingStyleNo] = {
//                           ...updatedRndData[setSettingStyleNo],
//                           chartData: updatedChartData,
//                         };
//                         setRndData(updatedRndData);
//                       }}
//                     >
//                       <Select.Trigger className="w-full h-10 bg-white text-sm text-gray-600 border border-gray-300 rounded-md" />
//                       <Select.Content position="popper" className="w-full">
//                         <Select.Item value={300} className="w-full">
//                           <span className="font-light pr-1">Ag</span> Light
//                         </Select.Item>
//                         <Select.Item value={400} className="w-full">
//                           <span className="font-normal pr-1">Ag</span> Normal
//                         </Select.Item>
//                         <Select.Item value={500} className="w-full">
//                           <span className="font-medium pr-1">Ag</span> Medium
//                         </Select.Item>
//                         <Select.Item value={600} className="w-full">
//                           <span className="font-semibold pr-1">Ag</span>{" "}
//                           Semibold
//                         </Select.Item>
//                         <Select.Item value={700} className="w-full">
//                           <span className="font-bold pr-1">Ag</span> Bold
//                         </Select.Item>
//                       </Select.Content>
//                     </Select.Root>
//                   </div>
//                 </div>
//               )}

//               <div className="space-y-2">
//                 <div className="text-sm font-medium">Font Color</div>
//                 <ColorPicker
//                   isFont={true}
//                   selectedColor={settingStyle.titleFontColor}
//                   setSelectedColor={(color) =>
//                     updateStyle({ titleFontColor: color })
//                   }
//                 />
//               </div>

//               <div className="space-y-2">
//                 <div className="text-sm font-medium">Background Color</div>
//                 <ColorPicker
//                   selectedColor={settingStyle.titleBGColor}
//                   setSelectedColor={(color) =>
//                     updateStyle({ titleBGColor: color })
//                   }
//                 />
//               </div>

//               <div className="space-y-2">
//                 <div className="text-sm font-medium">Border Color</div>
//                 <ColorPicker
//                   selectedColor={settingStyle.borderColor}
//                   setSelectedColor={(color) =>
//                     updateStyle({ borderColor: color })
//                   }
//                 />
//               </div>

//               <div className="space-y-2">
//                 <div className="text-sm font-medium">
//                   Background Transparency
//                 </div>
//                 <Slider
//                   min={0}
//                   max={100}
//                   step={1}
//                   value={[settingStyle.titleBgTransparent || 100]}
//                   onValueChange={(values) =>
//                     updateStyle({ titleBgTransparent: values[0] })
//                   }
//                   color="cyan"
//                   className="w-full"
//                 />
//               </div>
//             </div>
//           )}
//         </div>
//       )}

//       {chartData?.type !== "Text" && chartData?.type !== "banner" && (
//         <div className="space-y-2">
//           <div className="font-medium text-sm bg-gray-100 w-full py-2 px-3 rounded-lg">
//             Chart
//           </div>
//           <div className="space-y-4 px-3">
//             <div className="space-y-2">
//               <div className="text-sm font-medium">Background Color</div>
//               <ColorPicker
//                 selectedColor={settingStyle.cardBGColor}
//                 setSelectedColor={(color) =>
//                   updateStyle({ cardBGColor: color })
//                 }
//               />
//             </div>
//             <div className="space-y-2">
//               <div className="text-sm font-medium">Background Transparency</div>
//               <Slider
//                 min={0}
//                 max={100}
//                 step={1}
//                 value={[settingStyle.bgTransparent || 100]}
//                 onValueChange={(values) =>
//                   updateStyle({ bgTransparent: values[0] })
//                 }
//                 color="cyan"
//                 className="w-full"
//               />
//             </div>
//           </div>
//         </div>
//       )}

//       {/* card styling */}
//       {renderCustomStyling()}
//     </div>
//   );
// };
