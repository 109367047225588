import React, { useState } from 'react';
import Button from '../../Buton';
import { toast } from 'react-toastify';
import axios from 'axios';

const data = [
  { id: 1, name: 'Table', value: 'TABLE', imageName: 'Table' },
  { id: 2, name: 'Bar', value: 'BAR', imageName: 'Bar' },
  { id: 3, name: 'Line', value: 'LINE', imageName: 'Line' },
  { id: 4, name: 'Donut', value: 'DONUT', imageName: 'Donut' },
  { id: 5, name: 'Gauge', value: 'DONUTGAUAGE', imageName: 'Gauge' },
  { id: 6, name: 'Funnel', value: 'FUNNEL', imageName: 'Funnel' },
  { id: 7, name: 'StackBar', value: 'STACKBAR', imageName: 'StackBar' },
  { id: 8, name: 'GroupedBar', value: 'GROUPEDBAR', imageName: 'GroupedBar' },
  { id: 9, name: 'HeatMap', value: 'HEATMAP', imageName: 'HeatMap' },
  { id: 10, name: 'TreeMap', value: 'TREEMAP', imageName: 'TreeMap' },
  { id: 11, name: 'TimeLine', value: 'TIMELINE', imageName: 'TimeLine' },
  { id: 12, name: 'TextCloud', value: 'TEXTCLOUD', imageName: 'TextCloud' },
  { id: 13, name: 'Card', value: 'FLATCARD', imageName: 'Card' },
  { id: 14, name: 'GridBar', value: 'GRIDBAR', imageName: 'GridBar' },
  { id: 15, name: 'GridLine', value: 'GRIDLINE', imageName: 'GridLine' },
  { id: 16, name: 'GridDonut', value: 'GRIDDONUT', imageName: 'GridDonut' },
  { id: 17, name: 'Radar', value: 'RADAR', imageName: 'Radar' },
  { id: 18, name: 'Sankey', value: 'SANKEY', imageName: 'Sankey' },
  { id: 19, name: 'FilteredBar', value: 'FILTEREDBAR', imageName: 'FilteredBar' },
  { id: 20, name: 'Scatter', value: 'SCATTER', imageName: 'Scatter' },
  { id: 21, name: 'Sunburst', value: 'SUNBURST', imageName: 'Sunburst' },
  { id: 22, name: 'CirlePacking', value: 'CirlePacking', imageName: 'CirlePacking' },
  // { id: 23, name: 'ParliamentDonut', value: 'ParliamentDonut', imageName: 'ParliamentDonut' },
  { id: 23, name: 'RecordCard', value: 'RECORDCARD', imageName: 'RecordCard' },
  { id: 24, name: 'Accordion', value: 'ACCORDION', imageName: 'Accordion' },
  { id: 25, name: 'WebCard', value: 'WebCard', imageName: 'WebCard' },
  { id: 26, name: 'Graph', value: 'GRAPH', imageName: 'Graph' },
  { id: 27, name: 'Flywheel', value: 'FLYWHEEL', imageName: 'Flywheel' },
  { id: 28, name: 'ConcentricCircle', value: 'CONCENTRICCIRCLE', imageName: 'ConcentricCircle' },
  { id: 29, name: 'Process', value: 'PROCESS', imageName: 'Process' },
  { id: 30, name: 'CircularNetwork', value: 'CircularNetwork', imageName: 'CircularNetwork' },
  { id: 31, name: 'Network', value: 'NETWORK_FROM_TABLE', imageName: 'Network' },
  { id: 32, name: 'FlippableCards', value: 'FlippableCards', imageName: 'FlipCard' },
  { id: 33, name: 'HexagonWall', value: 'HexagonWall', imageName: 'HexagonWall' },
  { id: 34, name: 'MonthCalendar', value: 'TASKCALENDAR', imageName: 'Task' },
  { id: 35, name: 'SmartMap', value: 'SmartMap', imageName: 'Map' }
];

const dataText = [
  { id: 100, name: 'Text', value: 'TEXT' },
  { id: 101, name: 'H1', value: 'H1' },
  { id: 102, name: 'H2', value: 'H2' },
  { id: 103, name: 'Heroline', value: 'Heroline' }
];
const alternatives = ({ visualData, chartType, rndData, no }) => {
  const rnd = { ...rndData[no] };
  if (
    rndData[no].chartData.name === 'h1' ||
    rndData[no].chartData.name === 'h2' ||
    rndData[no].chartData.name === 'heroline'
  ) {
    return ['Text', 'H1', 'H2', 'Heroline'];
  } else if (rndData[no].chartData.name === 'text') {
    return [
      'Text',
      'H1',
      'H2',
      'Heroline',
      'Table',
      'Bar',
      'Line',
      'TimeLine',
      'Scatter',
      'Graph',
      'Donut',
      'Gauge',
      'Funnel',
      'TreeMap',
      'Radar',
      'TextCloud',
      'Card',
      'WebCard',
      'RecordCard',
      'Accordion',
      'CirlePacking',
      'Radar',
      'TreeMap',
      'HeatMap',
      'GroupedBar',
      'StackBar',
      'TextCloud',
      'GridBar',
      'GridLine',
      'GridDonut',
      'FilteredBar',
      'Card',
      'Sunburst',
      'Sankey',
      'CirlePacking',
      'ParliamentDonut'
      // 'Network',
      // 'FlippableCards',
      // 'HexagonWall',
      // 'MonthCalendar'
    ];
  } else if (chartType === 'TABLE' || rnd.currentType === 'TABLE') {
    const spec = visualData.spec;
    const columnsLength = spec.columns.length;
    if (columnsLength >= 4) {
      return [
        'Text',
        'H1',
        'H2',
        'Heroline',
        'Table',
        'Bar',
        'Line',
        'TimeLine',
        'Scatter',
        'Graph',
        'Donut',
        'Gauge',
        'Funnel',
        'TreeMap',
        'Radar',
        'TextCloud',
        'Card',
        'WebCard',
        'RecordCard',
        'Accordion',
        'CirlePacking',
        'Radar',
        'TreeMap',
        'HeatMap',
        'GroupedBar',
        'StackBar',
        'TextCloud',
        'GridBar',
        'GridLine',
        'GridDonut',
        'FilteredBar',
        'Card',
        'Sunburst',
        'Sankey',
        'CirlePacking',
        'ParliamentDonut',
        'Network',
        'FlippableCards',
        'HexagonWall',
        'MonthCalendar',
        'SmartMap',
        'Flywheel',
        'CircularNetwork',
        'Process',
        'ConcentricCircle'
      ];
    } else {
      return [
        'Text',
        'H1',
        'H2',
        'Heroline',
        'Table',
        'Bar',
        'Line',
        'TimeLine',
        'Scatter',
        'Graph',
        'Donut',
        'Gauge',
        'Funnel',
        'TreeMap',
        'Radar',
        'TextCloud',
        'Card',
        'WebCard',
        'RecordCard',
        'Accordion',
        'CirlePacking',
        'Radar',
        'TreeMap',
        'HeatMap',
        'GroupedBar',
        'StackBar',
        'TextCloud',
        'GridBar',
        'GridLine',
        'GridDonut',
        'FilteredBar',
        'Card',
        'Sunburst',
        'Sankey',
        'CirlePacking',
        'ParliamentDonut',
        'Network',
        'FlippableCards',
        'HexagonWall',
        'MonthCalendar'
      ];
    }
  } else {
    if (!visualData) {
      return;
    }
    const spec = visualData.spec;
    const dimensionsLength = spec.dimensions.length;
    const metricsLength = spec.metrics.length;
    const foundItem = data.find(item => item.value === chartType);
    let alternativesData = [];
    if (chartType === 'GRAPH' || chartType === 'ORGCHART' || chartType === 'LANDSCAPE') {
      return alternativesData.concat(foundItem?.name);
    }

    if (chartType === 'RECORDCARD') {
      return ['RecordCard', 'Accordion', 'WebCard'];
    }

    if (chartType === 'ACCORDION') {
      return ['RecordCard'];
    }
    if (dimensionsLength === 1 && metricsLength === 1) {
      alternativesData = [
        'Table',
        'Bar',
        'Line',
        'Donut',
        'Gauge',
        'Funnel',
        'TreeMap',
        'Radar',
        'TextCloud',
        'Card',
        'WebCard',
        'RecordCard',
        'Accordion',
        'CirlePacking',
        'Flywheel',
        'CircularNetwork',
        'Process',
        'ConcentricCircle'
      ];
    } else if (dimensionsLength === 2 && metricsLength === 1) {
      alternativesData = [
        'Table',
        'Radar',
        'TreeMap',
        'HeatMap',
        'GroupedBar',
        'StackBar',
        'TextCloud',
        'GridBar',
        'GridLine',
        'GridDonut',
        'FilteredBar',
        'Card',
        'Sunburst',
        'Sankey',
        'CirlePacking',
        'ParliamentDonut'
      ];
    } else if (metricsLength >= 2) {
      alternativesData = ['Scatter'];
    } else {
      alternativesData = ['Table'];
    }

    alternativesData = alternativesData.concat(foundItem?.name);
    return alternativesData;
  }
};
const ToggleChart = ({ onImageClick, chartType, visualData, rndData, setRndData, setDetails, no }) => {
  const [selectedCharts, setSelectedCharts] = useState(rndData[no].currentType ? rndData[no].currentType : chartType);
  const handleChartClick = name => {
    setSelectedCharts(null);
    setSelectedCharts(name);
    if (chartType === 'Text' || chartType === 'TABLE') {
      sendRequest(name, rndData[no].chartData.query, rndData, setRndData, setDetails, no);
    } else {
      onImageClick(name);
      const data = [...rndData];
      data[no].currentType = name;
      setRndData(data);
      setDetails({ type: name, rndNo: no });
      setTimeout(() => {
        changeHeight();
      }, 0);
    }
  };
  const handleClick = name => {
    setSelectedCharts(null);
    setSelectedCharts(name);
    const data = [...rndData];
    switch (name) {
      case 'TEXT':
        data[no].size.height = 36;
        data[no].chartData.data.font_size = 14;
        data[no].chartData.data.font_weight = 400;
        data[no].style.titleFontColor = '';
        data[no].chartData.name = 'text';
        data[no].style.titleBGColor = 'transparent';
        break;
      case 'H1': {
        data[no].chartData.data.font_size = 20;
        data[no].chartData.data.font_weight = 400;
        data[no].chartData.name = 'h1';
        data[no].size.height = 44;
        data[no].style.titleFontColor = 'white';
        data[no].style.titleBGColor = 'linear-gradient(90deg, #DDC1F8 0%, #A571F1 54%, #72D3FD 100%)';
        break;
      }
      case 'H2': {
        data[no].size.height = 42;
        data[no].chartData.data.font_size = 24;
        data[no].chartData.data.font_weight = 400;
        data[no].style.titleFontColor = '';
        data[no].chartData.name = 'h2';
        data[no].style.titleBGColor = 'transparent';
        break;
      }
      case 'Heroline': {
        data[no].size.height = 42;
        data[no].chartData.data.font_size = '3.75rem';
        data[no].chartData.data.font_weight = 800;
        data[no].chartData.name = 'heroline';
        data[no].style.titleFontColor = 'hsl(0deg 0% 62.7% /1)';
        data[no].style.titleBGColor = 'transparent';
        break;
      }
      default:
        break;
    }
    setRndData(data);
    setTimeout(() => {
      changeHeight();
    }, 0);
  };
  const sendRequest = async (name, query, rndData, setRndData, setDetails, no) => {
    const rnd = { ...rndData[no] };
    const loading = toast.loading('Please wait ...');
    try {
      let res;
      let reqiredColumn = {
        SmartMap: 'coord,sizeBy,colorBy,showCols,shape',
        FlippableCards: 'title_column,text_column,colorBy',
        HexagonWall: 'label,title, body,icon, colorBy'
      };
      if (name === 'SmartMap' || name === 'FlippableCards' || name === 'HexagonWall') {
        res = await axios.post(`smart/smartchart`, {
          q: query,
          vtype: name,
          required_columns: reqiredColumn[name]
        });
      } else {
        res = await axios.post(`smart/smartchart`, {
          q: query,
          vtype: name
        });
      }
      const data = res.data;
      if (data) {
        if (name === 'SmartMap' || name === 'FlippableCards' || name === 'HexagonWall') {
          const rnd2 = {
            ...rndData[no], // 保留原有的 rndData 内容
            chartData: {
              ...rndData[no].chartData, // 保留 chartData 的其他内容
              type: name,
              name: name, // 修改 type
              data: {
                ...rndData[no].chartData.data, // 保留 data 的其他内容
                visualData: {
                  ...rndData[no].chartData.data.visualData, // 保留 visualData 的其他内容
                  config: { ...data.answer } // 修改 config
                }
              }
            }
          };
          rndData[no] = { ...rnd2 };
          setRndData([...rndData]);
        } else {
          data.visuals.map((visual, ind) => {
            let type = visual?.spec?.vtype?.vtype;
            let filters = [];
            const dataConfig = visual?.data.config || {};
            const filtersData = dataConfig['key_filters'];

            if (filtersData && filtersData?.length > 0) {
              filters = filtersData.map(val => ({
                ...val,
                com_type: val?.values ? 'select' : 'input',
                cur_vals: val?.values ? [] : '',
                isShow: false
              }));
            }

            rnd.chartData = {
              ...(type === 'LANDSCAPE' && {
                settings: {
                  label: '',
                  layout: 'drip',
                  filters: filters
                }
              }),
              type: type,
              data: {
                visualData: visual
              },
              name: visual.title || type,
              query: query
            };
            rnd.style.titleFontColor = 'white';
            rnd.style.titleBGColor = 'linear-gradient(90deg, #DDC1F8 0%, #A571F1 54%, #72D3FD 100%)';

            rndData[no] = { ...rnd };
            setRndData([...rndData]);
          });
        }
        setTimeout(() => {
          changeHeight();
        }, 10);
      }
      toast.dismiss(loading);
    } catch (e) {
      toast.dismiss(loading);
      toast.error(e.message);
    }
  };
  const alternativesList = alternatives({ visualData, chartType, rndData, no });

  function convertToNumber(value) {
    if (typeof value === 'string') {
      return parseInt(value.replace(/\D/g, ''), 10);
    } else if (typeof value === 'number') {
      return parseInt(value);
    } else {
      throw new Error('Unsupported type');
    }
  }
  // const changeHeight=()=>{
  //     const targetObject = { ...rndData[no] };
  //     const minHeight = convertToNumber(targetObject.pos.y)

  //     const divElement = document.getElementById('card'+no);
  //     const height = divElement.clientHeight;
  //     console.log({height})// 新高度
  //     // const newPostY = height-convertToNumber(targetObject.size.height)
  //     // console.log({newPostY}) // 新的高度 - 原始高度
  //     // rndData[no].size.height=height
  //     // rndData.forEach((item, itemNo) => {
  //     //     if (item.pos.y>minHeight) {
  //     //       item.pos.y= convertToNumber(item.pos.y)+newPostY
  //     //     }
  //     //   });
  //     // setRndData([...rndData])

  //     const newPostY = minHeight+height
  //     console.log({newPostY}) // 新高度
  //     rndData[no].size.height=height
  //     let heights=0
  //     rndData.forEach((item, itemNo) => {
  //         if (item.pos.y>minHeight) {
  //           item.pos.y= newPostY+10
  //         }
  //       });
  //     setRndData([...rndData])

  // }
  const changeHeight = () => {
    const targetObject = { ...rndData[no] };
    const minHeight = convertToNumber(targetObject.pos.y);

    const divElement = document.getElementById('card' + no);
    const newHeight = divElement.clientHeight;
    rndData[no].size.height = newHeight;

    // 找到当前组件下方最靠近的组件
    let nearestComponent = null;
    rndData.forEach((item, itemNo) => {
      if (item.pos.y > minHeight) {
        if (!nearestComponent || item.pos.y < nearestComponent.pos.y) {
          nearestComponent = item;
        }
      }
    });

    // 如果找到了最近的组件，调整其位置
    if (nearestComponent) {
      const newY = minHeight + newHeight + 10; // 10 是组件间距，可以根据需要调整
      const heightDifference = newY - nearestComponent.pos.y;

      // 如果需要，调整其他下方的组件位置
      rndData.forEach(item => {
        if (item.pos.y > minHeight) {
          item.pos.y += heightDifference;
        }
      });

      setRndData([...rndData]);
    }
  };

  return (
    <div className="flex flex-col gap-2.5">
      <p className="text-[14px]  font-medium ">View Options</p>
      <hr />
      <div className="flex flex-wrap">
        {(chartType !== 'Text' || (chartType === 'Text' && rndData[no].chartData.name === 'text')) &&
          data.map(item => (
            <div key={item.id} className="w-full sm:w-1/3 md:w-1/6 p-2 flex flex-col items-center">
              <Button
                className={`relative border rounded p-1 h-[32px] w-[32px] flex items-center justify-center ${
                  alternativesList?.indexOf(item.name) === -1 ? '' : 'hover:bg-[#0694A3]'
                }  ${selectedCharts === item.value ? 'bg-[#0694A3]' : ''}`}
                disabled={alternativesList?.indexOf(item.name) === -1 ? true : false}
                onClick={() => handleChartClick(item.value)}>
                <img
                  src={
                    '/chartImages/' +
                    item.imageName +
                    (alternativesList?.indexOf(item.name) === -1 ? '_BW.png' : '_C.png')
                  }
                  alt={item.name}
                  width="20px"
                  height="20px"
                />
                {selectedCharts === item.value && (
                  <div className="absolute top-0 right-0 mt-1 mr-1">
                    <div className="w-2 h-2 bg-white rounded-full border-1 border-[#0694A3] flex items-center justify-center">
                      <svg
                        className="w-2 h-2 text-[#0694A3]"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                      </svg>
                    </div>
                  </div>
                )}
              </Button>
              <div className="text-[9px] mt-1">{item.imageName}</div>
            </div>
          ))}
        {chartType === 'Text' &&
          dataText.map(item => (
            <div key={item.id} className="w-full sm:w-1/3 md:w-1/6 p-2 flex flex-col items-center">
              <Button
                className={`relative border rounded p-1 h-[32px] w-[32px] flex items-center justify-center ${
                  alternativesList?.indexOf(item.name) === -1 ? '' : 'hover:bg-[#0694A3]'
                }  ${selectedCharts === item.value ? 'bg-[#0694A3]' : ''}`}
                disabled={alternativesList?.indexOf(item.name) === -1 ? true : false}
                onClick={() => handleClick(item.value)}>
                <div className="w-[20px] h-[20px]">{item.name.charAt(0)}</div>
                {selectedCharts === item.value && (
                  <div className="absolute top-0 right-0 mt-1 mr-1">
                    <div className="w-2 h-2 bg-white rounded-full border-1 border-[#0694A3] flex items-center justify-center">
                      <svg
                        className="w-2 h-2 text-[#0694A3]"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                      </svg>
                    </div>
                  </div>
                )}
              </Button>
              <div className="text-[9px] mt-1">{item.name}</div>
            </div>
          ))}
      </div>
    </div>
  );
};
export { ToggleChart };
