import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Importing translation files
import translationEN from "./langs/en";
import translationDE from "./langs/de";
import translationCH from "./langs/ch";

//Creating object with the variables of imported translation files
const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  ch: {
    translation: translationCH,
  },
};

//i18N Initialization

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.lang || "en",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;