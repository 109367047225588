export const PrintIcon = ({ width, height, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ? width : "28"}
    height={height ? height : "24"}
    viewBox="0 0 24 24"
    className={`fill-current ${className}`}
  >
    <path d="M16.45 7.8V4.5H7.55V7.8H6.05V3H17.95V7.8H16.45ZM18.475 11.675C18.675 11.675 18.85 11.6 19 11.45C19.15 11.3 19.225 11.125 19.225 10.925C19.225 10.725 19.15 10.55 19 10.4C18.85 10.25 18.675 10.175 18.475 10.175C18.275 10.175 18.1 10.25 17.95 10.4C17.8 10.55 17.725 10.725 17.725 10.925C17.725 11.125 17.8 11.3 17.95 11.45C18.1 11.6 18.275 11.675 18.475 11.675ZM16.45 19.5V14.7H7.55V19.5H16.45ZM17.95 21H6.05V16.6H2V10.45C2 9.69917 2.25417 9.06979 2.7625 8.56187C3.27083 8.05396 3.9 7.8 4.65 7.8H19.35C20.1008 7.8 20.7302 8.05396 21.2381 8.56187C21.746 9.06979 22 9.69917 22 10.45V16.6H17.95V21ZM20.5 15.1V10.4446C20.5 10.1149 20.3898 9.84167 20.1694 9.625C19.949 9.40833 19.6758 9.3 19.35 9.3H4.65C4.32417 9.3 4.05104 9.41021 3.83063 9.63062C3.61021 9.85104 3.5 10.1242 3.5 10.45V15.1H6.05V13.2H17.95V15.1H20.5Z" />
  </svg>
);
