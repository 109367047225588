import { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import autosize from 'autosize';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import Session from 'supertokens-web-js/recipe/session';

import CustomRnd from '../components/Smartdoc/CustomRnd';
import SmartInput from '../components/Smartdoc/SmartInput';
import HintCards from '../components/Smartdoc/HintCards';
import OptionList from '../components/Smartdoc/OptionList';
import VisualCard from '../components/Smartdoc/VisualCard';
import { ShareModal } from '../components/Smartdoc/ShareModal';
import ViewDrawer from '../components/Smartdoc/ViewDrawer';
import { useOutSideClick } from '../hooks/useOutSideClick';
import CommentDrawer from '../components/Smartdoc/CommentDrawer';
import { SettingsDrawer } from '../components/Smartdoc/VisualCardWidge/SettingsDrawer';
import {
  updateSmartdoc,
  setShowShareModal,
  setUnreadComments,
  setShowCommentDrawer,
  setShareType
} from '../store/slices/smartdocSlice';
import { fetchUserInfo, setUserSubscription } from '../store/slices/authSlice';
import MinimizableSmartInput from '../components/Smartdoc/MinimizableSmartInput';
import { motion, AnimatePresence } from 'framer-motion';

function SmartDoc() {
  const { docId, token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const shareType = searchParams.get('type');

  const showSidebar = useSelector(state => state.global.showSidebar);
  const isFullWidth = useSelector(state => state.global.isFullWidth);
  const isCanvasView = useSelector(state => state.global.isCanvasView);
  const isSmallText = useSelector(state => state.global.isSmallText);
  const userData = useSelector(state => state.auth.userData);
  const smartdoc = useSelector(state => state.smart.smartDoc);
  const isGrouping = useSelector(state => state.smart.isGrouping);
  const showShareModal = useSelector(state => state.smart.showShareModal);
  const showCommentDrawer = useSelector(state => state.smart.showCommentDrawer);

  const { t } = useTranslation();
  const textRef = useRef(null);
  const commentRef = useRef(null);
  const parentRef = useRef(null);

  const [rndData, setRndData] = useState([]);
  const [smartdocTheme, setSmartdocTheme] = useState({});
  const [isFirst, setIsFirst] = useState(null);
  const [details, setDetails] = useState({});
  const [visible, setVisible] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [isPrinting, setIsPrinting] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  const isPublicShare = !!token && shareType === 'public';
  const isPublic = isPublicShare && !userData.id;

  const removeNode = no => {
    const n_rndData = [...rndData];
    n_rndData.splice(no, 1);
    setRndData([...n_rndData]);
    setVisible(false);
  };

  const upNode = no => {
    const n_rndData = [...rndData];
    // 要上移动的元素，更改y轴
    const upObj = n_rndData[no];
    const id = n_rndData[no].id;
    var nearestAboveItem = n_rndData.reduce((nearestItem, currentItem) => {
      if (
        currentItem.id !== id &&
        convertToNumber(currentItem.pos.y) + convertToNumber(currentItem.size.height) < convertToNumber(upObj.pos.y)
      ) {
        if (
          !nearestItem ||
          convertToNumber(nearestItem.pos.y) + convertToNumber(nearestItem.size.height) <
            convertToNumber(currentItem.pos.y) + convertToNumber(currentItem.size.height)
        ) {
          nearestItem = currentItem;
        }
      }
      return nearestItem;
    }, null);
    // 如果找到了符合条件的数据项，则移动目标 ID 到其下方
    if (nearestAboveItem) {
      n_rndData[no].pos.y =
        convertToNumber(nearestAboveItem.pos.y) + convertToNumber(nearestAboveItem.size.height) + 10;
    } else if (upObj.pos.y !== 0) {
      // 如果没有找到上方元素，且目标元素的 pos.y 不为 0，则将其设为 0
      n_rndData[no].pos.y = 48;
    }
    setRndData([...n_rndData]);
  };

  function convertToNumber(value) {
    if (typeof value === 'string') {
      return parseInt(value.replace(/\D/g, ''), 10);
    } else if (typeof value === 'number') {
      return parseInt(value);
    } else {
      throw new Error('Unsupported type');
    }
  }

  const updateDoc = useCallback(
    data => {
      dispatch(updateSmartdoc(data));
    },
    [dispatch]
  );

  const getSmartDoc = useCallback(
    async docId => {
      const loading = toast.loading('Please wait ...');
      try {
        const res = await axios.get(`smartdoc/${docId}`);
        toast.dismiss(loading);
        const { data } = res;
        if (data) {
          setIsFirst(false);
          setRndData([...data.data.data]);
          updateDoc({ ...data, name: data.data.name });
          setSmartdocTheme(data.data.smartdocTheme);
        }
      } catch (e) {
        toast.dismiss(loading);
        toast.error(e.message);
      }
    },
    [updateDoc]
  );

  const getSharedDoc = useCallback(async () => {
    const loading = toast.loading('Please wait ...');
    try {
      const res = await axios.get(`smartdoc/share/${token}`);
      toast.dismiss(loading);
      const { data } = res;
      if (data) {
        setIsFirst(false);
        setRndData([...data.data.data]);
        updateDoc({ ...data, name: data.data.name });
        if (data.id && !isPublic) getAllUnreadComments(data.id);
      }
    } catch (e) {
      toast.dismiss(loading);
      if (e.response?.data?.message) {
        toast.warning(e.response.data.message);
      } else {
        toast.error(e.message);
      }
    }
  }, [token, updateDoc]);

  const getSubscription = async () => {
    const res = await axios.get('/subscription/get');
    dispatch(setUserSubscription(res.data));
    // setUserSubscription(res.data)
  };

  const saveDoc = async () => {
    if (!smartdoc.name) {
      toast.warn('Doc name is empty now.');
      return;
    }

    const loading = toast.loading('Please wait ...');
    try {
      if (smartdoc.id) {
        await axios.patch(`smartdoc/save/${smartdoc.id}`, {
          data: {
            data: rndData,
            name: smartdoc.name,
            smartdocTheme: smartdocTheme
          }
        });
        toast.dismiss(loading);
        toast.success('Your doc has been updated successfully.');
      } else {
        const res = await axios.post('smartdoc/create', {
          data: {
            name: smartdoc.name,
            data: rndData,
            smartdocTheme: smartdocTheme
          }
        });
        toast.success('Your new doc has been created successfully.');
        toast.dismiss(loading);
        const { data } = res;
        data.id && navigate(`/smartdoc/${data.id}`);
      }
    } catch (e) {
      const errorMessage = e?.response?.data?.message || e?.message;
      toast.dismiss(loading);
      toast.error(errorMessage);
    }
  };

  const createNew = useCallback(() => {
    setRndData([]);
    !token && setIsFirst(true);
    setVisible(false);
    setDetails({});
    setActiveItem(null);
    setSmartdocTheme({});
    updateDoc({ data: [], name: 'Untitled' });
  }, [updateDoc, token]);

  const deleteDoc = async (id = docId) => {
    if (!id) return;
    try {
      await axios.delete(`smartdoc/${id}`);
      toast.success('One doc has been deleted.');
      navigate('/smartdoc');
    } catch (e) {
      toast.error(e.message);
    }
  };

  const checkSession = useCallback(async () => {
    try {
      const session = await Session.doesSessionExist();
      if (session) return;
      else navigate('/signin');
    } catch (e) {
      navigate('/signin');
    }
  }, [navigate]);

  const getAllUnreadComments = async (smartdoc_id = '') => {
    const res = await axios.get(`visual/unread/${smartdoc_id}`);
    dispatch(setUnreadComments(res.data));
  };

  const groupVcards = () => {
    if (selectedItems.length < 2) return toast.warn('You should select 2 Vcards at least.');
    let firstNode = { ...rndData[selectedItems[0]] };
    const chartData = { ...firstNode.chartData };
    if (!chartData) return;

    const data = [];
    selectedItems.map((no, ind) => {
      const node = rndData[no];
      if (node.chartData.type === 'CAROUSEL') {
        node.chartData.data.map((val, no) => {
          data.push({
            chartData: val.chartData,
            style: val.style,
            size: val.size
          });
          return null;
        });
      } else {
        data.push({
          chartData: node.chartData,
          style: node.style,
          size: node.size
        });
      }
      return null;
    });

    chartData.type = 'CAROUSEL';
    chartData.name = 'CAROUSEL';
    chartData.query = '';
    chartData.data = data;

    firstNode.chartData = chartData;
    firstNode.style = { ...firstNode.style, isShowTitle: false };
    rndData[selectedItems[0]] = firstNode;

    selectedItems.map((no, ind) => {
      ind > 0 && rndData.splice(no, 1);
      return null;
    });

    setRndData([...rndData]);
    setSelectedItems([]);
  };

  const isSelectedItem = no => selectedItems.indexOf(no) > -1;

  const getHeight = () => {
    // const parent = document.getElementById('scroll')
    let totalHeight = 0;
    let lastRnd = {};
    rndData.forEach(child => {
      totalHeight = Math.max(totalHeight, parseInt(child.pos.y));
      lastRnd = child;
    });

    return totalHeight + 100 + parseInt(lastRnd?.size?.height);
  };

  // Print proceess Start

  const restoreLayout = useCallback(() => {
    const smartInput = parentRef.current.querySelector('.smart-input');
    toast.dismiss();
    setIsPrinting(false);
    if (smartInput) smartInput.style.display = 'flex';

    // Restore original layout properties
    if (parentRef.current) {
      parentRef.current.style.height = 'calc(100vh - 72px)';
      parentRef.current.style.minHeight = '';
      parentRef.current.style.overflow = '';
    }
  }, []);

  const print = () => {
    if (rndData.length < 1) return;
    const smartInput = parentRef.current.querySelector('.smart-input');
    // const minHeight = getHeight();

    // Prepare for printing
    if (parentRef.current) {
      parentRef.current.style.height = 'auto';
      // parentRef.current.style.minHeight = `${minHeight}px`;
      parentRef.current.style.minHeight = 'auto';
      parentRef.current.style.overflow = 'visible';
    }

    if (smartInput) smartInput.style.display = 'none';
    setIsPrinting(true);
    toast.loading(`Generating the PDF now...`);
    handlePrint();
    setTimeout(restoreLayout, 500);
  };

  // Modify handlePrint to use the restoreLayout function
  const handlePrint = useReactToPrint({
    content: () => parentRef.current,
    onAfterPrint: restoreLayout
  });
  // Print process Ended
  const bookMark = () => {
    if (!userData.id) {
      setTimeout(navigate('/signin'), 1000);
    } else {
    }
  };

  const getCredits = async () => {
    const res = await axios.get('/subscription/credits');
    console.log(res.data);
  };

  useEffect(() => {
    autosize(textRef.current);
    document.addEventListener('click', () => {
      setActiveItem(null);
    });

    return () => {
      document.removeEventListener('click', () => {
        setActiveItem(null);
      });
    };
  }, [textRef]);

  useEffect(() => {
    if (docId) {
      getSmartDoc(docId);
      !isPublic && getAllUnreadComments(docId);
    } else {
      createNew();
    }
  }, [docId, getSmartDoc, createNew, isPublic]);

  useEffect(() => {
    token && getSharedDoc();
  }, [token, getSharedDoc]);

  useEffect(() => {
    dispatch(setShareType(shareType));
    if (shareType === 'private') checkSession();
  }, [shareType, checkSession, dispatch]);

  useEffect(() => {
    setSelectedItems([]);
  }, [isGrouping]);

  useOutSideClick(() => {
    commentRef.current.style.display = `none`;
  }, commentRef);

  useEffect(() => {
    getCredits();
    dispatch(fetchUserInfo());
    getSubscription();
  }, []);

  const [isMinimized, setIsMinimized] = useState(false);

  useEffect(() => {
    // 每次内容变化时，滚动到容器底部
    if (parentRef.current) {
      parentRef.current.scrollTop = parentRef.current.scrollHeight - 350;
    }
  }, [rndData]); // 在 rndData 更新时触发

  const containerClasses = `z-[3] fixed bottom-12 ${
    showSidebar
      ? `${
          isFullWidth
            ? 'sm:w-[calc(100%-480px)]'
            : 'lg:w-[calc(100%-32%)] md:w-[calc(100%-640px)] sm:w-[calc(100%-515px)]'
        } w-[calc(100%-124px)]`
      : `${
          isFullWidth ? 'sm:w-[calc(100%-225px)] ' : 'lg:w-[calc(100%-32%)] md:w-[calc(100%-358px)]'
        } sm:w-[calc(100%-234px)] w-[calc(100%-74px)]`
  }`;

  return (
    // <div
    //   className={`w-full pt-4 pb-4   ${
    //     showSidebar
    //       ? `${isFullWidth ? 'sm:pl-[318px]' : 'lg:pl-[478px] md:pl-[414px] sm:pl-[350px]'} pl-16`
    //       : `${isFullWidth ? 'sm:pl-16' : 'lg:pl-56 md:pl-32 sm:pl-16'}`
    //   }
    // ${isFullWidth ? 'sm:pr-16' : 'lg:pr-56 md:pr-32 sm:pr-16'} bg-[#FAFBFC] relative`}>
    <div className={`w-full ${showSidebar ? 'lg:pl-[250px] md:pl-[414px] sm:pl-[350px]' : 'w-full'}  bg-white`}>
      <div
        id="print"
        ref={parentRef}
        style={{
          pageBreakInside: 'avoid',
          pageBreakAfter: 'always',
          background: smartdocTheme?.backgroundImage,
          overflowY: 'scroll', // 使用 scroll 让容器可以滚动
          minHeight: '50000px', // 设置一个超大高度限制，比如 50000px
          scrollbarWidth: 'none' // 针对 Firefox 浏览器隐藏滚动条
        }}
        className={`no-scrollbar ${isSmallText ? 'text-xs' : 'text-sm'} w-full h-[calc(100vh-40px)] overflow-auto 
      ${isPublicShare ? '' : 'mt-10'} relative ${isCanvasView ? 'bg-transparent' : 'bg-white'} overflow-x-hidden`}>
        {rndData.map((rd, no) => (
          <CustomRnd
            type={rd.type}
            zIndex={rd.chartData.type.includes('banner') ? 1 : rd.zIndex}
            parentRef={parentRef}
            setRndData={setRndData}
            rndData={rndData}
            key={no}
            no={no}>
            <div
              id={'rnd' + no}
              style={{
                borderColor: `${
                  isGrouping
                    ? isSelectedItem(no)
                      ? '#0694a3'
                      : `#00000000`
                    : activeItem === no
                    ? '#0694a3'
                    : `#00000000`
                }`
              }}
              className={`${rd.class} rnd w-full h-full`}
              onClick={e => {
                e.stopPropagation();
                setActiveItem(no);
                const index = selectedItems.indexOf(no);
                if (isGrouping) {
                  if (index > -1) {
                    selectedItems.splice(index, 1);
                  } else {
                    selectedItems.push(no);
                  }
                  setSelectedItems([...selectedItems]);
                }
              }}>
              <VisualCard
                {...rd}
                smartdocTheme={smartdocTheme}
                setShowComment={val => dispatch(setShowCommentDrawer(val))}
                removeNode={removeNode}
                setDetails={setDetails}
                setVisible={setVisible}
                setShowDrawer={setShowDrawer}
                isActive={activeItem === no}
                setRndData={setRndData}
                rndData={rndData}
                upNode={upNode}
                no={no}
                rd={rd}
              />
            </div>
          </CustomRnd>
        ))}
        {isFirst && (
          <div className={`p-4 sm:px-12 ${showSidebar ? '' : 'lg:pl-[250px] md:pl-[414px] sm:pl-[350px]'}`}>
            <p className="uppercase text-grey">{`✋ <${t('placeholder text')}>`}</p>
            <p className={`font-bold ${isSmallText ? 'text-2xl' : 'text-3xl'} mt-2`}>{t('Get Started With Us')}</p>
            <p className="mt-8 text-grey">{`👉 ${t('Type ‘/’ to see a list of predefined options')}`}</p>
            <p className="mt-2 text-grey">{`🤔 ${t(
              'If we have a question click on “?” icon above to get the documentation or send a message!'
            )}`}</p>
          </div>
        )}

        <div className={`px-4 sm:px-12 relative ${showSidebar ? '' : 'lg:pl-[250px] md:pl-[414px] sm:pl-[350px]'}`}>
          {/* <div
            className={`z-[3] fixed bottom-12
           ${
             showSidebar
               ? `${
                   isFullWidth
                     ? "sm:w-[calc(100%-480px)]"
                     : "lg:w-[calc(100%-804px)] md:w-[calc(100%-640px)] sm:w-[calc(100%-515px)]"
                 } w-[calc(100%-124px)]`
               : `${
                   isFullWidth
                     ? "sm:w-[calc(100%-225px)] "
                     : "lg:w-[calc(100%-550px)] md:w-[calc(100%-358px)]"
                 } sm:w-[calc(100%-234px)] w-[calc(100%-74px)]`
           }`}
          > */}

          <div className={containerClasses}>
            <AnimatePresence mode="sync">
              {isFirst && !isMinimized && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 10 }}
                  transition={{
                    duration: 0.4,
                    ease: 'easeOut'
                  }}
                  className="mb-4">
                  <HintCards rndData={rndData} setRndData={setRndData} setIsFirst={setIsFirst} />
                </motion.div>
              )}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  duration: 0.4,
                  delay: isFirst ? 0.2 : 0,
                  ease: 'easeOut'
                }}>
                <MinimizableSmartInput
                  smartdocTheme={smartdocTheme}
                  setSmartdocTheme={setSmartdocTheme}
                  isFirst={isFirst}
                  setIsFirst={setIsFirst}
                  rndData={rndData}
                  setRndData={setRndData}
                  parentRef={parentRef}
                  removeNode={removeNode}
                  updateDoc={updateDoc}
                  isMinimized={isMinimized}
                  setIsMinimized={setIsMinimized}
                />
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </div>

      <OptionList
        isPublicShare={isPublicShare}
        isPublic={isPublic}
        hideDeleteBtn={!docId}
        saveDoc={saveDoc}
        createNew={createNew}
        navigate={navigate}
        print={print}
        groupVcards={groupVcards}
        bookMark={bookMark}
        deleteDoc={deleteDoc}
      />
      {
        <SettingsDrawer
          isPublic={isPublic}
          visible={visible}
          onClose={() => setVisible(false)}
          rndData={rndData}
          setRndData={setRndData}
          details={details}
        />
      }
      {<ViewDrawer isPublic={isPublic} showDrawer={showDrawer} setShowDrawer={setShowDrawer} details={details} />}
      {
        <CommentDrawer
          rndNo={showCommentDrawer}
          setShowDrawer={val => dispatch(setShowCommentDrawer(val))}
          rndData={rndData}
          smartdoc={smartdoc}
          userData={userData}
        />
      }
      {showShareModal && (
        <ShareModal isModalOpen={showShareModal} closeModal={() => dispatch(setShowShareModal(false))} />
      )}
    </div>
  );
}

export default SmartDoc;
