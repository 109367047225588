import React, { useEffect, useRef } from 'react';
import cytoscape from 'cytoscape';
import dagre from 'cytoscape-dagre';

const dimensionColors = {}; // Store generated colors for each dimension
let colorIndex = 0;

function getColorForDimension(dimension) {
  // Check if a color is already generated for this dimension
  const color= [
    "#ddc1f8",  // 主色1
    "#1C295C",  // 主色2
    "#aa97e0",  // 类似色
    "#c6aff7",  // 类似色
    "#b983ff",  // 类似色
    "#e0bbf3",  // 类似色
    "#e7d8ff",  // 类似色
    "#273870",  // 类似色
    "#192342",  // 类似色
    "#2e3a76",  // 类似色
    "#34425c",  // 类似色
    "#ff9e80",  // 对比色
    "#ffab91",  // 对比色
    "#ff7043",  // 对比色
    "#ffb74d",  // 对比色
    "#f0e130",  // 对比色
    "#ffeb3b",  // 对比色
    "#eaeaea",  // 中性色
    "#f2f2f2",  // 中性色
    "#d3d3d3"   // 中性色
  ]
  if (!dimensionColors[dimension]) {
    // If not, generate a random color and store it
    dimensionColors[dimension] = color[colorIndex];
    colorIndex = (colorIndex + 1) % color.length;
  }
  return dimensionColors[dimension];
}

function convertNetworkSpecToElements(data) {
    let elements = [];
    data.data.edges.forEach((item)=>{
      let datas={data:{...item}}
      elements.push(datas)
    })
    // data.data.nodes.forEach((item)=>{
      
    //   let datas={data:{...item}}
    //   elements.push(datas)
    // })
    data.data.nodes.forEach(node => {
      const nodeType = node.type.toLowerCase(); // 确保不区分大小写
      const color = getColorForDimension(nodeType);
  
      elements.push({
        data: {
          nodeColor: color, // 根据节点类型分配颜色
         ...node
        },
      
      });
    });
    return elements;
  }
  

const NetworkGraphRaw = ({rndNo,visualData, setDetails,setShowDrawer}) => {
  const data= visualData.data
  const elements = convertNetworkSpecToElements(data);

  const cyRef = useRef(null);
  cytoscape.use( dagre );
  useEffect(() => {
    if (!cyRef.current) {
      cyRef.current = cytoscape({
        container: document.getElementById('cy'+rndNo), // Use an existing DOM element as container
        elements: elements,
        // layout: {
        //   name: 'elk',
        //   elk: {
        //     algorithm: 'disco', // Use the disco algorithm within the elk layout
        //     rankDir: 'LR', 
        //   }
        // },
        style: [
            {
              selector: 'node',
              style: {
                'cursor': 'grab',
                'shape': 'round-rectangle',
                'label': 'data(label)',
                'text-valign': 'center',
                'text-halign': 'center',
                'color': '#ffffff',
                'font-size': 16, // Font size for the label
                'background-color': 'data(nodeColor)',
                'background-image': 'data(image)', // 设置背景图
                'background-fit': 'cover', // 图片填充方式
                'width':'100%',
                // 'height':80,
                'padding':'8px'
              }
            },
            {
              selector: 'edge',
              style: {
                'label': 'data(label)',
                'labelFontSize': 2,
                'labelFontColor': 'white',
                'labelFontWeight': 'bold',
                'lineColor': 'data(color)',
                'width': 1,
                'target-arrow-color': 'data(color)',
                'curve-style': 'taxi',
                'target-arrow-shape': 'chevron',
                // 'target-arrow-color': 'data(edgeColor)',
              }
            }
          ]
        });
      cyRef.current.layout({
        "name": "dagre",
        "animate": false,
        "aspectRatio": 'auto',
        "rankDir":'LR'
        // "elk": {
        //   "algorithm": "stress"
        // }
      }).run();
   
      // cyRef.current.on('tap', 'node', function (event) {
      //   const node = event.target;
      //   const nodeData = node.data(); // 获取节点的数据对象
      //   setShowDrawer(true)
      //   const dataArray = [];
      //   const rowData={... nodeData.rowData}
      //   let propNodes= networkspec.nodes.find((item)=>{return item.entitytype===nodeData.entityType})
      //   let propCol= propNodes.prop_cols
      //   const filteredData = Object.fromEntries(
      //     Object.entries(rowData).filter(([key]) => propCol.includes(key))
      //   );
      //   for (const key in filteredData) {
      //     dataArray.push({ label: key, value: rowData[key] });
      //   }
      //   setDetails({ data: dataArray || {}, name: nodeData.entityType })
      // });
      // cyRef.current.on('mousedown', function (event) {
      //   event.stopPropagation()
      //   event.stopImmediatePropagation();
      // })
      
    }
  }, [elements, setDetails, setShowDrawer]);
  return(  
    <div className='h-full w-full'>
        <div id={'cy'+rndNo} onDrag={(event)=>event.stopPropagation()} className='flex gap-2 text-[12px] cursor-pointer items-center p-2 py-3 text-[#4F4764] leading-[15px] justify-center max-w-[100%] mx-auto w-full h-full text-white rounded'  />
    </div> 
  );
};

export default NetworkGraphRaw;
