import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import styled from 'styled-components';
import './shape.css';
const Node = styled.div`
  width: ${props => (props.width && 1000 > props.width && props.width > 90 ? `${props.width}px` : 'fit-content')};
  height: ${props => (props.height && 500 > props.height && props.height > 40 ? `${props.height}px` : 'fit-content')};
  // width: ${props => (props.width ? props.width : '100')}px;
  // height: ${props => (props.height ? `${props.height}px` : 'fit-content')};

  word-wrap: break-word;
  position: relative;
  z-index: 3;
  font-size: 12px;
  .react-flow__handle {
    background: ${props => (props.background ? props.background : '#01C2D7')};
    width: 8px;
    height: 8px;
    border-radius: 4px;
    // border: none;
  }
`;

function CustomNode(props) {
  const { data } = props;
  const {
    label: defaultLabel,
    style,
    setDetails,
    fields,
    color,
    rndNo,
    config,
    obj_key,
    setStyleSettingNo,
    setShowDrawer,
    direction
  } = data;
  let label = defaultLabel;
  let image = '';
  if (fields && config) {
    const matchImage = fields.filter(val => val.label === config['key_img'])[0];
    const matchLabel = fields.filter(val => val.label === config['key_label'])[0];
    if (matchImage) image = matchImage?.value;
    if (matchLabel) label = matchLabel?.value;
  }
  const sourcePosition = direction === 'horizontal' ? Position.Right : Position.Bottom;
  const targetPosition = direction === 'horizontal' ? Position.Left : Position.Top;

  const shapeClass =
    style?.shape === 'diamond'
      ? 'diamond'
      : style?.shape === 'circle'
      ? 'rounded-full'
      : style?.shape === 'cylinder'
      ? 'cylinder'
      : style?.shape === 'note'
      ? 'note'
      : 'rounded-lg';
  return (
    <Node background={style.color ? style.color : '#01BDD2'} width={style.width} height={style.height}>
      <>
        <Handle type="target" position={targetPosition} id="a" style={{ width: '3px', height: '3px' }} />
        <Handle type="source" position={sourcePosition} id="c" style={{ width: '3px', height: '3px' }} />
        <div
          onClick={() => {
            setShowDrawer(true);
            setStyleSettingNo();
            setDetails({
              data: fields,
              name: label,
              rndNo,
              obj_key: obj_key,
              type: 'flowchart'
            });
          }}
          style={{ background: style.color ? style.color : '#01BDD2' }}
          className={`text-[9px] flex flex-col justify-center items-center cursor-pointer p-2 gap-0 text-[#4F4764] leading-[15px] max-w-[100%] mx-auto w-full h-full text-white ${shapeClass}`}>
          {image && <img src={image} className="w-6 h-6 rounded" alt="" />}
          <div className="text-overflow-1">{label}</div>
        </div>
      </>
    </Node>
  );
}

export default memo(CustomNode);
