import React from 'react';
import Chart from '../Chart';

const processDataForRadarChart = (visualData) => {
  const visual=visualData
  let data = visual.data;
  let dimensions = visual.spec.dimensions;
  let metric = visual.spec.metrics[0]?.column;
  let dimensionData = {};
  let dimensionLabel = [];
  let tempSource = [];
  let legend = [];
  let tempValue = data[metric];
  if (dimensions && dimensions.length > 0 && dimensions[1]) {
      for (let i = 0; i < data[dimensions[1].column].length; i++) {
          let key = data[dimensions[1].column][i];
          if (Object.prototype.hasOwnProperty.call(dimensionData, key)) {
              dimensionData[key].push(tempValue[i]);
          } else {
              dimensionData[key] = [tempValue[i]];
          }
          legend.push(key);
      }
      for (let type in dimensionData) {
          tempSource.push({
              name: type,
              value: dimensionData[type],
          });
      }
      // 雷达label数据提取
      if (tempSource.length > 0) {
          let newArray = data[dimensions[0].column]
          dimensionLabel = newArray.slice();
          dimensionLabel = dimensionLabel.splice(0, tempSource[0].value.length)
              .map((item) => {
                  return {
                      name: item,
                  };
              });

      }
  } else {
      for (let i = 0; i < data[dimensions[0].column].length; i++) {
          let item = data[dimensions[0].column][i];
          dimensionLabel.push({
              name: item,
          });
      }
      tempSource = [
          {
              value: data[metric],
              name: dimensions[0].column,
          },
      ];
  }


  return {legend,dimensionLabel,tempSource};
};

const RadarChart = ({visualData,rd}) => {
  const {legend,dimensionLabel,tempSource}=processDataForRadarChart(visualData)
  const option = {
    radar: {
        shape: "circle",
        nameGap: 20,
        indicator: dimensionLabel,
        radius: '65%'
    },
    legend: {
        data: legend,
    },
    tooltip: {},
    series: [
        {
            type: "radar",
            symbolSize: 12,
            areaStyle: {
                normal: {
                    width: 1,
                    opacity: 0.3,
                },
            },
            data: tempSource,
        },
    ],
  };

  return (
    <Chart option={option} rd={rd}/>
  );
};

export { RadarChart };
