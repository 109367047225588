import React from "react";
import ReactDOM from "react-dom/client";
import { Theme } from "@radix-ui/themes";
import { Provider } from "react-redux";
import store from "./store";

import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "./index.css";
import "./theme-config.css";
import "@radix-ui/themes/styles.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Theme>
      <App />
    </Theme>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
