import React, { useState } from 'react';
import { BarChart } from "../EchartsOneDimension";
import Button from '../../../Buton';

const processDataForSelect = (visualData) => {
  const visual = visualData
  const data = visual.data;
  const quantity = visual.spec.dimensions[0].column;
  // this.optionName = quantity
  const options = [...new Set(data[quantity])].sort(function (a, b) { return a - b })
  return { options };
};


const FilteredBarChart = ({ visualData }) => {
  const { options } = processDataForSelect(visualData)
  const changeVisualData = JSON.parse(JSON.stringify(visualData));
  const [selectedOption, setSelectedOption] = useState(changeVisualData);
  // console.log(setSelectedOption)
  // const handleSelectChange = (selectedItem) => {
  //   const visual = changeVisualData
  //   const data = visual.data;
  //   const quantity = visual.spec.dimensions[0].column;
  //   // 找到要保留的项的索引
  //   const targetIndexes = data[quantity].reduce((acc, industry, index) => {
  //     if (industry === selectedItem) {
  //       acc.push(index);
  //     }
  //     return acc;
  //   }, []);

  //   // 构建新的对象
  //   const filteredData = Object.fromEntries(
  //     Object.entries(data).map(([key, values]) => [key, values.filter((_, index) => targetIndexes.includes(index))])
  //   );
  //   changeVisualData.data = filteredData
  //   setSelectedOption(changeVisualData)
  // };
  
  return (
    <>
      {/* <Select.Root defaultValue={options[0]} onValueChange={handleSelectChange}>
            <Select.Trigger color="cyan" variant="soft" />
            <Select.Content color="cyan">
                {options.map((item,index)=>(
                    <Select.Item value={item} key={index}>{item}</Select.Item>
                ))}
            </Select.Content>
        </Select.Root> */}
      <div className='flex flex-wrap'>
        {options.map((item, index) => (
          <Button className="border rounded p-1 h-[18px] text-[8px] m-1" value={item} key={index}>{item}</Button>
        ))}
      </div>

      <BarChart className="mt-2" visualData={selectedOption} type="filteredBar"></BarChart>
    </>
  );
};

export { FilteredBarChart };
