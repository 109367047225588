import React, { memo,useEffect,useState ,useCallback } from 'react';
import { Handle, Position, NodeResizer,NodeToolbar  } from 'reactflow';
import styled from 'styled-components';
import {DynamicChart} from "./GraphChart/DynamicChart";

import { toast } from 'react-toastify';
import axios from 'axios';

const Node = styled.div`
  width: 100%;
  height:100%;
  border-radius: 6px;
  word-wrap:  break-word;
  font-size: 12px;
  // border:solid 1px #aaa;
  background:white;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
`;
function CustomCard({data,selected }) {
  const { label,xAxisData,seriesData } = data;
  const [opacity, setOpacity] = useState(0)
  const handleMouseEnter = useCallback(() => {
    setOpacity(1)
  }, []);
  const handleMouseLeave = useCallback(() => {
    setOpacity(0)
  }, []);
  
  return (
    <Node background='#01BDD2' dashed='false' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <>
        <NodeResizer color="#0694A3" isVisible={selected} minWidth={100} minHeight={100} />
        <Handle type="target" position={Position.Left}  className=" bg-[white] border border-solid border-[gray] rounded-full" style={{'opacity':opacity}}/>
        <Handle type="source" position={Position.Right} className=" bg-[white] border border-solid border-[gray] rounded-full" style={{'opacity':opacity}}/>
        <div className='w-[200px] h-[36px]  flex justify-between'>
            {/* <div className='flex bg-[#006676] p-1.5 text-[white] text-[10px] rounded-t  uppercase font-montserrat overflow-hidden whitespace-nowrap overflow-ellipsis '>
                <div> {title?title:'unnamed'}</div>
                <button onClick={handleButtonClick}>按钮</button>
            </div> */}
            <div className="px-4 py-2  overflow-hidden h-full" >   
               {xAxisData}
            </div>
            <div className="px-4 py-2 overflow-hidden h-full" >   
               {seriesData}
            </div>
        </div>
      </>
    </Node>
  );
}

export default memo(CustomCard);