// import { CheckIcon, Cross1Icon } from "@radix-ui/react-icons";
// import { useDispatch, useSelector } from "react-redux";
// import { useEffect, useState } from "react";
// import axios from 'axios';

// import { Accordion } from "../components/Accordion";
// import { faqs, enterThings, freeThings, proThings, LookupKeys } from './config';
// import { setUserSubscription } from "../store/slices/authSlice";
// import { formatDate } from "../utils";
// import { useNavigate } from "react-router-dom";

// const RoundCheck = () =>
//   <div className="bg-[#dcdaed] rounded-full">
//     <CheckIcon />
//   </div>

// const PayModal = ({ content = 'standard', onClose, date = new Date() }) =>
//   <div className="w-full h-screen absolute left-0 top-0 bg-[#11111199] z-20 flex justify-center items-center p-4">
//     <div className="max-w-[100%] md:w-[600px] sm:w-[450px] p-6 pb-8 rounded-xl bg-white min-h-[350px] h-fit md:px-24 relative flex items-center">
//       <button className="absolute right-6 top-6" onClick={onClose}>
//         <Cross1Icon color="#555" />
//       </button>
//       <div className="flex flex-col items-center gap-4 text-[#374151] w-full mt-6">
//         <div className="bg-primary w-28 h-28 rounded-full text-white flex justify-center items-center">
//           <CheckIcon color="white" width={60} height={60} />
//         </div>
//         <div className="font-bold text-3xl text-black">Congratulations</div>
//         <p>You Have Successfully Subscribed !</p>
//         <div className="p-4 rounded-lg border w-full">
//           <p className="font-semibold">Payment Details</p>
//           <div className="border-b flex items-center justify-between p-2 text-sm mt-4">
//             <p>Date</p>
//             <p>{formatDate(new Date(date))}</p>
//           </div>
//           <div className="flex items-center justify-between p-2 text-sm mt-2">
//             <p>Plan</p>
//             <p><span className="uppercase">{content}</span> Plan (Monthly)</p>
//           </div>
//           {/* <div className="flex justify-center mt-6">
//             <button className="min-w-[200px] bg-primary rounded-lg py-3 text-center text-white text-sm max-w-[100%]">
//               Download Invoice
//             </button>
//           </div> */}
//         </div>
//       </div>
//     </div>
//   </div>

// function Pricing() {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const showSidebar = useSelector((state) => state.global.showSidebar)
//   const userData = useSelector((state) => state.auth.userData)
//   const userSubscription = useSelector((state) => state.auth.userSubscription)

//   const urlParams = new URLSearchParams(window.location.search);
//   const status = urlParams.get('status');

//   const [plan, setPlan] = useState('free')
//   const [loading, setLoading] = useState(null)
//   const [active, setActive] = useState(null);
//   const [showModal, setShowModal] = useState(status);

//   const isActive = userSubscription.sub_status === 'active'
//   const isFreeSub = (isActive && userSubscription?.membership_type === 'free') || userSubscription?.type === 'noSubscription'
//   const isProSub = isActive && userSubscription.membership_type === 'pro'
//   const isEnterSub = isActive && userSubscription.membership_type === 'enter'

//   const handleToggle = (index) => {
//     if (active === index) {
//       setActive(null);
//     } else {
//       setActive(index);
//     }
//   }

//   const LookupKeys = {
//     free: 'shugic_free',
//     pro: 'shugic_pro',
//     enter: 'shugic_enter',
//   }

//   const submitMembership = async (plan = '') => {
//     if (!userData.id) return
//     const params = {
//       type: plan,
//       lookup_key: LookupKeys[plan],
//       email: userData.email,
//       user_id: userData.id
//     }

//     setLoading(plan)
//     const res = await axios.post('/subscription/create-membership', { ...params })
//     window.location.href = res.data.url
//   }

//   const getSubscription = async () => {
//     const res = await axios.get('/subscription/get')
//     dispatch(setUserSubscription(res.data))
//     setPlan(res.data.membership_type)
//   }

//   const choosePlan = (plan = '') => {
//     // setPlan(plan);
//     if (plan === 'enter') {
//       navigate('/contact-sales')
//       return
//     }
//     if ((isActive && userSubscription.membership_type === plan) || loading) return

//     submitMembership(plan)
//   }

//   const Loading = () => (
//     <div className="flex w-full h-5 justify-center items-center">
//       <span className="loader"></span>
//     </div>
//   )

//   useEffect(() => {
//     getSubscription()
//   }, [])

//   const isDisabled = (pl) => (isActive && userSubscription.membership_type === pl)

//   return (
//     <div className={`pt-12 pb-20 ${showSidebar ? 'pl-16 sm:pl-[250px]' : ''}`}>
//       {showModal && <PayModal content={userSubscription.membership_type} onClose={() => setShowModal(null)} date={userSubscription.createdAt} />}
//       <div className="w-5/6 mx-auto mt-12 max-w-[1200px]">
//         <p className="text-primary font-[900] md:text-4xl text-3xl text-left title-grad">Plans & Prices</p>
//         <p className="text-primary font-bold mt-4">( Free forever for the Viewers! )</p>
//         <div className="gap-4 grid md:grid-cols-3 md:mt-16 mt-8 bg-[#fbf7c29c] rounded-2xl p-4 text-[#4F4764] text-sm font-normal pb-32">
//           <div
//             onMouseEnter={() => setPlan('free')}
//             onMouseLeave={() => setPlan(null)}
//             className={`w-full p-3 rounded-xl md:-mt-10 pt-12 transition-all ${isFreeSub && 'bg-primary text-white'}
//           ${plan === 'free' ? 'bg-primary text-white' : ''}`}>
//             <p className={`text-2xl font-[800] ${isFreeSub && 'text-white'} ${plan === 'free' ? 'text-white' : 'text-primary'}`}>Free</p>
//             <p className="h-24 py-4 text-[13px]">Create and share beautiful data report and apps at ease</p>
//             <div className="flex gap-2 items-center my-4">
//               <p className={`text-2xl font-[800] ${isFreeSub && 'text-white'} ${plan === 'free' ? 'text-white' : 'text-primary'}`}>$0</p>
//               <p className="">/month</p>
//             </div>
//             <div className="font-medium">
//               <p className="font-bold">Per User</p>
//               <div className="mt-4">
//                 {freeThings.map((val, ind) => (
//                   <div className="flex gap-2 items-center mt-2 text-[13px]" key={ind}>
//                     <RoundCheck />
//                     <p>{val}</p>
//                   </div>
//                 ))}
//               </div>
//               <button
//                 disabled={isDisabled('free')}
//                 onClick={() => isFreeSub ? '' : choosePlan('free')}
//                 className={`${isFreeSub ? 'bg-[#333]' : plan === 'free' ? 'bg-[#333]' : 'bg-[#95959d]'} w-full py-3 text-center rounded-full text-white mt-4 font-normal`}>
//                 {loading === 'free' ? <Loading /> : isFreeSub ? `Your Plan` : 'Choose Plan'}
//                 {/* (${userSubscription.sub_status}) */}
//               </button>
//             </div>
//           </div>

//           <div
//             onMouseEnter={() => setPlan('pro')}
//             onMouseLeave={() => setPlan(null)}
//             className={`w-full p-3 rounded-xl md:-mt-10 pt-12 transition-all ${isProSub && 'bg-primary text-white'}
//           ${plan === 'pro' ? 'bg-primary text-white' : ''}`}>
//             <p className={`text-2xl font-[800] ${isProSub && 'text-white'} ${plan === 'pro' ? 'text-white' : 'text-primary'}`}>Pro</p>
//             <p className="h-24 py-4 text-[13px]">Empower your team and your customer-relation with collaborative apps that fits to your daily workflow</p>
//             <div className="flex gap-2 items-center my-4">
//               <p className={`text-2xl font-[800] ${isProSub && 'text-white'} ${plan === 'pro' ? 'text-white' : 'text-primary'}`}>$18</p>
//               <p className="">/month</p>
//             </div>
//             <div className="font-medium">
//               <p className="font-bold">Per User</p>
//               <div className="mt-4">
//                 {proThings.map((val, ind) => (
//                   <div className="flex gap-2 items-center mt-2 text-[13px]" key={ind}>
//                     <RoundCheck />
//                     <p>{val}</p>
//                   </div>
//                 ))}
//               </div>
//               <button
//                 disabled={isDisabled('pro')}
//                 onClick={() => choosePlan('pro')}
//                 className={`${isProSub ? 'bg-[#333]' : plan === 'pro' ? 'bg-[#333]' : 'bg-[#95959d]'} w-full py-3 text-center rounded-full text-white mt-4 font-normal`}>
//                 {loading === 'pro' ? <Loading /> : isProSub ? `Your Plan (${userSubscription.sub_status})` : 'Choose Plan'}
//               </button>
//             </div>
//           </div>

//           <div
//             onMouseEnter={() => setPlan('enter')}
//             onMouseLeave={() => setPlan(null)}
//             className={`w-full p-3 rounded-xl md:-mt-10 pt-12 transition-all ${isEnterSub && 'bg-primary text-white'}
//           ${plan === 'enter' ? 'bg-primary text-white' : ''}`}>
//             <p className={`text-2xl font-[800] ${isEnterSub && 'text-white'} ${plan === 'enter' ? 'text-white' : 'text-primary'}`}>Enterprise</p>
//             <p className="py-4 text-[13px]">Supercharge your business with advanced analytics, created by non-tech business users on-demand via self-service. Cut off the human middle layer - the IT team - between data and business users!</p>
//             <div className="flex items-center">
//               <button className={`${plan === 'enter' ? 'text-[#AEFF73]' : 'text-[#8ee350]'} w-fit mx-auto text-center font-[800] text-2xl my-3`}>
//                 Contact Sales
//               </button>
//             </div>
//             <div className="font-medium">
//               <div className="mt-4">
//                 {enterThings.map((val, ind) => (
//                   <div className="flex gap-2 items-center mt-2 text-[13px]" key={ind}>
//                     <RoundCheck />
//                     <p>{val}</p>
//                   </div>
//                 ))}
//               </div>
//               <button
//                 disabled={isDisabled('enter')}
//                 onClick={() => choosePlan('enter')}
//                 className={`${isEnterSub ? 'bg-[#333]' : plan === 'enter' ? 'bg-[#333]' : 'bg-[#95959d]'} w-full py-3 text-center rounded-full text-white mt-4 font-normal`}>
//                 {isEnterSub ? `Your Plan ${userSubscription.sub_status}` : 'Contact Us'}
//               </button>
//             </div>
//           </div>
//         </div>

//         <section className='mt-16'>
//           <p className='title-grad text-2xl font-[800] text-left my-6'>Frequently asked questions</p>
//           <div className='w-full pb-16'>
//             {faqs.map((faq, index) => {
//               return (
//                 <Accordion key={index} active={active} handleToggle={handleToggle} faq={faq} />
//               )
//             })}
//           </div>
//         </section>
//       </div>
//     </div>
//   )
// }

// export default Pricing;

import React, { useState, useEffect } from 'react';
import { CheckIcon, Cross1Icon } from '@radix-ui/react-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { faqs, enterThings, freeThings, proThings, LookupKeys } from './config';
import { setUserSubscription } from '../store/slices/authSlice';
import { formatDate } from '../utils';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../components/ui/accordion';

const PlanCard = ({ title, price, features, description, isActive, onChoosePlan, loading, plan, isHighlighted }) => (
  <Card
    className={`w-full transition-all ${isActive ? 'border-primary border-2' : ''} ${
      isHighlighted ? 'transform scale-105 shadow-xl' : ''
    }`}>
    <CardHeader className="text-center">
      <CardTitle className="text-2xl font-bold mb-2">{title}</CardTitle>
      <CardDescription className="text-xl font-semibold">
        {price}
        <span className="text-sm font-normal">{title === 'Enterprise' ? '' : '/month'}</span>
        <p className="text-xs font-normal pt-2 text-left">{description}</p>
      </CardDescription>
    </CardHeader>
    <CardContent>
      <ul className="space-y-3">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center gap-2 text-sm">
            <CheckIcon className="text-green-500 flex-shrink-0" />
            <span>{feature}</span>
          </li>
        ))}
      </ul>
    </CardContent>
    <CardFooter>
      <Button
        onClick={onChoosePlan}
        disabled={isActive || loading === plan}
        className={`w-full py-2 ${(isHighlighted || isActive) && 'text-white'}`}
        variant={isActive ? 'secondary' : isHighlighted ? 'default' : 'outline'}>
        {loading === plan ? 'Loading...' : isActive ? 'Current Plan' : 'Choose Plan'}
      </Button>
    </CardFooter>
  </Card>
);

const PayModal = ({ content, onClose, date }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
    <Card className="w-full max-w-md">
      <CardHeader>
        <div className="flex justify-between items-center">
          <CardTitle className="text-2xl font-bold">Congratulations</CardTitle>
          <Button variant="ghost" size="icon" onClick={onClose}>
            <Cross1Icon />
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col items-center gap-4">
          <div className="bg-primary text-white p-4 rounded-full">
            <CheckIcon className="w-12 h-12" />
          </div>
          <p className="text-lg font-semibold">You Have Successfully Subscribed!</p>
          <div className="w-full space-y-2 bg-gray-100 p-4 rounded-lg">
            <h3 className="font-semibold text-lg mb-2">Payment Details</h3>
            <div className="flex justify-between">
              <span className="text-gray-600">Date</span>
              <span className="font-medium">{formatDate(new Date(date))}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-600">Plan</span>
              <span className="font-medium">{content.toUpperCase()} Plan (Monthly)</span>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  </div>
);

const Pricing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showSidebar = useSelector(state => state.global.showSidebar);
  const userData = useSelector(state => state.auth.userData);
  const userSubscription = useSelector(state => state.auth.userSubscription);

  const [loading, setLoading] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [activeAccordion, setActiveAccordion] = useState(null);

  const isActive = userSubscription.sub_status === 'active';
  const currentPlan = isActive ? userSubscription.membership_type : 'free';

  const submitMembership = async plan => {
    if (!userData.id) return;
    setLoading(plan);
    try {
      const res = await axios.post('/subscription/create-membership', {
        type: plan,
        lookup_key: LookupKeys[plan],
        email: userData.email,
        user_id: userData.id
      });
      window.location.href = res.data.url;
    } catch (error) {
      console.error('Error creating membership:', error);
      setLoading(null);
    }
  };

  const choosePlan = plan => {
    if (plan === 'enter') {
      navigate('/contact-sales');
      return;
    }
    if (isActive && userSubscription.membership_type === plan) return;
    submitMembership(plan);
  };

  useEffect(() => {
    const getSubscription = async () => {
      try {
        const res = await axios.get('/subscription/get');
        dispatch(setUserSubscription(res.data));
      } catch (error) {
        console.error('Error fetching subscription:', error);
      }
    };
    getSubscription();
  }, [dispatch]);

  const handleAccordionToggle = index => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  return (
    <div className={`container mx-auto py-12 px-4 ${showSidebar ? 'pl-16 sm:pl-[250px]' : ''}`}>
      <h1 className="text-4xl font-bold text-center mb-4">Plans & Pricing</h1>
      <p className="text-center text-gray-600 mb-12 max-w-2xl mx-auto">
        Choose the plan that fits your needs and start exploring our powerful features today.
      </p>

      <div className="grid md:grid-cols-3 gap-8 mb-16">
        <PlanCard
          title="Free"
          price="$0"
          description="Create and share beautiful data report and apps at ease"
          features={freeThings}
          isActive={currentPlan === 'free'}
          onChoosePlan={() => choosePlan('free')}
          loading={loading}
          plan="free"
        />
        <PlanCard
          title="Pro"
          price="$18"
          description="Empower your team and your customer-relation with collaborative apps that fits to your daily workflow"
          features={proThings}
          isActive={currentPlan === 'pro'}
          onChoosePlan={() => choosePlan('pro')}
          loading={loading}
          plan="pro"
          isHighlighted={true}
        />
        <PlanCard
          title="Enterprise"
          price="Contact Us"
          description="Supercharge your business with advanced analytics, created by non-tech business users on-demand via self-service. Cut off the human middle layer - the IT team - between data and business users!"
          features={enterThings}
          isActive={currentPlan === 'enter'}
          onChoosePlan={() => choosePlan('enter')}
          loading={loading}
          plan="enter"
        />
      </div>

      <section className="mb-16 max-w-3xl mx-auto">
        <h2 className="text-3xl font-bold text-center mb-8">Frequently Asked Questions</h2>
        <Accordion type="single" collapsible className="w-full">
          {faqs.map((faq, index) => (
            <AccordionItem key={index} value={`item-${index}`}>
              <AccordionTrigger
                onClick={() => handleAccordionToggle(index)}
                className="text-left font-semibold hover:text-primary transition-colors">
                {faq.header}
              </AccordionTrigger>
              <AccordionContent className={activeAccordion === index ? 'block' : 'hidden'}>
                <p className="text-gray-600">{faq.text}</p>
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </section>

      {showModal && (
        <PayModal
          content={userSubscription.membership_type}
          onClose={() => setShowModal(false)}
          date={userSubscription.createdAt}
        />
      )}
    </div>
  );
};

export default Pricing;
