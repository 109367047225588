import React from 'react';
import { BarChart, DonutChart, LineChart, PieChart, DonutGaugeChart, FunnelChart } from '../Chart/EchartsOneDimension';
import {
  StackBarChart,
  HeatMapChart,
  GroupedBarChart,
  TreeMapChart,
  TextCloudChart,
  CirlePackingChart,
  TimeLine,
  GridBarChart,
  GridLineChart,
  GridDonutChart,
  RadarChart,
  SankeyChart,
  SunburstChart,
  ScatterChart
} from '../Chart/EchartsColumnsMore';
import { Card, Tables } from '../Chart/Cards';
import { FilteredBarChart } from '../Chart/Filtered/FilteredBar';
import { ParliamentDonutChart, CalendarChart } from '../Chart/OthersChart';
import RecordCard from '../RecordCard/RecordCard';
import WebCard from '../RecordCard/WebCard';
import Landscape from '../LandScape';
import FlowDiagram from '../FlowDiagram';
import OrgChart from '../OrgChart';
import SmartAccordion from '../Accordion';
import Flywheel from '../Flywheel/Flywheel';
import Process from '../Process/process';

import ConcentricCirclesChart from '../ConcentricCircle/ConcentricCirclesChart';

import Network from '../Network/Network';
import NetworkGraphRaw from '../Network/NetworkGraphRaw';
import Tree from '../Network/Tree';
import HexagonWall from '../Chart/HexagonWall/HexagonWall';
import FlippableCards from '../Chart/FlippableCards/FlippableCards';
import MonthCalendar from '../Chart/MonthCalendar/MonthCalendar';

import CircularNetwork from '../CircularNetwork/CircularNetwork';

import SmartMap from '../Chart/SmartMap/SmartMap';
const chartComponents = {
  BAR: BarChart,
  DONUT: DonutChart,
  LINE: LineChart,
  PIE: PieChart,
  DONUTGAUAGE: DonutGaugeChart,

  STACKBAR: StackBarChart,
  HEATMAP: HeatMapChart,
  GROUPEDBAR: GroupedBarChart,
  TREEMAP: TreeMapChart,
  TEXTCLOUD: TextCloudChart,
  CirlePacking: CirlePackingChart,
  TIMELINE: TimeLine,
  GRIDBAR: GridBarChart,
  GRIDLINE: GridLineChart,
  GRIDDONUT: GridDonutChart,
  FLATCARD: Card,
  FUNNEL: FunnelChart,
  TABLE: Tables,
  RADAR: RadarChart,
  SANKEY: SankeyChart,
  SUNBURST: SunburstChart,
  FILTEREDBAR: FilteredBarChart,
  SCATTER: ScatterChart,
  ParliamentDonut: ParliamentDonutChart,
  CALENDAR: CalendarChart,
  RECORDCARD: RecordCard,
  LANDSCAPE: Landscape,
  GRAPH: FlowDiagram,
  ORGCHART: OrgChart,
  ACCORDION: SmartAccordion,
  WebCard: WebCard,
  NETWORK_FROM_TABLE: Network,
  GRAPH_RAW: NetworkGraphRaw,
  TREE: Tree,
  FLYWHEEL: Flywheel,
  CONCENTRICCIRCLE: ConcentricCirclesChart,
  PROCESS: Process,
  CircularNetwork: CircularNetwork,
  FlippableCards: FlippableCards,
  HexagonWall: HexagonWall,
  TASKCALENDAR: MonthCalendar,
  SmartMap: SmartMap
};

class ChartErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // 只在控制台输出错误，方便调试
    console.error('Chart Error:', error);
    console.error('Error Info:', errorInfo);

    // 如果有错误回调函数，静默调用它
    if (this.props.onChartError) {
      // 先重置错误状态
      this.setState({ hasError: false }, () => {
        // 然后在回调中更改图表类型
        this.props.onChartError(error, errorInfo);
      });
    }
  }
  render() {
    // 当发生错误时，直接返回 null，不显示任何错误 UI
    if (this.state.hasError) {
      return this.props.fallback || null;
    }
    return this.props.children;
  }
}

const DynamicChart = props => {
  const { rndData, rndNo, chartType, rd, setRndData, onChangeChartType } = props; // 从 props 中解构 onChartError
  const ChartComponent = chartComponents[rndData[rndNo]?.currentType || chartType];

  if (!ChartComponent) {
    return null;
  }
  return (
    <ChartErrorBoundary
      onChartError={(error, errorInfo) => {
        onChangeChartType(rndData[rndNo]?.chartData?.type);
      }}>
      <div onMouseDown={e => e.stopPropagation()} className="w-full h-full cursor-pointer">
        <ChartComponent {...props} />
      </div>
    </ChartErrorBoundary>
  );
};

export { DynamicChart };
