import React from 'react';
import * as Menubar from '@radix-ui/react-menubar';
import { DownloadIcon, ShareIcon, OppositeIcon, CodeIcon, MoreIcon, DeleteIcon, BookMarkIcon,UpIcon, CopyIcon, ToggleChartIcon } from '../../Icons'
import * as Popover from '@radix-ui/react-popover';
import { Tooltip } from '@radix-ui/themes';
import { AlignTopIcon, ChatBubbleIcon, Cross2Icon, PinTopIcon } from '@radix-ui/react-icons';
import { ToggleChart } from './ToggleChart';
import { useDispatch, useSelector } from 'react-redux'
import { setSettingStyleNo } from '../../../store/slices/globalSlice';
import { toast } from 'react-toastify';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';

const FunctionBar = ({
  no,
  chartType,
  onImageClick,
  removeNode,
  upNode,
  showToggleChart,
  visualData,
  setVisible,
  rndData,
  setRndData,
  setDetails,
  scaleOut,
  autoReduceWidth,
  autoIncreaseHeight,
  autoReduceHeight,
  showComment,
  setAlignTop
}) => {

  const smartdoc = useSelector((state) => state.smart.smartDoc)
  const dispatch = useDispatch()

  const handleDownload=()=>{
    const loading = toast.loading("Please wait ...")
    let name = "";
    if (rndData[no].chartData.name === undefined) {
        name = "diagram.jpg";
    } else {
        name = rndData[no].chartData.name.replaceAll(" ", "_").toLowerCase() + ".jpg";
    }
    domtoimage
        .toJpeg(document.getElementById("card" + no), { quality: 0.85 })
        .then(function (blob) {
            if (window.saveAs) {
                window.saveAs(blob, name);
            } else {
                saveAs(blob, name);
            }
        })
        .finally(() => {
           toast.dismiss(loading)
        });

  }
  return (
    <Menubar.Root className="flex bg-[#595959] p-[0px] rounded-md relative z-[25] scale-[0.9]">
      {chartType === 'image' &&
        <Menubar.Menu>
          <Menubar.Trigger onClick={() => setAlignTop(no)} className="py-1 px-1 outline-none select-none font-medium leading-none rounded text-violet11 text-[13px] flex items-center justify-between gap-[2px] data-[highlighted]:bg-violet4 data-[state=open]:bg-violet4">
            <PinTopIcon color="#fff" />
          </Menubar.Trigger>
        </Menubar.Menu>}


      {smartdoc.id &&
        <Menubar.Menu>
          <Menubar.Trigger onClick={showComment} className="py-1 px-1 outline-none select-none font-medium leading-none rounded text-violet11 text-[13px] flex items-center justify-between gap-[2px] data-[highlighted]:bg-violet4 data-[state=open]:bg-violet4">
            <ChatBubbleIcon color="#fff" />
          </Menubar.Trigger>
        </Menubar.Menu>}

      {/* 切换图表 ToggleChart */}
      <Popover.Root>
        <Tooltip content="Change chart">
          <Popover.Trigger asChild>
            <button
              disabled={!showToggleChart}
              className="py-1 px-1 outline-none select-none font-medium leading-none rounded text-violet11 text-[13px] flex items-center justify-between gap-[2px] data-[highlighted]:bg-violet4 data-[state=open]:bg-violet4"
              aria-label="Update dimensions"
            >
              <ToggleChartIcon color="#fff" />
            </button>
          </Popover.Trigger>
        </Tooltip>
        <Popover.Portal>
          <Popover.Content
            className="rounded p-3 w-[400px] bg-white shadow-[0_10px_18px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2)] focus:shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2),0_0_0_2px_theme(colors.violet7)] will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
            sideOffset={5}
          >
            <ToggleChart onImageClick={onImageClick} no={no} chartType={chartType} visualData={visualData} rndData={rndData} setRndData={setRndData} setDetails={setDetails}></ToggleChart>
            <Popover.Close
              className="rounded-full h-[25px] w-[25px] inline-flex items-center justify-center text-violet11 absolute top-[5px] right-[5px] hover:bg-violet4  focus:shadow-violet7 outline-none cursor-default focus:outline-none"
              aria-label="Close"
            >
              <Cross2Icon />
            </Popover.Close>
            <Popover.Arrow className="fill-white" />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
   
      {/* 下载 downLoad*/}
      <Menubar.Menu>
        <Tooltip content="Download">
          <Menubar.Trigger 
            onClick={handleDownload} 
            className="py-1 px-1 outline-none select-none font-medium leading-none rounded text-violet11 text-[13px] flex items-center justify-between gap-[2px] data-[highlighted]:bg-violet4 data-[state=open]:bg-violet4">
            <DownloadIcon width={20} height={20} color="#fff" />
          </Menubar.Trigger>
        </Tooltip>
      </Menubar.Menu>

   
      {/* <Menubar.Menu>
       <Tooltip content="Bookmark">
        <Menubar.Trigger className="py-1 px-1 outline-none select-none font-medium leading-none rounded text-violet11 text-[13px] flex items-center justify-between gap-[2px] data-[highlighted]:bg-violet4 data-[state=open]:bg-violet4">
          <BookMarkIcon color="#fff" />
        </Menubar.Trigger>
        </Tooltip>
      </Menubar.Menu> */}

      <Menubar.Menu>
       <Tooltip content="Auto width">
        <Menubar.Trigger onClick={scaleOut} className="py-1 px-1 outline-none select-none font-medium leading-none rounded text-violet11 text-[13px] flex items-center justify-between gap-[2px] data-[highlighted]:bg-violet4 data-[state=open]:bg-violet4">
          <CodeIcon color="#fff" />
        </Menubar.Trigger>
        </Tooltip>
      </Menubar.Menu>
      <Menubar.Menu>
       <Tooltip content="Auto reduce width">
        <Menubar.Trigger onClick={autoReduceWidth} className="py-1 px-1 outline-none select-none font-medium leading-none rounded text-violet11 text-[13px] flex items-center justify-between gap-[2px] data-[highlighted]:bg-violet4 data-[state=open]:bg-violet4">
          <OppositeIcon color="#fff" />
        </Menubar.Trigger>
        </Tooltip>
      </Menubar.Menu>
      <Menubar.Menu>
       <Tooltip content="Auto increase height">
        <Menubar.Trigger onClick={autoIncreaseHeight} className="py-1 px-1 outline-none select-none font-medium leading-none rounded text-violet11 text-[13px] flex items-center justify-between gap-[2px] data-[highlighted]:bg-violet4 data-[state=open]:bg-violet4">
          <CodeIcon color="#fff" transform={true}/>
        </Menubar.Trigger>
        </Tooltip>
      </Menubar.Menu>
      <Menubar.Menu>
       <Tooltip content="Auto reduce height">
        <Menubar.Trigger onClick={autoReduceHeight} className="py-1 px-1 outline-none select-none font-medium leading-none rounded text-violet11 text-[13px] flex items-center justify-between gap-[2px] data-[highlighted]:bg-violet4 data-[state=open]:bg-violet4">
          <OppositeIcon color="#fff" transform={true}/>
        </Menubar.Trigger>
        </Tooltip>
      </Menubar.Menu>
      <Menubar.Menu>
        <Tooltip content="Move above" side="top">
          <Menubar.Trigger 
            onClick={upNode}
            className="py-1 px-1 outline-none select-none font-medium leading-none rounded text-violet11 text-[13px] flex items-center justify-between gap-[2px] data-[highlighted]:bg-violet4 data-[state=open]:bg-violet4">
            <UpIcon color="#fff" />
          </Menubar.Trigger>
        </Tooltip>
      </Menubar.Menu>
      <Menubar.Menu>
        <Tooltip content="Delete">
          <Menubar.Trigger
            onClick={removeNode}
            className="py-1 px-1 outline-none select-none font-medium leading-none rounded text-violet11 text-[13px] flex items-center justify-between gap-[2px] data-[highlighted]:bg-violet4 data-[state=open]:bg-violet4">
            <DeleteIcon color="#fff" />
          </Menubar.Trigger>
        </Tooltip>
      </Menubar.Menu>

      <Menubar.Menu>
        <Menubar.Trigger
          onClick={() => { setVisible(true); dispatch(setSettingStyleNo(no)); }}
          className="py-1 px-1 outline-none select-none font-medium leading-none rounded text-violet11 text-[13px] flex items-center justify-between gap-[2px] data-[highlighted]:bg-violet4 data-[state=open]:bg-violet4">
          <MoreIcon color="#fff" />
        </Menubar.Trigger>
      </Menubar.Menu>
    </Menubar.Root>
  );
};

export { FunctionBar };