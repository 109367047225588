import { useSelector, useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import { setShowSidebar, setLanguage, setTheme } from '../store/slices/globalSlice'

function Layout() {
  const showSidebar = useSelector((state) => state.global.showSidebar)
  const language = useSelector((state) => state.global.language)
  const userData = useSelector(state => state.auth.userData)
  const theme = useSelector((state) => state.global.theme)
  const shareType = useSelector((state) => state.smart.shareType)

  const dispatch = useDispatch()
  const isPublic = !userData?.id && shareType === 'public'

  return (
    <>
      <div>
        <Sidebar showSidebar={showSidebar} />
        {!isPublic &&
          <Navbar
            language={language}
            showSidebar={showSidebar}
            theme={theme}
            setShowSidebar={
              (isShow) => dispatch(setShowSidebar(isShow))
            }
            setLanguage={(lang) => dispatch(setLanguage(lang))}
            setTheme={(theme) => dispatch(setTheme(theme))}
          />}
      </div>
      <main>
        {<Outlet />}
      </main>
    </>
  )
}

export default Layout;