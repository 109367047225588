import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import moment from "moment";
import OutsideClickHandler from 'react-outside-click-handler';
import { CheckIcon, CaretDownIcon, Cross1Icon, Cross2Icon, DashboardIcon, DotsVerticalIcon, EyeOpenIcon, HamburgerMenuIcon, MagnifyingGlassIcon, PlusIcon, ResetIcon } from "@radix-ui/react-icons";

import { DeleteIcon, ExternalIcon, PenIcon } from '../components/Icons'
import Input from "../components/Input";

const DropDown = (props) => {
  const dropDownref = useRef(null)

  const { ind, val, navigate, deleteDoc, setEditableNo, showDropdown, setShowDropdown } = props
  return (
    <div className="relative" ref={dropDownref}>
      <button onClick={() => setShowDropdown(ind)}>
        <DotsVerticalIcon color="#9CA3AF" width={20} height={20} />
      </button>
      {showDropdown === ind &&
        <OutsideClickHandler
          onOutsideClick={() => {
            setShowDropdown(-1)
          }}
        >
          <div className="absolute right-0 top-[101%] w-40 bg-white shadow-lg rounded-lg py-1 z-[2]">
            <div
              onClick={() => navigate(`/smartdoc/${val.id}`)}
              className="flex gap-2 text-xs w-40 px-2 py-[6px] hover:bg-[#f3f4f6] rounded cursor-pointer items-center">
              <ExternalIcon width={17} height={17} color="#6B7280" />
              <p>Open</p>
            </div>
            <div
              onClick={() => {
                setEditableNo(ind)
                setShowDropdown(false)
                setTimeout(() => {
                  const ele = document.getElementById(`smart_doc_${ind}`)
                  ele?.focus()
                }, 10)
              }}
              className="flex gap-2 text-xs w-40 px-2 py-[6px] hover:bg-[#f3f4f6] rounded cursor-pointer items-center">
              <PenIcon width={17} height={17} color="#6B7280" />
              <p>Rename</p>
            </div>
            <div
              onClick={() => { setShowDropdown(false); deleteDoc(val.id) }}
              className="flex gap-2 text-xs w-40 px-2 py-[6px] hover:bg-[#f3f4f6] rounded cursor-pointer items-center">
              <DeleteIcon width={17} height={17} color="#6B7280" />
              <p>Delete</p>
            </div>
          </div>
        </OutsideClickHandler>
      }
    </div>
  )
}

const CreateDocModal = ({ docName, setDocName, onClose, createDoc }) =>
  <div className="w-full h-screen absolute left-0 top-0 bg-[#11111199] z-20 flex justify-center items-center p-4" onClick={onClose}>
    <div className="max-w-[100%] md:w-[600px] sm:w-[450px] rounded-xl bg-white h-fit" onClick={(e) => e.stopPropagation()}>
      <div className="flex justify-between gap-4 px-10 py-8 items-center border-b">
        <div className="flex gap-3 items-center font-semibold">
          <PlusIcon color="#0694a3" width={20} height={20} />
          <p>New SmartDoc</p>
        </div>
        <button className="" onClick={onClose}>
          <Cross1Icon color="#555" />
        </button>
      </div>
      <div className="px-10 py-5 border-b">
        <Input
          className="my-3 text-sm"
          value={docName}
          setValue={setDocName}
          placeholder='SmartDoc Name'
          label=''
          type='text'
        />
      </div>
      <div className="px-10 py-4">
        <button
          onClick={createDoc}
          className="w-28 bg-primary rounded-lg py-3 text-center text-white text-sm">
          Add
        </button>
      </div>
    </div>
  </div>


const checkImageExists = (imageSrc) => {
  if (!imageSrc) return false
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = imageSrc;
  });
};

function MyDoc() {
  const navigate = useNavigate()
  const showSidebar = useSelector((state) => state.global.showSidebar)
  const [docs, setDocs] = useState([])
  const [tab, setTab] = useState('year')
  const [searchValue, setSearchValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [docName, setDocName] = useState('')

  const [showCreateModal, setShowCreateModal] = useState(false)

  const [showFilters, setShowFilters] = useState(false)
  const [filterValue, setFilerValue] = useState('Alphabetical')
  const [viewMode, setViewMode] = useState(1)

  const [editableNo, setEditableNo] = useState(-1)
  const [showDropdown, setShowDropdown] = useState(-1)

  const getAllSmartdoc = useCallback(async () => {
    const year = new Date().getFullYear()
    const firstDate = tab === 'year' ? `${year}-01-01` : ''
    const lastDate = tab === 'year' ? `${year}-12-31` : ''
    const shared = tab === 'shared' ? '1' : '0'
    setIsLoading(true)
    try {
      const res = await axios.get(`smartdoc/all?from=${firstDate}&to=${lastDate}&shared=${shared}`)
      setIsLoading(false)
      if (!res.data) return null
      let data = []
      for (let i = 0; i < res.data.length; i++) {
        const dt = res.data[i]
        const doc = {
          ...dt,
          description: '',
          title: dt.data.name,
          background: '',
        }

        for (let j = 0; j < dt.data.data.length; j++) {
          const sm = dt.data.data[j]
          if (sm.chartData.type === 'image') {
            const imageExist = await checkImageExists(sm.chartData.src)
            doc.background = !imageExist ? '/imgs/placeholder-image.png' : sm.chartData.src
          }
        }
        data.push(doc)
      }

      const filteredDocs = onFilterValueChange('Alphabetical', data)
      if (filteredDocs) setDocs(filteredDocs)
    } catch (e) {
      setIsLoading(false)
      toast.error(e.message)
    }
  }, [tab])

  const createDoc = async () => {
    if (!docName) return toast.warn('Please type smartdoc name.')
    const loading = toast.loading("Please wait ...")
    try {
      const res = await axios.post('smartdoc/create', {
        data: {
          name: docName,
          data: []
        }
      })
      toast.success('Your new doc has been created successfully.')
      toast.dismiss(loading)
      const { data } = res
      data.id && navigate(`/smartdoc/${data.id}`)
    } catch (e) {
      const errorMessage = e?.response?.data?.message || e?.message
      toast.dismiss(loading)
      toast.error(errorMessage)
    }
  }

  const deleteDoc = async (id) => {
    if (!id) return
    try {
      await axios.delete(`smartdoc/${id}`)
      getAllSmartdoc()
      toast.success('One doc has been deleted.')
    } catch (e) {
      toast.error(e.message)
    }
  }

  const titleChangeHandler = (e, val, ind) => {
    let data = val.data
    data.name = e.target.value
    docs[ind] = { ...docs[ind], data: data }
    setDocs([...docs])
  }

  const onBlurHandler = async (val) => {
    setEditableNo(-1)
    if (!val.id || !val.data.name) return
    await axios.patch(`smartdoc/save/${val.id}`, {
      data: { data: val.data.data, name: val.data.name }
    })
  }

  const filteredResults = docs ? docs.filter(val => (val.data.name.toUpperCase().includes(searchValue.toUpperCase()))) : []

  const Loader = () => (
    <div className="flex w-full justify-center">
      <span className="comment-loader"></span>
    </div>
  )

  function generateRandomGradient(uuid) {
    // Define possible gradient directions
    const directions = ['to right', 'to left', 'to bottom', 'to top', 'to bottom right', 'to bottom left', 'to top right', 'to top left'];

    // Function to generate color from UUID
    function getColorFromUUID(uuid, start, end) {
      // Extract a part of the UUID and pad if necessary to ensure 6 characters
      let part = uuid.replace(/-/g, '').substring(start, end).padEnd(6, '0');
      // Ensure the string length is 6 by slicing or padding, then construct the color
      return `#${part.substring(0, 6)}`;
    }

    // Select random direction
    const direction = directions[Math.floor(Math.random() * directions.length)];

    // Generate two colors from the UUID
    const color1 = getColorFromUUID(uuid, 0, 6); // Use the first 6 non-hyphen characters for color1
    const color2 = getColorFromUUID(uuid, 6, 12); // Use the next 6 for color2

    // Construct the CSS gradient string
    return `linear-gradient(to bottom right, ${color1}, ${color2})`;
  }

  const onFilterValueChange = (value, data = []) => {
    setFilerValue(value)
    let filteredDocs = data
    if (value === 'Alphabetical') {
      filteredDocs = data.sort((a, b) => a.data.name.localeCompare(b.data.name));
    }
    if (value === 'Older First') {
      filteredDocs = data.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
    }
    if (value === 'Newest First') {
      filteredDocs = data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    }

    return filteredDocs
  }

  useEffect(() => {
    getAllSmartdoc()
  }, [tab, getAllSmartdoc])

  return (
    <div className={`w-full h-screen min-h-[783px] pt-12 
    ${showSidebar
        ? 'lg:pl-[414px] md:pl-[326px] sm:pl-[288px] pl-12'
        : 'lg:pl-40 md:pl-20 sm:pl-8'} lg:pr-40 md:pr-20 sm:pr-8 relative`}>
      {showCreateModal &&
        <CreateDocModal
          createDoc={createDoc}
          setDocName={setDocName}
          docName={docName}
          onClose={() => setShowCreateModal(false)}
        />}

      <div className="w-full sm:px-12 py-2 px-6">
        <h1 className="text-2xl font-medium">Manage SmartDocs</h1>
        <button
          onClick={() => setShowCreateModal(true)}
          className="bg-primary text-white p-3 py-2 rounded-lg mt-2 text-sm flex gap-2 items-center">
          <PlusIcon />
          New SmartDoc
        </button>

        <div className="w-full border-b md:flex justify-between">
          <div className="flex">
            <div className={`${tab === 'year' && 'border-b border-primary text-primary'} text-sm p-3 w-fit cursor-pointer`} onClick={() => setTab('year')}>This Year</div>
            <div className={`${tab === 'all' && 'border-b border-primary text-primary'} text-sm p-3 w-fit cursor-pointer`} onClick={() => setTab('all')}>All</div>
            <div className={`${tab === 'shared' && 'border-b border-primary text-primary'} text-sm p-3 w-fit cursor-pointer`} onClick={() => setTab('shared')}>Shared</div>
          </div>
          <div className="flex pb-2 text-[#4b5563] gap-2 font-medium mt-3 md:mt-0">
            <OutsideClickHandler
              onOutsideClick={() => {
                setShowFilters(false)
              }}
            >
              <div className="text-xs relative">
                <button onClick={() => setShowFilters(!showFilters)} className="bg-[#f3f4f6] rounded-lg flex justify-between gap-1 items-center px-3 py-[10px] w-32">
                  {filterValue}
                  <CaretDownIcon />
                </button>
                {showFilters &&
                  <div onClick={(e) => e.stopPropagation()} className="w-40 py-1 absolute text-[11px] h-20 left-0 top-[40px] z-[2] bg-white h-[200px] shadow rounded-lg">
                    <div className="text-[#9ca3af] px-3 py-2 bg-[#f9fafb]">Sort By</div>
                    <div onClick={() => { onFilterValueChange('Alphabetical', docs); setShowFilters(false) }} className="cursor-pointer px-3 py-2 hover:text-primary hover:bg-[#dceef0]">Alphabetical</div>
                    {/* <div onClick={() => { onFilterValueChange('Last Viewed'); setShowFilters(false) }} className="cursor-pointer px-3 py-2 hover:text-primary hover:bg-[#dceef0]">Last Viewed</div> */}
                    <div className="text-[#9ca3af] px-3 py-2 bg-[#f9fafb]">Order</div>
                    <div onClick={() => { onFilterValueChange('Older First', docs); setShowFilters(false) }} className="cursor-pointer px-3 py-2 hover:text-primary hover:bg-[#dceef0]">Older First</div>
                    <div onClick={() => { onFilterValueChange('Newest First', docs); setShowFilters(false) }} className="cursor-pointer px-3 py-2 hover:text-primary hover:bg-[#dceef0]">Newest First</div>
                  </div>}
              </div>
            </OutsideClickHandler>
            <button onClick={() => setViewMode(1)} className={`${viewMode === 1 ? 'bg-[#f3f4f6]' : 'bg-[#fafafa]'} rounded-lg px-3 py-1 text-xs`}>
              <HamburgerMenuIcon />
            </button>
            <button onClick={() => setViewMode(2)} className={`${viewMode === 2 ? 'bg-[#f3f4f6]' : 'bg-[#fafafa]'} rounded-lg px-3 py-1 text-xs`}>
              <DashboardIcon />
            </button>
          </div>
        </div>
        <div className={`flex gap-3 w-full bg-[#f3f4f6] my-3 rounded-lg py-3 px-4 items-center border relative ${isActive ? 'border-primary' : ''}`}>
          <MagnifyingGlassIcon color="#0694A3" width={20} height={20} />
          <input
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onFocus={() => setIsActive(true)}
            onBlur={() => setIsActive(false)}
            placeholder="Quick search for files"
            className="bg-transparent w-full text-grey text-sm outline-none"
          />
          {searchValue &&
            <button className="bg-[#9CA3AF] rounded-full p-[2px]" onClick={() => setSearchValue('')}>
              <Cross2Icon color="white" />
            </button>}
          {
            searchValue &&
            <div className="w-full max-h-[500px] overflow-auto h-fit p-4 shadow-lg rounded-lg absolute top-[102%] left-0 bg-white z-10">
              {filteredResults.length > 0
                ?
                <>
                  <p className="font-bold text-sm">Recent</p>
                  {filteredResults.map((val, ind) => (
                    <div
                      key={ind}
                      className="p-2 flex justify-between my-1 bg-[#f9fafb] rounded-lg items-center text-[#9CA3AF] font-medium cursor-pointer"
                      onClick={() => navigate(`/smartdoc/${val.id}`)}>
                      <div className="flex gap-2 items-center">
                        <MagnifyingGlassIcon width={15} height={15} />
                        <div className="text-sm text-[#6B7280]">{val.data.name}</div>
                      </div>
                      <div><ResetIcon width={15} height={15} /></div>
                    </div>
                  ))}
                </>
                : <div className="text-sm text-grey">No results</div>
              }
            </div>
          }
        </div>
        <div
          className={`${showSidebar ? 'md:grid-cols-2 xl:grid-cols-3' : 'sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4'} 
        ${viewMode === 2 && 'grid lg:gap-12 sm:gap-6 gap-4'} w-full mt-2`}>
          {
            isLoading
              ?
              <Loader />
              :
              (docs && docs.length > 0)
                ? docs.map((val, ind) => (
                  <div key={ind}>
                    {viewMode === 1 ?
                      <div
                        style={{ boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.08)' }}
                        onClick={() => navigate(`/smartdoc/${val.id}`)}
                        className="max-w-[100%] w-full p-4 text-grey hover:bg-[#F3F4F6] cursor-pointer rounded-lg flex gap-4 justify-between items-center"
                        key={ind}>
                        <div className="flex gap-3 items-center">
                          <div
                            className="h-16 w-20 aspect-square rounded-lg bg-white bg-center bg-cover bg-no-repeat flex justify-center item-center"
                            style={{ backgroundImage: val.background ? `url(${val.background})` : generateRandomGradient(val.id) }}
                          >
                          </div>
                          <div>
                            <input
                              disabled={editableNo !== ind}
                              id={`smart_doc_${ind}`}
                              className='outline-none border-b disabled:border-0 disabled:bg-transparent'
                              value={val.data.name}
                              onChange={(e) => titleChangeHandler(e, val, ind)}
                              onBlur={() => onBlurHandler(val)}
                            />
                            <div className="flex gap-2 text-[#374151]">
                              <div className="text-xs mt-2 bg-[#f3f4f6] rounded-lg p-[6px] px-3">
                                {val.updatedAt && moment(val.updatedAt).fromNow()}
                              </div>
                              <div className="text-xs mt-2 bg-[#f3f4f6] rounded-lg p-[6px] px-3 flex gap-1">
                                <EyeOpenIcon />
                                {val.views}
                                <p>Views</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div onClick={(e) => e.stopPropagation()}>
                          <DropDown
                            ind={ind}
                            val={val}
                            navigate={navigate}
                            deleteDoc={deleteDoc}
                            showDropdown={showDropdown}
                            setEditableNo={setEditableNo}
                            setShowDropdown={setShowDropdown}
                          />
                        </div>
                      </div>
                      :
                      <div
                        className="w-full cursor-pointer"
                        onClick={() => navigate(`/smartdoc/${val.id}`)}
                      >
                        <div className="aspect-[1.4] w-full relative flex justify-center hover:bg-[#ebecef] bg-[#f9fafb] shadow rounded-lg relative">
                          <div
                            className="h-full aspect-[1.1] bg-white bg-center bg-cover bg-no-repeat flex justify-center item-center"
                            style={{ backgroundImage: val.background ? `url(${val.background})` : generateRandomGradient(val.id) }}
                          />
                          <div className="right-1 top-2 absolute" onClick={(e) => e.stopPropagation()}>
                            <DropDown
                              ind={ind}
                              val={val}
                              navigate={navigate}
                              deleteDoc={deleteDoc}
                              showDropdown={showDropdown}
                              setEditableNo={setEditableNo}
                              setShowDropdown={setShowDropdown}
                            />
                          </div>
                        </div>
                        <div className="mt-2">
                          <input
                            disabled={editableNo !== ind}
                            id={`smart_doc_${ind}`}
                            className='outline-none border-b disabled:border-0 disabled:bg-transparent'
                            value={val.data.name}
                            onChange={(e) => titleChangeHandler(e, val, ind)}
                            onBlur={() => onBlurHandler(val)}
                          />
                          <div className="flex gap-2 text-[#374151]">
                            <div className="text-xs mt-2 bg-[#f3f4f6] rounded-lg p-[6px] px-3">
                              {val.updatedAt && moment(val.updatedAt).fromNow()}
                            </div>
                            <div className="text-xs mt-2 bg-[#f3f4f6] rounded-lg p-[6px] px-3 flex gap-1">
                              <EyeOpenIcon />
                              {val.views}
                              <p>Views</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                ))
                : <>No data.</>
          }
        </div>
      </div>
    </div>
  )
}

export default MyDoc;