// import React from 'react';
// import Chart from '../Chart';
// const processDataForDonutGaugeChart = (visualData) => {
//     const visuals = visualData
//     const metrics=visuals.spec.metrics[0].column
//     // const seriesDataMap = parseFloat(Object.values(visuals.data[metrics])).toFixed(2)
//     let seriesDataMap=[]
//     console.log(visuals.data[metrics])
//     visuals.data[metrics].forEach((item) => {
//         seriesDataMap.push({
//             value: parseFloat(item).toFixed(2),
//             name: '',
//         })
//     });

//     return {seriesDataMap}
// };
// const DonutGaugeChart = ({ visualData }) => {
//     const { seriesDataMap } = processDataForDonutGaugeChart(visualData)
//     console.log({visualData})
//     const option = {
//         tooltip: {
//             trigger: "item",
//         },
//         series: [
//             {
//                 name: 'Pressure',
//                 type: 'gauge',
//                 progress: {
//                     show: true
//                 },
//                 detail: {
//                     valueAnimation: true,
//                     formatter: '{value}'
//                 },
//                 data:seriesDataMap
//             }
//         ]
//     };

//     return (
//         <Chart option={option} />
//     );
// };

// export { DonutGaugeChart };

import React from 'react';
import Chart from '../Chart';

const processDataForDonutGaugeChart = (visualData) => {
    const visuals = visualData;
    const metrics = visuals.spec.metrics[0].column;
    const seriesDataMap = visuals.data[metrics].map(value => parseFloat(value).toFixed(2));
    
    return {
        seriesDataMap
    };
};

const DonutGaugeChart = ({ visualData,rd }) => {
    const { seriesDataMap } = processDataForDonutGaugeChart(visualData);
    
    return (
        <div style={{ display: 'flex',flexFlow: 'wrap' }}>
            {seriesDataMap.map((value, index) => (
                <div style={{ width:'250px',height:'200px' }}>
                    <Chart
                        key={index}
                        rd={rd}
                        option={{
                            tooltip: {
                                trigger: "item",
                            },
                            series: [
                                {
                                    name: `Pressure ${index + 1}`,
                                    type: 'gauge',
                                    progress: {
                                        show: true
                                    },
                                    detail: {
                                        valueAnimation: true,
                                        formatter: '{value}'
                                    },
                                    data: [
                                        {
                                            value,
                                            name: ''
                                        }
                                    ]
                                }
                            ]
                        }}
                    />
                </div>
            ))}
        </div>
    );
};

export { DonutGaugeChart };
