import { useCallback, useEffect, useRef, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { DropdownMenu } from "@radix-ui/themes";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import autosize from "autosize";
import moment from "moment";
import axios from "axios";
import {
  ArrowUpIcon,
  CheckIcon,
  ChevronDownIcon,
  Cross2Icon,
  DotsHorizontalIcon,
  DoubleArrowUpIcon,
  FileIcon
} from "@radix-ui/react-icons";

import { FileAttachmentIcon } from "../Icons/FileAttachmentIcon";
import { isImgLink } from '../../utils'
import { setUnreadComments } from "../../store/slices/smartdocSlice";

export const AddComment = ({ leaveComment, isReply, origin_msg, no }) => {
  const [isFocused, setIsFocused] = useState(false)
  const [comment, setComment] = useState('')
  const [files, setFiles] = useState([])

  const textRef = useRef(null)

  const fileChangeHandler = (e) => {
    const file = e.target.files[0]
    if (!file) return
    if (file.type.includes('image')) {
      files.push({ file: file, url: URL.createObjectURL(file) })
    } else {
      files.push({ file: file, url: null })
    }
    setFiles([...files])
  }

  const onSubmit = async () => {
    const isDevMode = process.env.NODE_ENV === 'development'
    const staticUrl = isDevMode
      ? process.env.REACT_APP_STORAGE_BASE_URL
      : process.env.REACT_APP_PROD_STORAGE_BASE_URL
    const headers = {
      "Content-Type": "multipart/form-data",
    }

    let uploadedFiles = []
    setTimeout(() => setComment(''), 0)

    if (files && files.length > 0) {
      const loading = toast.loading('Please wait a moment...')
      try {
        const formData = new FormData()
        for (let i = 0; i < files.length; i++) {
          formData.append(`file-${i}`, files[i].file)
        }
        const response = await axios.post(`file/upload?static-url=${staticUrl}`, formData, { headers })
        uploadedFiles = response.data
        toast.dismiss(loading)
      } catch (e) {
        toast.dismiss(loading)
        toast.error(e?.response?.data?.message || e.message)
      }
    }

    setFiles([])
    await leaveComment({ isReply, origin_msg, no, comment, uploadedFiles })
  }

  useEffect(() => {
    autosize(textRef.current)
  }, [textRef])

  const isDisabled = (!comment && files.length < 1)

  return (
    <div className="mt-3">
      <div className={`w-full ${files.length > 0 ? 'h-[70px]' : 'h-0'} overflow-hidden overflow-x-auto`}>
        <div className="w-fit flex items-end gap-2 h-full">
          {
            files.map((file, ind) => (
              <div key={ind} className="relative pt-4 group">
                <button
                  onClick={() => {
                    files.splice(ind, 1)
                    setFiles([...files])
                  }}
                  className="absolute right-0 top-0 border rounded-full p-[1px] group-hover:block hidden">
                  <Cross2Icon width={12} height={12} color="#333" />
                </button>
                {file.url ?
                  <div style={{ backgroundImage: `url(${file.url})` }} className="w-12 h-12 rounded object-cover bg-cover bg-center" />
                  :
                  <div className="flex gap-2 rounded max-w-[120px] w-fit border items-center text-xs">
                    <div className="bg-[#eee] p-1">
                      <FileIcon />
                    </div>
                    <div className="text-overflow-1 px-1" key={ind}>{file.file.name}</div>
                  </div>
                }
              </div>
            ))
          }
        </div>
      </div>

      <OutsideClickHandler
        onOutsideClick={() => {
          setIsFocused(false)
        }}
      >
        <div
          className={`w-full border ${isFocused ? 'bg-white border-primary' : 'border-[#ddd]'} rounded-lg bg-transparent p-3 h-fit mt-2 hide-scroll`}
          onFocus={() => setIsFocused(true)}
        >
          <textarea
            rows={1}
            ref={textRef}
            value={comment}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault()
                onSubmit()
              }
            }}
            onChange={(e) => setComment(e.target.value)}
            className="rounded outline-none text-xs w-full resize-none h-full bg-transparent"
            placeholder={isReply ? "Reply..." : "Add a comment..."}
          />
          <div className="flex justify-end">
            {(!isReply || (isReply && isFocused)) &&
              <div className="flex gap-2 items-center">
                <div className="w-[14px] h-[14px] relative">
                  <input
                    type="file"
                    className="w-full absolute opacity-0 h-full left-[-3px] z-[2]"
                    onChange={(e) => { fileChangeHandler(e); e.target.value = null }}
                  />
                  <FileAttachmentIcon />
                </div>
                <button
                  onClick={onSubmit}
                  disabled={isDisabled}
                  className={`${!isDisabled ? 'bg-secondary' : 'bg-[#aaa] cursor-default'} rounded-full p-[2px]`}>
                  <ArrowUpIcon color="white" />
                </button>
              </div>
            }
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  )
}

function CommentDrawer({ rndNo, setShowDrawer, rndData, smartdoc, userData }) {
  const dispatch = useDispatch()
  const [visId, setVisId] = useState('')
  const [loading, setLoading] = useState(false)
  const [comments, setComments] = useState([])
  const unreadComments = useSelector((state) => state.smart.unreadComments)

  const drawerStyle = {
    height: 'calc(100% - 40px)',
    minHeight: 782,
    position: 'fixed',
    top: 40,
    zIndex: '10'
  };

  const updateComment = async (new_comment = null, comment_id) => {
    if (!comment_id || !new_comment) return
    return await axios.patch(`comment/${comment_id}`, new_comment)
  }

  const leaveComment = async ({ isReply = false, origin_msg = {}, no, comment, uploadedFiles }) => {
    if (!visId || !smartdoc.id) return
    if (!comment && !uploadedFiles) return

    const commentData = {
      timestamp: new Date().getTime(),
      sender: userData,
      content: comment,
      reads: [userData.id],
      replies: []
    }

    const new_data = {
      visualCardId: visId,
      content: commentData,
      files: uploadedFiles,
      emails: [],
    }

    try {
      if (isReply) {
        if (!origin_msg.id) return
        let content = { ...origin_msg.content }
        if (content.replies) {
          content.replies.push(new_data)
        } else {
          content.replies = [new_data]
        }
        content.reads = [userData.id]
        const newData = { ...origin_msg, content: content, isReply: true }
        comments[no] = newData
        setComments([...comments])
        updateComment(newData, origin_msg.id)
      } else {
        const res = await axios.post(`comment/${smartdoc.id}`, new_data)
        comments.push(res.data)
        setComments([...comments])
      }
    } catch (e) {
      toast.error(e.message)
    }
  }

  const deleteComment = async ({ origin_msg = {}, isReply = false, deleting_msg = {}, no }) => {
    const comment_id = origin_msg.id
    if (!comment_id) return
    if (isReply) {
      let content = { ...origin_msg.content }
      if (content.replies) {
        content.replies = content.replies.filter((val) => val.content.timestamp !== deleting_msg.content?.timestamp)
      } else {
        content.replies = []
      }
      const newData = { ...origin_msg, content: content }
      comments[no] = newData
      setComments([...comments])
      updateComment(newData, origin_msg.id)
    } else {
      const updatedComments = comments.filter(val => val.id !== comment_id)
      setComments(updatedComments)
      return await axios.delete(`comment/${comment_id}`)
    }
  }

  const markAsRead = async ({ origin_msg = {}, no }) => {
    const comment_id = origin_msg.id
    if (!comment_id) return
    let content = { ...origin_msg.content }
    let reads = [...content.reads]
    if (reads) {
      const index = reads.indexOf(userData.id)
      if (index > -1) reads.splice(index, 1)
      else reads.push(userData.id)
    } else {
      reads = [userData.id]
    }

    content = { ...content, reads: reads }
    const newData = { ...origin_msg, content: content }
    await updateComment(newData, origin_msg.id)
    const n_comments = [...comments]
    n_comments[no] = newData
    setComments(n_comments)

    if (!smartdoc.id) return
    const res = await axios.get(`visual/unread/${smartdoc.id}`)
    dispatch(setUnreadComments(res.data))
  }

  const sortByTimestamp = function (a, b) {
    return (a.content?.timestamp - b.content?.timestamp || true)
  }

  const getVisiualData = useCallback(async () => {
    if (!visId) return
    try {
      setLoading(true)
      const res = await axios.get(`visual/${visId}`)
      const comments = res.data.comments || []
      setComments(comments.sort(sortByTimestamp))
      setLoading(false)
    } catch (e) {
      setLoading(false)
      toast.error(e.message)
    }
  }, [visId])

  const isNew = (timestamp = '') => {
    if (!timestamp) return false
    const oneDayAgo = moment().subtract(1, 21, 'days');
    return moment(timestamp).isSameOrAfter(oneDayAgo)
  }

  const getName = (email = ``) => email.split('@')[0]

  useEffect(() => {
    if (rndData[rndNo]) {
      setVisId(rndData[rndNo].uuid)
    } else if (rndNo === 99999) {
      setVisId(null)
      setComments(unreadComments)
    }
  }, [rndData, rndNo])

  useEffect(() => {
    visId && getVisiualData()
  }, [visId, getVisiualData])

  const Loader = () => (
    <div className="flex w-full justify-center">
      <span className="comment-loader"></span>
    </div>
  )

  const CommentLayout = ({
    timestamp = '', email = '', content = '', isReply, isRead,
    deleteComment, origin_msg, deleting_msg, no, markAsRead, files = []
  }) => {
    return (
      <div className="my-3">
        <div className="flex justify-between items-center">
          <div className="flex gap-2 items-center py-1 pt-2">
            <div className="bg-grey rounded-full text-white text-xs p-1 w-fit h-6 text-center uppercase">
              {email.substring(0, 2)}
            </div>
            {isNew(timestamp) && <div className="text-xs text-primary bg-[#0694A31F] rounded px-3 py-[3px]">New</div>}
          </div>
          <div className="flex gap-2 items-center">
            <DropdownMenu.Root>
              <DropdownMenu.Trigger>
                <button>
                  <DotsHorizontalIcon />
                </button>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content size="1">
                <DropdownMenu.Item style={{ padding: 0 }} onClick={() => deleteComment({ origin_msg, isReply, deleting_msg, no })}>
                  <div className="w-full h-full hover:bg-red-500 text-grey hover:text-white rounded px-3 py-1">Delete</div>
                </DropdownMenu.Item>
              </DropdownMenu.Content>
            </DropdownMenu.Root>

            {!isReply &&
              <button
                onClick={() => markAsRead({ origin_msg, no })}
                className={`${isRead ? "border-primary bg-primary text-white" : "border-[#6B7280] "} border rounded-full p-[1px]`}>
                <CheckIcon width={14} height={14} />
              </button>
            }
          </div>
        </div>
        <div className="flex gap-2 mt-1">
          <p className="text-black">
            {email ? getName(email) : 'No name'}
          </p>
          <p className="text-[#aaa]">
            {timestamp ? moment(timestamp).fromNow() : ''}
          </p>
        </div>
        <div className="font-normal text-[#626262] mt-1 h-fit">
          {content}
        </div>
        <div className={`w-full ${files.length > 0 ? 'h-[54px]' : 'h-0'} overflow-hidden overflow-x-auto`}>
          <div className="w-fit flex items-end gap-2 h-full">
            {
              files.map((file, ind) => (
                <div
                  key={ind}
                  className="relative group cursor-pointer"
                  onDoubleClick={() => {
                    const link = document.createElement("a");
                    link.setAttribute('download', file.name);
                    link.setAttribute('target', "_blank");
                    link.href = file.url;
                    link.click();
                  }}
                >
                  {isImgLink(file.url) ?
                    <div
                      style={{ backgroundImage: `url('${file.url}')` }}
                      className="bg-grey w-12 h-12 rounded object-cover bg-cover bg-center" />
                    :
                    <div className="flex gap-2 rounded max-w-[120px] w-fit border items-center text-xs">
                      <div className="bg-[#eee] p-1">
                        <FileIcon />
                      </div>
                      <div className="text-overflow-1 px-1" key={ind}>{file.originalname}</div>
                    </div>
                  }
                </div>
              ))
            }
          </div>
        </div>
      </div>
    )
  }

  const isUnreadDrawer = rndNo === 99999

  return (
    <div
      style={drawerStyle}
      className={`sm:w-[400px] w-[320px] ${rndNo > -1 ? 'opacity-1 right-0' : 'opacity-0 right-[-100%] pointer-events-none'} 
      px-3 h-screen text-[#6B7280] transition-all bg-white shadow-xl shadow-[#ccc] overflow-y-auto overflow-hidden`}>
      <div className="flex items-center justify-between py-2 border-b">
        <div className="text-base tracking-tight font-medium">{isUnreadDrawer ? 'Unread Comments' : 'Comments'}</div>
        <button onClick={() => setShowDrawer(-1)}>
          <img src="/settingImages/x.png" alt="" width='16px' />
        </button>
      </div>
      <div className="h-[calc(100%-50px)] min-h-[700px] overflow-y-auto hide-scroll">
        {loading ?
          <Loader />
          : <>
            {(comments && comments.length > 0) ?
              comments.map((val, ind) => (
                <div key={ind} className={`px-2 pb-3 border-b text-sm 
                ${val?.content?.reads?.includes(userData.id) ? 'bg-[#efefef]' : ''} rounded`}>
                  <CommentLayout
                    files={val?.files}
                    content={val?.content?.content}
                    timestamp={val.content?.timestamp}
                    email={val?.content?.sender?.emails[0]}
                    deleteComment={deleteComment}
                    origin_msg={val}
                    markAsRead={markAsRead}
                    isRead={val?.content?.reads?.includes(userData.id) || null}
                    no={ind}
                  />

                  {(val?.content?.replies && val?.content?.replies.length > 0) &&
                    <>
                      {val.showReplies ?
                        <>
                          {val?.content?.replies.sort(sortByTimestamp).map((rep, id) => (
                            <CommentLayout
                              files={rep?.files}
                              isReply={true}
                              content={rep?.content?.content}
                              timestamp={rep.content?.timestamp}
                              email={rep?.content?.sender?.emails[0]}
                              deleteComment={deleteComment}
                              origin_msg={val}
                              deleting_msg={rep}
                              key={id}
                              no={ind}
                            />
                          ))}
                          <button className="text-primary flex gap-1 items-center"
                            onClick={() => {
                              comments[ind].showReplies = false
                              setComments([...comments])
                            }}
                          >
                            <DoubleArrowUpIcon />
                          </button>
                        </>
                        :
                        <button className="text-primary flex gap-1 items-center"
                          onClick={() => {
                            const n_comments = [...comments]
                            n_comments[ind] = { ...n_comments[ind], showReplies: true }
                            setComments(n_comments)
                          }}
                        >
                          <ChevronDownIcon />
                          {val?.content?.replies.length} Replies
                        </button>
                      }
                    </>}
                  <AddComment
                    leaveComment={leaveComment}
                    isReply={true}
                    origin_msg={val}
                    no={ind}
                  />
                </div>
              ))
              :
              <>
                {isUnreadDrawer && <div className="text-sm p-1 mt-1 text-[#aaa]">No unread comments.</div>}
              </>
            }
            {!isUnreadDrawer && <AddComment leaveComment={leaveComment} />}
          </>
        }
      </div>
    </div>
  )
}

export default CommentDrawer;