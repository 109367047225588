import { createSlice } from '@reduxjs/toolkit'

export const globalSlice = createSlice({
  name: 'global',
  initialState: {
    showSidebar: false,
    language: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',
    theme: localStorage.getItem('color-theme') ? localStorage.getItem('color-theme') : 'light',
    isSmallText: localStorage.getItem('small_text') === 'true' || false,
    isFullWidth: localStorage.getItem('full_width') === 'true' || false,
    isCanvasView: localStorage.getItem('canvas_view') === 'true' || false,
    settingStyleNo: 0,
  },
  reducers: {
    setShowSidebar: (state, action) => {
      state.showSidebar = action.payload
    },
    setFullWidth: (state, action) => {
      state.isFullWidth = action.payload
      localStorage.setItem('full_width', action.payload);
    },
    setIsSmallText: (state, action) => {
      state.isSmallText = action.payload
      localStorage.setItem('small_text', action.payload);
    },
    setLanguage: (state, action) => {
      state.language = action.payload
      localStorage.setItem('lang', action.payload)
    },
    setCanvasView: (state, action) => {
      state.isCanvasView = action.payload
      localStorage.setItem('canvas_view', action.payload)
    },
    setTheme: (state, action) => {
      state.theme = action.payload
      if (action.payload === 'light') {
        document.documentElement.classList.remove('dark');
        localStorage.setItem('color-theme', 'light');
      } else {
        document.documentElement.classList.add('dark');
        localStorage.setItem('color-theme', 'dark');
      }
    },
    setSettingStyleNo: (state, action) => {
      state.settingStyleNo = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setShowSidebar,
  setLanguage,
  setTheme,
  setSettingStyle,
  setSettingStyleNo,
  setFullWidth,
  setIsSmallText,
  setSettingChartData,
  setCanvasView } = globalSlice.actions


export default globalSlice.reducer