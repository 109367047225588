import { useEffect, useState } from "react";
import { AccordionItem } from "./AccordionItem"

export default function SmartAccordion(props) {
  const { visualData } = props
  const [data, setData] = useState([])
  const [active, setActive] = useState(null)
  const [cardSpec, setCardSpec] = useState([])

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  }

  const getArrayFromObj = (data) => {
    if (!data) return []

    const propertyNames = Object.keys(data);

    const result = data[propertyNames[0]].map((_, index) => {
      const newObj = {};
      propertyNames.forEach(propertyName => {
        newObj[propertyName] = data[propertyName][index];
      });
      return newObj;
    });

    return result
  };

  const getTitle = (item) => {
    let title = ''
    if (!item) return title

    Object.keys(item).map((key, ind) => {
      cardSpec.map((spec, no) => {
        if (spec.column && spec.column === key && spec.is_primary && spec.is_front) {
          title = item[key]
        }
        return null
      })
      return null
    })

    return title
  }

  const getContent = (item) => {
    let content = ''
    if (!item) return content

    Object.keys(item).map((key, ind) => {
      cardSpec.map((spec, no) => {
        if (spec.column && spec.column === key && !spec.is_primary && spec.is_front) {
          content = item[key]
        }
        return null
      })
      return null
    })

    return content
  }

  const getItem = (val, no) => {
    return { header: getTitle(val), id: no, text: getContent(val) }
  }

  useEffect(() => {
    if (visualData) {
      const accData = visualData?.data
      const specData = visualData?.spec || {}
      setCardSpec(getCardSpec(visualData))
      if (!accData) return
      const accAry = getArrayFromObj(accData)
      setData(accAry)
    }
  }, [visualData])
  const getCardSpec = (visualData) => {
    if (visualData.spec.cardspec.length > 0) {
        return visualData.spec.cardspec;
    } else {
        let cardspec = [];
        if (visualData.spec.dimensions.length > 0) {
            visualData.spec.dimensions.forEach(dimension => {
                cardspec.push({
                    column: dimension.column,
                    is_primary: true,
                    is_image: false,
                    is_boolean: false,
                    is_badge: false,
                    is_front: true,
                });
            });
        }
        if (visualData.spec.metrics.length > 0) {
            visualData.spec.metrics.forEach(metric => {
                cardspec.push({
                    column: metric.column,
                    is_primary: false,
                    is_image: false,
                    is_boolean: false,
                    is_badge: false,
                    is_front: true,
                });
            });
        }
        return cardspec;
    }
  };
  return (
    <div className="w-full h-full pt-2">
      {data.map((val, ind) => (
        <AccordionItem key={ind} handleToggle={handleToggle} active={active} item={getItem(val, ind)} cardspec={cardSpec} val={val} />
      ))}
    </div>
  )
}