export function isImgLink(url = '') {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
    const extension = url.split('.').pop().toLowerCase();
    return imageExtensions.includes(extension);
}

export function imageExists(imageUrl) {
    if (!imageUrl) return false
    const img = new Image();
    img.src = imageUrl;
    return img.naturalWidth !== 0 || img.width !== 0;
}

export function formatDate(date) {
    const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const day = date.getDate().toString().padStart(2, '0');
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    
    return `${day}-${month}-${year}`;
}