import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import CustomTable from "../CustomTable";
import Network from "../Network/Network";

import RecordCard from "../RecordCard/RecordCard";
import { DynamicChart } from "../VisualCardWidge/DynamicChart";
import LandScape from "../LandScape";
import OrgChart from "../OrgChart";
import FlowDiagram from "../FlowDiagram";

const chartTypes = [
  "LANDSCAPE",
  "GRAPH",
  "ORGCHART",
  "mindmap",
  "NETWORK_FROM_TABLE",
  "table",
  "RECORDCARD",
  "CAROUSEL",
];

const TextBox = ({
  fontSize,
  fontWeight,
  rndData,
  setRndData,
  no,
  settingStyle = {},
  dataNo,
  data,
  setData,
}) => {
  const isSmallText = useSelector((state) => state.global.isSmallText);
  const font_size = isSmallText ? fontSize - 4 : fontSize;
  const textRef = useRef(null);
  const handleInput = useCallback(
    (e) => {
      if (textRef.current && data[dataNo]) {
        const nData = [...data];
        textRef.current.style.height = "auto";
        const scorllHeight = e.target.scrollHeight;
        textRef.current.style.height = `${scorllHeight}px`;
        nData[dataNo] = {
          ...nData[dataNo],
          size: { ...nData[dataNo].size, height: scorllHeight + 10 },
        };
        setData([...nData]);

        rndData[no] = {
          ...rndData[no],
          chartData: { ...rndData[no].chartData, data: nData },
        };
        setRndData([...rndData]);
        textRef.current.style.height = `100%`;
      }
    },
    [rndData, no, setRndData]
  );

  useEffect(() => {
    // Set initial height after component mounts
    handleInput({ target: { scrollHeight: textRef.current.scrollHeight } });
  }, [font_size]);

  return (
    <div
      className="relative hide-scroll w-full h-full p-1"
      style={{ background: settingStyle.titleBGColor }}
    >
      <textarea
        rows={1}
        ref={textRef}
        style={{
          fontSize: font_size,
          fontWeight: fontWeight,
          lineHeight: "normal",
          color: settingStyle.titleFontColor,
          textAlign: settingStyle.titleAlignment,
        }}
        className="hide-scroll h-full rounded w-full bg-transparent outline-none p-1 resize-none"
        onChange={(e) => {
          let chartData = data[dataNo].chartData;
          chartData = { ...data[dataNo].chartData, query: e.target.value };
          data[dataNo] = { ...data[dataNo], chartData: chartData };
          setData([...data]);

          rndData[no] = {
            ...rndData[no],
            chartData: { ...rndData[no].chartData, data: data },
          };
          setRndData([...rndData]);
        }}
        onInput={handleInput}
        value={data[dataNo].chartData.query}
      ></textarea>
    </div>
  );
};

export default function VcardCarousel(props) {
  const {
    rndNo,
    rndData,
    setRndData,
    chartData,
    setDetails,
    setShowDrawer,
    setStyleSettingNo,
  } = props;
  const [currentItem, setCurrentItem] = useState(0);
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const nextItem = () => {
    setCurrentItem((currentItem + 1) % data.length);
  };

  const prevItem = () => {
    setCurrentItem((currentItem - 1 + data.length) % data.length);
  };

  useEffect(() => {
    chartData.data && setData(chartData.data);
  }, [chartData]);

  useEffect(() => {
    if (rndData[rndNo] && !loaded) {
      rndData[rndNo] = { ...rndData[rndNo], size: { height: 300, width: 350 } };
      setRndData([...rndData]);
      setLoaded(true);
    }
  }, [rndData, rndNo, loaded, setRndData]);

  return (
    <div
      className="w-full h-full relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={`transition-all slider-container h-full`}>
        {isHovered && (
          <div
            onClick={(e) => e.stopPropagation()}
            className="absolute w-full h-full flex justify-between items-center z-10 px-2 pointer-events-none"
          >
            <button
              className="bg-primary rounded-full text-white p-[2px] pointer-events-auto ml-[-3px]"
              onClick={prevItem}
            >
              <ChevronLeftIcon />
            </button>
            <button
              className="bg-primary rounded-full text-white p-[2px] pointer-events-auto mr-[-5px]"
              onClick={nextItem}
            >
              <ChevronRightIcon />
            </button>
          </div>
        )}
        <div
          className="slider mx-auto w-full h-full relative"
          style={{
            transform:
              currentItem !== data.length &&
              `translateX(-${currentItem * 100}%)`,
          }}
        >
          {data.length > 0 &&
            data.map((val, ind) => (
              <div
                key={ind}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className={`slide ${
                  ind === currentItem ? "active" : "pointer-events-none"
                } bg-red-100 h-full w-full`}
              >
                {/* <p className="legend">Legend {val}</p> */}
                <>
                  {val.chartData?.type === "Text" ? (
                    <TextBox
                      no={rndNo}
                      data={data}
                      dataNo={ind}
                      setData={setData}
                      rndData={rndData}
                      setRndData={setRndData}
                      settingStyle={val.style}
                      fontSize={val.chartData?.data?.font_size}
                      fontWeight={val.chartData?.data?.font_weight}
                    />
                  ) : (
                    <>
                      <div
                        className="px-4 py-2 flex-grow overflow-auto h-full"
                        style={{ background: val.style?.cardBGColor }}
                      >
                        {chartTypes.includes(val.chartData.type) ? (
                          <>
                            {/* {val.chartData.type === "table" && (
                              <CustomTable
                                dataNo={ind}
                                rndNo={rndNo}
                                rndData={rndData}
                                setRndData={setRndData}
                                chartData={val.chartData}
                              />
                            )} */}
                            {val.chartData.type === "CAROUSEL" && (
                              <VcardCarousel
                                rndNo={rndNo}
                                rndData={rndData}
                                setRndData={setRndData}
                                chartData={val.chartData}
                              />
                            )}
                            {val.chartData.type === "LANDSCAPE" && (
                              <LandScape
                                rndNo={rndNo}
                                setDetails={setDetails}
                                dummyJson={
                                  val.chartData.data.visualData.data.data || []
                                }
                                dataConfig={
                                  val.chartData.data.visualData.data?.config ||
                                  {}
                                }
                                setStyleSettingNo={setStyleSettingNo}
                                setShowDrawer={setShowDrawer}
                                settings={val.chartData.settings || {}}
                              />
                            )}
                            {val.chartData.type === "GRAPH" && (
                              <FlowDiagram
                                rndNo={rndNo}
                                setDetails={setDetails}
                                setStyleSettingNo={setStyleSettingNo}
                                setShowDrawer={setShowDrawer}
                                {...val.chartData.data.visualData.data}
                              />
                            )}
                            {val.chartData.type === "ORGCHART" && (
                              <OrgChart
                                rndNo={rndNo}
                                setDetails={setDetails}
                                setStyleSettingNo={setStyleSettingNo}
                                setShowDrawer={setShowDrawer}
                                {...val.chartData.data.visualData.data}
                              />
                            )}

                            {val.chartData.type === "NETWORK_FROM_TABLE" && (
                              <Network
                                rndNo={rndNo}
                                setDetails={setDetails}
                                setStyleSettingNo={setStyleSettingNo}
                                setShowDrawer={setShowDrawer}
                                visualData={val.chartData.data.visualData}
                              />
                            )}
                            {val.chartData.type === "RECORDCARD" && (
                              <RecordCard
                                rndNo={rndNo}
                                setDetails={setDetails}
                                setStyleSettingNo={setStyleSettingNo}
                                setShowDrawer={setShowDrawer}
                                visualData={val.chartData.data.visualData}
                              />
                            )}
                          </>
                        ) : (
                          <DynamicChart
                            rndNo={rndNo}
                            chartType={val.chartData.type}
                            visualData={val.chartData.data.visualData}
                            setDetails={setDetails}
                            rndData={rndData}
                            setRndData={setRndData}
                          />
                        )}
                      </div>
                    </>
                  )}
                </>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
