import { useSelector } from "react-redux"
import { ChromePicker } from 'react-color';
import { useState,useEffect, useRef  } from "react";

const ImageUpload = ({ onChange, imageId, defaultImageUrl }) => {
  const [image, setImage] = useState(defaultImageUrl);

  const handleImageChange1 = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        onChange(reader.result, imageId);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div
      className="clickable-image border"
      onClick={() => document.getElementById(`fileInput-${imageId}`).click()}
      style={{
        width: '18px',
        height: '18px',
        // borderRadius:'50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      <input
        type="file"
        id={`fileInput-${imageId}`}
        accept="image/*"
        style={{ display: 'none' }}
        onChange={handleImageChange1}
      />

      {image && <img src={image} alt="Uploaded" style={{ maxWidth: '100%', maxHeight: '100%' }} />}
      {!image && <svg xmlns="http://www.w3.org/2000/svg" height="10" width="10" viewBox="0 0 512 512"><path d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" fill={'#757575'} /></svg>}
    </div>
  );
};


export const CardSetting = (({ rndData, setRndData }) => {
  const settingStyleNo = useSelector((state) => state.global.settingStyleNo)
  const data= rndData[settingStyleNo].changeChartData.styleList.length>0? rndData[settingStyleNo].changeChartData.styleList:rndData[settingStyleNo].changeChartData.data.map(()=>({ color: '#fff', position: 'left',imageUrl:'' }))
  const [styleList, setStyleList] = useState(data);

  const [showPickerNo, setShowPickerNo] = useState(null);

  const handleCustomColorChange = (color,no) => {
    // setShowPickerNo(null);

    setStyleList(prevStyleList => {
      const newStyleList = [...prevStyleList];
      newStyleList[no]= { ...newStyleList[no], color: color.hex };
      return newStyleList;
    });
    rndData[settingStyleNo].changeChartData.styleList=styleList
    rndData=JSON.parse(JSON.stringify(rndData))
    setRndData(rndData)
    
  };
  const colorPickerRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
        setShowPickerNo(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [colorPickerRef]);

  const handleImageChange = (imageUrl, imageId) => {
    setStyleList(prevStyleList => {
      const newStyleList = [...prevStyleList];
      newStyleList[imageId]= { ...newStyleList[imageId], imageUrl: imageUrl };
      return newStyleList;
    });
    rndData[settingStyleNo].changeChartData.styleList=styleList
    rndData=JSON.parse(JSON.stringify(rndData))
    setRndData(rndData)
  };
  const handleButtonClick = (buttonType, no) => {
    setStyleList(prevStyleList => {
      const newStyleList = [...prevStyleList];
      newStyleList[no]= { ...newStyleList[no], position: buttonType };
      return newStyleList;
    });

    rndData[settingStyleNo].changeChartData.styleList=styleList
    rndData=JSON.parse(JSON.stringify(rndData))
    setRndData(rndData)
  };
  const settingChartData = { ...rndData[settingStyleNo]?.changeChartData }
  useEffect(() => {
      settingChartData.styleList = styleList
      rndData[settingStyleNo] = { ...rndData[settingStyleNo], changeChartData: settingChartData }
      setRndData(rndData)
  }, [styleList, settingStyleNo, rndData]);

  return (
    <div ref={colorPickerRef}> 
      {rndData[settingStyleNo].changeChartData.data.map((item, no) => (
        <div key={no} className="flex items-center p-[4px]">
          {/* <div className="w-[50px]">card{no+1}</div>  */}
          <div className="w-[150px] text-black text-opacity-90 text-[12px] font-normal  leading-[18px]">{item.one.value}</div>
          <div className={`ml-[4px] w-[18px] h-[18px] rounded-full cursor-pointer border flex justify-center items-center`}
            style={{ backgroundColor: styleList.length > 0 ? styleList[no].color : 'white' }}
            onClick={() => {
              setShowPickerNo(no)
            }}>
            <svg width="10" height="10"  fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M341.6 29.2L240.1 130.8l-9.4-9.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-9.4-9.4L482.8 170.4c39-39 39-102.2 0-141.1s-102.2-39-141.1 0zM55.4 323.3c-15 15-23.4 35.4-23.4 56.6v42.4L5.4 462.2c-8.5 12.7-6.8 29.6 4 40.4s27.7 12.5 40.4 4L89.7 480h42.4c21.2 0 41.6-8.4 56.6-23.4L309.4 335.9l-45.3-45.3L143.4 411.3c-3 3-7.1 4.7-11.3 4.7H96V379.9c0-4.2 1.7-8.3 4.7-11.3L221.4 247.9l-45.3-45.3L55.4 323.3z"  fill={styleList.length > 0 ? (styleList[no].color !== '#fff' ? 'white' : '#757575') : ''} fillRule="evenodd" clipRule="evenodd"></path></svg>
            {/* <svg width="10" height="10" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.8536 1.14645C11.6583 0.951184 11.3417 0.951184 11.1465 1.14645L3.71455 8.57836C3.62459 8.66832 3.55263 8.77461 3.50251 8.89155L2.04044 12.303C1.9599 12.491 2.00189 12.709 2.14646 12.8536C2.29103 12.9981 2.50905 13.0401 2.69697 12.9596L6.10847 11.4975C6.2254 11.4474 6.3317 11.3754 6.42166 11.2855L13.8536 3.85355C14.0488 3.65829 14.0488 3.34171 13.8536 3.14645L11.8536 1.14645ZM4.42166 9.28547L11.5 2.20711L12.7929 3.5L5.71455 10.5784L4.21924 11.2192L3.78081 10.7808L4.42166 9.28547Z" fill={styleList.length > 0 ? (styleList[no].color !== '#fff' ? 'white' : '#757575') : ''} fillRule="evenodd" clipRule="evenodd"></path></svg> */}
          </div>
          <div className="ml-[12px]">
            <ImageUpload onChange={handleImageChange} imageId={no} defaultImageUrl={styleList.length > 0 ? styleList[no].imageUrl : ''} />
          </div>
          <button
            className={`w-[18px] h-[18px] border ml-[12px] flex justify-center items-center ${styleList.length > 0 && styleList[no].position === 'top' && 'bg-gray-600'
              }`}
            onClick={() => handleButtonClick('top', no)}
          >
            <img src="/imgs/top.png" alt="" />
          </button>

          <button
            className={`w-[18px] h-[18px] border ml-[12px] ${styleList.length > 0 && styleList[no].position === 'bottom' && 'bg-gray-600'
              }`}
            onClick={() => handleButtonClick('bottom', no)}
          >
            <img src="/imgs/bottom.png" alt="" />
          </button>

          <button
            className={`w-[18px] h-[18px] border ml-[12px] ${styleList.length > 0 && styleList[no].position === 'left' && 'bg-gray-600'
              }`}
            onClick={() => handleButtonClick('left', no)}
          >
            <img src="/imgs/left.png" alt="" />
          </button>

          <button
            className={`w-[18px] h-[18px] border ml-[12px] ${styleList.length > 0 && styleList[no].position === 'right' && 'bg-gray-600'
              }`}
            onClick={() => handleButtonClick('right', no)}
          >
            <img src="/imgs/right.png" alt="" />
          </button>


          {showPickerNo === no && (
            <div className="absolute z-10 mt-2">
              {/* {styleList[no].color} */}
              <ChromePicker color={styleList.length > 0 ? styleList[no].color : '#fff'} onChange={(color) => handleCustomColorChange(color, no)}  />
            </div>
          )}
        </div>
      ))}

    </div>
  )
})