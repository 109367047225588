import React, { useEffect, useState } from 'react';
import { FoldIcon } from "../../Icons";
import echartThemeTech from "../Chart/themes/echartThemeTech.json";
import echartTheme from "../Chart/themes/echartTheme.json";
import echartThemeDark from "../Chart/themes/echartThemeDark.json";

const processDataForCard = (visualData) => {
    const data = visualData.data;
    const propertyNames = Object.keys(data);
    const result = data[propertyNames[0]].map((_, index) => {
        const newObj = {};
        propertyNames.forEach(propertyName => {
            newObj[propertyName] = data[propertyName][index];
        });
        return newObj;
    });
    return { result };
};

const isImageType = (str) => {
    const imageFormats = /^(jpeg|jpg|png|gif)$/i;
    const extension = String(str).split('.').pop();
    return imageFormats.test(extension);
};

const getTheme = (theme) => {
    switch (theme) {
        case 'tech':
            return echartThemeTech.color;
        case 'dark':
            return echartThemeDark.color;
        case 'normal':
        default:
            return echartTheme.color;
    }
};

const CardItem = ({ item, index, isPrimary, isImage, isText, isBoolean, isBadge, isText1, isBadge1, isBoolean1, cardVisibility, handleButtonClick, color, width, text, isFold }) => (
    <div key={index} className={`${width} bg-white rounded-[10px] border flex-col justify-start items-center inline-flex relative pb-1`}>
        <div className="flex-col justify-start items-start gap-1 inline-flex">
            <div className={`w-full h-20 rounded-t-[10px] flex bg-indigo-50 font-bold justify-center items-center ${text}`}
                style={{
                    backgroundImage: isImage[0] ? `url(${item[isImage[0].column]})` : 'none',
                    backgroundColor: isImage[0] ? 'transparent' : color[index],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    color: 'white'
                }}>
                {isPrimary.map((primaryItem, idx) => (
                    <div key={idx}>{item[primaryItem.column]}</div>
                ))}
            </div>

            {(cardVisibility[index] ? isText : isText1)?.map((textItem, idx) => (
                <div key={idx} className={`${width} px-1 flex flex-row items-center justify-between`}>
                    <div className={`text-zinc-600 font-normal font-montserrat leading-normal ${text}`}>{textItem.column}</div>
                    <div className={`text-right text-slate-800 font-normal font-montserrat leading-normal ${text}`}>{item[textItem.column]}</div>
                </div>
            ))}

            <div className="pl-1 pr-8 py-px rounded-[20px] justify-start items-center inline-flex">
                <div className="self-stretch justify-start items-start gap-1 inline-flex">
                    {(cardVisibility[index] ? isBadge : isBadge1)?.map((badgeItem, idx) => (
                        <div key={idx} className="px-1.5 py-1 bg-indigo-50 rounded-md justify-center items-center flex">
                            <div className="text-slate-800 text-[8px] font-normal font-montserrat leading-tight">{item[badgeItem.column]}</div>
                        </div>
                    ))}
                </div>
            </div>

            {(cardVisibility[index] ? isBoolean : isBoolean1)?.map((booleanItem, idx) => (
                <div key={idx} className={`${width} px-1 flex flex-row items-center justify-between`}>
                    <div className={`${text} font-normal`}>{booleanItem.column}</div>
                    {item[booleanItem.column] ? <div className="w-4 h-4 bg-emerald-500 rounded-full shadow-inner" /> : <div className="w-4 h-4 bg-slate-200 rounded-full shadow-inner" />}
                </div>
            ))}
        </div>

        {isFold.length > 0 && <div className={cardVisibility[index] ? 'absolute bottom-[-12px] cursor-pointer' : 'absolute bottom-[-12px] cursor-pointer transform rotate-180'} onClick={() => handleButtonClick(index)}>
            <FoldIcon />
        </div>}
    </div>
);
const getCardSpec = (visualData) => {
    if (visualData.spec.cardspec.length > 0) {
        return visualData.spec.cardspec;
    } else {
        let cardspec = [];
        if (visualData.spec.dimensions.length > 0) {
            visualData.spec.dimensions.forEach(dimension => {
                cardspec.push({
                    column: dimension.column,
                    is_primary: true,
                    is_image: false,
                    is_boolean: false,
                    is_badge: false,
                    is_front: true,
                });
            });
        }
        if (visualData.spec.metrics.length > 0) {
            visualData.spec.metrics.forEach(metric => {
                cardspec.push({
                    column: metric.column,
                    is_primary: false,
                    is_image: false,
                    is_boolean: false,
                    is_badge: false,
                    is_front: true,
                });
            });
        }
        return cardspec;
    }
};
const RecordCard = ({ visualData, chartType, currentType, rd }) => {
    const themes = rd?.style?.theme || '';
    const { result } = processDataForCard(visualData);
    const cardspec = getCardSpec(visualData);

    const isImage = cardspec.filter(item => item.is_primary === true && item.is_image === true);
    const isPrimary = cardspec.filter(item => item.is_primary === true && item.is_image !== true);

    const [cardVisibility, setCardVisibility] = useState(result.map(() => true));

    const isText1 = cardspec.filter(item => item.is_primary === false && item.is_boolean === false && item.is_badge === false);
    const isBoolean1 = cardspec.filter(item => item.is_boolean === true);
    const isBadge1 = cardspec.filter(item => item.is_badge === true);

    const isText = cardspec.filter(item => item.is_primary === false && item.is_boolean === false && item.is_badge === false && item.is_front === true);
    const isBoolean = cardspec.filter(item => item.is_boolean === true && item.is_front === true);
    const isBadge = cardspec.filter(item => item.is_badge === true && item.is_front === true);

    const isFold = cardspec.filter(item => item.is_front === false);

    const handleButtonClick = (index) => {
        const updatedVisibility = [...cardVisibility];
        updatedVisibility[index] = !updatedVisibility[index];
        setCardVisibility(updatedVisibility);
    };

    const [width, setWidth] = useState('w-[136px]');
    const [text, setText] = useState('text-[9px]');

    useEffect(() => {
        setWidth(currentType === 'WebCard' ? 'w-[400px]' : 'w-[136px]');
        setText(currentType === 'WebCard' ? 'text-[18px]' : 'text-[9px]');
    }, [currentType]);

    const color = getTheme(themes);

    return (
        <div className='flex flex-wrap gap-2 items-start'>
            {result.map((item, index) => (
                <CardItem
                    key={index}
                    item={item}
                    index={index}
                    isPrimary={isPrimary}
                    isImage={isImage}
                    isText={isText}
                    isBoolean={isBoolean}
                    isBadge={isBadge}
                    isText1={isText1}
                    isBoolean1={isBoolean1}
                    isBadge1={isBadge1}
                    cardVisibility={cardVisibility}
                    handleButtonClick={handleButtonClick}
                    color={color}
                    width={width}
                    text={text}
                    isFold={isFold}
                />
            ))}
        </div>
    );
};

export default RecordCard;
