import { useCallback, useEffect, useRef, useState } from 'react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Popover from '@radix-ui/react-popover';
import SmartImage from './SmartImage';
import CustomTable from './CustomTable';
import VcardCarousel from './VcardCarousel';
import { FunctionBar } from './VisualCardWidge/FunctionBar';
import { DynamicChart } from './VisualCardWidge/DynamicChart';
import { setSettingStyleNo } from '../../store/slices/globalSlice';
import DefineCard from 'flex-card';
import 'flex-card/dist/index.css';
// import testJson from "../test/test.json";
import ReactMarkdown from 'react-markdown';
import { cn } from '../../utils/cn';
// import remarkGfm from 'remark-gfm';
import Typed from 'typed.js';
export function linkifyWithRegex(input) {
  let html = input;
  html = html.replaceAll(/\n/g, '<div></div>');
  let regx =
    /(?!<a[^>]*>[^<])(((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?))(?![^<]*<\/a>)/gi;
  html = html.replace(regx, function (match) {
    return `<div><a href="${match}" target="_blank" style="color: blue; cursor: pointer; width: fit-content;">${match}</a></div>`;
  });
  return html;
}

const getSafeHTML = text => {
  if (!text) return ``;
  return linkifyWithRegex(text);
};
const TypedComponent = ({ stringList }) => {
  // Create reference to store the DOM element containing the animation
  const el = React.useRef(null);

  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: stringList,
      typeSpeed: 50,
      loop: true
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, []);

  return (
    <div className="App">
      <span ref={el} />
    </div>
  );
};
const TextBox = ({
  fontSize,
  fontWeight,
  rndData,
  setRndData,
  no,
  settingStyle = {},
  titleBgColor,
  chartData,
  name,
  smartdocTheme = {}
}) => {
  const isSmallText = useSelector(state => state.global.isSmallText);
  const { verticalAlignment, titleFontColor, titleAlignment } = settingStyle;
  const font_size = isSmallText ? fontSize - 4 : fontSize;
  const [value, setValue] = useState('');

  const textRef = useRef(null);

  const onChangeHandler = text => {
    let chartData = rndData[no].chartData;
    chartData = { ...rndData[no].chartData, query: text };
    rndData[no] = { ...rndData[no], chartData: chartData };
    setRndData([...rndData]);
  };

  const handleInput = useCallback(
    e => {
      if (e.key === `Enter` && !e.shiftKey) {
        const selection = window.getSelection();
        if (!selection.rangeCount) return;

        const range = selection.getRangeAt(0);
        let containerNode = range.startContainer;
        const atEndOfDiv =
          containerNode.nodeType === Node.TEXT_NODE &&
          containerNode?.parentNode?.tagName === 'DIV' &&
          range.startOffset === containerNode.length;

        const div = document.createElement('div');
        const br = document.createElement('br');
        div.appendChild(br);

        if (atEndOfDiv) {
          e.preventDefault();
          containerNode.parentNode.insertBefore(div, containerNode.nextSibling);

          const newRange = document.createRange();
          newRange.setStartAfter(div);
          newRange.collapse(true);

          selection.removeAllRanges();
          selection.addRange(newRange);
        }
      }

      if (textRef.current && rndData[no]) {
        textRef.current.style.height = 'auto';
        const scrollHeight = e.target.scrollHeight;
        textRef.current.style.height = `${scrollHeight}px`;
        rndData[no] = {
          ...rndData[no],
          size: { ...rndData[no].size, height: scrollHeight + 10 }
        };
        setRndData([...rndData]);
        textRef.current.style.height = `100%`;
      }
    },
    [rndData, no]
  );

  const onBlur = () => {
    let text = getSafeHTML(textRef.current.innerHTML);
    setValue(text);
    onChangeHandler(text);
  };

  useEffect(() => {
    if (font_size) {
      handleInput({ target: { scrollHeight: textRef.current.scrollHeight } });
    }
  }, [font_size, value]);

  useEffect(() => {
    if (rndData[no] && rndData[no].chartData.type === 'Text') {
      setValue(getSafeHTML(rndData[no].chartData.query));
    }
  }, [rndData[no].chartData.query]);

  const algin = titleAlignment === 'left' ? 'start' : titleAlignment === 'right' ? 'end' : titleAlignment;

  const { paddingTitle, colorPrimary, colorSecondary, fontColor } = smartdocTheme;

  const themeColor = name === 'heroline' ? colorPrimary : name === 'h1' ? colorSecondary : fontColor;

  return (
    <div
      className={`relative hide-scroll w-full h-full flex items-center p-1`}
      style={{
        background: titleBgColor,
        paddingTop: paddingTitle ? paddingTitle[0] : '',
        paddingRight: paddingTitle ? paddingTitle[1] : '',
        paddingBottom: paddingTitle ? paddingTitle[2] : '',
        paddingLeft: paddingTitle ? paddingTitle[3] : ''
      }}>
      <div
        ref={textRef}
        style={{
          fontSize: font_size,
          fontWeight: fontWeight,
          lineHeight: 'normal',
          color: themeColor || titleFontColor,
          textAlign: titleAlignment,
          alignItems: algin,
          maxHeight: '100%',
          height: '100%'
          // padding:paddingTitle?paddingTitle[0] paddingTitle[1] paddingTitle[2] paddingTitle[3]:"",
        }}
        onClick={e => {
          if (e.target.tagName === 'A') {
            window.open(e.target.href, '_blank');
          }
        }}
        // contentEditable
        onBlur={onBlur}
        onKeyDown={handleInput}
        className={`${'visCardHight' + no} ${
          verticalAlignment === 'middle' && 'justify-center'
        } flex-col flex rounded w-full bg-transparent outline-none p-1 resize-none cursor-text ${
          settingStyle.styles ? settingStyle.styles : ''
        }`}>
        {chartData?.parts?.length > 0 ? (
          chartData.parts.map((part, index) => (
            <div
              key={index}
              style={{
                fontSize: font_size,
                textAlign: titleAlignment,
                fontWeight: fontWeight,
                color: part.style.fontColor,
                background: part.style.backgroundColor || 'transparent',
                ...(part.style.backgroundColor?.includes('gradient') && {
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent'
                })
              }}>
              <div>{part.text}</div>
              <div>
                {part.animation === 'typewriter' && <TypedComponent stringList={part.children}></TypedComponent>}
              </div>
            </div>
          ))
        ) : (
          <div
            style={{
              color: themeColor || titleFontColor,
              fontSize: font_size,
              textAlign: titleAlignment,
              fontWeight: fontWeight
            }}
            dangerouslySetInnerHTML={{ __html: value }}
          />
        )}
      </div>
    </div>
  );
};

const Poplink = ({ fontSize, fontWeight, rndData, setRndData, no, settingStyle = {}, titleBgColor }) => {
  const [showModal, setShowModal] = useState(false); // 控制弹窗显示状态

  // 获取要显示的文本
  const text = rndData[no].chartData.query;
  const [firstLine, ...restOfText] = text.split('\n');
  const restText = restOfText.join('\n');

  return (
    <div className="relative hide-scroll w-full h-full flex items-center p-1">
      <Popover.Root>
        <Popover.Trigger asChild>
          <button
            style={{
              fontSize,
              fontWeight,
              ...settingStyle,
              backgroundColor: titleBgColor
            }}
            onClick={() => setShowModal(true)}>
            {firstLine}
          </button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className="rounded p-3 max-w-[820px] bg-white shadow-[0_10px_18px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2)] 
              focus:shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2),0_0_0_2px_theme(colors.violet7)] 
              will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade 
              data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade">
            <div className="prose max-w-none">
              <ReactMarkdown>{restText}</ReactMarkdown>
            </div>
            <Popover.Close
              className="rounded-full h-[25px] w-[25px] inline-flex items-center justify-center text-violet11 absolute top-[5px] right-[5px] hover:bg-violet4  focus:shadow-violet7 outline-none cursor-default focus:outline-none"
              aria-label="Close">
              {/* <Cross2Icon /> */}
            </Popover.Close>
            <Popover.Arrow className="fill-white" />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

const Markdown = ({ fontSize, fontWeight, rndData, setRndData, no, settingStyle = {}, titleBgColor }) => {
  const [showModal, setShowModal] = useState(false); // 控制弹窗显示状态

  // 获取要显示的文本
  const text = rndData[no].chartData.data.visualData
    ? rndData[no].chartData.data.visualData.data.text
    : rndData[no].chartData.query;
  return (
    <div className={`relative hide-scroll w-full h-full flex items-center prose p-1  max-w-full`}>
      <ReactMarkdown>{text}</ReactMarkdown>
    </div>
  );
};
const chartTypes = [
  'mindmap',
  // "table",
  'CAROUSEL',
  'image',
  'CARD',
  'TESTIMONIAL',
  'GALLERY',
  'ConceptCanvas'
];

const VisualCard = props => {
  const dispatch = useDispatch();
  const isSmallText = useSelector(state => state.global.isSmallText);
  const {
    removeNode,
    upNode,
    no,
    chartData,
    setVisible,
    setDetails,
    rndData,
    setRndData,
    setShowDrawer,
    setShowComment,
    smartdocTheme,
    rd
  } = props;
  const [data, setData] = useState(chartData.data);
  const [settings, setSettings] = useState({});
  const [chartType, setChartType] = useState('');
  const [isContainerVisible, setContainerVisible] = useState(false);

  const handleImageClick = (name, visNo) => {
    setChartType(name);
    if (!rndData[visNo]) return;
    const nVisData = { ...rndData[visNo] };
    nVisData.chartData.type = name;
  };
  const onChangeChartType = name => {
    setChartType(name);
    const data = [...rndData];
    data[no].currentType = name;
    setRndData(data);
    setDetails({ type: name, rndNo: no });
  };
  const copyToClipboard = async content => {
    if (content) {
      try {
        await navigator.clipboard.writeText(content);
        toast.success('Copied to clipboard.');
      } catch (e) {
        console.log(e);
      }
    }
  };

  const setStyleSettingNo = () => {
    dispatch(setSettingStyleNo(no));
  };
  const settingStyle = rndData[no].style;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  function convertToNumber(value) {
    if (typeof value === 'string') {
      return parseInt(value.replace(/\D/g, ''), 10);
    } else if (typeof value === 'number') {
      return parseInt(value);
    } else {
      throw new Error('Unsupported type');
    }
  }
  const isFullWidth = useSelector(state => state.global.isFullWidth);
  const setScaleOut = (nos, rndDatas) => {
    const targetObject = { ...rndDatas[nos] };
    const minHeight = convertToNumber(targetObject.pos.y);
    const maxHeight = convertToNumber(targetObject.pos.y) + convertToNumber(targetObject.size.height);

    // 检查相邻高度范围内是否有对象  y在最大最小范围内，或者y+height在最大最小范围内。
    const nearbyObjects = rndDatas.filter(
      obj =>
        (convertToNumber(obj.pos.y) < maxHeight && convertToNumber(obj.pos.y) > minHeight) ||
        (convertToNumber(obj.pos.y) + convertToNumber(obj.size.height) < maxHeight &&
          convertToNumber(obj.pos.y) + convertToNumber(obj.size.height) > minHeight) ||
        (convertToNumber(obj.pos.y) < minHeight &&
          convertToNumber(obj.pos.y) + convertToNumber(obj.size.height) > maxHeight) ||
        (convertToNumber(obj.pos.y) > minHeight &&
          convertToNumber(obj.pos.y) + convertToNumber(obj.size.height) < maxHeight)
    );
    // 检查左侧是否有对象
    const leftObject = nearbyObjects.reduce((closest, obj) => {
      if (
        convertToNumber(obj.pos.x) + convertToNumber(obj.size.width) < convertToNumber(targetObject.pos.x) &&
        (!closest || closest.pos.x + closest.size.width < obj.pos.x + obj.size.width)
      ) {
        return obj;
      }
      return closest;
    }, null);
    const leftWidth = leftObject
      ? convertToNumber(targetObject.pos.x) - convertToNumber(leftObject.pos.x) - convertToNumber(leftObject.size.width)
      : convertToNumber(targetObject.pos.x);

    // 检查右侧是否有对象
    const rightObject = nearbyObjects.reduce((closest, obj) => {
      if (
        convertToNumber(obj.pos.x) > convertToNumber(targetObject.pos.x) + convertToNumber(targetObject.size.width) &&
        (!closest || closest.pos.x > obj.pos.x)
      ) {
        return obj;
      }
      return closest;
    }, null);

    const maxWidth = isFullWidth
      ? screenWidth > 1024
        ? screenWidth - 180
        : screenWidth > 768
        ? screenWidth - 180
        : screenWidth - 60
      : screenWidth > 1024
      ? screenWidth - 500
      : screenWidth > 768
      ? screenWidth - 300
      : screenWidth - 150;
    const rightWidth = rightObject
      ? convertToNumber(rightObject.pos.x) -
        (convertToNumber(targetObject.pos.x) + convertToNumber(targetObject.size.width))
      : maxWidth - (convertToNumber(targetObject.pos.x) + convertToNumber(targetObject.size.width)); // 假设页面总宽度是 800

    // 计算剩余宽度
    const remainingWidth = leftWidth + rightWidth;
    let nPos = { ...targetObject.pos };
    let nSize = { ...targetObject.size };
    nPos.x = leftObject ? convertToNumber(leftObject.pos.x) + convertToNumber(leftObject.size.width) + 20 : 50;
    nSize.width = convertToNumber(targetObject.size.width) + remainingWidth - 60;
    rndDatas[nos] = { ...targetObject, pos: nPos, size: nSize };
    setRndData([...rndDatas]);
  };
  const setAutoReduceWidth = (nos, rndDatas) => {
    const targetObject = { ...rndDatas[nos] };
    let nSize = { ...targetObject.size };
    nSize.width =
      convertToNumber(targetObject.size.width) > 180
        ? convertToNumber(targetObject.size.width) / 2
        : convertToNumber(targetObject.size.width);
    rndDatas[nos] = { ...targetObject, size: nSize };
    setRndData([...rndDatas]);
  };
  const setAutoIncreaseHeight = (nos, rndDatas) => {
    const targetObject = { ...rndDatas[nos] };
    let nSize = { ...targetObject.size };
    nSize.height = convertToNumber(targetObject.size.height) + 200;
    rndDatas[nos] = { ...targetObject, size: nSize };
    setRndData([...rndDatas]);
  };
  const setAutoReduceHeight = (nos, rndDatas) => {
    const targetObject = { ...rndDatas[nos] };
    let nSize = { ...targetObject.size };
    nSize.height =
      convertToNumber(targetObject.size.height) > 200
        ? convertToNumber(targetObject.size.height) - 200
        : convertToNumber(targetObject.size.height);
    rndDatas[nos] = { ...targetObject, size: nSize };
    setRndData([...rndDatas]);
  };

  const isNoBorder = () => {
    return ['image', 'Text', 'banner'].includes(chartType);
  };

  const setAlignTop = rndNo => {
    const scrollParent = document.getElementById('scroll');
    if (!rndData || !rndData.length || !rndData[rndNo]) return;
    const chartType = rndData[rndNo].chartData.type;

    if (chartType !== 'image') return;
    const y = rndData[rndNo].size.height;
    const rndAry = rndData.map((dt, dno) => {
      if (dno === rndNo) {
        const ndata = {
          ...dt,
          pos: {
            ...dt.pos,
            y: 16
          }
        };
        return ndata;
      } else if (dt.pos.y < y + 32) {
        const ndata = {
          ...dt,
          pos: {
            ...dt.pos,
            y: dt.pos.y + y + 28
          }
        };
        return ndata;
      }
      return dt;
    });

    setRndData([...rndAry]);
    if (scrollParent) {
      scrollParent.scrollTop = 0;
    }
  };

  function opacityToHex(opacity) {
    if (!opacity) return 'ff';
    // Convert opacity percentage to decimal value
    const decimalOpacity = opacity / 100;

    // Calculate the opacity value in the range of 0 to 255
    const opacityValue = Math.round(decimalOpacity * 255);

    // Convert opacity value to hexadecimal
    const hexOpacity = opacityValue.toString(16).padStart(2, '0');

    return hexOpacity;
  }

  function generateHexWithOpacity(color = '', trans) {
    if (trans === 100) return color;
    if (color.includes('linear-gradient')) return color;
    const opacity = opacityToHex(trans);
    // Convert color name to hexadecimal if it is a string
    const colorHex = color.includes('#') ? color : getColorHex(color);

    // Combine color and opacity values
    const hexWithOpacity = '' + colorHex + opacity;
    return hexWithOpacity;
  }

  function getColorHex(color) {
    const colors = {
      white: '#ffffff',
      black: '#000000'
      // Add more color mappings as needed
    };

    return colors[color] || 'ffffff'; // Default to black if color not found
  }

  const cardBgColor = generateHexWithOpacity(settingStyle.cardBGColor, settingStyle.bgTransparent) || 'transparent';

  const titleBgColor = generateHexWithOpacity(settingStyle.titleBGColor, settingStyle.titleBgTransparent) || '#ffffff';

  const position = rndData[no]?.pos;

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // 添加事件监听器以在窗口大小更改时更新屏幕宽度
    window.addEventListener('resize', handleResize);

    // 在组件卸载时移除事件监听器
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // 第二个参数是一个空数组，表示只在组件挂载和卸载时运行useEffect

  useEffect(() => {
    if (props) {
      const { chartData } = props;
      const { type, data, settings } = chartData;
      setSettings(settings);
      setChartType(type);
      setData(data);
    }
  }, [props]);

  return (
    <div
      className={`${!isNoBorder() && rndData[no].currentType !== 'WebCard' && 'border'} 
        rounded overflow-y-auto w-full flex-grow h-full flex flex-col hide-scroll`}
      onMouseEnter={() => setContainerVisible(true)}
      onMouseLeave={() => setContainerVisible(false)}
      style={{
        borderColor: `${settingStyle.borderColor ? settingStyle.borderColor : ''}`
      }}>
      {/** Option List */}
      {isContainerVisible && (
        <div
          className={`absolute 
        ${position.y === 0 ? 'top-[-1px]' : 'top-[-10px]'}
        ${position.x === 0 ? 'left-[-5px]' : 'right-[-13px]'}`}>
          <FunctionBar
            no={no}
            rndData={rndData}
            chartType={chartType}
            showToggleChart={chartType !== 'banner'}
            visualData={data.visualData}
            setVisible={setVisible}
            setRndData={setRndData}
            setDetails={setDetails}
            showComment={() => setShowComment(no)}
            onImageClick={handleImageClick}
            removeNode={() => removeNode(no)}
            upNode={() => {
              upNode(no);
            }}
            copyQuery={() => copyToClipboard(chartData.query)}
            scaleOut={() => setScaleOut(no, rndData)}
            autoReduceWidth={() => setAutoReduceWidth(no, rndData)}
            autoIncreaseHeight={() => setAutoIncreaseHeight(no, rndData)}
            autoReduceHeight={() => setAutoReduceHeight(no, rndData)}
            setAlignTop={setAlignTop}
          />
        </div>
      )}

      {/** Body container */}
      <div className={`w-full  flex flex-col hide-scroll h-full`} id={'card' + no}>
        {chartType === 'banner' ? (
          rndData[no].chartData.name === 'horizontal' ? (
            <div
              className="w-full h-full"
              style={{
                background: 'transparent',
                display: 'flex',
                alignItems: 'center'
              }}>
              <hr style={{ flex: 1 }} />
            </div>
          ) : (
            <div className="w-full h-full" style={{ background: titleBgColor }}></div>
          )
        ) : chartType === 'Text' ? (
          rndData[no].chartData.name === 'poplink' || rndData[no].chartData.name === 'POPLINK' ? (
            <Poplink
              no={no}
              settingStyle={settingStyle}
              rndData={rndData}
              fontSize={chartData.data.font_size}
              fontWeight={chartData.data.font_weight}
              setRndData={setRndData}
              titleBgColor={titleBgColor}
            />
          ) : rndData[no].chartData.name === 'markdown' || rndData[no].chartData.name === 'MARKDOWN' ? (
            <Markdown
              no={no}
              settingStyle={settingStyle}
              rndData={rndData}
              fontSize={chartData.data.font_size}
              fontWeight={chartData.data.font_weight}
              setRndData={setRndData}
              titleBgColor={titleBgColor}
            />
          ) : (
            <TextBox
              no={no}
              name={chartData?.name}
              rndData={rndData}
              settingStyle={settingStyle}
              fontSize={chartData.data.font_size}
              fontWeight={chartData.data.font_weight}
              chartData={chartData}
              setRndData={setRndData}
              titleBgColor={titleBgColor}
              smartdocTheme={smartdocTheme}
            />
          )
        ) : (
          <>
            {rndData[no].chartData.title && settingStyle.isShowTitle && rndData[no].currentType !== 'WebCard' && (
              <div
                className={`${
                  isSmallText ? 'text-[10px]' : 'text-[12px]'
                } uppercase flex items-center min-h-[32px] h-8 px-3 font-montserrat overflow-hidden whitespace-nowrap overflow-ellipsis`}
                style={{
                  background: titleBgColor,
                  color: settingStyle.titleFontColor,
                  justifyContent:
                    settingStyle.titleAlignment === 'left'
                      ? 'start'
                      : settingStyle.titleAlignment === 'center'
                      ? 'center'
                      : settingStyle.titleAlignment === 'right'
                      ? 'end'
                      : ''
                  // 'border':settingStyle.borderColor?`1px solid ${settingStyle.borderColor}`:'none',
                }}>
                <div style={{ lineHeight: 'initial' }} className="w-fit">
                  {rndData[no].chartData.title}
                </div>
              </div>
            )}
            <div
              className={`${
                chartType === 'image' ? ' h-[300px]' : 'px-4 py-2  h-full'
              } flex-grow overflow-hidden hover:overflow-y-auto scrollbar-hide ${'visCardHight' + no}`}
              style={{ background: cardBgColor }}>
              {(
                rndData[no].currentType ? chartTypes.includes(rndData[no].currentType) : chartTypes.includes(chartType)
              ) ? (
                <>
                  {/* {chartType === "table" && (
                    <CustomTable
                      rndNo={no}
                      rndData={rndData}
                      setRndData={setRndData}
                      chartData={chartData}
                    />
                  )} */}
                  {chartType === 'image' && (
                    <SmartImage rndNo={no} rndData={rndData} setRndData={setRndData} chartData={chartData} />
                  )}
                  {chartType === 'CAROUSEL' && (
                    <VcardCarousel
                      rndNo={no}
                      rndData={rndData}
                      setRndData={setRndData}
                      chartData={chartData}
                      setDetails={setDetails}
                      setStyleSettingNo={setStyleSettingNo}
                      setShowDrawer={setShowDrawer}
                    />
                  )}

                  {(chartType === 'CARD' ||
                    chartType === 'TESTIMONIAL' ||
                    chartType === 'ConceptCanvas' ||
                    chartType === 'GALLERY') && (
                    <>
                      <div className="w-full">
                        <DefineCard
                          content={chartData?.data?.visualData?.data}
                          canvasType={chartType === 'ConceptCanvas' ? true : false}
                          customClassNames={{
                            title: cn(settingStyle?.customClassNames?.title || 'text-black'),
                            tabSelected: cn(settingStyle.customClassNames?.tabSelected || 'text-black'),
                            tab: cn(settingStyle.customClassNames?.tab || 'text-black'),
                            text: cn(settingStyle.customClassNames?.content || 'text-black'),
                            containerCarousal: cn(settingStyle.customClassNames?.container || 'bg-transparent')
                          }}
                          // wrapperClassName="max-w-4xl mx-auto p-4 bg-white shadow-lg rounded-lg"
                        />
                      </div>
                    </>
                  )}
                </>
              ) : rndData[no].chartData.name === 'MARKDOWN' ? (
                <Markdown
                  no={no}
                  settingStyle={settingStyle}
                  rndData={rndData}
                  fontSize={chartData.data.font_size}
                  fontWeight={chartData.data.font_weight}
                  setRndData={setRndData}
                  titleBgColor={titleBgColor}
                />
              ) : (
                <DynamicChart
                  rndNo={no}
                  chartType={chartType}
                  visualData={data.visualData}
                  setDetails={setDetails}
                  rndData={rndData}
                  setRndData={setRndData}
                  setStyleSettingNo={setStyleSettingNo}
                  setShowDrawer={setShowDrawer}
                  settings={settings}
                  {...data?.visualData?.data}
                  dummyJson={data?.visualData?.data?.data || []}
                  dataConfig={data?.visualData?.data?.config || {}}
                  rd={rd}
                  onChangeChartType={onChangeChartType}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default VisualCard;
