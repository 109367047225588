import React, { useState, useEffect } from 'react';
import DrawLandScape from './DrawLandScape';

const Landscape = ({
  dummyJson,
  dataConfig,
  setDetails,
  rndNo,
  setStyleSettingNo,
  setShowDrawer,
  settings
}) => {
  const [nodesData, setNodesData] = useState([]);

  useEffect(() => {
    const colors = [
      ['#FDBC1F', '#f8d378'],
      ['#BA68C8', '#f4b2ff'],
      ['#01BDD2', '#8ef2fd'],
      ['#1976D2', '#a3d1ff'],
      ['#ED6C02', '#fccaa1'],
      ['#42A5F5', '#bbdffc'],
      ['#30D6B0', '#adffec'],
      ['#51B960', '#aaffb6'],
      ['#304FFE', '#afbafc'],
      ['#69F0AE', '#bcfdde'],
      ['#FE8668', '#f7c3b6'],
    ];

    const getColor = (no) => {
      const randomNum = no % colors.length;
      return colors[randomNum];
    };
    if (dummyJson && dummyJson?.length > 0) {
      setNodesData(
        dummyJson.map((val, ind) => {
          const new_val = { ...val };
          if (new_val?.subgraph && new_val.subgraph.length > 0) {
            const new_subgraph = [...new_val.subgraph];
            new_val.subgraph = new_subgraph.map((el) => ({
              ...el,
              color: getColor(ind)[1],
            }));
          }
          new_val.color = getColor(ind)[0];
          return new_val;
        })
      );
    }
  }, [dummyJson, dataConfig]);

  const { label, layout, filters } = settings

  return (
    <div className="w-full transition-all duration-300 customCheckBox-shadow bg-white h-fit rounded">
      <div className="text-grey">
        <DrawLandScape
          isContainer={true}
          label={label}
          landJson={nodesData}
          landscapeLayout={layout}
          dataConfig={dataConfig}
          filters={filters || []}
          setDetails={setDetails}
          setShowDrawer={setShowDrawer}
          rndNo={rndNo}
          setStyleSettingNo={setStyleSettingNo}
        />
      </div>
    </div>
  );
};

export default Landscape;