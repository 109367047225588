import axios from "axios";
import { useState } from "react";
import Loader from '../../Loader'
import { toast } from "react-toastify";
import { UploadIcon } from "@radix-ui/react-icons";
import { imageExists } from "../../../utils";

const isDevMode = process.env.NODE_ENV === 'development'
const staticUrl = isDevMode
  ? process.env.REACT_APP_STORAGE_BASE_URL
  : process.env.REACT_APP_PROD_STORAGE_BASE_URL

const headers = {
  "Content-Type": "multipart/form-data",
}

export default function SmartImage(props) {
  const [isHovered, setIsHovered] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { rndNo, rndData, setRndData, chartData } = props
  const fileChangeHandler = async (e) => {
    try {
      const file = e.target.files[0]
      if (!file) return
      const formData = new FormData()
      formData.append(`file`, file)
      setIsLoading(true)
      const response = await axios.post(`file/upload?static-url=${staticUrl}`, formData, { headers })
      setIsLoading(false)
      const uploadedFiles = response.data
      const nChartData = { ...chartData }
      nChartData.src = uploadedFiles[0]?.url
      rndData[rndNo] = { ...rndData[rndNo], chartData: nChartData }
      setRndData([...rndData])
    } catch (e) {
      setIsLoading(false)
      toast.error(e.message)
    }
  }

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="relative flex items-center justify-center w-full h-full bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `${(chartData.src) ? `url(${chartData.src})` : 'url(https://i0.wp.com/ourscene.org/wp-content/uploads/2022/01/placeholder-2.png?fit=1200%2C800&ssl=1)'}` }}>
      {isHovered &&
        <button className="relative text-grey font-medium text-lg cursor-pointer w-fit h-fit z-20">
          <input
            type="file"
            className="w-full absolute opacity-0 h-full left-0 top-0 !cursor-pointer z-10"
            onChange={fileChangeHandler}
          />
          {!isLoading &&
            <label className="cursor-pointer relative black">
              <UploadIcon width={28} height={24} stroke="#374151" strokeWidth={0.5}/>
            </label>
          }
        </button>}
      {isLoading &&
        <Loader className="w-[6px] h-[6px] text-[5px]" />}
      <div className={`top-0 left-0 absolute w-full h-full bg-[#33333344] transition-all ${isHovered ? 'opacity-100' : 'opacity-0'}`}>
      </div>
    </div>
  )

}