export const DesktopIcon = ({ width, height, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ? width : "24"}
    height={height ? height : "24"}
    viewBox="0 0 24 28"
    className={`fill-current ${className}`} // Tailwind class for color
  >
    <path d="M3.5 12.925V15.5H20.5V12.925H3.5ZM3.5 2H20.5C20.9125 2 21.2656 2.14688 21.5594 2.44063C21.8531 2.73438 22 3.0875 22 3.5V15.5C22 15.9125 21.8531 16.2656 21.5594 16.5594C21.2656 16.8531 20.9125 17 20.5 17H15.65V22L12 20.15L8.35 22V17H3.5C3.0875 17 2.73438 16.8531 2.44063 16.5594C2.14688 16.2656 2 15.9125 2 15.5V3.5C2 3.0875 2.14688 2.73438 2.44063 2.44063C2.73438 2.14688 3.0875 2 3.5 2ZM3.5 10.225H20.5V3.5H3.5V10.225Z" />
  </svg>
);
