import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";

const PasswordRecovery = () => {
  const navigate = useNavigate()
  const [userId, setUserId] = useState('')
  const urlParams = new URLSearchParams(window.location.search);
  const id = decodeURIComponent(urlParams.get('id'));

  useEffect(() => {
    (id !== 'null') && setUserId(id)
  }, [id])

  if (userId) navigate(`/reset?step=2&id=${userId}`)
  return (
    <div>{userId ? 'redirecting...' : 'invalid reset link'}</div>
  )
}

export default PasswordRecovery