import React, { memo } from 'react';
import styled from 'styled-components';

const Node = styled.div`
  width: ${props => (props.width ? `${props.width}px` : 'auto')};
  height: ${props => (props.height ? `${props.height}px` : 'auto')};
  background: ${props => (props.background ? `${props.background}` : 'transparent')};
  border: ${props => (props.dashed ? `dashed 1px #aaa` : 'solid 1px #aaa')};
  border-radius: 6px;
  display: flex;
  align-items: flex-start; /* 内容顶部对齐 */
  justify-content: center; /* 水平居中 */
  word-wrap: break-word;
  font-size: 12px;
  padding-top: 4px; /* 给顶部一点间距 */
`;

function CustomSubFlow(props) {
  const { data } = props;
  const { label, style } = data;
  const { width, height, color = '', dashed = false } = style;

  return (
    <Node background={color} width={width} height={height} dashed={dashed}>
      <div className="text-[11px] font-semibold text-gray-400 text-center">{label}</div>
    </Node>
  );
}

export default memo(CustomSubFlow);
