import React from 'react';
import Chart from '../Chart';

const processDataForSunburstChart = (visualData) => {
  const visual=visualData
  let data = visual.data;
  let dimensions = visual.spec.dimensions;
  let metrics = visual.spec.metrics;
  // 将数据处理为Sunburst格式
  let sunburstData = {
      name: "root",
      children: []
  };
  for (let i = 0; i < data[dimensions[0].column].length; i++) {
      let currentNode = sunburstData.children;
      let value = parseInt(data[metrics[0]?.column][i])
      for (let j = 0; j < dimensions.length; j++) {
          let dimension = dimensions[j].column;
          let dimensionValue = data[dimension][i];

          let existingNode = currentNode.find(function (node) {
              return node.name === dimensionValue;
          });

          if (existingNode) {
              currentNode = existingNode.children;
          } else {
              let newNode = {
                  name: dimensionValue,
                  children: [],
                  value: j === dimensions.length - 1 ? value : null
              };
              currentNode.push(newNode);
              currentNode = newNode.children;
          }
          if (j === dimensions.length - 1) {
              // 累积值到叶子节点
              if (currentNode[0]) {
                  if (currentNode[0].value === null) {
                      currentNode[0].value = 0;
                  }
                  currentNode[0].value += value;

              }
          }
      }
  }
  function addValueToInnermost (data) {
      data.forEach(item => {
          if (item.children.length === 0) {
              item.children.push({
                  name: String(item.value),
                  children: [],
                  value: item.value
              });
          } else {
              addValueToInnermost(item.children);
          }
      });
  }

  addValueToInnermost(sunburstData.children);
  // 删除 root 层级
  const sunburstDatas = sunburstData.children;
  return {sunburstDatas};
};

const SunburstChart = ({visualData,rd}) => {
  const {sunburstDatas}=processDataForSunburstChart(visualData)
  const option = {
    series: {
        type: 'sunburst',
        data: sunburstDatas,
        label: {
            formatter: function (param) {
                if (param&&param.data&&param.data.name&&param.data.name.length > 7) {
                    return `${param.data.name.slice(0, 6)}...`;
                }
            }
        },
        radius: [0, '95%'],
        levels: [
            {},
            {
                r0: '10%',
                r: '45%',
                itemStyle: {
                    borderWidth: 1,
                },
                label: {
                    align: 'right'
                  }
            },
            {
                r0: '45%',
                r: '85%',
                label: {
                    align: 'right'
                  }
            },
            {
                r0: '85%',
                r: '87%',
                label: {
                    position: 'outside',
                    padding: 3,
                    silent: false
                },
                itemStyle: {
                    borderWidth: 3
                }
            }
        ]
    }
  };

  return (
    <Chart option={option}  rd={rd}/>
  );
};

export { SunburstChart };
