export const StarsIcon = ({ color, width, height }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={`${width ? width : '20'}`} height={`${height ? height : '20'}`} viewBox="0 0 18 18" fill="none">
        <g clipPath="url(#clip0_920_61504)">
            <path d="M12.668 0.666016L11.828 2.49935L10.0013 3.33268L11.828 4.17268L12.668 5.99935L13.5013 4.17268L15.3346 3.33268L13.5013 2.49935M6.0013 2.66602L4.33464 6.33268L0.667969 7.99935L4.33464 9.66602L6.0013 13.3327L7.66797 9.66602L11.3346 7.99935L7.66797 6.33268M12.668 9.99935L11.828 11.826L10.0013 12.666L11.828 13.4993L12.668 15.3327L13.5013 13.4993L15.3346 12.666L13.5013 11.826" fill={color ? color : 'black'} />
        </g>
        <defs>
            <clipPath id="clip0_920_61504">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

