import React, { useState, useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import * as d3 from 'd3';
import { hexbin } from 'd3-hexbin';
import { Icon } from '@iconify/react';

const getData = data => {
  const keys = Object.keys(data);
  const length = data[keys[0]].length;

  const processedData = Array.from({ length }, (_, index) => {
    const obj = {};
    keys.forEach(key => {
      obj[key] = data[key][index];
    });
    return obj;
  });
  return processedData;
};

const HexagonWall = ({ visualData }) => {
  let data;
  let config;

  try {
    data = getData(visualData.data);
    console.log(data);
    config = { ...visualData.config };
  } catch (err) {
    console.error('Error processing visualData:', err);
  }

  const svgRef = useRef(null);
  const [selectedHexagon, setSelectedHexagon] = useState(null);
  const [error, setError] = useState(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateDimensions = () => {
      const container = svgRef.current?.parentNode;
      setDimensions({
        width: container.clientWidth,
        height: container.clientHeight
      });
    };

    window.addEventListener('resize', updateDimensions);
    updateDimensions();

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  useEffect(() => {
    if (!data || !config || !dimensions.width || !dimensions.height) {
      return;
    }

    try {
      const svg = d3.select(svgRef.current);
      svg.attr('width', dimensions.width).attr('height', dimensions.height);
      svg.selectAll('*').remove();

      const fixedTopMargin = 20;
      const hexRadius = Math.min(
        Math.floor((0.94 * dimensions.width) / data.length),
        Math.floor(0.1 * dimensions.width)
      );

      const hexbinGenerator = hexbin()
        .radius(hexRadius)
        .extent([
          [0, 0],
          [dimensions.width, dimensions.height]
        ]);

      // const points = data.map((_, i) => [
      //   (i % 6) * (hexRadius * 1.75),
      //   Math.floor(i / 6) * (hexRadius * 1.5) + hexRadius + fixedTopMargin
      // ]);
      const points = data.map((_, i) => {
        const row = Math.floor(i / 6);
        const col = i % 6;
        const xOffset = row % 2 === 0 ? 0 : hexRadius * 0.875; // 偶数行偏移

        return [col * (hexRadius * 1.75) + xOffset, row * (hexRadius * 1.5) + hexRadius + fixedTopMargin];
      });

      const colorPalette = [
        '#E6F3F5',
        '#F7EBE5',
        '#E8F4D4',
        '#F5E6EA',
        '#E5EAF7',
        '#F4E9D1',
        '#DCF5EB',
        '#F2E4F6',
        '#E9E9ED',
        '#E3C9B5',
        '#30D6B0'
      ];

      const colorScale = d3.scaleOrdinal(colorPalette);

      const hexagons = svg
        .selectAll('g')
        .data(hexbinGenerator(points))
        .enter()
        .append('g')
        .attr('transform', d => `translate(${d.x},${d.y})`);

      hexagons
        .append('path')
        .attr('d', hexbinGenerator.hexagon())
        .attr('fill', (d, i) => colorScale(data[i][config.colorBy] || i))
        .attr('stroke', '#fff')
        .attr('stroke-width', 2)
        .style('filter', 'drop-shadow(0px 3px 3px rgba(0,0,0,0.2))');

      hexagons
        .append('foreignObject')
        .attr('width', hexRadius * 1.5)
        .attr('height', hexRadius * 1.5)
        .attr('x', -hexRadius * 0.75)
        .attr('y', -hexRadius * 0.75)
        .append('xhtml:div')
        .style('display', 'flex')
        .style('flex-direction', 'column')
        .style('justify-content', 'center')
        .style('align-items', 'center')
        .style('width', '100%')
        .style('height', '100%')
        .style('text-align', 'center')
        .style('font-size', '10px')
        .style('word-wrap', 'break-word')
        .html(
          (d, i) => `
            <div style="font-weight: bold;">${
              data[i][config.label] ? data[i][config.label] : Object.values(data[i])[0]
            }</div>
            <div>${data[i][config.title] ? data[i][config.title] : Object.values(data[i])[1]}</div>
            <div>${
              data[i][config.body] && config?.body
                ? truncateText(data[i][config.body], 40)
                : Object.values(data[i][2] ? Object.values(data[i])[2] : '')
            }</div>
          `
        );

      hexagons.each(function (d, i) {
        const iconName = data[i][config.icon];
        if (iconName) {
          d3.select(this)
            .append('foreignObject')
            .attr('width', 24)
            .attr('height', 24)
            .attr('x', -12)
            .attr('y', hexRadius * 0.5)
            .append('xhtml:div')
            .style('display', 'flex')
            .style('justify-content', 'center')
            .style('align-items', 'center')
            .style('width', '100%')
            .style('height', '100%')
            .html(`<div id="icon-${i}"></div>`);
        }
      });

      hexagons.on('click', (event, d) => {
        const index = points.findIndex(p => p[0] === d.x && p[1] === d.y);
        setSelectedHexagon(data[index]);
      });

      data.forEach((item, i) => {
        const iconName = item[config.icon];
        if (iconName) {
          const iconElement = document.getElementById(`icon-${i}`);
          if (iconElement) {
            const root = createRoot(iconElement);
            root.render(<Icon icon={iconName} />);
          }
        }
      });
    } catch (err) {
      console.error('Error rendering hexagons:', err);
      setError('An error occurred while rendering the hexagon visualization.');
    }
  }, [data, config, dimensions]);

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <svg
        ref={svgRef}
        style={{
          position: 'relative',
          width: '100%',
          height: '100%'
        }}></svg>
      {selectedHexagon && (
        <div style={{ marginTop: '20px', padding: '10px', border: '1px solid #ccc' }}>
          <h3>Selected Hexagon Details:</h3>
          <pre>{JSON.stringify(selectedHexagon, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default HexagonWall;
