import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";

function AuthCarousel() {
  const sliderContents = [
    {
      children: (
        <div className="text-white text-left p-8">
          <h1 className='font-[500] sm:text-4xl text-2xl mt-16'>What is Shugic</h1>
          <div className='mt-4 font-[300] text-[15px]'>Shugic is designed for the End Users.
            Anyone can directly request data dnd perform analytics, with no training nor IT team required.
            <a href='https://shugic.netlify.com' target='_blank' rel="noreferrer" className='font-bold'> Learn all about </a>
            Shugic and how they helped to answer business qustions and more.</div>
        </div>
      ),
      background: '/imgs/slider-bg1.png'
    },
    {
      children: (
        <div className="text-white text-left p-8">
          <h1 className='font-[500] sm:text-4xl text-2xl mt-16'>New look, same service!</h1>
          <div className='mt-4 font-[300] text-[15px]'>
            <a href='https://shugic.netlify.com' target='_blank' rel="noreferrer" className='font-bold'>The Shugic team </a>
            is excited to share with you our new look!
            Check out our enhanced changes to our web app, features and tools.</div>
        </div>
      ),
      background: '/imgs/slider-bg1.png'
    },
    {
      children: (
        <div className="text-white text-left p-8">
          <h1 className='font-[500] sm:text-4xl text-2xl mt-16'>New Shugic Website</h1>
          <div className='mt-4 font-[300] text-[15px]'>Check out our
            <a href='https://shugic.netlify.com' target='_blank' rel="noreferrer" className='font-bold'> brand new website </a>
            , to learn more about
            who we are, what services we provide, and what make us better than the other.</div>
        </div>
      ),
      background: '/imgs/slider-bg1.png'
    },
    {
      children: (
        <div className="text-white text-left p-8">
          <h1 className='font-[500] sm:text-4xl text-2xl mt-16'>Get a free Trial!</h1>
          <div className='mt-4 font-[300] text-[15px]'>We are thrilled to announce that we are offering a free trial for our web app for a limited time.
            You can sign up now and get access to all our amazing tools and features for 30 days.</div>
        </div>
      ),
      background: '/imgs/slider-bg1.png'
    },
  ]
  
  return (
    <div className='h-full bg-[#25646b]'>
      <Carousel
        className='h-full'
        autoPlay={true}
        showThumbs={false}
        showStatus={false}
        infiniteLoop={true}
        showArrows={false}
        transitionTime={1000}
      >
        {
          sliderContents.map((sl, no) => (
            <div
              className='w-full h-full min-h-screen bg-cover bg-center'
              style={{ backgroundImage: `url(${sl.background})` }}
              key={no}>
              {sl.children}
            </div>
          ))
        }
      </Carousel>
    </div>
  )
}

export default AuthCarousel