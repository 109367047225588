import React from 'react';
import Chart from '../Chart';

const processDataForScatterChart = (visualData) => {
        const visual=visualData
        const data = visual.data;
        const metrics = visual.spec.metrics;
        const dimensions = visual.spec.dimensions[0].column;
        let tempData = {};
        let tempXAxis = {};
        let tempYAxis = {};
        let tempSeries = [];
        // let tempColor = [];
        let tempLegend = [];
        for (let i = 0; i < data[metrics[0].column].length; i++) {
            let x = data[metrics[0].column][i];
            let y = data[metrics[1].column][i];
            let color = null;
            let value = data[dimensions][i];
            let tempDataKey = `${x}_${y}`;
            if (!Object.prototype.hasOwnProperty.call(tempXAxis, x)) {
                tempXAxis[x] = x;
            }
            if (!Object.prototype.hasOwnProperty.call(tempYAxis, y)) {
                tempYAxis[y] = y;
            }
            if (metrics[2]) {
                color = data[metrics[2].column][i];
                tempDataKey = `${tempDataKey}_${color}`;
            }
            if (Object.prototype.hasOwnProperty.call(tempData, tempDataKey)) {
                tempData[tempDataKey]  =tempData[tempDataKey]+ value;
            } else {
                tempData[tempDataKey] = value;
            }
        }
        let max = 0;
        let tempDataKeys = Object.keys(tempData);
        // 预设的五种颜色
        const presetColors = [   "#0e9ab0",
        "#26b2e4",
        "#4acbff",
        "#86e9ff",
        "#b6ffec",
        "#e6ffd4",
        "#ffffb8",
        "#14a0bd",
        "#2cb8f1",
        "#56d1FF",
        "#92EFFE",
        "#C2FFE6",
        "#E5FBC7",
        "#FFF5A8",
        "#1AA6CA",
        "#32BEFE",
        "#6EDDFF",
        "#9EF5F8",
        "#CEFFE0",
        "#E6F7B9",];

        // 映射表，将每个不同的tempData[key]映射到一个颜色
        const colorMapping = {};
        for (let i = 0; i < tempDataKeys.length; i++) {
            let key = tempDataKeys[i];
            let coordinate = key.split("_");
            if (tempData[key] > max) {
                max = tempData[key];
            }
            let value = [coordinate[0], coordinate[1], tempData[key]];
            if (metrics[2]) {
                value.push(coordinate[2]);
                tempLegend.push(coordinate[2]);
            }
          // 使用映射表中的颜色，如果没有则新增映射
            if (!colorMapping[tempData[key]]) {
                colorMapping[tempData[key]] = presetColors[tempSeries.length % presetColors.length];
            }

            let finishValue = { value: value, itemStyle: { color: colorMapping[tempData[key]] } };
            tempSeries.push(finishValue);
        }
        // if (metrics[2]) {
        //     this.option.visualMap = [
        //         {
        //             type: "piecewise",
        //             dimension: 3,
        //             categories: [...new Set(tempLegend)],
        //             left: 10,
        //             bottom: 35,
        //             calculable: true,
        //             precision: 0.1,
        //             textGap: 10,
        //             itemGap: 3,
        //             textStyle: {
        //                 color: "#ccc",
        //             },
        //             inRange: {
        //                 color: tempColor,
        //             },
        //         },
        //         {
        //             show: false,
        //             type: "continuous",
        //             dimension: 2,
        //             max: max,
        //             inRange: {
        //                 symbolSize: [1, max],
        //             },
        //         },
        //     ];
        // } else {
        //     if (this.option.visualMap) {
        //         delete this.option.visualMap;
        //     }
        // }
        const xAxisName = metrics[0].column;
        const xAxisData = Object.keys(tempXAxis);
        const yAxisName = metrics[1].column;
        const yAxisData = Object.keys(tempYAxis);
        const seriesData = tempSeries;

  return {xAxisName,xAxisData,yAxisName,yAxisData,seriesData};
};

const ScatterChart = ({visualData,rd}) => {
    // const visualData1={
    //     "visuals": [
    //         {
    //             "vid": "6de3d4e6b3a970d7866013feae410b41",
    //             "title": "Financial_Small by Date and Segment as timeline",
    //             "databasequery": "select count(\"Financial_Small\") as \"Financial_Small_count\", \"Date\" as \"Date\", \"Segment\" as \"Segment\"\nfrom Financial_Small\ngroup by \"Date\", \"Segment\"\nlimit 20",
    //             "interpretations": [
    //                 {
    //                     "term_id": "4c0eb4e898b7dd5d",
    //                     "text": "Financial_Small",
    //                     "techtext": "Financial_Small",
    //                     "tag": "class",
    //                     "tip": "-PLACEHOLDER-",
    //                     "alternatives": [],
    //                     "values": {},
    //                     "datatype": ""
    //                 },
    //                 {
    //                     "term_id": "298997532187",
    //                     "text": "by",
    //                     "techtext": "by",
    //                     "tag": "partition",
    //                     "tip": "-PLACEHOLDER-",
    //                     "alternatives": [],
    //                     "values": {},
    //                     "datatype": ""
    //                 },
    //                 {
    //                     "term_id": "c985436efcd61be9",
    //                     "text": "Date",
    //                     "techtext": "Date",
    //                     "tag": "attribute",
    //                     "tip": "-PLACEHOLDER-",
    //                     "alternatives": [
    //                         {
    //                             "term_id": "53fc8fc708fb1fea",
    //                             "text": "Segment",
    //                             "techtext": "Segment",
    //                             "tag": "ATTRIBUTE",
    //                             "tip": "_PLACEHOLDER_",
    //                             "alternatives": [],
    //                             "values": {},
    //                             "datatype": ""
    //                         },
    //                         {
    //                             "term_id": "48d09eaa7890a62c",
    //                             "text": "Country",
    //                             "techtext": "Country",
    //                             "tag": "ATTRIBUTE",
    //                             "tip": "_PLACEHOLDER_",
    //                             "alternatives": [],
    //                             "values": {},
    //                             "datatype": ""
    //                         },
    //                         {
    //                             "term_id": "a4b0c414da1348b0",
    //                             "text": "Product",
    //                             "techtext": "Product",
    //                             "tag": "ATTRIBUTE",
    //                             "tip": "_PLACEHOLDER_",
    //                             "alternatives": [],
    //                             "values": {},
    //                             "datatype": ""
    //                         },
    //                         {
    //                             "term_id": "a5e6f71af28ba155",
    //                             "text": "DiscountBand",
    //                             "techtext": "DiscountBand",
    //                             "tag": "ATTRIBUTE",
    //                             "tip": "_PLACEHOLDER_",
    //                             "alternatives": [],
    //                             "values": {},
    //                             "datatype": ""
    //                         },
    //                         {
    //                             "term_id": "c5463f0d2e21f7e3",
    //                             "text": "UnitsSold",
    //                             "techtext": "UnitsSold",
    //                             "tag": "ATTRIBUTE",
    //                             "tip": "_PLACEHOLDER_",
    //                             "alternatives": [],
    //                             "values": {},
    //                             "datatype": ""
    //                         },
    //                         {
    //                             "term_id": "87a5a1ad01a1f7fa",
    //                             "text": "ManufacturingPrice",
    //                             "techtext": "ManufacturingPrice",
    //                             "tag": "ATTRIBUTE",
    //                             "tip": "_PLACEHOLDER_",
    //                             "alternatives": [],
    //                             "values": {},
    //                             "datatype": ""
    //                         },
    //                         {
    //                             "term_id": "6e359edc2cc5a437",
    //                             "text": "SalePrice",
    //                             "techtext": "SalePrice",
    //                             "tag": "ATTRIBUTE",
    //                             "tip": "_PLACEHOLDER_",
    //                             "alternatives": [],
    //                             "values": {},
    //                             "datatype": ""
    //                         },
    //                         {
    //                             "term_id": "279c5a14c11da3bc",
    //                             "text": "GrossSales",
    //                             "techtext": "GrossSales",
    //                             "tag": "ATTRIBUTE",
    //                             "tip": "_PLACEHOLDER_",
    //                             "alternatives": [],
    //                             "values": {},
    //                             "datatype": ""
    //                         },
    //                         {
    //                             "term_id": "38df0f2922b37d03",
    //                             "text": "Discounts",
    //                             "techtext": "Discounts",
    //                             "tag": "ATTRIBUTE",
    //                             "tip": "_PLACEHOLDER_",
    //                             "alternatives": [],
    //                             "values": {},
    //                             "datatype": ""
    //                         },
    //                         {
    //                             "term_id": "a480f4299314a1a5",
    //                             "text": "Sales",
    //                             "techtext": "Sales",
    //                             "tag": "ATTRIBUTE",
    //                             "tip": "_PLACEHOLDER_",
    //                             "alternatives": [],
    //                             "values": {},
    //                             "datatype": ""
    //                         },
    //                         {
    //                             "term_id": "63e025b30aa7b211",
    //                             "text": "COGS",
    //                             "techtext": "COGS",
    //                             "tag": "ATTRIBUTE",
    //                             "tip": "_PLACEHOLDER_",
    //                             "alternatives": [],
    //                             "values": {},
    //                             "datatype": ""
    //                         },
    //                         {
    //                             "term_id": "f91887debf0656a0",
    //                             "text": "Profit",
    //                             "techtext": "Profit",
    //                             "tag": "ATTRIBUTE",
    //                             "tip": "_PLACEHOLDER_",
    //                             "alternatives": [],
    //                             "values": {},
    //                             "datatype": ""
    //                         },
    //                         {
    //                             "term_id": "c985436efcd61be9",
    //                             "text": "Date",
    //                             "techtext": "Date",
    //                             "tag": "ATTRIBUTE",
    //                             "tip": "_PLACEHOLDER_",
    //                             "alternatives": [],
    //                             "values": {},
    //                             "datatype": ""
    //                         },
    //                         {
    //                             "term_id": "6242405016ca74a1",
    //                             "text": "MonthNumber",
    //                             "techtext": "MonthNumber",
    //                             "tag": "ATTRIBUTE",
    //                             "tip": "_PLACEHOLDER_",
    //                             "alternatives": [],
    //                             "values": {},
    //                             "datatype": ""
    //                         },
    //                         {
    //                             "term_id": "d0d0539abc78de8e",
    //                             "text": "MonthName",
    //                             "techtext": "MonthName",
    //                             "tag": "ATTRIBUTE",
    //                             "tip": "_PLACEHOLDER_",
    //                             "alternatives": [],
    //                             "values": {},
    //                             "datatype": ""
    //                         },
    //                         {
    //                             "term_id": "b3807aa643d4ae39",
    //                             "text": "Year",
    //                             "techtext": "Year",
    //                             "tag": "ATTRIBUTE",
    //                             "tip": "_PLACEHOLDER_",
    //                             "alternatives": [],
    //                             "values": {},
    //                             "datatype": ""
    //                         }
    //                     ],
    //                     "values": {},
    //                     "datatype": ""
    //                 },
    //                 {
    //                     "term_id": "raw3",
    //                     "text": "and",
    //                     "techtext": "and",
    //                     "tag": "text",
    //                     "tip": "-PLACEHOLDER-",
    //                     "alternatives": [],
    //                     "values": {},
    //                     "datatype": ""
    //                 },
    //                 {
    //                     "term_id": "53fc8fc708fb1fea",
    //                     "text": "Segment",
    //                     "techtext": "Segment",
    //                     "tag": "attribute",
    //                     "tip": "-PLACEHOLDER-",
    //                     "alternatives": [],
    //                     "values": {},
    //                     "datatype": ""
    //                 },
    //                 {
    //                     "term_id": "raw5",
    //                     "text": "as",
    //                     "techtext": "as",
    //                     "tag": "text",
    //                     "tip": "-PLACEHOLDER-",
    //                     "alternatives": [],
    //                     "values": {},
    //                     "datatype": ""
    //                 },
    //                 {
    //                     "term_id": "raw6",
    //                     "text": "timeline",
    //                     "techtext": "timeline",
    //                     "tag": "text",
    //                     "tip": "-PLACEHOLDER-",
    //                     "alternatives": [],
    //                     "values": {},
    //                     "datatype": ""
    //                 }
    //             ],
    //             "spec": {
    //                 "columns": [
    //                     {
    //                         "id": "Financial_Small_count",
    //                         "text": "Financial Small count",
    //                         "techtext": "Financial_Small_count",
    //                         "dtype": "float",
    //                         "dunit": "",
    //                         "term_id": ""
    //                     },
    //                     {
    //                         "id": "Date",
    //                         "text": "Date",
    //                         "techtext": "Date",
    //                         "dtype": "timestamp",
    //                         "dunit": "",
    //                         "term_id": ""
    //                     },
    //                     {
    //                         "id": "Segment",
    //                         "text": "Segment",
    //                         "techtext": "Segment",
    //                         "dtype": "character",
    //                         "dunit": "",
    //                         "term_id": ""
    //                     }
    //                 ],
    //                 "dimensions": [
    //                     {
    //                         "column": "People"
    //                     } 
    //                 ],
    //                 "metrics": [
    //                     {
    //                         "column": "age"
    //                     },
    //                     {
    //                         "column": "height"
    //                     }
    //                 ],
    //                 "category": [
    //                     {
    //                         "column": "Date"
    //                     },
    //                     {
    //                         "column": "Segment"
    //                     }
    //                 ],
    //                 "commands": [],
    //                 "vtype": {
    //                     "vtype": "TIMELINE",
    //                     "alternatives": [],
    //                     "resistence_to_transit": 0.875
    //                 },
    //                 "visualaction": ""
    //             },
    //             "data": {
    //                 "height": [
    //                     "51",
    //                     "53",
    //                     "51",
    //                     "71",
    //                     "51",
    //                     "41",
    //                     "22",
    //                     "91",
    //                     "91",
    //                     "91",
    //                     "81",
    //                     "81",
    //                     "84"
    //                 ],
    //                 "age": [
    //                     "18",
    //                     "30",
    //                     "18",
    //                     "18",
    //                     "15",
    //                     "14",
    //                     "23",
    //                     "13",
    //                     "15",
    //                     "15",
    //                     "17",
    //                     "12",
    //                     "43"
    //                 ],
    //                 "People": [
    //                     "Midmarket",
    //                     "Channel Partners",
    //                     "Government",
    //                     "Enterprise",
    //                     "Government",
    //                     "Midmarket",
    //                     "Government",
    //                     "Enterprise",
    //                     "Small Business",
    //                     "Government",
    //                     "Government",
    //                     "Midmarket",
    //                     "Midmarket"
    //                 ]
    //             },
    //             "suggestions": {
    //                 "breakdown": [
    //                     {
    //                         "id": "48d09eaa7890a62c",
    //                         "techtext": "Country"
    //                     },
    //                     {
    //                         "id": "a4b0c414da1348b0",
    //                         "techtext": "Product"
    //                     },
    //                     {
    //                         "id": "a5e6f71af28ba155",
    //                         "techtext": "Discount Band"
    //                     },
    //                     {
    //                         "id": "c5463f0d2e21f7e3",
    //                         "techtext": "Units Sold"
    //                     },
    //                     {
    //                         "id": "87a5a1ad01a1f7fa",
    //                         "techtext": "Manufacturing Price"
    //                     },
    //                     {
    //                         "id": "6e359edc2cc5a437",
    //                         "techtext": "Sale Price"
    //                     },
    //                     {
    //                         "id": "279c5a14c11da3bc",
    //                         "techtext": "Gross Sales"
    //                     },
    //                     {
    //                         "id": "38df0f2922b37d03",
    //                         "techtext": "Discounts"
    //                     },
    //                     {
    //                         "id": "a480f4299314a1a5",
    //                         "techtext": "Sales"
    //                     },
    //                     {
    //                         "id": "63e025b30aa7b211",
    //                         "techtext": "COGS"
    //                     },
    //                     {
    //                         "id": "f91887debf0656a0",
    //                         "techtext": "Profit"
    //                     },
    //                     {
    //                         "id": "6242405016ca74a1",
    //                         "techtext": "Month Number"
    //                     },
    //                     {
    //                         "id": "d0d0539abc78de8e",
    //                         "techtext": "Month Name"
    //                     },
    //                     {
    //                         "id": "b3807aa643d4ae39",
    //                         "techtext": "Year"
    //                     }
    //                 ],
    //                 "filters": [
    //                     {
    //                         "term_id": "48d09eaa7890a62c",
    //                         "humanreadable_text": "Country",
    //                         "techtext": "Country",
    //                         "dtype": "character",
    //                         "value_range": null,
    //                         "value_choices": [
    //                             "A",
    //                             "B",
    //                             "C"
    //                         ]
    //                     },
    //                     {
    //                         "term_id": "a4b0c414da1348b0",
    //                         "humanreadable_text": "Product",
    //                         "techtext": "Product",
    //                         "dtype": "character",
    //                         "value_range": null,
    //                         "value_choices": [
    //                             "A",
    //                             "B",
    //                             "C"
    //                         ]
    //                     },
    //                     {
    //                         "term_id": "a5e6f71af28ba155",
    //                         "humanreadable_text": "Discount Band",
    //                         "techtext": "Discount Band",
    //                         "dtype": "character",
    //                         "value_range": null,
    //                         "value_choices": [
    //                             "A",
    //                             "B",
    //                             "C"
    //                         ]
    //                     },
    //                     {
    //                         "term_id": "c5463f0d2e21f7e3",
    //                         "humanreadable_text": "Units Sold",
    //                         "techtext": "Units Sold",
    //                         "dtype": "float",
    //                         "value_range": {
    //                             "v_max": 1000,
    //                             "v_min": 0
    //                         },
    //                         "value_choices": null
    //                     },
    //                     {
    //                         "term_id": "87a5a1ad01a1f7fa",
    //                         "humanreadable_text": "Manufacturing Price",
    //                         "techtext": "Manufacturing Price",
    //                         "dtype": "float",
    //                         "value_range": {
    //                             "v_max": 1000,
    //                             "v_min": 0
    //                         },
    //                         "value_choices": null
    //                     },
    //                     {
    //                         "term_id": "6e359edc2cc5a437",
    //                         "humanreadable_text": "Sale Price",
    //                         "techtext": "Sale Price",
    //                         "dtype": "integer",
    //                         "value_range": {
    //                             "v_max": 1000,
    //                             "v_min": 0
    //                         },
    //                         "value_choices": null
    //                     },
    //                     {
    //                         "term_id": "279c5a14c11da3bc",
    //                         "humanreadable_text": "Gross Sales",
    //                         "techtext": "Gross Sales",
    //                         "dtype": "float",
    //                         "value_range": {
    //                             "v_max": 1000,
    //                             "v_min": 0
    //                         },
    //                         "value_choices": null
    //                     },
    //                     {
    //                         "term_id": "38df0f2922b37d03",
    //                         "humanreadable_text": "Discounts",
    //                         "techtext": "Discounts",
    //                         "dtype": "character",
    //                         "value_range": null,
    //                         "value_choices": [
    //                             "A",
    //                             "B",
    //                             "C"
    //                         ]
    //                     },
    //                     {
    //                         "term_id": "a480f4299314a1a5",
    //                         "humanreadable_text": "Sales",
    //                         "techtext": "Sales",
    //                         "dtype": "float",
    //                         "value_range": {
    //                             "v_max": 1000,
    //                             "v_min": 0
    //                         },
    //                         "value_choices": null
    //                     },
    //                     {
    //                         "term_id": "63e025b30aa7b211",
    //                         "humanreadable_text": "COGS",
    //                         "techtext": "COGS",
    //                         "dtype": "float",
    //                         "value_range": {
    //                             "v_max": 1000,
    //                             "v_min": 0
    //                         },
    //                         "value_choices": null
    //                     },
    //                     {
    //                         "term_id": "f91887debf0656a0",
    //                         "humanreadable_text": "Profit",
    //                         "techtext": "Profit",
    //                         "dtype": "float",
    //                         "value_range": {
    //                             "v_max": 1000,
    //                             "v_min": 0
    //                         },
    //                         "value_choices": null
    //                     },
    //                     {
    //                         "term_id": "6242405016ca74a1",
    //                         "humanreadable_text": "Month Number",
    //                         "techtext": "Month Number",
    //                         "dtype": "integer",
    //                         "value_range": {
    //                             "v_max": 1000,
    //                             "v_min": 0
    //                         },
    //                         "value_choices": null
    //                     },
    //                     {
    //                         "term_id": "d0d0539abc78de8e",
    //                         "humanreadable_text": "Month Name",
    //                         "techtext": "Month Name",
    //                         "dtype": "timestamp",
    //                         "value_range": null,
    //                         "value_choices": [
    //                             "A",
    //                             "B",
    //                             "C"
    //                         ]
    //                     },
    //                     {
    //                         "term_id": "b3807aa643d4ae39",
    //                         "humanreadable_text": "Year",
    //                         "techtext": "Year",
    //                         "dtype": "timestamp",
    //                         "value_range": null,
    //                         "value_choices": [
    //                             "A",
    //                             "B",
    //                             "C"
    //                         ]
    //                     }
    //                 ],
    //                 "details": [
    //                     {
    //                         "term_id": "4c0eb4e898b7dd5d",
    //                         "text": "Financial_Small"
    //                     }
    //                 ]
    //             },
    //             "computationsetting": []
    //         }
    //     ],
    //     "commands": [],
    //     "networks": {},
    //     "network_generation_plans": [],
    //     "profiles": [],
    //     "ambiguity": null,
    //     "error": {
    //         "code": 0,
    //         "message": "",
    //         "debugmsg": ""
    //     }
    // }
    
  const {xAxisName,xAxisData,yAxisName,yAxisData,seriesData}=processDataForScatterChart(visualData)
  const option = {
    tooltip: {
        axisPointer: {
            type: "cross",
            lineStyle: {
                type: "dashed",
                width: 1,
            },
        },
        formatter: function(params) {
            　　var result = ''
            // 　　var dotHtml = '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#1197b8"></span>'
            　　result = params.data.value[2]+'<br/>'+xAxisName+":"+params.data.value[0]+'<br/>'+yAxisName+":"+params.data.value[1]
            　　return result
            }
    },
    grid: {
        left: "10%",
        bottom:"10%",
        right: "5%",
    },
    xAxis: {
        name: xAxisName,
        nameLocation: "middle",
        nameTextStyle: {
            padding: 25,
            fontWeight: "bold",
        },
        data: xAxisData,
    },
    yAxis: {
        name: yAxisName,
        nameLocation: "middle",
        nameTextStyle: {
            padding: 25,
            fontWeight: "bold",
        },
        data:yAxisData,
    },
    series: [
        {
            type: "scatter",
            data: seriesData,
        },
    ],
}
  return (
    <Chart option={option}  rd={rd}/>
  );
};

export { ScatterChart };
