import React,{useRef} from 'react';
import Chart from '../Chart';
import * as d3 from 'd3';
import echartThemeTech from "../themes/echartThemeTech.json";
import echartTheme from "../themes/echartTheme.json";
import echartThemeDark from "../themes/echartThemeDark.json";

const processDataForCirlePackingChart = (visualData) => {
    const visual = JSON.parse(JSON.stringify(visualData));
    const { spec, data } = visual;
    const dimensions = spec.dimensions.map((dim) => dim.column);
    const metrics = spec.metrics.map((metric) => metric.column);
    const nestedData = {};
    if(dimensions.length===1){
      dimensions.unshift('Root')
      data['Root']=[]
      data[dimensions[1]].forEach(() => {
        data['Root'].push('root')
      })
    }
    data[dimensions[0]].forEach((dim1Value, index) => {
        const dim2Value = data[dimensions[1]][index];
        const key1 = `option.${dim1Value}`;
        const key2 = `${key1}.${dim2Value}`;
    
        if (!nestedData[key1]) {
          nestedData[key1] = { value: 0, children: {} };
        }
    
        if (!nestedData[key1].children[key2]) {
          nestedData[key1].children[key2] = { value: 0, children: {} };
        }
        const metricValue = parseInt(data[metrics[0]][index], 10);
        nestedData[key1].value += metricValue;
        nestedData[key1].children[key2].value += metricValue;
    });
    const convertToSeriesData = (data, currentDepth = 0, currentIndex = 0) => {
        const seriesData = [];
        const traverse = (currentData, depth, index) => {
          for (const [key, value] of Object.entries(currentData)) {
            const id=key
            seriesData.push({ id, depth, index, value: value.value });
            if (value.children) {
              traverse(value.children, depth + 1, index + 1);
            }
            index++;
          }
        };
      
        traverse(data, currentDepth, currentIndex);
        return seriesData;
    };
    const seriesData = [
        {
          id: 'option',
          depth: 0,
          index: 0,
        },
        ...convertToSeriesData(nestedData, 1, dimensions.length),
    ];
    const displayRoot = stratify();
    function stratify() {
      return d3
        .stratify()
        .parentId(function (d) {
          return d.id.substring(0, d.id.lastIndexOf('.'));
        })(seriesData)
        .sum(function (d) {
          return d.value || 0;
        })
        .sort(function (a, b) {
          return b.value - a.value;
        });
    }
    function overallLayout(params, api) {
      var context = params.context;
      d3
        .pack()
        .size([api.getWidth() - 2, api.getHeight() - 2])
        .padding(3)(displayRoot);
      context.nodes = {};
      displayRoot.descendants().forEach(function (node, index) {
        context.nodes[node.id] = node;
      });
    }
    function renderItem(params, api) {
        var context = params.context;
        // Only do that layout once in each time `setOption` called.
        if (!context.layout) {
          context.layout = true;
          overallLayout(params, api);
        }
        var nodePath = api.value('id');
        var node = context.nodes[nodePath];
        if (!node) {
          // Reder nothing.
          return;
        }
        var isLeaf = !node.children || !node.children.length;
        var focus = new Uint32Array(
          node.descendants().map(function (node) {
            return node.data.index;
          })
        );
        var nodeName = isLeaf
          ? nodePath
              .slice(nodePath.lastIndexOf('.') + 1)
              .split(/(?=[A-Z][^A-Z])/g)
              .join('\n')
          : '';
        var z2 = api.value('depth') * 2;
        return {
          type: 'circle',
          focus: focus,
          shape: {
            cx: node.x,
            cy: node.y,
            r: node.r
          },
          transition: ['shape'],
          z2: z2,
          textContent: {
            type: 'text',
            style: {
              // transition: isLeaf ? 'fontSize' : null,
              text: nodeName,
              fontFamily: 'Arial',
              width: node.r * 1.3,
              overflow: 'truncate',
              fontSize: node.r / 3
            },
            emphasis: {
              style: {
                overflow: null,
                fontSize: Math.max(node.r / 3, 12)
              }
            }
          },
          textConfig: {
            position: 'inside'
          },
          style: {
            fill: api.visual('color')
          },
          emphasis: {
            style: {
              fontFamily: 'Arial',
              fontSize: 12,
              shadowBlur: 20,
              shadowOffsetX: 3,
              shadowOffsetY: 5,
              shadowColor: 'rgba(0,0,0,0.3)'
            }
          }
        };
    }
    const maxDepth=3
    return {seriesData,maxDepth,renderItem,stratify};
};

// 1D Vcard --> Circlepacking :
// 根据你的描述我们这里其实可以加一个特殊处理，
// 就是当1d时，实际上它默认包含了一个隐藏初始的dimension，比如叫做root-dimension，这是对所有1d的数据都适用的，就是说前端要做一个简单的数据转换变成circlepacking可接受的然后再画图 - 这个逻辑其实应该是做chart的工具本身支持，但是如果它不支持我们就来自己处理下，比如：
// Name: [Zhang, Li, Wang]
// Score: [90, 80, 70]
// 先转换成
// RootDimension: [Root, Root, Root]
// Name: [Zhang, Li, Wang]
// Score: [90, 80, 70]
const CirlePackingChart = ({visualData,rd}) => {
    const {seriesData,maxDepth,renderItem,stratify}=processDataForCirlePackingChart(visualData)
    const themes=rd?.style?.theme?rd?.style?.theme:''
    const chartRef = useRef(null);
    const events = {
        click: (params) => {
            handleDrillDown(params.data.id);
        }
    }
    const handleDrillDown = (targetNodeId) => {
        const myChart = chartRef.current.getEchartsInstance();
        var displayRoot = stratify();
        if (targetNodeId != null) {
          displayRoot = displayRoot.descendants().find(function (node) {
            return node.data.id === targetNodeId;
          });
        }
        displayRoot.parent = null;
        console.log({ targetNodeId, seriesData, displayRoot });
        myChart.setOption({
            dataset: {
              source: seriesData
            }
          });
      };
   
      const getTheme = (theme) => {
        switch (theme) {
          case 'tech':
            return echartThemeTech.color;
          case 'dark':
            return echartThemeDark.color;
          case 'normal':
          default:
            return echartTheme.color;
        }
      };
    const option = {
        dataset: {
            source: seriesData
          },
          tooltip: {
            formatter: function (value) {
                return value.data.id +":"+value.data.value;
            }
          },
          visualMap: [
            {
              show: false,
              min: 0,
              max: maxDepth,
              dimension: 'depth',
              inRange: {
                color: getTheme(themes)
              }
            }
          ],
          hoverLayerThreshold: Infinity,
          series: {
            type: 'custom',
            renderItem: renderItem,
            progressive: 0,
            coordinateSystem: 'none',
            encode: {
              tooltip: 'value',
              itemName: 'id'
            }
          }
    };
    
    return (
        <Chart option={option}  onDrillDown={handleDrillDown} chartRef={chartRef} events={events} rd={rd}/>
    );
};

export { CirlePackingChart };
