import { useState } from "react"
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { fetchLogin } from "../../store/slices/authSlice";
import AuthCarousel from "../../components/AuthCarousel"
import Input from "../../components/Input"
import Button from "../../components/Buton"
import Loader from '../../components/Loader'
import store from '../../store/index'

function SignIn() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const userState = useSelector((state) => state.auth.userState)

  const signIn = async () => {
    dispatch(fetchLogin({ email, password }))
      .then(() => {
        const userSt = store.getState().auth.userState
        if (userSt.isError) {
          toast.error(userSt.errorMessage)
        } else if (userSt.status === 'FAILED SIGNIN') {
          userSt.errorMessage.map((msg) => toast.warn(msg))
        } else if (userSt.status === 'OK' && userSt.isLoggedIn) {
          toast.success('Logged in successfully!')
          navigate('/smartdoc')
        }
      })
      .catch(e => console.log(e))
  }

  return (
    <div className="flex bg-white min-h-screen overflow-y-auto h-fit">
      <div className="w-full sm:w-[55%] pt-8 px-8 pb-4 relative flex justify-center">
        <section className={`w-full md:w-[400px]`}>
          <img src="/logo-title.png" className="sm:w-52 w-36" alt="" />
          <div className="font-bold sm:text-4xl text-2xl sm:mt-8 mt-6 relative">
            Sign in.
          </div>
          <p className="text-grey-10 mt-2">Enter your account details below.</p>
          <div className="py-3">
            <Input
              className="my-3"
              value={email}
              setValue={setEmail}
              placeholder='name@mail.com'
              label='Email'
              type='text'
            />
            <Input
              className="my-3"
              value={password}
              setValue={setPassword}
              placeholder='at least 12 characters'
              label='Password'
              type='password'
            />
          </div>

          <div>
            <Button className='w-full mt-4 text-white bg-primary' onClick={() => signIn()}>
              {userState.isLoading ? <Loader className="w-[6px] h-[6px] text-[5px]" /> : 'SIGN IN'}
            </Button>
            <div className="flex w-full items-center text-[#BDBDBD] gap-4 text-sm font-[500] my-6">
              <div className="bg-[#BDBDBD] h-[1px] w-[45%]"></div>
              OR
              <div className="bg-[#BDBDBD] h-[1px] w-[45%]"></div>
            </div>
            <Button
              className='w-full mt-4 bg-white border border-[#BDBDBD] font-[600] text-[#00000061]'>
              <img src="/imgs/google.png" className="w-5 h-5 mr-2" alt="" />SIGN IN WITH GOOGLE
            </Button>
          </div>

          <div className="text-[#989898] text-sm font-[500] mt-4 text-center">
            Already have an account? <button className="text-primary font-bold" onClick={() => navigate('/signup')}>Sign up</button>
          </div>
          <div className="text-center mt-4 text-sm">
            <button className="text-primary font-bold" onClick={() => navigate('/reset')}>Forgot Password?</button>
          </div>
        </section>
      </div>

      <div className="hidden sm:block sm:w-[45%]">
        <AuthCarousel />
      </div>
    </div>
  )
}

export default SignIn