import React from 'react';
import Chart from '../Chart';
import "echarts-wordcloud";
import echartThemeTech from "../themes/echartThemeTech.json";
import echartTheme from "../themes/echartTheme.json";
import echartThemeDark from "../themes/echartThemeDark.json";

const processDataForTextCloudChart = (visualData,themes) => {
    const getTheme = (theme) => {
        switch (theme) {
          case 'tech':
            return echartThemeTech.color;
          case 'dark':
            return echartThemeDark.color;
          case 'normal':
          default:
            return echartTheme.color;
        }
      };
    const visual=visualData
    const data = visual.data;
    let dimensions = ''
    let metrics = ''
    let result_dict={}
    if (visual.spec.metrics.length <= 0) {
        dimensions = Object.keys(data)[0]
        metrics = 'values'
        result_dict = data[dimensions].reduce((obj, name) => {
            if (name in obj) {
                 obj[name]++
            } else {
                obj[name] = 1
             }
                return obj
            }, {})
        } else {
            dimensions = visual.spec.dimensions[0].column;
            metrics = visual.spec.metrics[0].column;
            for (let i in data[dimensions]) {
                result_dict[data[dimensions][i]] = data[metrics][i];
            }
        }
        const colors = getTheme(themes)
        // 格式化云点图数据
        const tempData = Object.keys(result_dict).map(function (
            key,index
        ) {
            let symbolSize = result_dict[key];
            if (symbolSize >= 80) {
                symbolSize = 80;
            }
            if (symbolSize <= 20) {
                symbolSize = 20;
            }
            return {
                name: key,
                value: result_dict[key],
                symbolSize: symbolSize,
                dimensionName: dimensions,
                metricsName: metrics,
                textStyle: {
                    color:colors[index]
                       
                },
            };
        });
    return {tempData};
};

const TextCloudChart = ({visualData,rd}) => {
    const themes=rd?.style?.theme?rd?.style?.theme:''

    const {tempData}=processDataForTextCloudChart(visualData,themes)
   
    const option = {
        tooltip: {
            formatter: (params) => {
                return `${params.data.dimensionName}：${params.data.name}<br />${params.data.metricsName}：${params.data.value}`;
            },
        },
        series: [
            {
                type: "wordCloud",
                gridSize: 20,
                sizeRange: [12, 50],
                rotationRange: [0, 0],
                shape: "circle",
                autoSize: {
                    enable: true,
                    minSize: 18,
                },
                data: tempData,
            },
        ],
    };
    return (
        <Chart option={option} rd={rd}/>
    );
};

export { TextCloudChart };
