import React from 'react';
import Chart from '../Chart';

const processDataForFunnelChart = (visualData) => {
    const visual=visualData
    const data = visual.data;
    const dimensions = visual.spec.dimensions[0].column;
    const metrics = visual.spec.metrics[0].column;
    let tempList = [];
    let max = 0;
    for (let i = 0; i < data[dimensions].length; i++) {
        const value = data[metrics][i];
        if (Number(value) > max) {
            max = Number(value);
        }
        tempList.push({
            name: data[dimensions][i],
            value: value,
        });
    }
    const dataDimension=data[dimensions]
  return {dataDimension,max,tempList};
};

const FunnelChart = ({visualData,rd}) => {
  const {dataDimension,max,tempList}=processDataForFunnelChart(visualData)
  const option = {
    tooltip: {},
    legend: {
        data: dataDimension,
    },
    series: [
        {
            type: "funnel",
            left: "10%",
            top: 25,
            bottom: 0,
            width: "80%",
            min: 0,
            max: max,
            minSize: "0%",
            maxSize: "100%",
            sort: "descending",
            gap: 2,
            label: {
                show: true,
                position: "inside",
            },
            labelLine: {
                length: 10,
                lineStyle: {
                    width: 1,
                    type: "solid",
                },
            },
            itemStyle: {
                borderColor: "#fff",
                borderWidth: 1,
            },
            emphasis: {
                label: {
                    fontSize: 20,
                },
            },
            data: tempList,
        },
    ],
  };

  return (
    <Chart option={option} rd={rd}/>
  );
};

export { FunnelChart };
