export const MindmapIcon = ({ color, width, height }) => (
    <svg fill={color ? color : 'black'} width={`${width ? width : '24'}`} height={`${height ? height : '24'}`} version="1.1" id="Capa_1"
        viewBox="0 0 341.594 341.594">
        <path d="M311.049,164.758c-9.275,0-17.481,4.644-22.432,11.724l-20.171-3.495c0.025-0.758,0.058-1.515,0.058-2.279
	c0-8.954-1.776-17.498-4.973-25.316l34.163-20.612c4.597,3.501,10.327,5.584,16.538,5.584c15.087,0,27.361-12.274,27.361-27.361
	c0-15.086-12.274-27.359-27.361-27.359c-15.086,0-27.359,12.273-27.359,27.359c0,1.603,0.146,3.171,0.412,4.699l-33.993,20.509
	c-10.314-12.574-25.097-21.345-41.899-23.867V62.373c12.537-4.194,21.604-16.04,21.604-29.971c0-17.427-14.178-31.606-31.604-31.606
	c-17.425,0-31.602,14.178-31.602,31.606c0,13.931,9.064,25.776,21.602,29.971v41.971c-22.896,3.436-42.029,18.486-51.227,38.937
	l-62.597-19.227c0.188-1.547,0.296-3.119,0.296-4.716c0-21.468-17.464-38.934-38.931-38.934C17.465,80.404,0,97.87,0,119.338
	c0,21.467,17.465,38.932,38.935,38.932c12.72,0,24.032-6.133,31.141-15.595l64.72,19.879c-0.325,2.675-0.513,5.392-0.513,8.153
	c0,18,7.138,34.355,18.714,46.419l-28.195,30.008c-4.115-1.837-8.668-2.867-13.459-2.867c-18.279,0-33.148,14.87-33.148,33.148
	c0,18.276,14.869,33.145,33.148,33.145c18.276,0,33.146-14.869,33.146-33.145c0-6.216-1.723-12.035-4.711-17.012l29.128-31.002
	c9.633,5.353,20.707,8.414,32.488,8.414c11.254,0,21.863-2.798,31.19-7.715l29.064,44.193c-7.081,7.054-11.471,16.808-11.471,27.568
	c0,21.468,17.466,38.934,38.934,38.934s38.934-17.465,38.934-38.934c0-21.467-17.466-38.932-38.934-38.932
	c-3.498,0-6.888,0.471-10.115,1.341l-30.275-46.033c7.155-7.124,12.702-15.853,16.08-25.587l19.168,3.321
	c1.879,13.267,13.305,23.505,27.082,23.505c15.087,0,27.361-12.274,27.361-27.361C338.41,177.031,326.136,164.758,311.049,164.758z
	 M154.282,170.707c0-25.977,21.134-47.111,47.11-47.111c25.978,0,47.111,21.134,47.111,47.111c0,25.976-21.134,47.109-47.111,47.109
	C175.416,217.816,154.282,196.683,154.282,170.707z"/>
    </svg>
)