import React from 'react';
import Chart from '../Chart';
const processDataForDonutChart = (data) => {
    const visuals=data
    const { dimensions, metrics } = visuals.spec;
    const seriesData = visuals.data[metrics[0].column];
    const seriesDataMap=seriesData.map((value, index) => ({
        value,
        name: visuals.data[dimensions[0].column][index],
    }))
    return {
        seriesDataMap
    }
};
const DonutChart = ({visualData,rd}) => {
  const {seriesDataMap}=processDataForDonutChart(visualData)
  const option = {
    tooltip: {
        trigger: "item",
    },
    // legend: {
    //     // orient: "horizontal", 
    // },
    series: [
        {
            type: "pie",
            radius: ["40%", "70%"],
            top: 0,
            emphasis: {
                label: {
                    show: true,
                    fontSize: '16',
                }
            },
            data:seriesDataMap,
        },
    ],
  };
  return (
    <Chart option={option} rd={rd}/>
  );
};

export { DonutChart };
